import { Directive } from '@angular/core';

@Directive({
    selector: '[hopListItemLabelRight]',
    host: {
        class: 'c-hop-list-item__label-right'
    }
})
export class HopListItemLabelRightDirective {

}
