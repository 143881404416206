/**
 * A user's role.
 *
 * The role is not used for permission-like behavior, but acts as a user-group
 * Each role / user group will have a specific set of {@linkplain Permission permissions}
 */
export enum UserRole {

    /**
     * Administrator
     */
    ADMIN = 'admin',

    /**
     * Verkäufer
     */
    SALESPERSON = 'salesperson',

    /**
     * Kunde
     */
    CUSTOMER = 'customer'
}
