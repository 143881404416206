<div class="c-hop-env-debitor-picker__container" *ngIf="activeDebitor; else emptyTemplate">

    <a hopEnvDebitorSwitcherTab
       *ngIf="activeDebitor"
       [title]="activeDebitor.fullName"
       [subtitle]="activeDebitor.navisionId"
       [active]="true"
       [routerLink]="[ '/debitors', activeDebitor.navisionId ]"
       [hopHighlightRecord]="activeDebitor.headquarters"
       [highlightLabel]="'ENVIRONMENT.DEBITOR_SWITCHER.HIGHLIGHTED.HEADQUARTER' | translate"
       highlightColor="var(--bs-primary)">
    </a>

    <div class="c-hop-env-debitor-picker__actions">
        <div [ngbTooltip]="disabled ? ('ENVIRONMENT.DEBITOR_SWITCHER.FEEDBACK.NOT_ALLOWED' | translate) : ''">
            <button class="btn btn-light"
                    [disabled]="disabled"
                    (click)="openDebitorDrawer()">
                <fa-icon [icon]="_searchIcon"></fa-icon>
                {{ 'ENVIRONMENT.DEBITOR_SWITCHER.PICKER.ACTION.CHANGE_SELECTION' | translate }}
            </button>
        </div>

        <div *hasPermission="UserPermission.DESELECT_ACTIVE_DEBITOR"
             [ngbTooltip]="disabled ? ('ENVIRONMENT.DEBITOR_SWITCHER.FEEDBACK.NOT_ALLOWED' | translate) : ''">
            <button class="btn btn-light"
                    [disabled]="disabled"
                    (click)="clearActiveDebitor()">
                <fa-icon [icon]="_closeIcon"></fa-icon>
                {{ 'ENVIRONMENT.DEBITOR_SWITCHER.PICKER.ACTION.REMOVE_SELECTION' | translate }}
            </button>
        </div>
    </div>

</div>

<ng-template #emptyTemplate>
    <button hopEnvDebitorSwitcherTab
            class="c-hop-env-debitor-picker__empty-button"
            [title]="'ENVIRONMENT.DEBITOR_SWITCHER.PICKER.EMPTY_BUTTON.TITLE' | translate"
            [subtitle]="'ENVIRONMENT.DEBITOR_SWITCHER.PICKER.EMPTY_BUTTON.SUBTITLE' | translate"
            [ngbTooltip]="disabled ? ('ENVIRONMENT.DEBITOR_SWITCHER.FEEDBACK.NOT_ALLOWED' | translate) : ''"
            [active]="true"
            [disabled]="disabled"
            (click)="openDebitorDrawer()">
        <fa-icon [icon]="_searchIcon"></fa-icon>
    </button>
</ng-template>
