import { ListRange } from '@angular/cdk/collections';
import { ChangeDetectorRef, Directive, Inject, Input, OnDestroy, Optional } from '@angular/core';
import { BehaviorSubject, NEVER, Observable, Subject, Subscription, switchAll } from 'rxjs';

import { ICollectionViewer } from '../models/collection-viewer.interface';
import { Connection } from '../models/connection.class';
import { DataSource } from '../models/data-source.class';
import { IPaginatedData } from '../models/paginated-data.interface';
import { INITIAL_LIST_RANGE } from '../tokens/initial-list-range.token';
import { INITIAL_QUERY } from '../tokens/initial-query.token';
import { ArrayDataSource } from '../utils/array.data-source';

@Directive({
    selector: '[hopCollectionViewer]',
    exportAs: 'hopCollectionViewer'
})
export class HopCollectionViewerDirective<T, QUERY = unknown> implements ICollectionViewer<T, QUERY>, OnDestroy {

    readonly viewChange: BehaviorSubject<ListRange>;
    readonly queryChange: BehaviorSubject<QUERY | null>;
    readonly total$: Observable<number>;

    private _dataSource?: DataSource<T, QUERY>;
    private _connection?: Connection<T, QUERY>;
    private readonly _totalSubject = new BehaviorSubject<Observable<number>>(NEVER);

    private _data: IPaginatedData<T> | null = null;
    private _isLoading: boolean = false;
    private _connectionSubscription: Subscription | null = null;
    protected readonly _onDestroy = new Subject<void>();

    constructor(@Optional() @Inject(INITIAL_LIST_RANGE) initialListRange: ListRange | undefined,
                @Optional() @Inject(INITIAL_QUERY) initialQuery: QUERY | undefined,
                private readonly _changeDetectorRef: ChangeDetectorRef) {
        this.viewChange = new BehaviorSubject<ListRange>(initialListRange ?? { start: 0, end: 10 });
        this.queryChange = new BehaviorSubject<QUERY | null>(initialQuery ?? null);
        this.total$ = this._totalSubject.pipe(switchAll());
    }

    @Input()
    get query(): QUERY | null {
        return this.queryChange.getValue();
    }

    set query(value: QUERY | null) {
        this.queryChange.next(value);
    }

    @Input()
    get dataSource(): DataSource<T, QUERY> | T[] | null | undefined {
        return this._dataSource;
    }

    set dataSource(value: DataSource<T, QUERY> | T[] | null | undefined) {
        this._switchDataSource(Array.isArray(value) ? new ArrayDataSource(value) : value);
    }

    get connection(): Connection<T, QUERY> {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this._connection!;
    }

    get data$(): Observable<T[]> {
        return this.connection?.data$;
    }

    get isLoading() {
        return this._isLoading;
    }

    get data(): T[] | undefined {
        return this._data?.data;
    }

    get total(): number | undefined {
        return this._data?.total;
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    protected _switchDataSource(dataSource: DataSource<T, QUERY> | null | undefined) {
        if (dataSource === this.dataSource) {
            return;
        }

        if (this._connection != null) {
            this._connection.disconnect();
        }

        if (dataSource == null) {
            return;
        }

        this._dataSource = dataSource;
        this._connection = this._dataSource.connect(this);
        this._totalSubject.next(this._connection.total$);

        this._connectionSubscription = this._connection
            .subscribe({
                loading: (loading) => this._setLoading(loading),
                next: (paginated) => this._setNext(paginated)
            });
    }

    protected _setLoading(loading: boolean) {
        this._isLoading = loading;
        this._changeDetectorRef.markForCheck();
    }

    protected _setNext(data: IPaginatedData<T>) {
        this._data = data;
        this._setLoading(false);
    }

}
