<ul #debitorList="hopDataCollectionViewer"
    hopDataCollectionViewer
    hopScrollContainer>
    <ng-content></ng-content>
    <li *ngFor="let debitor of debitorList.data">
        <hop-env-debitor-card [debitor]="debitor"
                              [hopHighlightRecord]="debitor.headquarters"
                              [highlightLabel]="'ENVIRONMENT.DEBITOR_SWITCHER.HIGHLIGHTED.HEADQUARTER' | translate"
                              highlightColor="var(--bs-primary)"
                              (click)="selectDebitor.emit(debitor)"></hop-env-debitor-card>
    </li>
</ul>

<hop-loading-indicator [loading]="debitorList.isLoading" appearance="edge"></hop-loading-indicator>
