import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
    selector: '[hopLceCustomState]'
})
export class HopLceCustomStateDirective {

    @Input('hopLceCustomState')
    state!: string;

    constructor(readonly templateRef: TemplateRef<unknown>) {

    }

}
