import { Inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { ActiveDebitorRepository } from '../infrastructure/active-debitor.repository';
import { EnvironmentDebitorRepository } from '../infrastructure/debitor.repository';
import { IActiveDebitor } from '../models/active-debitor.interface';
import { DebitorSwitcherMode } from '../models/debitor-switcher-mode.enum';
import { DEBITOR_SWITCHER_TAB_LIST_MAX_COUNT } from '../tokens/debitor-switcher-tab-list-max-count.token';

import { DebitorSwitcherActions } from './actions/debitor-switcher.actions';

interface IDebitorSwitcherStateModel {
    activeDebitor: IActiveDebitor | null;
    recentActiveDebitors: IActiveDebitor[];
    isShown: boolean;
    isDisabled: boolean;
    mode?: DebitorSwitcherMode;
}

@State<IDebitorSwitcherStateModel>({
    name: 'debitorSwitcher',
    defaults: {
        activeDebitor: null,
        recentActiveDebitors: [],
        isShown: false,
        isDisabled: false
    }
})
@Injectable()
export class DebitorSwitcherState {

    @Selector()
    static selectIsShown(state: IDebitorSwitcherStateModel) {
        return state.isShown;
    }

    @Selector()
    static selectIsDisabled(state: IDebitorSwitcherStateModel) {
        return state.isDisabled;
    }

    @Selector()
    static selectMode(state: IDebitorSwitcherStateModel) {
        return state.mode;
    }

    @Selector()
    static selectActiveDebitor(state: IDebitorSwitcherStateModel) {
        return state.activeDebitor;
    }

    @Selector()
    static selectRecentActiveDebitors(state: IDebitorSwitcherStateModel) {
        return state.recentActiveDebitors;
    }

    constructor(@Inject(DEBITOR_SWITCHER_TAB_LIST_MAX_COUNT) private readonly _maxTabListCount: number,
                private readonly _debitorsRepository: EnvironmentDebitorRepository,
                private readonly _activeDebitorsRepository: ActiveDebitorRepository) {

    }

    @Action(DebitorSwitcherActions.InitDebitorSwitcher)
    async initDebitorSwitcher(ctx: StateContext<IDebitorSwitcherStateModel>) {
        const activeDebitor = await this._activeDebitorsRepository.getActiveDebitor().catch(() => null);
        const debitorCount = await this._debitorsRepository.count();

        ctx?.patchState({
            activeDebitor,
            mode: debitorCount > this._maxTabListCount ? DebitorSwitcherMode.PICKER : DebitorSwitcherMode.TABS
        });
    }

    @Action(DebitorSwitcherActions.Show)
    showDebitorSwitcher(ctx: StateContext<IDebitorSwitcherStateModel>, action: DebitorSwitcherActions.Show) {
        ctx.patchState({
            isShown: action.show
        });
    }

    @Action(DebitorSwitcherActions.Disable)
    disableDebitorSwitcher(ctx: StateContext<IDebitorSwitcherStateModel>) {
        ctx.patchState({
            isDisabled: true
        });
    }

    @Action(DebitorSwitcherActions.Enable)
    enableDebitorSwitcher(ctx: StateContext<IDebitorSwitcherStateModel>) {
        ctx.patchState({
            isDisabled: false
        });
    }

    @Action(DebitorSwitcherActions.SetMode)
    setDebitorSwitcherMode(ctx: StateContext<IDebitorSwitcherStateModel>, action: DebitorSwitcherActions.SetMode) {
        ctx.patchState({
            mode: action.mode
        });
    }

    @Action(DebitorSwitcherActions.SetActiveDebitor)
    async setActiveDebitor(ctx: StateContext<IDebitorSwitcherStateModel>, action: DebitorSwitcherActions.SetActiveDebitor) {
        const recentActiveDebitors = await this._activeDebitorsRepository.setActiveDebitor(action.debitor.navisionId);

        ctx.patchState({
            activeDebitor: action.debitor
        });

        return ctx.dispatch(new DebitorSwitcherActions.SetRecentActiveDebitors(recentActiveDebitors));
    }

    @Action(DebitorSwitcherActions.SetActiveDebitorById)
    async SetActiveDebitorById(ctx: StateContext<IDebitorSwitcherStateModel>, action: DebitorSwitcherActions.SetActiveDebitorById) {
        const recentActiveDebitors = await this._activeDebitorsRepository.setActiveDebitor(action.debitorId);

        ctx.patchState({
            activeDebitor: recentActiveDebitors[0]
        });

        return ctx.dispatch(new DebitorSwitcherActions.SetRecentActiveDebitors(recentActiveDebitors));
    }

    @Action(DebitorSwitcherActions.ClearActiveDebitor)
    async clearActiveDebitor(ctx: StateContext<IDebitorSwitcherStateModel>) {
        await this._activeDebitorsRepository.removeActiveDebitor();

        ctx.patchState({
            activeDebitor: null
        });
    }

    @Action(DebitorSwitcherActions.SetRecentActiveDebitors)
    setRecentActiveDebitors(ctx: StateContext<IDebitorSwitcherStateModel>, action: DebitorSwitcherActions.SetRecentActiveDebitors) {
        ctx.patchState({
            recentActiveDebitors: action.debitors
        });
    }
}
