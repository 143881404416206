import { Injectable } from '@angular/core';
import { Action, createSelector, State, StateContext } from '@ngxs/store';

import { IAccountPreferences } from '../entities/preferences/account-preferences.interface';
import { PreferenceGroupColumn } from '../entities/preferences/preference-group-column';
import { PreferenceGroup } from '../entities/preferences/preference-group.enum';
import { PreferencesService } from '../infrastructure/preferences.service';
import { sanitizePreferences } from '../utils/preference.utils';

import { PreferenceActions } from './actions/preference.actions';

@State<IAccountPreferences>({
    name: 'preferences',
    defaults: {
        [PreferenceGroup.CONTRACT]: {
            browsePage: {
                columns: []
            }
        },
        [PreferenceGroup.DEBITOR]: {
            browsePage: {
                columns: []
            }
        },
        [PreferenceGroup.ORDER_REQUEST]: {
            browsePage: {
                columns: []
            }
        },
        [PreferenceGroup.USER]: {
            browsePage: {
                columns: []
            }
        }
    }
})
@Injectable()
export class PreferencesState {

    static selectGroup<GROUP extends PreferenceGroup>(group: GROUP) {
        return createSelector([ PreferencesState ], (preferences: IAccountPreferences) => {
            return preferences[group];
        });
    }

    static selectBrowsePageColumns<GROUP extends PreferenceGroup>(group: GROUP) {
        return createSelector([ PreferencesState.selectGroup<GROUP>(group) ], (preferences: IAccountPreferences[GROUP]) => {
            return preferences.browsePage?.columns ?? [];
        }) as () => PreferenceGroupColumn<GROUP>[];
    }

    constructor(private readonly _preferencesService: PreferencesService) {
    }

    @Action(PreferenceActions.LoadPreferences)
    async loadPreferences(ctx: StateContext<IAccountPreferences>) {
        const preferences = await this._preferencesService.getAllPreferences();

        ctx.setState(preferences);
    }

    @Action(PreferenceActions.SetBrowsePageColumns)
    async setDisplayedColumns<GROUP extends PreferenceGroup>(ctx: StateContext<IAccountPreferences>, action: PreferenceActions.SetBrowsePageColumns<GROUP>) {
        const preferences = sanitizePreferences(action.group, { browsePage: { columns: action.columns } } as Partial<IAccountPreferences[GROUP]>);

        await this._preferencesService.patchGroupPreferences(action.group, preferences);

        ctx.patchState({
            [action.group]: preferences
        });
    }

}
