import { Injectable } from '@angular/core';

import { InvoiceRepository } from '../../infrastructure/invoice.repository';

@Injectable({
    providedIn: 'root'
})
export class HopInvoiceFacade {

    constructor(private readonly _invoiceRepository: InvoiceRepository) {
    }

    async downloadInvoice(invoiceId: string) {
        return this._invoiceRepository.export(invoiceId);
    }
}
