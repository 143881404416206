import { Pipe, PipeTransform } from '@angular/core';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Pipe({
    name: 'isWithinRange'
})
export class HopIsWithinRangePipe implements PipeTransform {

    transform(date: NgbDate, startDate: NgbDateStruct | null, endDate: NgbDateStruct | null): boolean {
        return startDate != null && endDate != null && date.after(startDate) && date.before(endDate);
    }
}
