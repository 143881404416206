<swiper [slidesPerView]="1"
        [navigation]="{ nextEl: nextButton.nativeElement, prevEl: prevButton.nativeElement }"
        [pagination]="{ type: 'fraction', el: pagination }">
    <ng-template swiperSlide *ngFor="let slide of slides">
        <ng-container [ngTemplateOutlet]="slide.templateRef"></ng-container>
    </ng-template>
</swiper>

<div class="c-hop-carousel__controls">

    <button #prevButton hopIconButton class="c-hop-carousel__navigation-button">
        <fa-icon [icon]="_icons.faChevronLeft"></fa-icon>
    </button>

    <div #pagination class="c-hop-carousel__pagination"></div>

    <button #nextButton hopIconButton class="c-hop-carousel__navigation-button">
        <fa-icon [icon]="_icons.faChevronRight"></fa-icon>
    </button>

</div>
