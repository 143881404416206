<hop-search-bar *ngIf="showSearchBar" [search]="_searchTerm$ | async" (searchChange)="onSearchTermChanged($event)"></hop-search-bar>
<hop-filter-button *ngIf="filters"
                   [filters]="filters"
                   [result]="_filterResult$ | async"
                   (resultChange)="onFiltersChanged($event)">
</hop-filter-button>

<div class="c-hop-overview-layout__actions">
    <div ngbDropdown *ngIf="_showCompactMenu$ | async; else actionsTemplate">
        <button hopIconButton="outline" ngbDropdownToggle>
            <fa-icon [icon]="_overflowIcon"></fa-icon>
        </button>
        <div ngbDropdownMenu>
            <ng-container *ngTemplateOutlet="dropdownItemsTemplate"></ng-container>
        </div>
    </div>
</div>

<div class="c-hop-overview-layout__collection-viewer" hopInfiniteScroll>
    <ng-container *ngTemplateOutlet="isMobile ? mobileTemplate : desktopTemplate"></ng-container>
</div>

<ng-template #actionsTemplate>
    <ng-container *ngIf="_showFullMenu$ | async">
        <ng-content></ng-content>
    </ng-container>
</ng-template>

<ng-template #desktopTemplate>
    <ng-content select="[hopDesktopCollectionViewer]"></ng-content>
</ng-template>

<ng-template #mobileTemplate>
    <ng-content select="[hopMobileCollectionViewer]"></ng-content>
</ng-template>

<ng-template #dropdownItemsTemplate>
    <ng-content select="[ngbDropdownItem]"></ng-content>
</ng-template>

