import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HopKeyValueTableModule, HopYesNoPipeModule } from '@hopsteiner/shared/components';
import { HopDrawerModule } from '@hopsteiner/shared/overlays';
import { TranslateModule } from '@ngx-translate/core';

import { HopShipmentDrawer } from './drawers/shipment/shipment.drawer';

@NgModule({
    imports: [
        CommonModule,

        TranslateModule,

        HopDrawerModule,
        HopKeyValueTableModule,
        HopYesNoPipeModule
    ],
    declarations: [
        HopShipmentDrawer
    ]
})
export class HopShipmentDrawerModule {
}
