import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseRepository, Endpoint } from '@hopsteiner/shared/data-access';
import { firstValueFrom } from 'rxjs';

import { IInvoice } from '../entities/invoice.interface';

@Injectable({
    providedIn: 'root'
})
export class InvoiceRepository extends BaseRepository<IInvoice> {

    constructor(httpClient: HttpClient) {
        super(httpClient, {
            endpoint: Endpoint.INVOICES
        });
    }

    async export(invoiceId: string) {
        return firstValueFrom(this._httpClient.get(`${this.url}/${invoiceId}/export`, {
            responseType: 'blob'
        }));
    }
}
