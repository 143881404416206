import { ISort } from '@hopsteiner/shared/collections';
import { FilterResult, IFilterDrawerResult } from '@hopsteiner/shared/filter';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace OverviewLayoutActions {

    class BaseAction {
        constructor(readonly featureName: string) {
        }
    }

    export class SetSearchTerm extends BaseAction {

        static readonly type = '[Overview Layout] Set search term';

        constructor(featureName: string, readonly searchTerm: string | undefined) {
            super(featureName);
        }
    }

    export class SetFilterResult extends BaseAction {

        static readonly type = '[Overview Layout] Set filter result';

        constructor(featureName: string, readonly filterResult: IFilterDrawerResult<FilterResult> | undefined) {
            super(featureName);
        }
    }

    export class SetSort extends BaseAction {

        static readonly type = '[Overview Layout] Set sort';

        constructor(featureName: string, readonly sort: ISort | undefined) {
            super(featureName);
        }
    }

    export class SetPage extends BaseAction {

        static readonly type = '[Overview Layout] Set page';

        constructor(featureName: string, readonly page: number) {
            super(featureName);
        }
    }

}
