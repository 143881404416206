import { Pipe, PipeTransform } from '@angular/core';
import { Language } from '@hopsteiner/shared/models';

@Pipe({
    name: 'hopLanguageFlag'
})
export class HopLanguageFlagPipe implements PipeTransform {

    transform(language: Language): string {
        switch (language) {
            case Language.EN:
                return 'gb';
            default:
                return language;
        }
    }

}
