import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SessionFacade } from '../application/facades/session.facade';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {

    static readonly SESSION_ID_HEADER = 'hopsteiner-authorization';

    constructor(private readonly _sessionFacade: SessionFacade) {

    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const sessionId = this._sessionFacade.sessionId;

        if (sessionId != null) {
            return next.handle(request.clone({
                setHeaders: {
                    [SessionInterceptor.SESSION_ID_HEADER]: sessionId
                }
            }));
        }

        return next.handle(request);
    }
}
