import { ChangeDetectionStrategy, Component, Optional, ViewEncapsulation } from '@angular/core';

import { HopHighlightRecordDirective } from '../../../shared/directives/highlight-record.directive';

@Component({
    selector: 'hop-data-card',
    templateUrl: './data-card.component.html',
    styleUrls: [ './data-card.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-data-card'
    }
})
export class HopDataCardComponent {

    constructor(@Optional() readonly highlight?: HopHighlightRecordDirective) {
    }
}
