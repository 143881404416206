import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';

import { ForgotPasswordActions } from '../actions/forgot-password.actions';
import { IResetPasswordCredentials } from '../models/reset-password-credentials.interface';

@Injectable({
    providedIn: 'root'
})
export class HopForgotPasswordFacade {

    constructor(private readonly _store: Store) {

    }

    passwordForgotten(email: string) {
        return this._store.dispatch(new ForgotPasswordActions.ForgotPassword(email));
    }

    resetPassword(data: IResetPasswordCredentials) {
        return firstValueFrom(this._store.dispatch(new ForgotPasswordActions.ResetForgottenPassword(data)));
    }

    checkResetPasswordErrors(hash: string) {
        return this._store.dispatch(new ForgotPasswordActions.CheckResetPasswordErrors(hash));
    }
}
