import { Injectable } from '@angular/core';
import { HopToast } from '@hopsteiner/shared/overlays';
import { Store } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';

import { UserRepository } from '../../infrastructure/user.repository';
import { UserActions } from '../actions/user.actions';

@Injectable({
    providedIn: 'root'
})
export class HopUserFacade {

    constructor(private readonly _store: Store,
                private readonly _userRepository: UserRepository,
                private readonly _toast: HopToast) {
    }

    getUser(id: string) {
        return this._userRepository.get(id);
    }

    async sendPasswordLink(userId: number) {
        const ref = this._toast.show({
            message: 'USER.DOMAIN.SEND_PASSWORD_LINK.MESSAGE',
            autoHide: false
        });

        try {
            await this._userRepository.sendPasswordLink(userId);

            this._toast.success('USER.DOMAIN.SEND_PASSWORD_LINK.FEEDBACK.SUCCESS');
        } catch (error) {
            this._toast.error('USER.DOMAIN.SEND_PASSWORD_LINK.FEEDBACK.ERROR');
        }

        ref?.close();
    }

    async activateUser(id: number) {
        const ref = this._toast.show({
            message: 'USER.DOMAIN.ACTIVATE_USER.MESSAGE',
            autoHide: false
        });

        try {
            await lastValueFrom(this._store.dispatch(new UserActions.ActivateUser(id)));

            this._toast.success('USER.DOMAIN.ACTIVATE_USER.FEEDBACK.SUCCESS');
        } catch (error) {
            this._toast.error('USER.DOMAIN.ACTIVATE_USER.FEEDBACK.ERROR');
        }

        ref?.close();
    }

    async deactivateUser(id: number) {
        const ref = this._toast.show({
            message: 'USER.DOMAIN.DEACTIVATE_USER.MESSAGE',
            autoHide: false
        });

        try {
            await lastValueFrom(this._store.dispatch(new UserActions.DeactivateUser(id)));

            this._toast.success('USER.DOMAIN.DEACTIVATE_USER.FEEDBACK.SUCCESS');
        } catch (error) {
            this._toast.error('USER.DOMAIN.DEACTIVATE_USER.FEEDBACK.ERROR');
        }

        ref?.close();
    }

}
