<div class="c-hop-detail-layout__toolbar">
    <button class="c-hop-detail-layout__back-button btn" (click)="returnToPreviousPage()">
        <fa-icon [icon]="_icon.faAngleLeft"></fa-icon>
        {{ 'LAYOUTS.DETAIL.ACTION.BACK' | translate }}
    </button>

    <div class="c-hop-detail-layout__button-group">
        <ng-content select="[hopDetailLayoutToolbarItem]"></ng-content>
    </div>

</div>

<div class="c-hop-detail-layout__main">
    <ng-content></ng-content>
</div>
