import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'hop-country-flag-with-iso-code',
    templateUrl: './country-flag-with-iso-code.component.html',
    styleUrls: [ './country-flag-with-iso-code.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-country-flag-with-iso-code'
    }
})
export class HopCountryFlagWithIsoCodeComponent {

    @Input()
    country?: {
        name: string;
        shortName: string;
    };

}
