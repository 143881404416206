import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseRepository, Endpoint } from '@hopsteiner/shared/data-access';
import { firstValueFrom } from 'rxjs';

import { ICreateShipmentRequestDto } from '../entities/create-shipment-request.dto';
import { IRequestShipmentData } from '../entities/request-shipment-data.interface';
import { IShipmentRequest } from '../entities/shipment-request.interface';

@Injectable({
    providedIn: 'root'
})
export class ShipmentRequestRepository extends BaseRepository<IShipmentRequest> {

    constructor(httpClient: HttpClient) {
        super(httpClient, {
            endpoint: Endpoint.CONTRACTS
        });
    }

    get(contractId: string): Promise<IShipmentRequest[]> {
        return firstValueFrom(this._httpClient.get<IShipmentRequest[]>(`${this.url}/${contractId}/shipment-requests`));
    }

    create(contractId: string, createShipmentRequestData: ICreateShipmentRequestDto): Promise<IShipmentRequest> {
        return firstValueFrom(this._httpClient.post<IShipmentRequest>(`${this.url}/${contractId}/shipment-requests`, createShipmentRequestData));
    }

    getRequestShipmentData(contractId: string): Promise<IRequestShipmentData> {
        return firstValueFrom(this._httpClient.get<IRequestShipmentData>(`${this.url}/${contractId}/shipment-requests-data`));
    }

}
