import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

import { IContactPerson } from '../../models/contact-person.interface';

@Component({
    selector: 'hop-contact-person',
    templateUrl: './contact-person.component.html',
    styleUrls: [ './contact-person.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-contact-person'
    }
})
export class HopContactPersonComponent {

    @Input()
    person?: IContactPerson;

}
