import { ISort } from '@hopsteiner/shared/collections';

export interface IUserExportOptions {

    /**
     * The query
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    where?: any;

    /**
     * The sorting
     */
    sort?: ISort;
}
