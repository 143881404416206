<div #viewer="hopCollectionViewer" [dataSource]="filter?.dataSource" hopCollectionViewer>

    <div [id]="key" class="form-check" *ngFor="let option of (viewer.data$ | async); let index = index">
        <input id="{{ key }}-option-{{ index }}"
               class="form-check-input"
               type="radio"
               [name]="key"
               [value]="getOptionValue(option)"
               [formControl]="formControl">
        <label class="form-check-label" for="{{ key }}-option-{{ index }}">
            <ng-container
                *ngTemplateOutlet="optionTemplate || optionDirective?.templateRef || defaultOptionTemplate; context: { $implicit: option }">
            </ng-container>
        </label>
    </div>

</div>

<div class="c-hop-single-select-filter__loading-indicator" *ngIf="viewer.isLoading">
    <div class="spinner-border spinner-border-sm" role="status"></div>
</div>

<ng-template #defaultOptionTemplate let-option>
    {{ option }}
</ng-template>
