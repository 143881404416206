import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HopButtonModule, HopPaginatorModule, HopSearchBarModule } from '@hopsteiner/shared/components';
import { HopFilterModule } from '@hopsteiner/shared/filter';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxsModule } from '@ngxs/store';

import { HopDataCollectionContainerModule } from '../data-collection-container/data-collection-container.module';
import { HopInfiniteScrollDirectiveModule } from '../data-collection-container/directives/infinite-scroll.directive';

import { HopOverviewLayout } from './layouts/overview/overview.layout';
import { OverviewLayoutState } from './state/overview-layout.state';


@NgModule({
    imports: [
        CommonModule,

        FontAwesomeModule,
        NgbDropdownModule,
        NgxsModule.forFeature([ OverviewLayoutState ]),

        HopButtonModule,
        HopFilterModule,
        HopPaginatorModule,
        HopSearchBarModule,
        HopDataCollectionContainerModule,
        HopInfiniteScrollDirectiveModule
    ],
    declarations: [
        HopOverviewLayout
    ],
    exports: [
        HopOverviewLayout,
        HopDataCollectionContainerModule
    ]
})
export class HopOverviewLayoutModule {

}
