import { uniqueId as _uniqueId } from 'lodash';

import { CloseToastFunction } from './close-toast-function';
import { HopToastConfig } from './toast-config';

export class HopToastRef {

    constructor(private readonly _close: CloseToastFunction,
                readonly config: HopToastConfig,
                readonly id = config.id || _uniqueId('hop-toast-')) {

    }

    close() {
        this._close(this);
    }
}
