import { ICultivar, IDisplayCultivar } from './cultivar.interface';
import { IDisplayGrowingArea, IGrowingArea } from './growing-area.interface';
import { IDisplayProduct, IProduct } from './product.interface';

interface IBaseArticle {
    navisionId: string;
    navisionTimestamp: number;
    sysLanguageUid: number;
    title: string;
}

export interface IArticle extends IBaseArticle {
    cultivar?: string;
    product?: string;
    growingArea?: string;
}

export interface IDetailArticle extends IBaseArticle {
    cultivar?: ICultivar;
    product?: IProduct;
    growingArea?: IGrowingArea;
}

export interface IOverviewArticle extends IBaseArticle {
    cultivar?: IDisplayCultivar;
    product?: IDisplayProduct;
    growingArea?: IDisplayGrowingArea;
}
