import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoint, UnpaginatedRepository } from '@hopsteiner/shared/data-access';

import { IGrowingArea } from '../entities/growing-area.interface';

@Injectable({
    providedIn: 'root'
})
export class GrowingAreaRepository extends UnpaginatedRepository<IGrowingArea> {

    constructor(httpClient: HttpClient) {
        super(httpClient, {
            endpoint: Endpoint.GROWING_AREAS
        });
    }
}
