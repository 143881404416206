import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterContentInit, ChangeDetectorRef, ContentChild, Directive, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ArrayDataSource, DataSource, ISortableQuery } from '@hopsteiner/shared/collections';
import { HopPaginatorComponent, HopSortDirective, ISortChangeEvent, PaginatorUtils } from '@hopsteiner/shared/components';
import { IDataCollectionViewer } from '@hopsteiner/shared/data-table';
import { FilterResult, IFilterDrawerResult, IFilterGroup } from '@hopsteiner/shared/filter';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';

import { HopDataCollectionContainerFacade } from '../facades/data-collection-container.facade';
import { IDataCollectionContainerFacade } from '../models/data-collection-container-facade.interface';

import { HopDesktopCollectionViewerDirective } from './desktop-collection-viewer.directive';
import { HopMobileCollectionViewerDirective } from './mobile-collection-viewer.directive';

@Directive({
    selector: '[hopDataCollectionContainer]',
    exportAs: 'hopDataCollectionContainer',
    providers: [ HopDataCollectionContainerFacade ]
})
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class HopDataCollectionContainerDirective<T = any> implements OnInit, OnDestroy, AfterContentInit {

    @Input()
    filters?: IFilterGroup;

    @ContentChild(HopPaginatorComponent, { static: true })
    paginator?: HopPaginatorComponent;

    @ContentChild(HopDesktopCollectionViewerDirective)
    desktopViewer?: HopDesktopCollectionViewerDirective<T>;

    @ContentChild(HopMobileCollectionViewerDirective)
    mobileViewer?: HopMobileCollectionViewerDirective<T>;


    readonly _searchTerm$: Observable<string | undefined>;
    readonly _filterResult$: Observable<IFilterDrawerResult<FilterResult> | undefined>;
    readonly _query$: Observable<ISortableQuery>;
    readonly _page$: Observable<number>;

    protected readonly _onDestroy = new Subject<void>();

    _collectionViewer?: IDataCollectionViewer<T>;
    private _isMobile: boolean = false;
    private _dataSource?: DataSource<T, ISortableQuery> | null;

    protected _sortDirective?: HopSortDirective;
    private _sortSubscription: Subscription | null = null;

    constructor(protected readonly _router: Router,
                private readonly _activatedRoute: ActivatedRoute,
                protected readonly _changeDetectorRef: ChangeDetectorRef,
                @Inject(HopDataCollectionContainerFacade) protected readonly _facade: IDataCollectionContainerFacade) {
        this._searchTerm$ = this._facade.searchTerm$;
        this._filterResult$ = this._facade.filterResult$;
        this._query$ = this._facade.query$;
        this._page$ = this._facade.page$;
    }

    @Input()
    get isMobile(): boolean {
        return this._isMobile;
    }

    set isMobile(value: BooleanInput) {
        const coerced = coerceBooleanProperty(value);

        if (coerced === this._isMobile) {
            return;
        }

        this._isMobile = coerced;
        this._updateCollectionViewer();
    }

    @Input()
    get dataSource(): DataSource<T, ISortableQuery> | null | undefined {
        return this._dataSource;
    }

    set dataSource(value: DataSource<T, ISortableQuery> | T[] | null | undefined) {
        if (value === this._dataSource) {
            return;
        }

        this._switchDataSource(Array.isArray(value) ? new ArrayDataSource(value) : value);
    }

    get currentQuery(): ISortableQuery {
        return this._facade.query;
    }

    @ContentChild(HopSortDirective)
    get sortDirective(): HopSortDirective | undefined {
        return this._sortDirective;
    }

    set sortDirective(value: HopSortDirective | undefined) {
        this._bindSortDirective(value);
    }

    ngOnInit() {
        this._query$
            .pipe(takeUntil(this._onDestroy))
            .subscribe((query) => this._bindQuery(query));
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    ngAfterContentInit() {
        this._updateCollectionViewer();
    }

    onSearchTermChanged($event: string) {
        void this._facade.setSearchTerm($event);
    }

    async onFiltersChanged($event: IFilterDrawerResult<FilterResult>) {
        void this._facade.setFilterResult($event);
        this._collectionViewer?.firstPage();
    }

    onSortChanged($event: ISortChangeEvent) {
        void this._facade.setSort({
            field: $event.activeSortableId,
            direction: $event.direction
        });
        this._collectionViewer?.firstPage();
    }

    onPageChanged($event: number) {
        void this._facade.setPage($event);
    }

    protected _switchDataSource(dataSource: DataSource<T, ISortableQuery> | null | undefined) {
        this._dataSource = dataSource;

        if (this._dataSource && this._collectionViewer != null) {
            this._collectionViewer.setDataSource(this._dataSource);
        }
    }

    protected _updateCollectionViewer() {
        this._bindCollectionViewer(this._isMobile ? this.mobileViewer?.collectionViewer : this.desktopViewer?.collectionViewer);
    }

    protected _bindCollectionViewer(collectionViewer: IDataCollectionViewer<T> | undefined) {
        if (this._collectionViewer === collectionViewer || !collectionViewer) {
            return;
        }

        if (this._collectionViewer != null) {
            // unbind the data source from the current collectionViewer
            this._collectionViewer.setDataSource(undefined);
        }

        this._collectionViewer = collectionViewer;

        this._collectionViewer.initCollectionViewer(this._dataSource ?? undefined, this._facade.query);

        if (this.paginator) {
            this.paginator.collectionViewer = collectionViewer;
            this._collectionViewer.viewChange.next(PaginatorUtils.toListRange(this.paginator.page, this.paginator.pageSize));
        }

        this._changeDetectorRef.markForCheck();
    }

    protected _bindSortDirective(sortDirective: HopSortDirective | undefined) {
        if (this._sortDirective === sortDirective) {
            return;
        }

        this._sortDirective = sortDirective;

        if (this._sortSubscription != null) {
            this._sortSubscription.unsubscribe();
            this._sortSubscription = null;
        }

        if (!sortDirective) {
            return;
        }

        const currentSort = this._facade.sort;

        if (currentSort) {
            sortDirective.activeSortableId = currentSort.field;
            sortDirective.direction = currentSort.direction;
        }

        this._sortSubscription = sortDirective.sortChange
            .pipe(takeUntil(this._onDestroy))
            .subscribe((sort) => this.onSortChanged(sort));
    }

    protected _bindQuery(query: ISortableQuery | undefined) {
        if (!this._collectionViewer) {
            return;
        }

        this._collectionViewer.setQuery(query);
    }

}
