import { ListRange } from '@angular/cdk/collections';
import { HopEnvironmentApiFacade } from '@hopsteiner/environment/api';
import { DataSource, DataSourceUtils, IPaginatedData } from '@hopsteiner/shared/collections';
import { combineLatest, map, Observable } from 'rxjs';

import { ICountry } from '../entities/country.interface';

import { CountryRepository } from './country.repository';

export class CountryDataSource extends DataSource<ICountry> {

    constructor(readonly repository: CountryRepository,
                private readonly _environmentFacade: HopEnvironmentApiFacade) {
        super();
    }

    fetch(listRange: ListRange | null): Observable<IPaginatedData<ICountry>> {
        return combineLatest([
            this.repository.find(),
            this._environmentFacade.currentLanguage$
        ]).pipe(
            map(([ data, language ]) => {
                const collator = Intl.Collator(language);
                const sorted = data.sort((a, b) => {
                    return collator.compare(a.name[language], b.name[language]);
                });

                return DataSourceUtils.paginate(sorted, listRange);
            })
        );
    }

}
