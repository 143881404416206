/* eslint-disable @angular-eslint/directive-class-suffix,@angular-eslint/directive-selector */
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CdkCell, CdkFooterCell, CdkHeaderCell } from '@angular/cdk/table';
import { Directive, ElementRef, HostBinding, Input } from '@angular/core';

import { HopColumnDef } from './column-def.directive';


function getFlexValue(width?: string | null) {
    return width != null ? `0 0 ${width}` : undefined;
}

/** Cell template container that adds the right classes and role. */
@Directive({
    selector: 'hop-cell',
    host: {
        class: 'c-hop-cell',
        role: 'gridcell'
    }
})
export class HopCell extends CdkCell {

    private _overflowVisible: boolean = false;

    constructor(readonly columnDef: HopColumnDef, elementRef: ElementRef) {
        super(columnDef, elementRef);
    }

    @HostBinding('class.c-hop-cell--sticky')
    get isSticky(): boolean {
        return this.columnDef.sticky;
    }

    @HostBinding('class.c-hop-cell--sticky-end')
    get isStickyEnd(): boolean {
        return this.columnDef.stickyEnd;
    }

    @Input()
    @HostBinding('class.c-hop-cell--overflow-visible')
    get overflowVisible(): boolean {
        return this._overflowVisible;
    }

    set overflowVisible(value: string | boolean) {
        this._overflowVisible = coerceBooleanProperty(value);
    }

    @HostBinding('style.width')
    get width() {
        return this.columnDef.width;
    }

    @HostBinding('style.flex')
    get _flexValue() {
        return getFlexValue(this.columnDef.width);
    }
}


/** Header cell template container that adds the right classes and role. */
@Directive({
    selector: 'hop-header-cell',
    host: {
        class: 'c-hop-header-cell',
        role: 'columnheader'
    }
})
export class HopHeaderCell extends CdkHeaderCell {

    constructor(readonly columnDef: HopColumnDef, elementRef: ElementRef) {
        super(columnDef, elementRef);
    }

    @HostBinding('class.c-hop-header-cell--sticky')
    get isSticky(): boolean {
        return this.columnDef.sticky;
    }

    @HostBinding('class.c-hop-header-cell--sticky-end')
    get isStickyEnd(): boolean {
        return this.columnDef.stickyEnd;
    }

    @HostBinding('style.width')
    get width() {
        return this.columnDef.width;
    }

    @HostBinding('style.flex')
    get _flexValue() {
        return getFlexValue(this.columnDef.width);
    }
}

/** Footer cell template container that adds the right classes and role. */
@Directive({
    selector: 'hop-footer-cell',
    host: {
        class: 'c-hop-footer-cell',
        role: 'gridcell'
    }
})
export class HopFooterCell extends CdkFooterCell {

    constructor(readonly columnDef: HopColumnDef, elementRef: ElementRef) {
        super(columnDef, elementRef);
    }

    @HostBinding('class.c-hop-footer-cell--sticky')
    get isSticky(): boolean {
        return this.columnDef.sticky;
    }

    @HostBinding('class.c-hop-footer-cell--sticky-end')
    get isStickyEnd(): boolean {
        return this.columnDef.stickyEnd;
    }

    @HostBinding('style.width')
    get width() {
        return this.columnDef.width;
    }

    @HostBinding('style.flex')
    get _flexValue() {
        return getFlexValue(this.columnDef.width);
    }
}
