/**
 * Represents a subset / range of a data collection
 */
export interface IPaginatedData<T> {
    /**
     * The number of elements skipped. Aka the start index
     */
    skip: number;

    /**
     * The maximum number of elements in this response. Aka the page size
     */
    limit: number;

    /**
     * The total count of elements in the collection
     */
    total: number;

    /**
     * The subset / data within the range
     */
    data: T[];
}
