/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DebitorSwitcherFacade } from '@hopsteiner/environment/domain';


@Injectable({
    providedIn: 'root'
})
export class HopDisableDebitorSwitcherGuard {

    constructor(private readonly _debitorSwitcherFacade: DebitorSwitcherFacade) {
    }

    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): boolean {
        this._debitorSwitcherFacade.disable();
        return true;
    }

    canDeactivate(component: unknown,
                  currentRoute: ActivatedRouteSnapshot,
                  currentState: RouterStateSnapshot,
                  nextState?: RouterStateSnapshot): boolean {
        this._debitorSwitcherFacade.enable();
        return true;
    }
}

@Injectable({
    providedIn: 'root'
})
export class HopEnableDebitorSwitcherGuard {

    constructor(private readonly _debitorSwitcherFacade: DebitorSwitcherFacade) {
    }

    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): boolean {
        this._debitorSwitcherFacade.enable();
        return true;
    }

    canDeactivate(component: unknown,
                  currentRoute: ActivatedRouteSnapshot,
                  currentState: RouterStateSnapshot,
                  nextState?: RouterStateSnapshot): boolean {
        this._debitorSwitcherFacade.disable();
        return true;
    }

}
