import { Directive, HostListener, Inject, Input } from '@angular/core';

import { IExpandableProvider } from '../models/expandable-provider.interface';
import { EXPANDABLE_PROVIDER } from '../tokens/expandable-provider.token';

@Directive({
    selector: '[hopExpandableTrigger]',
    exportAs: 'hopExpandableTrigger'
})
export class HopExpandableTriggerDirective<T> {

    @Input('hopExpandableTrigger')
    element!: T;


    constructor(@Inject(EXPANDABLE_PROVIDER) readonly expandableProvider: IExpandableProvider<T>) {

    }

    get isExpanded(): boolean {
        return this.expandableProvider.isExpanded(this.element);
    }

    @HostListener('click')
    onClick() {
        this.expandableProvider.toggleExpansion(this.element);
    }


}
