import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
    selector: 'hop-country-flag,[hopCountryFlag]'
})
export class HopCountryFlagDirective {

    @HostBinding('class')
    private _countryFlagClass: string | null = null;

    private _isoCode?: string | null;

    @Input()
    get isoCode(): string | undefined | null {
        return this._isoCode;
    }

    set isoCode(value: string | undefined | null) {
        this._isoCode = value;
        this._countryFlagClass = value ? `fi fi-${value}` : null;
    }

    @Input()
    get hopCountryFlag(): string | undefined | null {
        return this.isoCode;
    }

    set hopCountryFlag(value: string | undefined | null) {
        this.isoCode = value;
    }
}
