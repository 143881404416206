import { Injectable } from '@angular/core';
import { Language } from '@hopsteiner/shared/models';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';

import { LanguageService } from '../infrastructure/language.service';

import { LanguageActions } from './actions/language.actions';
import { SessionActions } from './actions/session.actions';
import { DebitorSwitcherState } from './debitor-switcher.state';

interface IEnvironmentStateModel {
    language: Language;
    sessionId: string | null;
}

const LOCAL_STORAGE_SESSION_ID_KEY = 'session';

@State<IEnvironmentStateModel>({
    name: 'environment',
    defaults: {
        language: Language.DE,
        sessionId: null
    },
    children: [ DebitorSwitcherState ]
})
@Injectable()
export class EnvironmentState implements NgxsOnInit {

    @Selector()
    static selectCurrentLanguage(state: IEnvironmentStateModel) {
        return state.language;
    }

    @Selector()
    static selectSessionId(state: IEnvironmentStateModel) {
        return state.sessionId;
    }

    constructor(private readonly _languageService: LanguageService) {

    }

    async ngxsOnInit(ctx: StateContext<IEnvironmentStateModel>) {
        ctx.patchState({
            sessionId: localStorage.getItem(LOCAL_STORAGE_SESSION_ID_KEY)
        });

        const language = await this._languageService.getCurrentLanguage();
        this._languageService.switchTranslationLanguage(language);

        ctx.patchState({
            language
        });
    }

    @Action(LanguageActions.ChangeLanguage)
    async changeLanguage(ctx: StateContext<IEnvironmentStateModel>, action: LanguageActions.ChangeLanguage) {
        ctx.patchState({
            language: action.language
        });

        const sessionId = await this._languageService.switchLanguageTemporarily(action.language);

        window.location.reload();

        return ctx.dispatch(new SessionActions.SetSessionId(sessionId));
    }

    @Action(SessionActions.SetSessionId)
    async setSessionId(ctx: StateContext<IEnvironmentStateModel>, action: SessionActions.SetSessionId) {
        localStorage.setItem(LOCAL_STORAGE_SESSION_ID_KEY, action.sessionId);

        ctx.patchState({
            sessionId: action.sessionId
        });
    }

    @Action(SessionActions.ClearSessionId)
    async clearSessionId(ctx: StateContext<IEnvironmentStateModel>) {
        localStorage.removeItem(LOCAL_STORAGE_SESSION_ID_KEY);

        ctx.patchState({
            sessionId: null
        });
    }

    @Action(LanguageActions.ChangeTranslationLanguage)
    async changeTranslationLanguage(ctx: StateContext<IEnvironmentStateModel>, action: LanguageActions.ChangeLanguage) {
        this._languageService.switchTranslationLanguage(action.language);

        ctx.patchState({
            language: action.language
        });
    }
}
