import { Injectable } from '@angular/core';
import { DataSource, ISortableQuery } from '@hopsteiner/shared/collections';
import { RepositoryDataSource, transformOptions } from '@hopsteiner/shared/data-access';
import { CsvExportService } from '@hopsteiner/shared/export';
import { pick } from 'lodash';

import { IOverviewUser } from '../../entities/user.interface';
import { UserRepository } from '../../infrastructure/user.repository';
import { IUserExportOptions } from '../../models/user-export-options.interface';


@Injectable({
    providedIn: 'root'
})
export class HopUserBrowseFacade {

    constructor(private readonly _userRepository: UserRepository,
                private readonly _csvExportService: CsvExportService) {

    }

    createDataSource(): DataSource<IOverviewUser, ISortableQuery> {
        return new RepositoryDataSource(this._userRepository);
    }

    async export(options: IUserExportOptions) {
        const transformedQuery = transformOptions()(null, options);

        return this._userRepository.export(pick(transformedQuery, 'where', 'sort'));
    }

    async exportToFile(options: IUserExportOptions) {
        return this._csvExportService.export(await this._userRepository.export(options), 'users');
    }
}
