import { Injectable } from '@angular/core';
import { IInvoice } from '@hopsteiner/invoice/api';
import { DataSource } from '@hopsteiner/shared/collections';
import { IFindOptions } from '@hopsteiner/shared/data-access';

import { DebitorInvoiceDataSource } from '../../infrastructure/debitor-invoice.data-source';
import { DebitorUserDataSource } from '../../infrastructure/debitor-user.data-source';
import { DebitorRepository } from '../../infrastructure/debitor.repository';
import { IAssignedUser } from '../../models/assigned-user.interface';

@Injectable({
    providedIn: 'root'
})
export class HopDebitorFacade {

    constructor(private readonly _debitorRepository: DebitorRepository) {

    }

    getDebitor(id: string) {
        return this._debitorRepository.get(id);
    }

    createAssignedUserDataSource(id: string): DataSource<IAssignedUser> {
        return new DebitorUserDataSource(id, this._debitorRepository);
    }

    findDebitors(findOptions: IFindOptions) {
        return this._debitorRepository.find(findOptions);
    }

    getDebitorChangeRequest(id: string) {
        return this._debitorRepository.getDebitorChangeRequest(id);
    }

    createInvoicesDataSource(debitorId: string): DataSource<IInvoice> {
        return new DebitorInvoiceDataSource(debitorId, this._debitorRepository);
    }
}
