import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { HopCountryFlagWithIsoCodeComponent } from './components/country-flag-with-iso-code/country-flag-with-iso-code.component';
import { HopCountryFlagDirective } from './directives/country-flag.directive';


@NgModule({
    imports: [
        CommonModule,

        TranslateModule,
        NgbTooltipModule
    ],
    declarations: [
        HopCountryFlagDirective,
        HopCountryFlagWithIsoCodeComponent
    ],
    exports: [
        HopCountryFlagDirective,
        HopCountryFlagWithIsoCodeComponent
    ]
})
export class HopCountryFlagModule {
}
