import { Directive } from '@angular/core';

@Directive({
    selector: '[hopDialogTitle]',
    host: {
        class: 'c-hop-dialog__title h5'
    }
})
export class HopDialogTitleDirective {

}
