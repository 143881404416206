import { ConfigurableFocusTrap, ConfigurableFocusTrapFactory, FocusMonitor, FocusOrigin, InteractivityChecker } from '@angular/cdk/a11y';
import { _getFocusedElementPierceShadowDom } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { ElementRef, Inject, Injectable, NgZone, Optional } from '@angular/core';

import { HopOverlayConfig } from '../models/overlay-config';


@Injectable()
export class OverlayFocusHelper {

    /**
     * Type of interaction that led to the overlay being closed. This is used to determine
     * whether the focus style will be applied when returning focus to its original location
     * after the overlay is closed.
     */
    _closeInteractionType: FocusOrigin | null = null;

    /** The class that traps and manages focus within the overlay. */
    private _focusTrap?: ConfigurableFocusTrap;

    /** Element that was focused before the overlay was opened. Save this to restore upon close. */
    private _elementFocusedBeforeOverlayWasOpened: HTMLElement | null = null;

    constructor(private readonly _elementRef: ElementRef,
                private readonly _ngZone: NgZone,
                private readonly _interactivityChecker: InteractivityChecker,
                protected readonly _focusTrapFactory: ConfigurableFocusTrapFactory,
                private readonly _config: HopOverlayConfig,
                @Optional() @Inject(DOCUMENT) protected readonly _document?: Document,
                @Optional() private readonly _focusMonitor?: FocusMonitor) {
    }

    /**
     * Focuses the element via the specified focus origin.
     * @param element Element to focus.
     * @param origin Focus origin.
     * @param options Options that can be used to configure the focus behavior.
     */
    focusVia(element: HTMLElement, origin: FocusOrigin, options?: FocusOptions) {
        if (this._focusMonitor) {
            this._focusMonitor.focusVia(element, origin, options);
        } else {
            element.focus();
        }
    }

    forceFocusBySelector(selector: string, options?: FocusOptions) {
        this.forceFocus(this._elementRef.nativeElement.querySelector(selector) as HTMLElement, options);
    }

    forceFocus(element: HTMLElement, options?: FocusOptions) {
        if (!element) {
            return;
        }

        if (!this._interactivityChecker.isFocusable(element)) {
            this.makeFocusable(element);
        }

        element.focus(options);
    }

    makeFocusable(element: HTMLElement) {
        element.tabIndex = -1;
        // The tabindex attribute should be removed to avoid navigating to that element again
        this._ngZone.runOutsideAngular(() => {
            element.addEventListener('blur', () => element.removeAttribute('tabindex'));
            element.addEventListener('mousedown', () => element.removeAttribute('tabindex'));
        });
    }

    /**
     * Returns whether focus is inside the element.
     **/
    containsFocus() {
        const element = this._elementRef.nativeElement;
        const activeElement = _getFocusedElementPierceShadowDom();
        return element === activeElement || element.contains(activeElement);
    }

    /**
     * Focuses the element itself
     * */
    focusSelf() {
        // Note that there is no focus method when rendering on the server.
        if (this._elementRef.nativeElement.focus) {
            this._elementRef.nativeElement.focus();
        }
    }

    /** Sets up the focus trap. */
    setupFocusTrap() {
        this._focusTrap = this._focusTrapFactory.create(this._elementRef.nativeElement);
        // Save the previously focused element. This element will be re-focused
        // when the overlay closes.
        this._capturePreviouslyFocusedElement();
    }

    /** Moves focus back into the overlay if it was moved out. */
    recaptureFocus() {
        if (!this.containsFocus()) {
            this.trapFocus();
        }
    }

    /**
     * Moves the focus inside the focus trap. When autoFocus is not set to 'overlay', if focus
     * cannot be moved then focus will go to the overlay container.
     */
    trapFocus() {
        // If were to attempt to focus immediately, then the content of the overlay would not yet be
        // ready in instances where change detection has to run first. To deal with this, we simply
        // wait for the microtask queue to be empty when setting focus when autoFocus isn't set to
        // overlay. If the element inside the overlay can't be focused, then the container is focused
        // so the user can't tab into other elements behind it.
        switch (this._config.autoFocus) {
            case false:
            case 'overlay':
                // Ensure that focus is on the overlay container. It's possible that a different
                // component tried to move focus while the open animation was running. See:
                // https://github.com/angular/components/issues/16215. Note that we only want to do this
                // if the focus isn't inside the overlay already, because it's possible that the consumer
                // turned off `autoFocus` in order to move focus themselves.
                if (!this.containsFocus()) {
                    this.focusSelf();
                }
                break;
            case true:
            case 'first-tabbable':
                this._focusTrap?.focusInitialElementWhenReady().then(focusedSuccessfully => {
                    // If we weren't able to find a focusable element in the overlay, then focus the overlay
                    // container instead.
                    if (!focusedSuccessfully) {
                        this.focusSelf();
                    }
                });
                break;
            case 'first-heading':
                this.forceFocusBySelector('h1, h2, h3, h4, h5, h6, [role="heading"]');
                break;
            default:
                this.forceFocusBySelector(this._config.autoFocus as string);
                break;
        }
    }

    /** Restores focus to the element that was focused before the overlay opened. */
    restoreFocus() {
        const previousElement = this._elementFocusedBeforeOverlayWasOpened;

        // We need the extra check, because IE can set the `activeElement` to null in some cases.
        if (
            this._config.restoreFocus &&
            previousElement &&
            typeof previousElement.focus === 'function'
        ) {
            const activeElement = _getFocusedElementPierceShadowDom();
            const element = this._elementRef.nativeElement;

            // Make sure that focus is still inside the overlay or is on the body (usually because a
            // non-focusable element like the backdrop was clicked) before moving it. It's possible that
            // the consumer moved it themselves before the animation was done, in which case we shouldn't
            // do anything.
            if (
                !activeElement ||
                activeElement === this._document?.body ||
                activeElement === element ||
                element.contains(activeElement)
            ) {
                if (this._focusMonitor) {
                    this._focusMonitor.focusVia(previousElement, this._closeInteractionType);
                    this._closeInteractionType = null;
                } else {
                    previousElement.focus();
                }
            }
        }

        if (this._focusTrap) {
            this._focusTrap.destroy();
        }
    }

    /** Captures the element that was focused before the overlay was opened. */
    private _capturePreviouslyFocusedElement() {
        if (this._document) {
            this._elementFocusedBeforeOverlayWasOpened = _getFocusedElementPierceShadowDom();
        }
    }
}
