import { ChangeDetectionStrategy, Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { LanguageFacade } from '@hopsteiner/environment/domain';
import { Language } from '@hopsteiner/shared/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ILanguageData } from '../../models/language-data.interface';

@Component({
    selector: 'hop-env-language-menu',
    templateUrl: './language-menu.component.html',
    styleUrls: [ './language-menu.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-env-language-menu'
    }
})
export class HopEnvLanguageMenuComponent {
    readonly _icon = {
        faChevronDown
    };

    readonly languages: Record<Language, ILanguageData> = {
        [Language.DE]: {
            flagUrl: 'assets/images/ger-flag.svg',
            name: 'ENVIRONMENT.LANGUAGE_MENU.DE'
        },
        [Language.EN]: {
            flagUrl: 'assets/images/uk-flag.svg',
            name: 'ENVIRONMENT.LANGUAGE_MENU.EN'
        }
    };

    readonly availableLanguages = [ Language.DE, Language.EN ];

    readonly currentLanguage$: Observable<Language>;
    readonly currentLanguageData$: Observable<ILanguageData>;

    @Output()
    readonly itemClick = new EventEmitter<MouseEvent>();

    constructor(private readonly _languageFacade: LanguageFacade) {
        this.currentLanguage$ = _languageFacade.currentLanguage$;

        this.currentLanguageData$ = this.currentLanguage$.pipe(
            map((language) => this.languages[language])
        );
    }

    onItemClick($event: MouseEvent, language: Language) {
        this._languageFacade.changeLanguage(language);
        this.itemClick.emit($event);
    }
}
