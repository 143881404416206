import { Directive, TemplateRef } from '@angular/core';

@Directive({
    selector: 'ng-template[hopExpandableRowContent]'
})
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class HopExpandableRowContentDirective<T = any> {

    constructor(readonly templateRef: TemplateRef<T>) {
    }
}
