/**
 * used in a Bitmask to show icons
 *
 * 0b0001 = Bio
 * 0b0010 = Naturland
 * 0b0100 = Bioland
 * 0b1000 = Demeter
 */
export enum OrganicLabel {
    /**
     * Bio
     */
    BIO = 'bio',

    /**
     * Naturland
     */
    NATURLAND = 'naturland',

    /**
     * Bioland
     */
    BIOLAND = 'bioland',

    /**
     * Demeter
     */
    DEMETER = 'demeter'
}
