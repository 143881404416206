import { Injectable } from '@angular/core';

import { ICreateOrderRequestOptions } from '../../entities/create-order-request-options.interface';
import { ICreateOrderRequestDto } from '../../entities/create-order-request.dto';
import { CreateOrderRequestOptionsRepository } from '../../infrastructure/create-order-request-options.repository';
import { OrderRequestRepository } from '../../infrastructure/order-request.repository';

@Injectable({ providedIn: 'root' })
export class HopOrderRequestCreateFacade {

    constructor(private readonly _orderRequestRepository: OrderRequestRepository,
                private readonly _createOrderRequestOptionsRepository: CreateOrderRequestOptionsRepository) {
    }

    createOrderRequest(data: ICreateOrderRequestDto) {
        return this._orderRequestRepository.create(data);
    }

    getOrderRequestOptions(): Promise<ICreateOrderRequestOptions> {
        return this._createOrderRequestOptionsRepository.get();
    }
}
