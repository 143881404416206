import { ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { isFunction as _isFunction, isObject as _isObject } from 'lodash';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { IHasTitle } from '../models/has-title.interface';
import { IActivatedRouteSnapshotWithTitle } from '../models/route-with-title.interface';

/**
 * Utility methods for extracting / building the browser tab title
 */
export abstract class TitleUtils {

    static hasTitle(value: unknown): value is IHasTitle {
        return value != null && _isObject(value) && 'title' in value;
    }

    static routeHasTitle(value: ActivatedRouteSnapshot): value is IActivatedRouteSnapshotWithTitle {
        return value != null && TitleUtils.hasTitle(value.data);
    }

    static getTitleSegments(activatedRoute: ActivatedRouteSnapshot | null, titleSegments: string[] = []): string[] {
        if (!activatedRoute) {
            return [ ...titleSegments ];
        }

        if (TitleUtils.routeHasTitle(activatedRoute)) {

            if (activatedRoute.data.overrideTitle) {
                titleSegments = [ TitleUtils._getTitle(activatedRoute) ];
            } else {
                titleSegments = [ TitleUtils._getTitle(activatedRoute), ...titleSegments ];
            }
        }

        if (activatedRoute.firstChild) {
            return TitleUtils.getTitleSegments(activatedRoute.firstChild, titleSegments);
        }

        return [ ...titleSegments ];
    }

    static translateAll(titleSegments: string[], translateService: TranslateService): Observable<string[]> {
        if (!Array.isArray(titleSegments) || !titleSegments.length) {
            return of([] as string[]);
        }

        return combineLatest(titleSegments.map((title) => translateService.get(title) as Observable<string>));
    }

    static translateAndMergeAll(titleSegments: string[], translateService: TranslateService): Observable<string> {
        return TitleUtils.translateAll(titleSegments, translateService).pipe(
            map((translatedSegments) => TitleUtils.mergeSegments(translatedSegments))
        );
    }

    static mergeSegments(titleSegments: string[]): string {
        return Array.isArray(titleSegments) ? titleSegments.join(' - ') : '';
    }

    private static _getTitle(route: IActivatedRouteSnapshotWithTitle): string;
    private static _getTitle(route: ActivatedRouteSnapshot): string | null;
    private static _getTitle(route: ActivatedRouteSnapshot): string | null {
        if (!TitleUtils.hasTitle(route.data)) {
            return null;
        }

        if (_isFunction(route.data?.title)) {
            return route.data.title(route);
        }

        return route.data?.title;
    }
}
