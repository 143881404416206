import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HopKeyValueTableComponent } from './components/key-value-table/key-value-table.component';


@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        HopKeyValueTableComponent
    ],
    exports: [
        HopKeyValueTableComponent
    ]
})
export class HopKeyValueTableModule {
}
