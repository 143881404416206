<nav>
    <ul hopNavList [indicatorPosition]="indicatorPosition">
        <li hopNavItem>
            <a class="nav-link" routerLink="/dashboard" routerLinkActive="active">
                <fa-icon [icon]="_icons.faHome"></fa-icon>
                {{ 'NAVIGATION.DASHBOARD' | translate }}
            </a>
        </li>
        <li hopNavItem>
            <a class="nav-link" routerLink="/contracts" routerLinkActive="active">
                <fa-icon [icon]="_icons.faFileContract"></fa-icon>
                {{ 'NAVIGATION.CONTRACTS' | translate }}
            </a>
        </li>
        <li hopNavItem>
            <a class="nav-link" routerLink="/debitors" routerLinkActive="active">
                <fa-icon [icon]="_icons.faDatabase"></fa-icon>
                {{ 'NAVIGATION.DEBITORS' | translate }}
            </a>
        </li>
        <li hopNavItem *hasPermission="UserPermission.VIEW_ORDER_REQUESTS">
            <a class="nav-link" routerLink="/order-requests" routerLinkActive="active">
                <fa-icon [icon]="_icons.faShoppingCart"></fa-icon>
                {{ 'NAVIGATION.ORDER_REQUESTS' | translate }}
            </a>
        </li>
        <li hopNavItem *hasPermission="UserPermission.VIEW_USERS">
            <a class="nav-link" routerLink="/users" routerLinkActive="active">
                <fa-icon [icon]="_icons.faUserCog"></fa-icon>
                {{ 'NAVIGATION.USERS' | translate }}
            </a>
        </li>
        <li hopNavItem *hasPermission="UserPermission.VIEW_EXAMPLES">
            <a class="nav-link" routerLink="/example" routerLinkActive="active">
                <fa-icon [icon]="_icons.faGamepad"></fa-icon>
                {{ 'NAVIGATION.EXAMPLES' | translate }}
            </a>
        </li>
    </ul>
</nav>
