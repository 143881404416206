import { ListRange } from '@angular/cdk/collections';
import { DataSource, IPaginatedData, ISortableQuery } from '@hopsteiner/shared/collections';
import { transformOptions, TransformOptionsFunction } from '@hopsteiner/shared/data-access';
import { from, Observable } from 'rxjs';

import { IShipment } from '../entities/shipment.interface';

import { ContractRepository } from './contract.repository';

export class ShipmentDataSource<QUERY = ISortableQuery> extends DataSource<IShipment, QUERY> {

    constructor(private readonly _contractId: string,
                private readonly _repository: ContractRepository,
                private readonly _createFindOptions: TransformOptionsFunction<QUERY> = transformOptions() as TransformOptionsFunction<QUERY>) {
        super();
    }

    fetch(listRange: ListRange | null, query: QUERY | null): Observable<IPaginatedData<IShipment>> {
        // special case: forcing to 0 because backend then outputs all shipments and all should be output here
        return from(this._repository.findShipments(this._contractId, this._createFindOptions({ end: 0, start: 0 }, query)));
    }

}
