import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HopStatusComponent } from './components/status/status.component';


@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        HopStatusComponent
    ],
    exports: [
        HopStatusComponent
    ]
})
export class HopStatusModule {
}
