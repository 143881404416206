import { Pipe, PipeTransform } from '@angular/core';

import { FiltersWithKeys, FiltersWithValues } from '../models/filter-group';
import { FilterResult } from '../models/filter-result';
import { FilterUtils } from '../utils/filter.utils';

@Pipe({
    name: 'withValues'
})
export class HopWithValuesPipe implements PipeTransform {

    transform<R extends FilterResult>(filters: FiltersWithKeys<R>, result: R) {
        return filters.reduce((reduced, filter) => {
            if (!FilterUtils.hasValue(filter, result[filter.key])) {
                return reduced;
            }

            return [
                ...reduced,
                {
                    filter,
                    value: result[filter.key]
                }
            ];
        }, [] as FiltersWithValues<R>);
    }

}
