import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { HopAuthStateFacade } from '@hopsteiner/auth/domain';
import { UserPermission } from '@hopsteiner/shared/models';
import { map, Observable } from 'rxjs';

import { FORBIDDEN_REDIRECT } from '../tokens/forbidden-redirect.token';

const PERMISSIONS_PROPERTY = 'permissions';

export interface IHasPermissionGuardData {
    [PERMISSIONS_PROPERTY]: UserPermission[];
}

export interface IHasPermissionRouteData {
    data: IHasPermissionGuardData;
}

@Injectable({
    providedIn: 'root'
})
export class HopHasPermissionGuard {

    static readonly PERMISSIONS_PROPERTY = PERMISSIONS_PROPERTY;

    constructor(private readonly _router: Router,
                private readonly _authStateFacade: HopAuthStateFacade,
                @Inject(FORBIDDEN_REDIRECT) private readonly _forbiddenRedirect: string) {

    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canActivate(route: ActivatedRouteSnapshot & IHasPermissionRouteData, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this._verifyPermissions(route.data[HopHasPermissionGuard.PERMISSIONS_PROPERTY]);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canActivateChild(childRoute: ActivatedRouteSnapshot & IHasPermissionRouteData, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this._verifyPermissions(childRoute.data[HopHasPermissionGuard.PERMISSIONS_PROPERTY]);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canLoad(route: Route & IHasPermissionRouteData, segments: UrlSegment[]): Observable<boolean | UrlTree> {
        return this._verifyPermissions(route.data[HopHasPermissionGuard.PERMISSIONS_PROPERTY]);
    }

    protected _verifyPermissions(requiredPermissions: UserPermission[]) {
        return this._authStateFacade.hasPermissions(requiredPermissions).pipe(
            map((hasPermissions) => {
                return hasPermissions || this._router.parseUrl(this._forbiddenRedirect);
            })
        );
    }

}
