import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HopCardComponent } from './components/card/card.component';
import { HopCardTitleDirective } from './directives/card-title.directive';


@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        HopCardComponent,
        HopCardTitleDirective
    ],
    exports: [
        HopCardComponent,
        HopCardTitleDirective
    ]
})
export class HopCardModule {
}
