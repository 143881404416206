import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HopAlertModule } from '@hopsteiner/shared/components';
import { TranslateModule } from '@ngx-translate/core';

import { HopLceContentDirective } from './directives/lce-content.directive';
import { HopLceCustomStateDirective } from './directives/lce-custom-state.directive';
import { HopLceErrorMessageDirective } from './directives/lce-error-message.directive';
import { HopLceErrorDirective } from './directives/lce-error.directive';
import { HopLceLoadingDirective } from './directives/lce-loading.directive';
import { HopLceLayout } from './layouts/lce/lce.layout';
import { HopFilterStatePipe } from './pipes/filter-state.pipe';
import { HopLceStatePipe } from './pipes/lce-state.pipe';


@NgModule({
    imports: [
        CommonModule,

        TranslateModule,

        HopAlertModule
    ],
    declarations: [
        HopLceLayout,

        HopLceContentDirective,
        HopLceCustomStateDirective,
        HopLceErrorDirective,
        HopLceErrorMessageDirective,
        HopLceLoadingDirective,

        HopLceStatePipe,
        HopFilterStatePipe
    ],
    exports: [
        HopLceLayout,

        HopLceContentDirective,
        HopLceCustomStateDirective,
        HopLceErrorDirective,
        HopLceErrorMessageDirective,
        HopLceLoadingDirective,

        HopLceStatePipe
    ]
})
export class HopLceLayoutModule {

}
