import { Injectable } from '@angular/core';
import { DataSource, ISortableQuery } from '@hopsteiner/shared/collections';

import { IDebitorAddress } from '../../entities/address.interface';
import { DebitorAddressDataSource } from '../../infrastructure/debitor-address.data-source';
import { DebitorRepository } from '../../infrastructure/debitor.repository';

@Injectable({
    providedIn: 'root'
})
export class HopDebitorViewFacade {

    constructor(private readonly _debitorRepository: DebitorRepository) {
    }

    createAddressDataSource(navisionId: string): DataSource<IDebitorAddress, ISortableQuery> {
        return new DebitorAddressDataSource(navisionId, this._debitorRepository);
    }
}
