import { DataSource, IPaginatedData } from '@hopsteiner/shared/collections';
import { from, Observable } from 'rxjs';

import { IOrderRequest } from '../entities/order-request.interface';

import { OrderRequestRepository } from './order-request.repository';

export class OrderRequestDataSource extends DataSource<IOrderRequest> {

    constructor(private readonly _repository: OrderRequestRepository) {
        super();
    }

    fetch(): Observable<IPaginatedData<IOrderRequest>> {
        return from(this._repository.find());
    }
}
