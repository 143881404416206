import { ILogInCredentials } from '../models/log-in-credentials.interface';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace LoginActions {

    export class LogIn {
        static readonly type = '[Auth] Log in';

        constructor(readonly credentials: ILogInCredentials) {
        }
    }

    export class SilentLogIn {
        static readonly type = '[Auth] Silent log in';
    }
}
