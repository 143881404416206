/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DebitorSwitcherFacade } from '@hopsteiner/environment/domain';

abstract class BaseDebitorSwitcherGuard {

    constructor(private readonly _debitorSwitcherFacade: DebitorSwitcherFacade,
                private readonly _showOnActivatedRoute: boolean) {
    }

    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): boolean {
        this._debitorSwitcherFacade.show(this._showOnActivatedRoute);
        return true;
    }

    canDeactivate(component: unknown,
                  currentRoute: ActivatedRouteSnapshot,
                  currentState: RouterStateSnapshot,
                  nextState?: RouterStateSnapshot): boolean {
        this._debitorSwitcherFacade.show(!this._showOnActivatedRoute);
        return true;
    }

}

@Injectable({
    providedIn: 'root'
})
export class HopShowDebitorSwitcherGuard extends BaseDebitorSwitcherGuard {

    constructor(debitorSwitcherFacade: DebitorSwitcherFacade) {
        super(debitorSwitcherFacade, true);
    }

}

@Injectable({
    providedIn: 'root'
})
export class HopHideDebitorSwitcherGuard extends BaseDebitorSwitcherGuard {

    constructor(debitorSwitcherFacade: DebitorSwitcherFacade) {
        super(debitorSwitcherFacade, false);
    }

}
