import { animate, state, style, transition, trigger } from '@angular/animations';

export const DATA_TABLE_ANIMATIONS = {
    /** Animation that expands or collapses the row. */
    expandRow: trigger('expandRow', [
        state('collapsed', style({
            borderBottom: '0',
            height: '0',
            pointerEvents: 'none',
            visibility: 'hidden'
        })),
        state('expanded', style({
            height: '*'
        })),
        transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
};
