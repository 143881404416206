import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { EnumLike } from '@hopsteiner/shared/models';

import { IndicatorPosition } from '../../models/nav-indicator-position.enum';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'ul[hopNavList]',
    templateUrl: './nav-list.component.html',
    styleUrls: [ './nav-list.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-nav-list nav'
    }
})
export class HopNavListComponent {

    @Input()
    indicatorPosition: EnumLike<IndicatorPosition> = IndicatorPosition.RIGHT;

    @HostBinding('class.c-hop-nav-list--indicator-left')
    get isIndicatorLeft(): boolean {
        return this.indicatorPosition == IndicatorPosition.LEFT;
    }

}
