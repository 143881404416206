import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HopLanguageFlagPipe } from './pipes/language-flag.pipe';
import { HopLanguageNamePipe } from './pipes/language-name.pipe';


@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        HopLanguageFlagPipe,
        HopLanguageNamePipe
    ],
    exports: [
        HopLanguageFlagPipe,
        HopLanguageNamePipe
    ]
})
export class HopLanguageModule {
}
