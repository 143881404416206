import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HopButtonModule } from '@hopsteiner/shared/components';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { HopToastContainerComponent } from './components/toast-container/toast-container.component';
import { HopToast } from './services/toast.service';


@NgModule({
    imports: [
        CommonModule,

        NgbToastModule,
        FontAwesomeModule,

        HopButtonModule,
        TranslateModule
    ],
    providers: [
        HopToast
    ],
    declarations: [
        HopToastContainerComponent
    ]
})
export class HopToastModule {

}
