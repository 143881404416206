import { ChangeDetectionStrategy, Component, Host, ViewEncapsulation } from '@angular/core';

import { HopDataTableComponent } from '../data-table/data-table.component';

@Component({
    selector: 'hop-no-data-row',
    templateUrl: './no-data-row.component.html',
    styleUrls: [ './no-data-row.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-no-data-row'
    }
})
export class HopNoDataRowComponent {

    constructor(@Host() readonly _table: HopDataTableComponent<unknown>) {

    }
}
