import { Directive, TemplateRef } from '@angular/core';

@Directive({
    selector: '[hopListItemDef]'
})
export class HopListItemDefDirective {

    constructor(readonly templateRef: TemplateRef<unknown>) {

    }

}
