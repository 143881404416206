import { FilterResult } from '@hopsteiner/shared/filter';
import { flatten, unflatten } from 'flat';
import { isEmpty as _isEmpty } from 'lodash';

import { IOverviewLayoutParams } from '../models/overview-layout-params.interface';
import { IQueryParamsTransformer } from '../models/query-params-transformer.interface';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class DefaultQueryParamsTransformer<FILTER_RESULT extends FilterResult = FilterResult, QUERY_PARAMS extends object = any> implements IQueryParamsTransformer<QUERY_PARAMS> {

    fromQueryParams(queryParams: QUERY_PARAMS): IOverviewLayoutParams<FILTER_RESULT> {
        if (_isEmpty(queryParams)) {
            return {};
        }

        const transformed: IOverviewLayoutParams & { page?: string } = unflatten(queryParams);

        return {
            ...transformed,
            page: transformed.page ? Number.parseInt(transformed.page, 10) : 0
        } as IOverviewLayoutParams<FILTER_RESULT>;
    }

    toQueryParams(result: IOverviewLayoutParams<FILTER_RESULT>): QUERY_PARAMS {
        return flatten(result);
    }

}
