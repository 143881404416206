import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { UserState } from './application/user.state';

@NgModule({
    imports: [
        CommonModule,

        NgxsModule.forFeature([ UserState ])
    ]
})
export class HopUserDomainRootModule {

}
