<hop-drawer>
    <hop-drawer-body>
        <hop-main-nav class="mb-5" indicatorPosition="left" (click)="close()"></hop-main-nav>

        <hop-env-debitor-switcher orientation="vertical" (debitorChange)="close()"></hop-env-debitor-switcher>
    </hop-drawer-body>
    <hop-drawer-footer>
        <hr />
        <div class="c-hop-navigation-drawer__menus">
            <hop-env-language-menu (itemClick)="close()"></hop-env-language-menu>
            <hop-env-account-menu (itemClick)="close()"></hop-env-account-menu>
        </div>
        <hop-legal-nav (click)="close()"></hop-legal-nav>
    </hop-drawer-footer>
</hop-drawer>
