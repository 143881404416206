<form (submit)="onSubmit()" [formGroup]="form" class="c-hop-search-bar__form">

    <input [attr.aria-label]="placeholder | translate"
           [placeholder]="placeholder | translate"
           class="c-hop-search-bar__input form-control"
           formControlName="search"
           type="text" />

    <button class="c-hop-search-bar__submit-button" hopIconButton type="submit">
        <fa-icon [icon]="_icons.faSearch"></fa-icon>
    </button>

</form>
