import { ALL_SELECTABLE_CONTRACT_COLUMNS } from '../data/all-selectable-contract-columns';
import { ALL_SELECTABLE_DEBITOR_COLUMNS } from '../data/all-selectable-debitor-columns';
import { ALL_SELECTABLE_ORDER_REQUEST_COLUMNS } from '../data/all-selectable-order-request-columns';
import { ALL_SELECTABLE_USER_COLUMNS } from '../data/all-selectable-user-columns';
import { IAccountPreferences } from '../entities/preferences/account-preferences.interface';
import { PreferenceGroupColumn } from '../entities/preferences/preference-group-column';
import { PreferenceGroup } from '../entities/preferences/preference-group.enum';

export type SanitizedPreferences<GROUP extends PreferenceGroup> = Partial<IAccountPreferences[GROUP]> & { __sanitized: true };

export function sanitizePreferences<GROUP extends PreferenceGroup>(group: GROUP, preferences: Partial<IAccountPreferences[GROUP]>): SanitizedPreferences<GROUP> {
    return {
        browsePage: {
            columns: _sanitizeColumns(group, preferences.browsePage?.columns)
        }
    } as SanitizedPreferences<GROUP>;
}

function _sanitizeColumns<GROUP extends PreferenceGroup>(group: GROUP, columns?: PreferenceGroupColumn<GROUP>[]) {
    if (!columns?.length) {
        throw new Error('No columns to display');
    }

    const columnsSet = new Set(columns);

    const sanitizedColumns = _getSortedColumns(group)?.filter((column) => columnsSet.has(column as PreferenceGroupColumn<GROUP>));

    if (!sanitizedColumns?.length) {
        throw new Error('No columns to display');
    }

    return sanitizedColumns;
}

function _getSortedColumns<GROUP extends PreferenceGroup>(group: GROUP): PreferenceGroupColumn<GROUP>[] | undefined {
    switch (group) {
        case PreferenceGroup.CONTRACT:
            return ALL_SELECTABLE_CONTRACT_COLUMNS;
        case PreferenceGroup.DEBITOR:
            return ALL_SELECTABLE_DEBITOR_COLUMNS;
        case PreferenceGroup.ORDER_REQUEST:
            return ALL_SELECTABLE_ORDER_REQUEST_COLUMNS;
        case PreferenceGroup.USER:
            return ALL_SELECTABLE_USER_COLUMNS;
    }

    return undefined;
}
