import { Pipe, PipeTransform } from '@angular/core';

import { FilterWithKey } from '../models/filter';
import { FilterResult } from '../models/filter-result';
import { FilterUtils } from '../utils/filter.utils';

@Pipe({
    name: 'hasValue'
})
export class HopHasValuePipe implements PipeTransform {

    transform<R extends FilterResult[keyof FilterResult]>(filter: FilterWithKey<R>, result: FilterResult): boolean {
        if (filter == null || result == null) {
            return false;
        }

        return FilterUtils.hasValue(filter, result[filter.key]);
    }

}
