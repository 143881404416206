import { ComponentType, Overlay, OverlayConfig, OverlayRef, ScrollStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Inject, Injectable, InjectionToken, Injector, Optional, TemplateRef, Type } from '@angular/core';
import { defaults as _defaults } from 'lodash';

import { OverlayContainerComponent } from '../directives/overlay-container.component';
import { HopOverlayConfig } from '../models/overlay-config';
import { HopOverlayRef } from '../models/overlay-ref';
import { OVERLAY_DATA_TOKEN } from '../tokens/overlay-data-token.token';
import { OVERLAY_REF_TYPE } from '../tokens/overlay-ref-type.token';
import { OVERLAY_SCROLL_STRATEGY } from '../tokens/overlay-scroll-strategy.token';

/**
 * Base class for overlay services. The base overlay service allows
 * for arbitrary overlay refs and overlay container components.
 */
@Injectable()
export abstract class OverlayBase<CONFIG extends HopOverlayConfig> {

    protected constructor(protected readonly _overlay: Overlay,
                          protected readonly _injector: Injector,
                          protected readonly _containerType: Type<OverlayContainerComponent<CONFIG>>,
                          protected readonly _configType: Type<CONFIG>,
                          protected readonly _refType: Type<CONFIG>,
                          protected readonly _dataToken: InjectionToken<unknown>,
                          @Inject(OVERLAY_SCROLL_STRATEGY) private readonly _scrollStrategy: ScrollStrategy,
                          @Optional() @Inject(HopOverlayConfig) protected readonly _defaultConfig?: CONFIG) {

    }

    /**
     * Opens a overlay with the content passed.
     *
     * @param content the content to display inside the overlay
     * @param config custom configuration to use when displaying the overlay
     */
    open<T, R>(content: ComponentType<T> | TemplateRef<T>, config: CONFIG): HopOverlayRef<CONFIG, T, R> {
        config = _defaults(config, this._defaultConfig, this.createDefaultConfig());


        const overlayRef = this._overlay.create(this._createOverlayConfig(config));
        const overlayContainer = this._attachOverlayContainer(config, overlayRef);
        const ref = this.createOverlayRef<T, R>(overlayRef, overlayContainer);

        overlayContainer.attachContent(content, config, ref);

        // JH: maybe store open overlays?
        // this._openOverlays.push(ref);

        overlayContainer.initializeWithAttachedContent();

        return ref;
    }

    /**
     * Attaches a overlay container to the overlay.
     *
     * @param config the custom {@link HopOverlayConfig}
     * @param overlayRef the overlay ref
     * @private
     */
    private _attachOverlayContainer(config: CONFIG, overlayRef: OverlayRef) {
        const parent = config?.viewContainerRef?.injector ?? this._injector;
        const injector = Injector.create({
            parent,
            providers: [
                { provide: this._configType, useValue: config },
                { provide: HopOverlayConfig, useExisting: this._configType },
                { provide: OVERLAY_REF_TYPE, useValue: this._refType },
                { provide: OVERLAY_DATA_TOKEN, useValue: this._dataToken }
            ]
        });

        const containerPortal = new ComponentPortal(
            this._containerType,
            config.viewContainerRef,
            injector
        );
        const containerRef = overlayRef.attach(containerPortal);

        return containerRef.instance;
    }

    /**
     * Creates a {@link OverlayConfig} from a {@link HopOverlayConfig}
     *
     * @param config
     * @protected
     */
    protected _createOverlayConfig(config: CONFIG): OverlayConfig {
        return new OverlayConfig({
            height: config.height,
            width: config.width,
            maxHeight: config.maxHeight,
            maxWidth: config.maxWidth,
            minHeight: config.minHeight,
            minWidth: config.minWidth,
            disposeOnNavigation: config.closeOnNavigation,
            backdropClass: config.backdropClass,
            direction: config.direction,
            panelClass: config.panelClass,
            scrollStrategy: config.scrollStrategy || this._scrollStrategy,
            hasBackdrop: true
        });
    }

    protected abstract createOverlayRef<T, R>(overlayRef: OverlayRef, overlayContainer: OverlayContainerComponent<CONFIG>): HopOverlayRef<CONFIG, T, R>;

    protected abstract createDefaultConfig(): CONFIG;

}
