import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { DEBITOR_SWITCHER_TAB_LIST_MAX_COUNT, DebitorSwitcherFacade, IActiveDebitor } from '@hopsteiner/environment/domain';
import { DataSource, INITIAL_LIST_RANGE } from '@hopsteiner/shared/collections';

import { BaseDebitorChooserComponent } from '../base-debitor-chooser.component';

@Component({
    selector: 'hop-env-debitor-switcher-tab-list',
    templateUrl: './debitor-switcher-tab-list.component.html',
    styleUrls: [ './debitor-switcher-tab-list.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-env-debitor-switcher-tab-list'
    },
    providers: [
        {
            provide: INITIAL_LIST_RANGE,
            useFactory: (maxCount: number) => ({ start: 0, end: maxCount }),
            deps: [ DEBITOR_SWITCHER_TAB_LIST_MAX_COUNT ]
        }
    ]
})
export class HopDebitorSwitcherTabListComponent extends BaseDebitorChooserComponent {

    readonly debitorsDataSource: DataSource<IActiveDebitor>;

    constructor(private readonly _debitorSwitcherFacade: DebitorSwitcherFacade) {
        super();
        this.debitorsDataSource = _debitorSwitcherFacade.createAllDebitorsDataSource();
    }

}
