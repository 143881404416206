import { Directive, NgModule } from '@angular/core';

@Directive({
    selector: '[hopScrollContainer]'
})
export class HopScrollContainerDirective {

}

@NgModule({
    declarations: [ HopScrollContainerDirective ],
    exports: [ HopScrollContainerDirective ]
})
export class HopScrollContainerDirectiveModule {

}
