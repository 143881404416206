import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HopCollectionsModule } from '@hopsteiner/shared/collections';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';

import { HopPaginatorComponent } from './components/paginator/paginator.component';


@NgModule({
    imports: [
        CommonModule,

        NgbPaginationModule,
        FontAwesomeModule,

        HopCollectionsModule
    ],
    declarations: [
        HopPaginatorComponent
    ],
    exports: [
        HopPaginatorComponent,

        HopCollectionsModule
    ]
})
export class HopPaginatorModule {

}
