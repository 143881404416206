export interface ICancellation {
    contractId: string;
    cancelledAt: Date;
    originQuantity: number;
    cancelledQuantity: number;
    newQuantity: number;
    quantityUnit: number;
    navisionId: string;
    displayQuantityUnit: string;
    displayOriginQuantityUnitLess: number;
    displayOriginQuantity: string;
    displayCancelledQuantityUnitLess: number;
    displayCancelledQuantity: string;
    displayNewQuantityUnitLess: number;
    displayNewQuantity: string;
}
