import { Pipe, PipeTransform, QueryList } from '@angular/core';

import { HopLceCustomStateDirective } from '../directives/lce-custom-state.directive';
import { LceStateType } from '../models/lce-state.enum';

@Pipe({
    name: 'filterState'
})
export class HopFilterStatePipe implements PipeTransform {

    transform(queryList: QueryList<HopLceCustomStateDirective>, state: LceStateType | string): HopLceCustomStateDirective[] {
        return queryList?.filter((child) => child.state === state) ?? [];
    }

}
