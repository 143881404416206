import { IChange, IContactPerson } from '@hopsteiner/shared/components';

export interface IDebitorChangeRequest {
    name: IChange;
    additionalName: IChange;
    fullName: IChange;
    address: IChange;
    additionalAddress: IChange;
    phone: IChange;
    zip: IChange;
    city: IChange;
    email: IChange;
    homepage: IChange;
    beerOutputInHectoliters: IChange;
    gramAlphaPerHectoLiter: IChange;
    lastModified: Date;
    createdAt: Date;
    completedAt: Date;
    proposer: IContactPerson;
}
