import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { EnumLike } from '@hopsteiner/shared/models';

import { FormError } from '../models/form-error.enum';

const ERROR_MESSAGES: Record<FormError, string> = {
    [FormError.REQUIRED]: 'COMPONENTS.FORM.ERROR.REQUIRED',
    [FormError.NO_EQUAL_PASSWORD]: 'COMPONENTS.FORM.ERROR.NO_EQUAL_PASSWORD',
    [FormError.EMAIL]: 'COMPONENTS.FORM.ERROR.EMAIL',
    [FormError.MAX]: 'COMPONENTS.FORM.ERROR.MAX',
    [FormError.DEFAULT]: 'COMPONENTS.FORM.ERROR.UNKNOWN',
    [FormError.PASSWORD_WEAK]: 'COMPONENTS.FORM.ERROR.PASSWORD_STRENGTH.WEAK'
};

const ERRORS = [
    FormError.REQUIRED,
    FormError.EMAIL,
    FormError.NO_EQUAL_PASSWORD,
    FormError.MAX,
    FormError.DEFAULT,
    FormError.PASSWORD_WEAK
];

@Pipe({
    name: 'formError'
})
export class HopFormErrorPipe implements PipeTransform {

    transform(errors: ValidationErrors | null | undefined, ...ignoreErrors: (EnumLike<FormError> | string)[]): string {

        // return empty string and not null because pipe only accepts string on html
        if (!errors) {
            return '';
        }

        const errorCode = ERRORS.find((error) => error in errors);

        if (errorCode) {
            if (ignoreErrors != null && ignoreErrors.includes(errorCode)) {
                return '';
            }

            return ERROR_MESSAGES[errorCode];
        }

        return ERROR_MESSAGES[FormError.DEFAULT];
    }
}
