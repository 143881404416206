<div *ngIf="(user$ | async) as user" ngbDropdown [placement]="['bottom-right', 'top-right']">
    <button ngbDropdownToggle hopButton>
        {{ user.name }}
        <fa-icon [icon]="_icon.faChevronDown"></fa-icon>
    </button>
    <div ngbDropdownMenu>
        <button ngbDropdownItem
                routerLink="/account"
                (click)="onItemClick($event)">
            {{ 'ENVIRONMENT.ACCOUNT_MENU.MENU_ITEMS.ACCOUNT.LABEL' | translate }}
        </button>
        <button ngbDropdownItem (click)="logOut($event)">{{ 'ENVIRONMENT.ACCOUNT_MENU.MENU_ITEMS.LOGOUT.LABEL' | translate }}</button>
    </div>
</div>
