import { ChangeDetectorRef, ContentChild, Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

import { HopSelectOptionDirective } from '../directives/select-option/select-option.directive';
import { FilterType } from '../models/filter-type.enum';

import { BaseFilterComponent } from './base-filter.component';

@Directive()
export abstract class BaseSelectFilterComponent<TYPE extends FilterType, VALUE, OPTION = VALUE> extends BaseFilterComponent<TYPE, VALUE> implements OnInit, OnDestroy {

    @Input()
    optionTemplate?: TemplateRef<VALUE>;

    @ContentChild(HopSelectOptionDirective)
    optionDirective?: HopSelectOptionDirective;

    readonly formControl = new FormControl();

    private readonly _onDestroy = new Subject<void>();

    constructor(renderer: Renderer2,
                elementRef: ElementRef,
                private readonly _changeDetectorRef: ChangeDetectorRef) {
        super(renderer, elementRef);
    }

    ngOnInit() {
        this.formControl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe((value) => this._dispatchValueChange(value));
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    override writeValue(value: VALUE | undefined | null) {
        super.writeValue(value);
        this.formControl.setValue(value);
        this._changeDetectorRef.markForCheck();
    }

    abstract toggleOption(option: OPTION): void;

    abstract isSelected(option: OPTION): boolean;

}
