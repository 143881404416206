import { ChangeDetectionStrategy, Component, Host, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { uniqueId } from 'lodash';

import { HopRadioGroupComponent } from '../radio-group/radio-group.component';

@Component({
    selector: 'hop-option',
    templateUrl: './option.component.html',
    styleUrls: [ './option.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-option'
    }
})
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class HopOptionComponent<T = any> {

    @Input()
    value?: T;

    private _name?: string;
    private readonly _defaultName = uniqueId();

    constructor(@Host() private readonly _radioGroup: HopRadioGroupComponent) {

    }

    @HostBinding('class.c-hop-option--disabled')
    get disabled(): boolean {
        return this._radioGroup.disabled;
    }

    @HostBinding('class.c-hop-option--checked')
    get checked(): boolean {
        return this._radioGroup.value === this.value;
    }

    set checked(value: boolean) {
        if (value != null) {
            this._radioGroup.value = this.value;
        }
    }

    @Input()
    get name(): string {
        return this._name ?? this._defaultName;
    }

    set name(value: string) {
        this._name = value;
    }

    select() {
        this.checked = true;
    }
}
