import { ICreateUserChangeRequestData } from '../models/create-user-change-request-data.interface';
import { IEditUserData } from '../models/edit-user-data.interface';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace EditUserActions {

    export class EditUser {
        static readonly type = '[User] Edit User';

        constructor(readonly userId: number, readonly editUserData: IEditUserData) {
        }
    }

    export class SendChangeRequest {
        static readonly type = '[User] Send User Change Request';

        constructor(readonly userId: number, readonly userChangeRequestData: ICreateUserChangeRequestData) {
        }
    }
}
