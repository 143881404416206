import { Injectable } from '@angular/core';
import { Language } from '@hopsteiner/shared/models';
import { Store } from '@ngxs/store';

import { LanguageActions } from '../actions/language.actions';
import { EnvironmentState } from '../environment.state';

@Injectable({
    providedIn: 'root'
})
export class LanguageFacade {

    constructor(private readonly _store: Store) {

    }

    get currentLanguage() {
        return this._store.selectSnapshot(EnvironmentState.selectCurrentLanguage);
    }

    get currentLanguage$() {
        return this._store.select(EnvironmentState.selectCurrentLanguage);
    }

    changeLanguage(language: Language) {
        this._store.dispatch(new LanguageActions.ChangeLanguage(language));
    }

    changeTranslationLanguage(language: Language) {
        this._store.dispatch(new LanguageActions.ChangeTranslationLanguage(language));
    }
}
