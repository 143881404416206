import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { PreferenceGroupColumn } from '../../entities/preferences/preference-group-column';
import { PreferenceGroup } from '../../entities/preferences/preference-group.enum';
import { PreferenceActions } from '../actions/preference.actions';
import { PreferencesState } from '../preferences.state';

@Injectable({ providedIn: 'root' })
export class HopPreferencesFacade {

    constructor(private readonly _store: Store) {

    }

    get preferences$() {
        return this._store.select(PreferencesState);
    }

    getPreferenceGroup(group: PreferenceGroup) {
        return this._store.select(PreferencesState.selectGroup(group));
    }

    getBrowsePageColumns<GROUP extends PreferenceGroup>(group: GROUP) {
        return this._store.selectSnapshot(PreferencesState.selectBrowsePageColumns<GROUP>(group));
    }

    watchBrowsePageColumns<GROUP extends PreferenceGroup>(group: GROUP) {
        return this._store.select(PreferencesState.selectBrowsePageColumns<GROUP>(group));
    }

    setBrowsePageColumns<GROUP extends PreferenceGroup>(group: PreferenceGroup, columns: PreferenceGroupColumn<GROUP>[]) {
        return this._store.dispatch(new PreferenceActions.SetBrowsePageColumns(group, columns));
    }

}
