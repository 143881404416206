import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';
import { DebitorSwitcherFacade, DebitorSwitcherMode, IActiveDebitor } from '@hopsteiner/environment/domain';
import { EnumLike } from '@hopsteiner/shared/models';
import { Observable } from 'rxjs';

import { Orientation } from '../../models/orientation.enum';


@Component({
    selector: 'hop-env-debitor-switcher',
    templateUrl: './debitor-switcher.component.html',
    styleUrls: [ './debitor-switcher.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-env-debitor-switcher'
    }
})
export class HopDebitorSwitcherComponent {

    readonly DebitorSwitcherAppearance = DebitorSwitcherMode;

    @Input()
    orientation: EnumLike<Orientation> = Orientation.HORIZONTAL;

    readonly _isShown$: Observable<boolean>;
    readonly _isDisabled$: Observable<boolean>;
    readonly _mode$: Observable<DebitorSwitcherMode | undefined>;
    readonly _activeDebitor$: Observable<IActiveDebitor | null>;

    @Output()
    readonly debitorChange = new EventEmitter<IActiveDebitor | null>();

    constructor(private readonly _debitorSwitcherFacade: DebitorSwitcherFacade) {
        this._isShown$ = _debitorSwitcherFacade.isShown$;
        this._isDisabled$ = _debitorSwitcherFacade.isDisabled$;
        this._mode$ = _debitorSwitcherFacade.mode$;
        this._activeDebitor$ = _debitorSwitcherFacade.activeDebitor$;
    }

    @HostBinding('class.c-hop-env-debitor-switcher--vertical')
    get isVertical(): boolean {
        return this.orientation === Orientation.VERTICAL;
    }

    setActiveDebitor(debitor: IActiveDebitor | null) {
        if (debitor == null) {
            this._debitorSwitcherFacade.clearActiveDebitor();
        } else {
            this._debitorSwitcherFacade.setActiveDebitor(debitor);
        }

        this.debitorChange.emit(debitor);
    }

}
