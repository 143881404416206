import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faGamepad } from '@fortawesome/free-solid-svg-icons/faGamepad';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { faUserCog } from '@fortawesome/free-solid-svg-icons/faUserCog';
import { HopAuthApiFacade, HopAuthApiModule, IActiveUser } from '@hopsteiner/auth/api';
import { HopEnvironmentApiModule } from '@hopsteiner/environment/api';
import { HopEnvFeatureAccountMenuModule } from '@hopsteiner/environment/feature-account-menu';
import { HopEnvDebitorSwitcherModule } from '@hopsteiner/environment/feature-debitor-switcher';
import { HopEnvFeatureLanguageMenuModule } from '@hopsteiner/environment/feature-language-menu';
import { HopLegalNavModule } from '@hopsteiner/legal/ui-nav';
import { Breakpoint, Breakpoints } from '@hopsteiner/shared/breakpoints';
import { HopButtonModule, HopNavModule } from '@hopsteiner/shared/components';
import { UserPermission } from '@hopsteiner/shared/models';
import { HopDrawer } from '@hopsteiner/shared/overlays';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { AppMainNavComponentModule } from '../../components/main-nav/main-nav.component';
import { AppNavigationDrawer, AppNavigationDrawerModule } from '../../drawers/navigation/navigation.drawer';

@Component({
    selector: 'hop-root-layout',
    templateUrl: './root.layout.html',
    styleUrls: [ './root.layout.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-root-layout'
    }
})
export class AppRootLayout {
    readonly UserPermission = UserPermission;

    readonly _icons = {
        faBars,
        faGamepad,
        faHome,
        faUserCog
    };

    readonly showCompactToolbar$: Observable<boolean>;
    readonly activeUser$: Observable<IActiveUser | null>;

    constructor(breakpoints: Breakpoints,
                private readonly _drawer: HopDrawer,
                private readonly _authFacade: HopAuthApiFacade) {
        this.showCompactToolbar$ = breakpoints.observeMatched(Breakpoints.down(Breakpoint.XXL));
        this.activeUser$ = this._authFacade.activeUser$;
    }

    openNavigationDrawer() {
        AppNavigationDrawer.open(this._drawer);
    }
}

@NgModule({
    imports: [
        CommonModule,
        RouterModule,

        FontAwesomeModule,
        TranslateModule,
        FontAwesomeModule,

        HopAuthApiModule,
        HopButtonModule,
        HopEnvironmentApiModule,
        HopEnvDebitorSwitcherModule,
        HopEnvFeatureAccountMenuModule,
        HopEnvFeatureLanguageMenuModule,
        HopNavModule,
        HopLegalNavModule,

        AppMainNavComponentModule,
        AppNavigationDrawerModule
    ],
    declarations: [ AppRootLayout ],
    exports: [ AppRootLayout ]
})
export class AppRootLayoutModule {

}
