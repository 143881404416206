import { ICreateOrderRequestDto } from '../../entities/create-order-request.dto';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace RequestOrderActions {

    export class RequestOrder {
        static readonly type = '[OrderRequest] Request Order';

        constructor(readonly orderData: ICreateOrderRequestDto) {
        }
    }
}
