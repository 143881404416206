import { TemplateRef } from '@angular/core';
import { DataSource } from '@hopsteiner/shared/collections';

import { DateRange } from './date-range';
import { FilterType } from './filter-type.enum';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface IBaseFilter<FILTER_TYPE extends FilterType, DATA_TYPE, VALUE_TYPE = DATA_TYPE> {

    /**
     * The type of the filter
     */
    type: FILTER_TYPE;

    /**
     * Translation Key for the filter name
     */
    name: string;

    hasValue?: (value: DATA_TYPE) => boolean;

    valueTemplate?: TemplateRef<VALUE_TYPE>;

    /**
     * Optional value to be set initially
     */
    initialValue?: DATA_TYPE;

    /**
     * Optional value to allow removing of filter, default value resolves to true
     */
    removable?: boolean;
}

export interface ISingleSelectFilter<T, OPTION = T> extends IBaseFilter<FilterType.SINGLE_SELECT, T> {

    /**
     * The data available in this filter
     */
    dataSource: DataSource<OPTION>;

    optionTemplate?: TemplateRef<OPTION>;

    getOptionValue?(option: OPTION): T;
}

export interface IMultiSelectFilter<T, OPTION = T> extends IBaseFilter<FilterType.MULTI_SELECT, T[], T> {

    /**
     * The data available in this filter
     */
    dataSource: DataSource<OPTION>;

    optionTemplate?: TemplateRef<OPTION>;

    getOptionValue?(option: OPTION): T;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DateOrFactory<T = any> = Date | ((result: T) => Date | null);

export interface IDateFilter extends IBaseFilter<FilterType.DATE, Date> {

    /**
     * The minimum allowed date
     */
    minDate?: DateOrFactory;

    /**
     * The maximum allowed date
     */
    maxDate?: DateOrFactory;

}

export interface IDateRangeFilter extends IBaseFilter<FilterType.DATE_RANGE, DateRange> {

    /**
     * The minimum allowed date
     */
    minDate?: DateOrFactory;

    /**
     * The maximum allowed date
     */
    maxDate?: DateOrFactory;
}

/**
 * All allowed filter variants for the data type RESULT
 */
export type Filter<RESULT> =
// Every type can be represented by a single select filter
// eslint-disable-next-line @typescript-eslint/no-explicit-any
    ISingleSelectFilter<RESULT, any> |
    // Only arrays can be represented by a multi select filter
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (RESULT extends (infer E)[] ? IMultiSelectFilter<E, any> :
        // Dates can be represented by a date filter
        RESULT extends Date ? IDateFilter :
            // Date ranges can be represented by a date range filter
            RESULT extends DateRange ? IDateRangeFilter : never);

export type FilterWithKey<T> = Filter<T> & { key: string };
export type FilterWithValue<T> = { filter: FilterWithKey<T>, value: T };
