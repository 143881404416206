import { IResetPasswordCredentials } from '../models/reset-password-credentials.interface';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ForgotPasswordActions {

    export class ForgotPassword {
        static readonly type = '[Auth] Forgot Password';

        constructor(readonly email: string) {

        }
    }

    export class ResetForgottenPassword {
        static readonly type = '[Auth] Reset forgotten password';

        constructor(readonly data: IResetPasswordCredentials) {
        }
    }

    export class CheckResetPasswordErrors {
        static readonly type = '[Auth] Check reset password errors';

        constructor(readonly hash: string) {
        }
    }
}
