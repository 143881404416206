<hop-dialog>
    <hop-dialog-header>
        <h2 hopDialogTitle id="confirm-dialog-title">{{ data.title | translate }}</h2>
        <p>{{ 'OVERLAYS.CONFIRM_DIALOG.CURRENT_DATE_TIME' | translate:{ dateTime: currentDateTime } }}</p>
    </hop-dialog-header>

    <hop-dialog-body>
        <hop-alert [status]="data.status || 'warning'">
            <p class="c-hop-confirm-dialog__message" [innerHTML]="data.message | translate:data.messageArgs | sanitizeHtml"></p>
        </hop-alert>
    </hop-dialog-body>

    <hop-dialog-footer>
        <button hopButton="plain" (click)="cancel()">
            {{ data.cancelButtonLabel || 'OVERLAYS.CONFIRM_DIALOG.CANCEL_BUTTON_LABEL' | translate }}
        </button>
        <button hopButton="solid" (click)="confirm()">
            {{ data.confirmButtonLabel || 'OVERLAYS.CONFIRM_DIALOG.CONFIRM_BUTTON_LABEL' | translate }}
        </button>
    </hop-dialog-footer>
</hop-dialog>
