import { Injectable } from '@angular/core';
import { UserStatus } from '@hopsteiner/shared/models';
import { Action, State, StateContext } from '@ngxs/store';

import { UserRepository } from '../infrastructure/user.repository';

import { DeleteUserActions } from './actions/delete-user.actions';
import { EditUserActions } from './actions/edit-user.actions';
import { UserActions } from './actions/user.actions';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IUserStateModel {

}

@State<IUserStateModel>({
    name: 'user',
    defaults: {}
})
@Injectable({
    providedIn: 'root'
})
export class UserState {

    constructor(private readonly _userRepository: UserRepository) {
    }

    @Action(DeleteUserActions.SendDeleteRequest)
    async sendDeleteRequest(ctx: StateContext<IUserStateModel>, action: DeleteUserActions.SendDeleteRequest) {
        await this._userRepository.createDeleteRequest(action.userId);
    }

    @Action(EditUserActions.EditUser)
    async updateUser(ctx: StateContext<IUserStateModel>, action: EditUserActions.EditUser) {
        await this._userRepository.patch(action.userId, action.editUserData);
    }

    @Action(EditUserActions.SendChangeRequest)
    async sendChangeRequest(ctx: StateContext<IUserStateModel>, action: EditUserActions.SendChangeRequest) {
        await this._userRepository.createChangeRequest(action.userId, action.userChangeRequestData);
    }

    @Action(UserActions.ActivateUser)
    async activateUser(ctx: StateContext<IUserStateModel>, action: UserActions.ActivateUser) {
        await this._userRepository.patch(action.userId, { status: UserStatus.ACTIVE });
    }

    @Action(UserActions.DeactivateUser)
    async deactivateUser(ctx: StateContext<IUserStateModel>, action: UserActions.DeactivateUser) {
        await this._userRepository.patch(action.userId, { status: UserStatus.INACTIVE });
    }
}
