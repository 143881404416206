import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';

import { environment } from '../../environments/environment';


@NgModule({
    imports: [
        CommonModule,

        NgxsModule.forRoot([], { developmentMode: !environment.production }),
        NgxsRouterPluginModule.forRoot(),
        environment.production ? [] : NgxsReduxDevtoolsPluginModule.forRoot()
    ],
    exports: [
        NgxsModule
    ]
})
export class AppStateModule {
}
