import { ChangeDetectionStrategy, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { IActiveDebitor } from '@hopsteiner/environment/domain';
import { ISortableQuery } from '@hopsteiner/shared/collections';
import { HopSortDirective } from '@hopsteiner/shared/components';
import { HOP_DATA_COLLECTION_VIEWER_HOST, HopDataTableComponent, IDataCollectionViewer } from '@hopsteiner/shared/data-table';

import { BaseDebitorDrawerCollectionComponent } from '../base-debitor-drawer-collection.component';

@Component({
    selector: 'hop-env-debitor-table',
    templateUrl: './table.component.html',
    styleUrls: [ './table.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-env-debitor-table'
    },
    providers: [
        {
            provide: HopSortDirective,
            useFactory: (component: HopEnvDebitorTableComponent) => {
                return component.sort;
            },
            deps: [ HopEnvDebitorTableComponent ]
        },
        {
            provide: HOP_DATA_COLLECTION_VIEWER_HOST,
            useExisting: HopEnvDebitorTableComponent
        }
    ]
})
export class HopEnvDebitorTableComponent extends BaseDebitorDrawerCollectionComponent {

    @ViewChild(HopDataTableComponent, { static: true })
    table!: HopDataTableComponent<IActiveDebitor, ISortableQuery>;

    @ViewChild(HopSortDirective, { static: true })
    sort!: HopSortDirective;

    readonly _tableColumns = [ 'navisionId', 'fullName', 'zip', 'city', 'select' ];

    get collectionViewer(): IDataCollectionViewer<IActiveDebitor, ISortableQuery> {
        return this.table;
    }
}
