import { FocusOrigin } from '@angular/cdk/a11y';
import { ESCAPE, hasModifierKey } from '@angular/cdk/keycodes';
import { OverlayRef } from '@angular/cdk/overlay';
import { uniqueId as _uniqueId } from 'lodash';
import { filter, Observable, Subject, take } from 'rxjs';

import { OverlayContainerComponent } from '../directives/overlay-container.component';

import { HopOverlayConfig } from './overlay-config';
import { OverlayState } from './overlay-state.enum';

export abstract class HopOverlayRef<CONFIG extends HopOverlayConfig = HopOverlayConfig, T = unknown, R = unknown> {

    componentInstance?: T | null;

    /** Whether the user is allowed to close the overlay. */
    disableClose?: boolean;

    /** Subject for notifying the user that the overlay has finished opening. */
    private readonly _afterOpened = new Subject<void>();

    /** Subject for notifying the user that the overlay has finished closing. */
    private readonly _afterClosed = new Subject<R | undefined>();

    /** Subject for notifying the user that the overlay has started closing. */
    private readonly _beforeClosed = new Subject<R | undefined>();

    private _result?: R;

    /** Handle to the timeout that's running as a fallback in case the exit animation doesn't fire. */
    private _closeFallbackTimeout?: number;

    /** Current state of the overlay. */
    private _state = OverlayState.OPENED;

    constructor(readonly _overlayRef: OverlayRef,
                private readonly _containerInstance: OverlayContainerComponent<CONFIG>,
                readonly id = _uniqueId('hop-overlay-')) {
        this.disableClose = this._containerInstance.config.disableClose;

        // Pass the id along to the container.
        _containerInstance.id = id;

        // Emit when opening animation completes
        _containerInstance._animationStateChanged
            .pipe(
                filter(event => event.state === OverlayState.OPENED),
                take(1)
            )
            .subscribe(() => {
                this._afterOpened.next();
                this._afterOpened.complete();
            });

        // Dispose overlay when closing animation is complete
        _containerInstance._animationStateChanged
            .pipe(
                filter(event => event.state === OverlayState.CLOSED),
                take(1)
            )
            .subscribe(() => {
                clearTimeout(this._closeFallbackTimeout);
                this._finishOverlayClose();
            });

        _overlayRef.detachments().subscribe(() => {
            this._beforeClosed.next(this._result);
            this._beforeClosed.complete();
            this._afterClosed.next(this._result);
            this._afterClosed.complete();
            this.componentInstance = null;
            this._overlayRef.dispose();
        });

        _overlayRef
            .keydownEvents()
            .pipe(
                filter(event => {
                    return event.keyCode === ESCAPE && !this.disableClose && !hasModifierKey(event);
                })
            )
            .subscribe(event => {
                event.preventDefault();
                this._closeOverlayVia('keyboard');
            });

        _overlayRef.backdropClick().subscribe(() => {
            if (this.disableClose) {
                this._containerInstance.recaptureFocus();
            } else {
                this._closeOverlayVia('mouse');
            }
        });
    }

    close(result?: R): void {
        this._result = result;

        // Transition the backdrop in parallel to the overlay.
        this._containerInstance._animationStateChanged
            .pipe(
                filter(event => event.state === OverlayState.CLOSING),
                take(1)
            )
            .subscribe(event => {
                this._beforeClosed.next(result);
                this._beforeClosed.complete();
                this._overlayRef.detachBackdrop();

                // The logic that disposes of the overlay depends on the exit animation completing, however
                // it isn't guaranteed if the parent view is destroyed while it's running. Add a fallback
                // timeout which will clean everything up if the animation hasn't fired within the specified
                // amount of time plus 100ms. We don't need to run this outside the NgZone, because for the
                // vast majority of cases the timeout will have been cleared before it has the chance to fire.
                this._closeFallbackTimeout = setTimeout(
                    () => this._finishOverlayClose(),
                    event.totalTime + 100
                );
            });

        this._state = OverlayState.CLOSING;
        this._containerInstance.startExitAnimation();
    }

    /**
     * Gets an observable that is notified when the overlay is finished opening.
     */
    afterOpened(): Observable<void> {
        return this._afterOpened;
    }

    /**
     * Gets an observable that is notified when the overlay is finished closing.
     */
    afterClosed(): Observable<R | undefined> {
        return this._afterClosed;
    }

    /**
     * Gets an observable that is notified when the overlay has started closing.
     */
    beforeClosed(): Observable<R | undefined> {
        return this._beforeClosed;
    }

    /**
     * Gets an observable that emits when the overlay's backdrop has been clicked.
     */
    backdropClick(): Observable<MouseEvent> {
        return this._overlayRef.backdropClick();
    }

    /**
     * Gets an observable that emits when keydown events are targeted on the overlay.
     */
    keydownEvents(): Observable<KeyboardEvent> {
        return this._overlayRef.keydownEvents();
    }

    /** Add a CSS class or an array of classes to the overlay pane. */
    addPanelClass(classes: string | string[]): this {
        this._overlayRef.addPanelClass(classes);
        return this;
    }

    /** Remove a CSS class or an array of classes from the overlay pane. */
    removePanelClass(classes: string | string[]): this {
        this._overlayRef.removePanelClass(classes);
        return this;
    }

    /** Gets the current state of the overlay's lifecycle. */
    getState(): OverlayState {
        return this._state;
    }

    /**
     * Finishes the overlay close by updating the state of the overlay
     * and disposing the overlay.
     */
    private _finishOverlayClose() {
        this._state = OverlayState.CLOSED;
        this._overlayRef.dispose();
    }

    /**
     * Closes the overlay with the specified interaction type.
     */
    private _closeOverlayVia(interactionType: FocusOrigin, result?: R) {
        this._containerInstance.closeInteractionType = interactionType;

        return this.close(result);
    }
}

