import { Injectable } from '@angular/core';
import { UserRole } from '@hopsteiner/shared/models';

import { ICreateUserFromNavisionDto } from '../../entities/create-user-from-navision.dto';
import { ICreateUserDto } from '../../entities/create-user.dto';
import { UserRepository } from '../../infrastructure/user.repository';

@Injectable({ providedIn: 'root' })
export class HopUserCreateFacade {

    constructor(private readonly _userRepository: UserRepository) {
    }

    validateEmail(email: string | null | undefined, role: UserRole) {
        return this._userRepository.validateEmail(email, role);
    }

    createUser(data: ICreateUserDto) {
        return this._userRepository.create(data);
    }

    createUserFromNavision(data: ICreateUserFromNavisionDto) {
        return this._userRepository.createFromNavision(data);
    }
}
