import { ChangeDetectionStrategy, Component, Input, SecurityContext, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope, faGlobe, faPhone } from '@fortawesome/free-solid-svg-icons';

import { ContactMethod, ContactMethodType } from '../../models/contact-method.enum';

const CONTACT_METHOD_ICONS: Record<ContactMethod, IconDefinition> = {
    [ContactMethod.PHONE]: faPhone,
    [ContactMethod.MAIL]: faEnvelope,
    [ContactMethod.HOMEPAGE]: faGlobe
};

@Component({
    selector: 'hop-contact-method',
    templateUrl: './contact-method.component.html',
    styleUrls: [ './contact-method.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-contact-method'
    }
})
export class HopContactMethodComponent {

    @Input()
    value?: string;

    _icon!: IconDefinition;

    private _method!: ContactMethodType;

    constructor(private readonly _domSanitizer: DomSanitizer) {
        this.method = ContactMethod.HOMEPAGE;
    }

    @Input()
    get method() {
        return this._method;
    }

    set method(value: ContactMethodType) {
        this._method = value;
        this._icon = CONTACT_METHOD_ICONS[this._method];
    }

    get link() {
        if (this.value == null) {
            return null;
        }

        switch (this.method) {
            case ContactMethod.PHONE:
                return this._domSanitizer.sanitize(SecurityContext.URL, `tel:${this.value}`);
            case ContactMethod.MAIL:
                return this._domSanitizer.sanitize(SecurityContext.URL, `mailto:${this.value}`);
            default:
                return this._sanitizeWebsiteUrl(this.value);
        }
    }

    private _sanitizeWebsiteUrl(value: string) {
        if (!value.startsWith('http://') && !value.startsWith('https://')) {
            value = `//${value}`;
        }

        return this._domSanitizer.sanitize(SecurityContext.URL, value);
    }
}
