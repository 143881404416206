import { ListRange } from '@angular/cdk/collections';

import { IPaginatedData } from '../models/paginated-data.interface';

export abstract class DataSourceUtils {

    /**
     * Breaks an array down into a page
     *
     * @param data the array to break down
     * @param listRange the page / list range to return
     */
    static paginate<T>(data: T[], listRange?: ListRange | null): IPaginatedData<T> {
        if (!listRange) {
            return {
                skip: 0,
                limit: data.length,
                total: data.length,
                data: data
            };
        }

        return {
            skip: listRange.start,
            limit: listRange.end,
            total: data.length,
            data: data.slice(listRange.start, listRange.end)
        };
    }
}
