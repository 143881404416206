import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HopButtonModule } from '@hopsteiner/shared/components';
import { TranslateModule } from '@ngx-translate/core';

import { OVERLAY_SCROLL_STRATEGY, overlayScrollStrategyFactory } from '../shared/tokens/overlay-scroll-strategy.token';

import { HopDrawerBodyComponent } from './components/drawer-body/drawer-body.component';
import { HopDrawerContainerComponent } from './components/drawer-container/drawer-container.component';
import { HopDrawerFooterComponent } from './components/drawer-footer/drawer-footer.component';
import { HopDrawerHeaderComponent } from './components/drawer-header/drawer-header.component';
import { HopDrawerComponent } from './components/drawer/drawer.component';
import { HopDrawerTitleDirective } from './directives/drawer-title.directive';
import { HopDrawer } from './services/drawer.service';


@NgModule({
    imports: [
        CommonModule,

        PortalModule,
        OverlayModule,
        FontAwesomeModule,
        TranslateModule,

        HopButtonModule
    ],
    providers: [
        HopDrawer,
        { provide: OVERLAY_SCROLL_STRATEGY, useFactory: overlayScrollStrategyFactory }
    ],
    declarations: [
        HopDrawerContainerComponent,
        HopDrawerComponent,
        HopDrawerHeaderComponent,
        HopDrawerBodyComponent,
        HopDrawerFooterComponent,

        HopDrawerTitleDirective
    ],
    exports: [
        HopDrawerComponent,
        HopDrawerHeaderComponent,
        HopDrawerBodyComponent,
        HopDrawerFooterComponent,

        HopDrawerTitleDirective
    ]
})
export class HopDrawerModule {

}
