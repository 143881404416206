import { Directive, TemplateRef } from '@angular/core';

@Directive({
    selector: 'ng-template[hopLabelDef]'
})
export class HopLabelDefDirective {

    constructor(readonly templateRef: TemplateRef<unknown>) {

    }

}
