import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';

import { UserRepository } from '../../infrastructure/user.repository';
import { EditUserActions } from '../actions/edit-user.actions';
import { ICreateUserChangeRequestData } from '../models/create-user-change-request-data.interface';
import { IEditUserData } from '../models/edit-user-data.interface';

@Injectable({
    providedIn: 'root'
})
export class HopUserEditFacade {

    constructor(private readonly _store: Store,
                private readonly _userRepository: UserRepository) {
    }

    updateUser(userId: number, editUserData: IEditUserData) {
        return lastValueFrom(this._store.dispatch(new EditUserActions.EditUser(userId, editUserData)));
    }

    sendChangeRequest(userId: number, userChangeRequestData: ICreateUserChangeRequestData) {
        return lastValueFrom(this._store.dispatch(new EditUserActions.SendChangeRequest(userId, userChangeRequestData)));
    }

    getChangeRequest(userId: number) {
        return this._userRepository.getChangeRequest(userId);
    }
}
