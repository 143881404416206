import { Directive, TemplateRef } from '@angular/core';

@Directive({
    selector: 'ng-template[hopTitleDef]'
})
export class HopTitleDefDirective {

    constructor(readonly templateRef: TemplateRef<unknown>) {

    }

}
