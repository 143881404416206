import { Injectable } from '@angular/core';
import { HopInvoiceApiFacade } from '@hopsteiner/invoice/api';
import { DataSource, ISortableQuery } from '@hopsteiner/shared/collections';
import { HopDrawer } from '@hopsteiner/shared/overlays';

import { ICancellation } from '../../entities/cancellation.interface';
import { IDetailContract } from '../../entities/contract.interface';
import { IRequestShipmentData } from '../../entities/request-shipment-data.interface';
import { IShipmentRequest } from '../../entities/shipment-request.interface';
import { IShipment } from '../../entities/shipment.interface';
import { CancellationDataSource } from '../../infrastructure/cancellation.data-source';
import { ContractRepository } from '../../infrastructure/contract.repository';
import { DeliveryNoteRepository } from '../../infrastructure/delivery-note.repository';
import { ShipmentRequestRepository } from '../../infrastructure/shipment-request.repository';
import { ShipmentDataSource } from '../../infrastructure/shipment.data-source';

@Injectable({
    providedIn: 'root'
})
export class HopContractDetailFacade {

    constructor(private readonly _drawer: HopDrawer,
                private readonly _contractRepository: ContractRepository,
                private readonly _shipmentRequestRepository: ShipmentRequestRepository,
                private readonly _deliveryNoteRepository: DeliveryNoteRepository,
                private readonly _invoiceApiFacade: HopInvoiceApiFacade) {

    }

    getContract(id: string): Promise<IDetailContract> {
        return this._contractRepository.get(id);
    }

    createShipmentsDataSource(contractId: string): DataSource<IShipment, ISortableQuery> {
        return new ShipmentDataSource(contractId, this._contractRepository);
    }

    createCancellationsDataSource(contractId: string): DataSource<ICancellation, ISortableQuery> {
        return new CancellationDataSource(contractId, this._contractRepository);
    }

    getShipmentRequests(id: string): Promise<IShipmentRequest[]> {
        return this._shipmentRequestRepository.get(id);
    }

    getRequestShipmentData(id: string): Promise<IRequestShipmentData> {
        return this._shipmentRequestRepository.getRequestShipmentData(id);
    }

    async downloadDeliveryNote(deliveryNoteId: string): Promise<Blob> {
        return this._deliveryNoteRepository.export(deliveryNoteId);
    }

    async downloadInvoice(invoiceId: string): Promise<Blob> {
        return this._invoiceApiFacade.downloadInvoice(invoiceId);
    }

    async downloadCultivar(contractId: string) {
        return this._contractRepository.exportCultivar(contractId);
    }
}
