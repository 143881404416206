import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { HopContractDomainRootModule } from '@hopsteiner/contract/domain';

@NgModule({
    imports: [ CommonModule ]
})
export class ContractApiModule {
}

@NgModule({})
export class HopContractApiModule {

    static forRoot(): ModuleWithProviders<HopContractApiRootModule> {
        return {
            ngModule: HopContractApiRootModule,
            providers: []
        };
    }
}

@NgModule({
    imports: [
        HopContractDomainRootModule,
        HopContractApiModule
    ],
    exports: [
        HopContractApiModule
    ]
})
export class HopContractApiRootModule {

}
