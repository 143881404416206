import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseRepository, Endpoint } from '@hopsteiner/shared/data-access';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DeliveryNoteRepository extends BaseRepository<unknown> {

    constructor(httpClient: HttpClient) {
        super(httpClient, {
            endpoint: Endpoint.DELIVERY_NOTES
        });
    }

    async export(deliveryNoteId: string): Promise<Blob> {
        return firstValueFrom(this._httpClient.get(`${this.url}/${deliveryNoteId}/export`, {
            responseType: 'blob'
        }));
    }
}
