import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoint, UnpaginatedRepository } from '@hopsteiner/shared/data-access';

import { ICultivar } from '../entities/cultivar.interface';

@Injectable({
    providedIn: 'root'
})
export class CultivarRepository extends UnpaginatedRepository<ICultivar> {

    constructor(httpClient: HttpClient) {
        super(httpClient, {
            endpoint: Endpoint.CULTIVARS
        });
    }
}
