import { ChangeDetectionStrategy, Component, forwardRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';

import { DateRange } from '../../models/date-range';
import { IDateRangeFilter } from '../../models/filter';
import { FilterType } from '../../models/filter-type.enum';
import { DateFilterUtils } from '../../utils/date-filter.utils';
import { BaseFilterComponent } from '../base-filter.component';

@Component({
    selector: 'hop-date-range-filter',
    templateUrl: './date-range-filter.component.html',
    styleUrls: [ './date-range-filter.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => HopDateRangeFilterComponent),
            multi: true
        }
    ],
    host: {
        class: 'c-hop-date-range-filter'
    }
})
export class HopDateRangeFilterComponent extends BaseFilterComponent<FilterType.DATE_RANGE, DateRange> implements OnInit, OnDestroy, OnChanges {

    @Input()
    override filter?: IDateRangeFilter;

    @Input()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    result?: any;

    startDate: NgbDateStruct | null = null;
    endDate: NgbDateStruct | null = null;
    hoveredDate: NgbDate | null = null;

    minDate: NgbDateStruct = DateFilterUtils.getDefaultMinDate();
    maxDate: NgbDateStruct = DateFilterUtils.getDefaultMaxDate();

    readonly formControl = new FormControl();

    private readonly _onDestroy = new Subject<void>();

    override writeValue(value: DateRange | undefined | null) {
        super.writeValue(value);
        this.formControl.setValue(value);
    }

    ngOnInit() {
        this.formControl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe((value) => this._dispatchValueChange(value));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (('filter' in changes || 'result' in changes) && this.filter) {
            this.minDate = DateFilterUtils.getMinDate(this.filter, this.result);
            this.maxDate = DateFilterUtils.getMaxDate(this.filter, this.result);
        }
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    onDateSelection(date: NgbDate) {
        if (this.startDate && !this.endDate && date.after(this.startDate)) {
            this.endDate = date;
        } else {
            this.endDate = null;
            this.startDate = date;
        }

        this._dispatchValueChange({
            startDate: DateFilterUtils.convertNgbDateStructToDate(this.startDate),
            endDate: DateFilterUtils.convertNgbDateStructToDate(this.endDate)
        });
    }

    setHoveredDate(date: NgbDate | null) {
        this.hoveredDate = date;
    }
}
