import { Pipe, PipeTransform } from '@angular/core';
import { Language } from '@hopsteiner/shared/models';

@Pipe({
    name: 'hopLanguageName'
})
export class HopLanguageNamePipe implements PipeTransform {

    transform(language: Language): string {
        switch (language) {
            case Language.DE:
                return 'COMPONENTS.LANGUAGE.VALUE.GERMAN';
            case Language.EN:
                return 'COMPONENTS.LANGUAGE.VALUE.ENGLISH';
            default:
                return language;
        }
    }

}
