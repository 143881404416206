import { ListRange } from '@angular/cdk/collections';
import { DataSource, DataSourceUtils, IPaginatedData, ISortableQuery } from '@hopsteiner/shared/collections';
import { identity, size } from 'lodash';
import { from, map, Observable } from 'rxjs';

import { IFindOptions } from '../models/find-options.interface';

import { Repository, UnpaginatedRepository } from './repository';


export type TransformOptionsFunction<QUERY> = (listRange: ListRange | null, query: QUERY | null) => IFindOptions;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformOptions = <QUERY extends ISortableQuery>(transformWhere: (where: any) => any = identity): TransformOptionsFunction<QUERY> => {
    return (listRange: ListRange | null, query: QUERY | null): IFindOptions => {
        const options: IFindOptions = {
            skip: listRange?.start ?? 0,
            limit: listRange ? listRange.end - listRange.start : 100
        };

        if (query) {
            if (query.where && size(query.where) > 0) {
                options.where = transformWhere(query.where);
            }

            if (query.sort) {
                options.sort = query.sort;
            }
        }

        return options;
    };
};

export class RepositoryDataSource<T, QUERY = unknown> extends DataSource<T, QUERY> {

    constructor(readonly repository: Pick<Repository<T>, 'find'>,
                private readonly _createFindOptions: TransformOptionsFunction<QUERY> = transformOptions() as TransformOptionsFunction<QUERY>) {
        super();
    }

    fetch(listRange: ListRange | null, query: QUERY | null): Observable<IPaginatedData<T>> {
        return from(this.repository.find(this._createFindOptions(listRange, query)));
    }

}

export class UnpaginatedRepositoryDataSource<T, QUERY = unknown> extends DataSource<T, QUERY> {

    constructor(readonly repository: Pick<UnpaginatedRepository<T>, 'find'>,
                private readonly _createFindOptions: TransformOptionsFunction<QUERY> = transformOptions() as TransformOptionsFunction<QUERY>) {
        super();
    }

    fetch(listRange: ListRange | null, query: QUERY | null): Observable<IPaginatedData<T>> {
        return from(this.repository.find(this._createFindOptions(listRange, query)))
            .pipe(
                map((data) => DataSourceUtils.paginate(data))
            );
    }

}
