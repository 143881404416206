import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HopEnvironmentApiFacade } from '@hopsteiner/environment/api';
import { UserPermission } from '@hopsteiner/shared/models';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { difference } from 'lodash';
import { firstValueFrom } from 'rxjs';

import { IActiveUser } from '../entities/active-user.interface';
import { AuthService } from '../infrastructure/auth.service';

import { AuthActions } from './actions/auth.actions';
import { ForgotPasswordActions } from './actions/forgot-password.actions';
import { LoginActions } from './actions/login.actions';
import { LogoutActions } from './actions/logout.actions';
import { PreferenceActions } from './actions/preference.actions';
import { ITokenValidationErrors } from './models/token-validation-errors.interface';


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IAuthStateModel {
    activeUser: IActiveUser | null;
    pages: {
        resetPassword: {
            errors?: ITokenValidationErrors[];
        }
    }
}

@State<IAuthStateModel>({
    name: 'auth',
    defaults: {
        activeUser: null,
        pages: {
            resetPassword: {}
        }
    }
})
@Injectable()
export class AuthState {

    @Selector()
    static selectIsAuthenticated(state: IAuthStateModel) {
        return state.activeUser != null;
    }

    @Selector()
    static selectActiveUser(state: IAuthStateModel) {
        return state.activeUser;
    }

    @Selector([ AuthState.selectActiveUser ])
    static selectPermissions(state: IAuthStateModel, activeUser: IActiveUser) {
        return activeUser?.permissions ?? [];
    }

    static hasPermission(requiredPermission: UserPermission) {
        return createSelector(
            [ AuthState.selectPermissions ],
            (permissions: UserPermission[]) => permissions != null && permissions.includes(requiredPermission)
        );
    }

    static hasPermissions(requiredPermissions: UserPermission[]) {
        return createSelector(
            [ AuthState.selectPermissions ],
            (permissions: UserPermission[]) => permissions != null && difference(requiredPermissions, permissions).length === 0
        );
    }

    @Selector()
    static selectResetPasswordPage(state: IAuthStateModel) {
        return state.pages.resetPassword;
    }

    @Selector([ AuthState.selectResetPasswordPage ])
    static selectResetPasswordPageErrors(state: IAuthStateModel, resetPasswordPage: { errors: string[] }) {
        return resetPasswordPage.errors;
    }

    constructor(private readonly _activatedRoute: ActivatedRoute,
                private readonly _location: Location,
                private readonly _authService: AuthService,
                private readonly _environmentFacade: HopEnvironmentApiFacade) {

    }

    @Action(AuthActions.UpdateActiveUser)
    async updateActiveUser(ctx: StateContext<IAuthStateModel>) {
        const activeUser = await this._authService.getActiveUser();

        await firstValueFrom(ctx.dispatch(new AuthActions.SetActiveUser(activeUser)));
    }

    @Action(AuthActions.SetActiveUser)
    setActiveUser(ctx: StateContext<IAuthStateModel>, action: AuthActions.SetActiveUser) {
        this._environmentFacade.initDebitorSwitcher();

        ctx.patchState({
            activeUser: action.activeUser
        });

        ctx.dispatch(new PreferenceActions.LoadPreferences());
    }

    @Action(LoginActions.LogIn)
    async logIn(ctx: StateContext<IAuthStateModel>, action: LoginActions.LogIn) {
        const { sessionId, language } = await this._authService.logIn(action.credentials);

        await firstValueFrom(this._environmentFacade.setSessionId(sessionId));

        if (language !== this._environmentFacade.currentLanguage) {
            this._environmentFacade.changeTranslationLanguage(language);
        }

        await firstValueFrom(ctx.dispatch(new AuthActions.UpdateActiveUser()));
    }

    @Action(LoginActions.SilentLogIn)
    async silentLogIn(ctx: StateContext<IAuthStateModel>) {
        try {
            const activeUser = await this._authService.getActiveUser();

            await firstValueFrom(ctx.dispatch(new AuthActions.SetActiveUser(activeUser)));
        } catch (e) {
            // ignore errors
        }
    }

    @Action(LogoutActions.LogOut)
    async logOut(ctx: StateContext<IAuthStateModel>) {
        await this._authService.logOut();

        ctx.patchState({
            activeUser: null
        });

        window.location.assign('/');
    }

    @Action(ForgotPasswordActions.ForgotPassword)
    forgotPassword(ctx: StateContext<IAuthStateModel>, action: ForgotPasswordActions.ForgotPassword) {
        return this._authService.forgotPassword(action.email);
    }

    @Action(ForgotPasswordActions.ResetForgottenPassword)
    async resetForgottenPassword(ctx: StateContext<IAuthStateModel>, action: ForgotPasswordActions.ResetForgottenPassword) {
        await this._authService.resetForgottenPassword(action.data);
    }

    @Action(ForgotPasswordActions.CheckResetPasswordErrors)
    async checkResetPasswordErrors(ctx: StateContext<IAuthStateModel>, action: ForgotPasswordActions.CheckResetPasswordErrors) {
        const errors = await this._authService.checkResetPasswordErrors(action.hash);

        ctx.setState(patch({
            pages: patch({
                resetPassword: patch({
                    errors
                })
            })
        }));
    }
}
