import { size as _size } from 'lodash';

import { Filter } from '../models/filter';
import { FiltersWithKeys, IFilterGroup } from '../models/filter-group';
import { FilterResult } from '../models/filter-result';
import { FilterType } from '../models/filter-type.enum';
import { DateFilter, DateRangeFilter, MultiSelectFilter, SingleSelectFilter } from '../models/filter.class';

export abstract class FilterUtils {

    static createInstances<R extends FilterResult>(filterGroup: IFilterGroup<R>): FiltersWithKeys<R> {
        return filterGroup.order.map((key) => {
            const filter = filterGroup.filters[key];

            switch (filter.type) {
                case FilterType.SINGLE_SELECT:
                    return new SingleSelectFilter<R[keyof R]>(key as string, filter);
                case FilterType.MULTI_SELECT:
                    return new MultiSelectFilter(key as string, filter);
                case FilterType.DATE:
                    return new DateFilter(key as string, filter);
                case FilterType.DATE_RANGE:
                    return new DateRangeFilter(key as string, filter);
            }
        }) as FiltersWithKeys<R>;
    }

    static hasValue<T>(filter: Filter<T>, value: T): boolean {
        if (filter?.hasValue != null) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return filter.hasValue(value);
        }

        return value != null;
    }

    static hasSomeValue<RESULT extends FilterResult>(filters: FiltersWithKeys<RESULT>, result: RESULT): boolean {
        return filters.some((filter) => FilterUtils.hasValue(filter, result[filter.key]));
    }

    static getValueCount<T>(filter: Filter<T>, value: T): number {
        if (!FilterUtils.hasValue(filter, value)) {
            return 0;
        }

        if (filter?.type === FilterType.MULTI_SELECT) {
            return _size(value as unknown as unknown[]);
        }

        return 1;
    }

    static getFilterCount<RESULT extends FilterResult>(filters: FiltersWithKeys<RESULT>, result: RESULT): number {
        return filters.reduce((count, filter) => {
            return count + FilterUtils.getValueCount(filter, result[filter.key]);
        }, 0);
    }
}
