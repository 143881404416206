import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChange, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter';
import { HopDrawer } from '@hopsteiner/shared/overlays';
import { isEmpty as _isEmpty, size as _size } from 'lodash';
import { firstValueFrom } from 'rxjs';

import { HopFilterDrawer } from '../../drawers/filter/filter.drawer';
import { IFilterDrawerResult } from '../../models/filter-drawer-result.interface';
import { IFilterGroup } from '../../models/filter-group';
import { FilterResult } from '../../models/filter-result';

interface TypedChange<T> extends SimpleChange {
    previousValue: T;
    currentValue: T;
}

@Component({
    selector: 'hop-filter-button',
    templateUrl: './filter-button.component.html',
    styleUrls: [ './filter-button.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-filter-button'
    }
})
export class HopFilterButtonComponent<RESULT extends FilterResult> implements OnChanges {

    @Input()
    filters?: IFilterGroup<RESULT>;

    @Output()
    readonly resultChange = new EventEmitter<IFilterDrawerResult<RESULT>>();

    readonly _filterIcon = faFilter;

    private _result?: IFilterDrawerResult<RESULT> | null = null;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private _initialResult?: any;

    constructor(private readonly _drawer: HopDrawer,
                private readonly _changeDetectorRef: ChangeDetectorRef) {

    }

    @Input()
    get result(): IFilterDrawerResult<RESULT> | undefined | null {
        return this._result;
    }

    set result(value: IFilterDrawerResult<RESULT> | undefined | null) {
        this._result = value;
        this._changeDetectorRef.detectChanges();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!('filters' in changes)) {
            return;
        }

        const change: TypedChange<IFilterGroup> = changes['filters'];

        if (change.previousValue) {
            return;
        }

        const { filters } = change.currentValue;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this._initialResult = {};

        for (const key in filters) {
            if (typeof filters[key].initialValue !== 'undefined') {
                this._initialResult[key] = filters[key].initialValue;
            }
        }

        if (_isEmpty(this._initialResult)) {
            return;
        }

        if (this.result != null) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const initialFilterDrawerResult: IFilterDrawerResult<any> = {
            result: this._initialResult,
            count: _size(this._initialResult)
        };

        this.result = initialFilterDrawerResult;
        void this.resultChange.emit(initialFilterDrawerResult);
    }

    async openDrawer() {
        if (!this.filters) {
            return;
        }

        const ref = HopFilterDrawer.open<RESULT>(this._drawer, this.filters, this._result?.result, this._initialResult);

        const result = await firstValueFrom(ref.afterClosed());

        if (result) {
            this._result = result;
            this.resultChange.emit(result);
            this._changeDetectorRef.markForCheck();
        }
    }
}
