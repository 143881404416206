import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { HopSortHeaderComponent } from './components/sort-header/sort-header.component';
import { HopSortDirective } from './directives/sort.directive';
import { ISortOptions } from './models/sort-options.interface';
import { SORT_OPTIONS } from './tokens/sort-options.token';


@NgModule({
    imports: [
        CommonModule,

        FontAwesomeModule
    ],
    providers: [
        { provide: SORT_OPTIONS, useValue: { disableClear: false } as ISortOptions }
    ],
    declarations: [
        HopSortHeaderComponent,
        HopSortDirective
    ],
    exports: [
        HopSortHeaderComponent,
        HopSortDirective
    ]
})
export class HopSortModule {

}
