import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { DataSource } from '@hopsteiner/shared/collections';

import { IndicatorAppearance } from '../../../shared/components/loading-indicator/loading-indicator.component';
import { IDataCollectionViewer } from '../../../shared/models/data-collection-viewer.interface';
import { HOP_DATA_COLLECTION_VIEWER } from '../../../shared/tokens/data-collection-viewer.token';
import { BaseDataListComponent } from '../base-data-list.component';


@Component({
    selector: 'hop-data-list',
    templateUrl: './data-list.component.html',
    styleUrls: [ './data-list.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-data-list'
    },
    providers: [
        { provide: HOP_DATA_COLLECTION_VIEWER, useExisting: HopDataListComponent }
    ]
})
export class HopDataListComponent<T, QUERY = unknown> extends BaseDataListComponent<T, QUERY> implements IDataCollectionViewer<T, QUERY> {

    readonly IndicatorAppearance = IndicatorAppearance;

    setDataSource(dataSource: DataSource<T, QUERY>) {
        this.dataSource = dataSource;
    }

    setQuery(query: QUERY) {
        this.query = query;
    }

    initCollectionViewer(dataSource: DataSource<T, QUERY>, query: QUERY) {
        this.dataSource = dataSource;
        this.query = query;
    }

}
