<div class="c-hop-root-layout__logo">
    <a routerLink="/">
        <img src="assets/images/hopsteiner_logo.svg" alt="Hopsteiner Logo" />
    </a>
</div>

<div class="c-hop-root-layout__toolbar c-hop-root-layout__toolbar--compact"
     *ngIf="(showCompactToolbar$ | async) === true; else fullToolbarTemplate">
    <div *ngIf="(activeUser$ | async)" class="c-hop-root-layout__toolbar-actions">
        <button class="c-hop-root-layout__menu-button" hopIconButton="plain" (click)="openNavigationDrawer()">
            <fa-icon [icon]="_icons.faBars"></fa-icon>
        </button>
    </div>
</div>

<ng-template #fullToolbarTemplate>
    <div class="c-hop-root-layout__toolbar">
        <div class="c-hop-root-layout__toolbar-debitor-switcher">
            <hop-env-debitor-switcher></hop-env-debitor-switcher>
        </div>
        <div class="c-hop-root-layout__toolbar-actions">
            <hop-env-language-menu></hop-env-language-menu>
            <hop-env-account-menu *ngIf="(activeUser$ | async)"></hop-env-account-menu>
        </div>
    </div>
</ng-template>

<div class="c-hop-root-layout__sidebar">
    <hop-main-nav *ngIf="(activeUser$ | async)"></hop-main-nav>
    <hop-legal-nav></hop-legal-nav>
</div>

<div class="c-hop-root-layout__main">
    <router-outlet #rla="outlet"></router-outlet>
    <!-- TODO: only for development purposes; remove before release! -->
    <pre *ngIf="!rla.isActivated">Main</pre>
</div>
