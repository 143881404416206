import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { ILogInCredentials } from '../application/models/log-in-credentials.interface';
import { IResetPasswordCredentials } from '../application/models/reset-password-credentials.interface';
import { ITokenValidationErrors } from '../application/models/token-validation-errors.interface';
import { IActiveUser } from '../entities/active-user.interface';
import { ILoginResponse } from '../entities/login-response.interface';

@Injectable()
export class AuthService {

    constructor(private readonly _httpClient: HttpClient) {
    }

    logIn(credentials: ILogInCredentials): Promise<ILoginResponse> {
        return firstValueFrom(this._httpClient.post<ILoginResponse>('/api/auth/login', credentials));
    }

    async logOut(): Promise<void> {
        await firstValueFrom(this._httpClient.post('/api/auth/logout', {}));
    }

    forgotPassword(email: string): Promise<void> {
        return firstValueFrom(this._httpClient.post<void>(`/api/auth/password-forgotten`, { email }));
    }

    resetForgottenPassword(data: IResetPasswordCredentials): Promise<void> {
        return firstValueFrom(this._httpClient.post<void>('/api/auth/reset-password', data));
    }

    getActiveUser() {
        return firstValueFrom(this._httpClient.get<IActiveUser>('/api/account'));
    }

    async checkResetPasswordErrors(hash: string): Promise<ITokenValidationErrors[]> {
        return await firstValueFrom(this._httpClient.post<ITokenValidationErrors[]>('/api/auth/validate-reset-password-hash', { hash }));
    }
}
