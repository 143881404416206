import { ICreateShipmentRequestDto } from '../../entities/create-shipment-request.dto';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace RequestShipmentActions {

    export class RequestShipment {
        static readonly type = '[Contract] Request Shipment';

        constructor(readonly navisionId: string, readonly shipmentData: ICreateShipmentRequestDto) {
        }
    }
}
