import { Pipe, PipeTransform } from '@angular/core';

import { FiltersWithKeys } from '../models/filter-group';
import { FilterResult } from '../models/filter-result';
import { FilterUtils } from '../utils/filter.utils';

@Pipe({
    name: 'hasSomeValue'
})
export class HopHasSomeValuePipe implements PipeTransform {

    transform<R extends FilterResult>(filters: FiltersWithKeys<R>, result: R): boolean {
        if (filters == null || result == null) {
            return false;
        }

        return filters.some((filter) => FilterUtils.hasValue(filter, result[filter.key]));
    }

}
