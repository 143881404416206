import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { IActiveDebitor } from '@hopsteiner/environment/domain';

@Component({
    selector: 'hop-env-debitor-card',
    templateUrl: './card.component.html',
    styleUrls: [ './card.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-env-debitor-card'
    }
})
export class HopEnvDebitorCardComponent {

    @Input()
    debitor?: IActiveDebitor;

}
