import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IInvoice } from '@hopsteiner/invoice/api';
import { BaseRepository, Endpoint, IFindOptions, IPaginated } from '@hopsteiner/shared/data-access';
import { firstValueFrom } from 'rxjs';

import { IDebitorAddress } from '../entities/address.interface';
import { IDebitorChangeRequest } from '../entities/debitor-change-request.interface';
import { ApiDebitor, Debitor, IDebitor, IDetailDebitor, IOverviewDebitor } from '../entities/debitor.interface';
import { IAssignedUser } from '../models/assigned-user.interface';
import { IDebitorExportOptions } from '../models/debitor-export-options.interface';


@Injectable({
    providedIn: 'root'
})
export class DebitorRepository extends BaseRepository<IDebitor> {

    constructor(httpClient: HttpClient) {
        super(httpClient, {
            endpoint: Endpoint.DEBITORS
        });
    }

    async find(options: IFindOptions): Promise<IPaginated<IOverviewDebitor>> {
        const debitors = await this._find<IPaginated<ApiDebitor<IOverviewDebitor>>>(options);

        return {
            ...debitors,
            data: debitors.data.map((debitor) => this._parse(debitor))
        };
    }

    async export(options: IDebitorExportOptions) {
        const params = this._toParams(options);

        return firstValueFrom(this._httpClient.get(`${this.url}/export`, {
            params,
            responseType: 'blob'
        }));
    }

    async get(debitorId: string, track: boolean = true): Promise<IDetailDebitor> {
        const debitor = await this._get<ApiDebitor<IDetailDebitor>>(debitorId, { track });

        return this._parse(debitor);
    }

    findAssignedUsers(debitorId: string, options: Pick<IFindOptions, 'skip' | 'limit'>): Promise<IPaginated<IAssignedUser>> {
        return this._getPaginatedData(`/${debitorId}/users`, options);
    }

    getDebitorChangeRequest(debitorId: string): Promise<IDebitorChangeRequest> {
        return firstValueFrom(this._httpClient.get<IDebitorChangeRequest>(`${this.url}/${debitorId}/change-requests`));
    }

    private _parse<T extends Debitor>(debitor: ApiDebitor<T>): T {
        return {
            ...debitor,
            beerOutputInHectoliters: Number.parseFloat(debitor.beerOutputInHectoliters),
            gramAlphaPerHectoLiter: Number.parseFloat(debitor.gramAlphaPerHectoLiter)
        };
    }

    findInvoices(debitorId: string, options: IFindOptions): Promise<IPaginated<IInvoice>> {
        const params = this._toParams(options);

        return firstValueFrom(this._httpClient.get<IPaginated<IInvoice>>(`${this.url}/${debitorId}/invoices`, { params }));
    }

    findAddresses(debitorId: string, options: IFindOptions): Promise<IPaginated<IDebitorAddress>> {
        const params = this._toParams(options);

        return firstValueFrom(this._httpClient.get<IPaginated<IDebitorAddress>>(`${this.url}/${debitorId}/shipment-addresses`, { params }));
    }
}
