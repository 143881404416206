import { ExplicitSortDirection } from '@hopsteiner/shared/collection-models';

/**
 * Describes a sortable element
 */
export interface ISortable {

    /**
     * The id of the column being sorted.
     */
    id: string;

    /**
     * Initial sort direction.
     */
    initialSortDirection: ExplicitSortDirection;

    /**
     * Whether to disable clearing the sorting state.
     */
    disableClear: boolean;

}
