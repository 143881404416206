import { ListRange } from '@angular/cdk/collections';
import { DataSource, IPaginatedData, ISortableQuery } from '@hopsteiner/shared/collections';
import { from, Observable } from 'rxjs';

import { IAssignedUser } from '../models/assigned-user.interface';

import { DebitorRepository } from './debitor.repository';

export class DebitorUserDataSource<QUERY = ISortableQuery> extends DataSource<IAssignedUser, QUERY> {

    constructor(private readonly _debitorId: string,
                private readonly _repository: DebitorRepository) {
        super();
    }

    fetch(listRange: ListRange | null): Observable<IPaginatedData<IAssignedUser>> {
        return from(this._repository.findAssignedUsers(this._debitorId, { skip: listRange?.start, limit: listRange?.end }));
    }
}
