import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Optional, Output, ViewEncapsulation } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { HopOverlayRef } from '../../../shared/models/overlay-ref';

@Component({
    selector: 'hop-drawer',
    templateUrl: './drawer.component.html',
    styleUrls: [ './drawer.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-drawer'
    }
})
export class HopDrawerComponent {

    @Input()
    drawerRef?: HopOverlayRef;

    readonly _icons = { faTimes };

    @Output()
    readonly closeClick = new EventEmitter<MouseEvent>();

    private _hideCloseButton: boolean = false;

    constructor(@Optional() drawerRef?: HopOverlayRef) {
        this.drawerRef = drawerRef;
    }

    @Input()
    get hideCloseButton(): boolean {
        return this._hideCloseButton;
    }

    set hideCloseButton(value: boolean) {
        this._hideCloseButton = coerceBooleanProperty(value);
    }

    /**
     * Emits a close event and attempts to close the drawer unless the event's default is prevented
     *
     * @param $event the mouse event that triggered the close
     */
    close($event: MouseEvent) {
        this.closeClick.emit($event);

        if ($event.defaultPrevented) {
            return;
        }

        if (this.drawerRef) {
            this.drawerRef.close();
        }
    }

}
