import { Directive } from '@angular/core';

@Directive({
    selector: '[hopCardTitle]',
    host: {
        class: 'c-hop-card__title card-title h5'
    }
})
export class HopCardTitleDirective {

}
