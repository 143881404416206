import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HopButtonModule } from '@hopsteiner/shared/components';
import { HopToastModule } from '@hopsteiner/shared/overlays';
import { TranslateModule } from '@ngx-translate/core';

import { HopExportButtonDirective } from './directives/export-button/export-button.directive';

@NgModule({
    imports: [
        CommonModule,

        FontAwesomeModule,
        TranslateModule,

        HopButtonModule,
        HopToastModule
    ],
    declarations: [
        HopExportButtonDirective
    ],
    exports: [
        HopExportButtonDirective
    ]
})
export class HopExportModule {
}
