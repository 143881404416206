import { IBrowsePagePreferences } from './browse-page-preferences.interface';
import { PreferenceGroup } from './preference-group.enum';
import { SelectableContractTableColumn } from './selectable-contract-table-column';
import { SelectableDebitorTableColumn } from './selectable-debitor-table-column';
import { SelectableOrderRequestTableColumn } from './selectable-order-request-table-column';
import { SelectableUserTableColumn } from './selectable-user-table-column';

export interface IAccountPreferences {
    [PreferenceGroup.CONTRACT]: {
        browsePage: IBrowsePagePreferences<SelectableContractTableColumn>;
    },
    [PreferenceGroup.DEBITOR]: {
        browsePage: IBrowsePagePreferences<SelectableDebitorTableColumn>;
    },
    [PreferenceGroup.ORDER_REQUEST]: {
        browsePage: IBrowsePagePreferences<SelectableOrderRequestTableColumn>;
    },
    [PreferenceGroup.USER]: {
        browsePage: IBrowsePagePreferences<SelectableUserTableColumn>;
    },
}
