<button hopIconButton
        class="c-hop-drawer__close"
        *ngIf="!hideCloseButton"
        type="button"
        [attr.aria-label]="'OVERLAYS.DRAWER.ACTION.CLOSE' | translate"
        (click)="close($event)">
    <fa-icon [icon]="_icons.faTimes"></fa-icon>
</button>

<div class="c-hop-drawer__scroll-container">
    <ng-content select="hop-drawer-header"></ng-content>
    <ng-content select="hop-drawer-body"></ng-content>
    <ng-content></ng-content>
    <ng-content select="hop-drawer-footer"></ng-content>
</div>
