import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

import { FormError } from '../models/form-error.enum';

export abstract class PasswordUtils {

    /**
     * check if password contains at least one digit
     */
    private static readonly REGEX_DIGIT: RegExp = /[0-9]/;

    /**
     * check if password contains at least one special character
     */
    private static readonly REGEX_SPECIAL_CHAR: RegExp = /[-+_!@#$%^&*,.?]/;

    /**
     * check if password contains at least one upper case and one lower case letter
     */
    private static readonly REGEX_LETTER_CASE: RegExp = /(?=.*[a-z])(?=.*[A-Z])/;

    /**
     * min length of a password
     */
    private static readonly MIN_PASSWORD_LENGTH: number = 8;

    /**
     * name of the form error when a password is weak
     *
     * @see validatePasswordStrength
     */
    private static readonly FORM_ERROR_PASSWORD_WEAK: string = 'passwordWeak';

    /**
     * Validates that the value of the current control matches the value of another one.
     * Usually required for a confirm password field and therefore named accordingly.
     *
     * @param passwordControlPath the path of the other password control from root
     */
    static validateEqualPassword(passwordControlPath: string): ValidatorFn {
        return (control: AbstractControl) => {
            if (!control?.parent) {
                return null;
            }

            const password = control.root.get(passwordControlPath);

            if (!password) {
                throw new Error(`No password found at path '${passwordControlPath}'`);
            }

            if (control.value !== password.value) {
                return {
                    [FormError.NO_EQUAL_PASSWORD]: true
                };
            }

            return null;

        };
    }

    /**
     * Validates that the value of the new password matches the requirements for a secure password.
     * Usually required for a new password.
     *
     */
    static validatePasswordStrength(): ValidatorFn {
        return (control: AbstractControl) => {
            if (!control) {
                return null;
            }

            if (control.value && !PasswordUtils.isPasswordStrong(control.value)) {
                return {
                    [PasswordUtils.FORM_ERROR_PASSWORD_WEAK]: true
                };
            }

            return null;
        };
    }

    /**
     * Marks the control as required if the target is not empty
     *
     * @param targetControlPath path from root of the form to the control that this validator depends on
     */
    static requiredIfNotEmpty(targetControlPath: string): ValidatorFn {
        return (control: AbstractControl) => {
            if (!control?.parent) {
                return null;
            }

            const target = control.root.get(targetControlPath);


            if (!target?.value) {
                return null;
            }

            return Validators.required(control);
        };
    }

    /**
     * Updates the target controls whenever this control gets validated
     *
     * @param targetControlPaths paths from root of the form to the controls that this validator should update when validated
     */
    static updateControls(...targetControlPaths: string[]): ValidatorFn {
        return (control: AbstractControl) => {
            if (!control?.parent) {
                return null;
            }

            for (const path of targetControlPaths) {
                const target = control.root.get(path);

                if (target) {
                    target.updateValueAndValidity();
                }
            }

            return null;
        };
    }

    static isPasswordStrong(input: string): boolean {
        return PasswordUtils.REGEX_LETTER_CASE.test(input)
            && PasswordUtils.REGEX_DIGIT.test(input)
            && PasswordUtils.REGEX_SPECIAL_CHAR.test(input)
            && input.length >= PasswordUtils.MIN_PASSWORD_LENGTH;
    }
}
