import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class DatePickerI18nService extends NgbDatepickerI18n {

    constructor(private _translateService: TranslateService) {
        super();
    }

    getWeekdayLabel(weekday: number): string {
        return this._translateService.instant(`FILTER.DATE_FILTER.WEEKDAYS.${weekday - 1}`);
    }

    override getWeekLabel(): string {
        return '';
    }

    getMonthShortName(month: number): string {
        return this._translateService.instant(`FILTER.DATE_FILTER.MONTHS.${month - 1}`);
    }

    getMonthFullName(month: number): string {
        return this.getMonthShortName(month);
    }

    getDayAriaLabel(date: NgbDateStruct): string {
        return `${date.day}-${date.month}-${date.year}`;
    }
}
