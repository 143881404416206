import { ListRange } from '@angular/cdk/collections';
import { IInvoice, IInvoiceFilter } from '@hopsteiner/invoice/api';
import { DataSource, IPaginatedData, ISortableQuery } from '@hopsteiner/shared/collections';
import { transformOptions, TransformOptionsFunction, TransformOptionsUtils } from '@hopsteiner/shared/data-access';
import { from, Observable } from 'rxjs';

import { IDebitorInvoiceFindQueryParams } from '../entities/debitor-invoice-find-query-params.interface';

import { DebitorRepository } from './debitor.repository';

const defaultInvoiceTransformOptions = transformOptions((where: IInvoiceFilter & { query?: string }) => {
    const params: Partial<IDebitorInvoiceFindQueryParams> = {};

    if ('query' in where) {
        params.query = where.query;
    }

    if ('invoiceStartDate' in where || 'invoiceEndDate' in where) {
        params.invoiceDate = TransformOptionsUtils.convertDateRangeToISOStrings({ startDate: where.invoiceStartDate, endDate: where.invoiceEndDate });
    }

    if ('dueDateStart' in where || 'dueDateEnd' in where) {
        params.dueDate = TransformOptionsUtils.convertDateRangeToISOStrings({ startDate: where.dueDateStart, endDate: where.dueDateEnd });
    }

    return params;
});

export class DebitorInvoiceDataSource<QUERY = ISortableQuery> extends DataSource<IInvoice, QUERY> {

    constructor(private readonly _debitorId: string,
                private readonly _repository: DebitorRepository,
                private readonly _createFindOptions: TransformOptionsFunction<QUERY> = defaultInvoiceTransformOptions as TransformOptionsFunction<QUERY>) {
        super();
    }

    fetch(listRange: ListRange | null, query: QUERY | null): Observable<IPaginatedData<IInvoice>> {
        return from(this._repository.findInvoices(this._debitorId, this._createFindOptions(listRange, query)));
    }

}
