import { ISort } from '@hopsteiner/shared/collections';

export interface IFindOptions {

    /**
     * The query
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    where?: any;

    /**
     * The number of elements to skip
     */
    skip?: number;

    /**
     * The maximum number of elements to return
     */
    limit?: number;

    /**
     * The sorting
     */
    sort?: ISort;
}
