import { IActiveDebitor } from '../../models/active-debitor.interface';
import { DebitorSwitcherMode } from '../../models/debitor-switcher-mode.enum';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace DebitorSwitcherActions {

    export class Show {

        static readonly type = '[Environment] Show Debitor Switcher';

        constructor(readonly show: boolean) {

        }
    }

    export class Disable {

        static readonly type = '[Environment] Disable Debitor Switcher';

    }

    export class Enable {

        static readonly type = '[Environment] Enable Debitor Switcher';

    }

    export class SetMode {

        static readonly type = '[Environment] Set debitor switcher mode';

        constructor(readonly mode: DebitorSwitcherMode) {

        }
    }

    export class SetActiveDebitor {

        static readonly type = '[Environment] Set active debitor';

        constructor(readonly debitor: IActiveDebitor) {

        }
    }

    export class SetActiveDebitorById {

        static readonly type = '[Environment] Set active debitor by ID';

        constructor(readonly debitorId: string) {

        }
    }

    export class SetRecentActiveDebitors {

        static readonly type = '[Environment] Set recent active debitors';

        constructor(readonly debitors: IActiveDebitor[]) {

        }
    }

    export class ClearActiveDebitor {
        static readonly type = '[Environment] Clear Active Debitor';
    }

    export class InitDebitorSwitcher {
        static readonly type = '[Environment] Initialise Debitor Switcher on Login';
    }
}
