import { ModuleWithProviders, NgModule } from '@angular/core';
import { HopEnvironmentDomainRootModule } from '@hopsteiner/environment/domain';

import { IEnvironmentApiModuleOptions } from './models/environment-api-module-options.interface';
import { NO_ACTIVE_DEBITOR_REDIRECT } from './tokens/forbidden-redirect.token';

@NgModule({})
export class BaseEnvironmentApiRootModule {
}

@NgModule({
    imports: [ HopEnvironmentDomainRootModule, BaseEnvironmentApiRootModule ],
    exports: [ BaseEnvironmentApiRootModule ]
})
export class HopEnvironmentApiRootModule {
    static forRoot(): ModuleWithProviders<HopEnvironmentApiRootModule> {
        return {
            ngModule: HopEnvironmentApiRootModule
        };
    }
}

@NgModule({
    imports: [ BaseEnvironmentApiRootModule ],
    exports: [ BaseEnvironmentApiRootModule ]
})
export class HopEnvironmentApiModule {
    static forRoot(options: IEnvironmentApiModuleOptions): ModuleWithProviders<HopEnvironmentApiRootModule> {
        return {
            ngModule: HopEnvironmentApiRootModule,
            providers: [
                { provide: NO_ACTIVE_DEBITOR_REDIRECT, useValue: options.redirects.noActiveDebitor }
            ]
        };
    }
}
