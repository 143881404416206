import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HopAuthApiModule } from '@hopsteiner/auth/api';
import { HopContractApiModule } from '@hopsteiner/contract/api';
import { HopDebitorApiModule } from '@hopsteiner/debitor/api';
import { HopEnvironmentApiModule } from '@hopsteiner/environment/api';
import { HopOrderRequestApiModule } from '@hopsteiner/order-request/api';
import { HopBreakpointsModule } from '@hopsteiner/shared/breakpoints';
import { HopDataAccessModule } from '@hopsteiner/shared/data-access';
import { HopDrawerModule } from '@hopsteiner/shared/overlays';
import { HopTitleModule } from '@hopsteiner/shared/title';
import { HopUserApiModule } from '@hopsteiner/user/api';
import { NgbConfig, NgbDropdownConfig, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { createDefaultMatomoScriptElement, NgxMatomoModule, NgxMatomoRouterModule } from 'ngx-matomo-client';

import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { AuthInterceptor } from './interceptors/auth-interceptor';
import { AppRoutingModule } from './routing/routing.module';
import { AppStateModule } from './state/state.module';
import { AppTranslationModule } from './translation/translation.module';

export const ngbTooltipConfigFactory = (config: NgbConfig) => {
    const tooltipConfig = new NgbTooltipConfig(config);
    tooltipConfig.container = 'body';
    return tooltipConfig;
};

export const ngbDropdownConfigFactory = () => {
    const dropdownConfig = new NgbDropdownConfig();
    dropdownConfig.container = 'body';
    return dropdownConfig;
};

@NgModule({
    imports: [
        // first party (@angular/*) modules
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,

        // third party modules
        NgxMatomoModule.forRoot({
                siteId: '3', // your Matomo's site ID
                trackerUrl: 'https://analytics.hopsteiner-shop.de/' // your matomo server root url
            },
            (scriptUrl: string, document: Document) => {
                const script = createDefaultMatomoScriptElement(scriptUrl, document);
                script.type = 'text/plain';
                script.setAttribute('class', 'cmplazyload');
                script.setAttribute('data-cmp-vendor', 's974');

                return script;
            }
        ),
        NgxMatomoRouterModule,

        // App specific modules
        AppRoutingModule,
        AppStateModule,
        AppTranslationModule,

        // Shared modules
        HopAuthApiModule.forRoot({
            redirects: {
                unauthorized: '/auth/login',
                forbidden: '/forbidden'
            }
        }),
        HopBreakpointsModule.forRoot(),
        HopContractApiModule.forRoot(),
        HopDataAccessModule.forRoot(environment.apiUrl),
        HopDebitorApiModule.forRoot(),
        HopDrawerModule,
        HopEnvironmentApiModule.forRoot({
            redirects: {
                noActiveDebitor: '/forbidden'
            }
        }),
        HopOrderRequestApiModule.forRoot(),
        HopTitleModule.forRoot('TITLE'),
        HopUserApiModule.forRoot()
    ],
    declarations: [ AppComponent ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: NgbTooltipConfig, useFactory: ngbTooltipConfigFactory, deps: [ NgbConfig ] },
        { provide: NgbDropdownConfig, useFactory: ngbDropdownConfigFactory }
    ],
    bootstrap: [ AppComponent ]
})
export class AppModule {
}
