import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { ContractUiState } from './application/contract-ui.state';

@NgModule({
    imports: [
        CommonModule,

        NgxsModule.forFeature([ ContractUiState ])
    ]
})
export class HopContractDomainRootModule {
}
