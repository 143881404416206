import { TemplateRef } from '@angular/core';
import { DataSource } from '@hopsteiner/shared/collections';

import { DateRange } from './date-range';
import { DateOrFactory, IDateFilter, IDateRangeFilter, IMultiSelectFilter, ISingleSelectFilter } from './filter';
import { IBaseFilterOptions, IDateFilterOptions, IDateRangeFilterOptions, IMultiSelectFilterOptions, ISingleSelectFilterOptions } from './filter-options';
import { FilterType } from './filter-type.enum';

export abstract class BaseFilter<FILTER_TYPE extends FilterType, DATA_TYPE, VALUE_TYPE = DATA_TYPE, OPTIONS extends IBaseFilterOptions<FILTER_TYPE, DATA_TYPE, VALUE_TYPE> = IBaseFilterOptions<FILTER_TYPE, DATA_TYPE, VALUE_TYPE>> {

    abstract readonly type: FILTER_TYPE;

    readonly name: string;

    readonly valueTemplate?: TemplateRef<VALUE_TYPE>;

    readonly removable?: boolean;

    constructor(readonly key: string, protected readonly _options: OPTIONS) {
        this.name = _options.name;
        this.valueTemplate = _options.valueTemplate;
        this.removable = _options.removable;
    }

    hasValue(value: DATA_TYPE): boolean {
        if (this._options.hasValue) {
            return this._options.hasValue(value);
        }

        return this._defaultHasValue(value);
    }

    protected _defaultHasValue(value: DATA_TYPE): boolean {
        return value != null;
    }
}

export class SingleSelectFilter<T, OPTION = T> extends BaseFilter<FilterType.SINGLE_SELECT, T, T, ISingleSelectFilterOptions<T, OPTION>> implements ISingleSelectFilter<T, OPTION> {

    readonly type = FilterType.SINGLE_SELECT;

    readonly dataSource: DataSource<OPTION>;
    readonly optionTemplate?: TemplateRef<OPTION>;

    constructor(key: string, options: ISingleSelectFilterOptions<T, OPTION>) {
        super(key, options);
        this.dataSource = options.dataSource;
        this.optionTemplate = options.optionTemplate;
    }

    getOptionValue(option: OPTION): T {
        if (this._options.getOptionValue) {
            return this._options.getOptionValue(option);
        }

        return option as unknown as T;
    }
}

export class MultiSelectFilter<T, OPTION> extends BaseFilter<FilterType.MULTI_SELECT, T[], T, IMultiSelectFilterOptions<T, OPTION>> implements IMultiSelectFilter<T, OPTION> {

    readonly type = FilterType.MULTI_SELECT;

    readonly dataSource: DataSource<OPTION>;
    readonly optionTemplate?: TemplateRef<OPTION>;

    constructor(key: string, options: IMultiSelectFilterOptions<T, OPTION>) {
        super(key, options);
        this.dataSource = options.dataSource;
        this.optionTemplate = options.optionTemplate;
    }

    protected override _defaultHasValue(value: T[]): boolean {
        return !!value?.length;
    }

    getOptionValue(option: OPTION): T {
        if (this._options.getOptionValue) {
            return this._options.getOptionValue(option);
        }

        return option as unknown as T;
    }
}

export class DateFilter extends BaseFilter<FilterType.DATE, Date> implements IDateFilter {

    readonly type = FilterType.DATE;

    minDate?: DateOrFactory;
    maxDate?: DateOrFactory;

    constructor(key: string, options: IDateFilterOptions) {
        super(key, options);
        this.minDate = options.minDate;
        this.maxDate = options.maxDate;
    }
}


export class DateRangeFilter extends BaseFilter<FilterType.DATE_RANGE, DateRange> implements IDateRangeFilter {

    readonly type = FilterType.DATE_RANGE;

    readonly minDate?: DateOrFactory;
    readonly maxDate?: DateOrFactory;

    constructor(key: string, options: IDateRangeFilterOptions) {
        super(key, options);
        this.minDate = options.minDate;
        this.maxDate = options.maxDate;
    }

    protected override _defaultHasValue(value: DateRange): boolean {
        return value?.startDate != null && value?.endDate != null;
    }
}
