import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HopDataCollectionContainerComponent } from './components/data-collection-container/data-collection-container.component';
import { HopDataCollectionContainerDirective } from './directives/data-collection-container.directive';
import { HopDesktopCollectionViewerDirective } from './directives/desktop-collection-viewer.directive';
import { HopMobileCollectionViewerDirective } from './directives/mobile-collection-viewer.directive';


@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        HopDesktopCollectionViewerDirective,
        HopMobileCollectionViewerDirective,
        HopDataCollectionContainerDirective,
        HopDataCollectionContainerComponent
    ],
    exports: [
        HopDesktopCollectionViewerDirective,
        HopMobileCollectionViewerDirective,
        HopDataCollectionContainerDirective,
        HopDataCollectionContainerComponent
    ]
})
export class HopDataCollectionContainerModule {
}
