import { ISort } from './sort.interface';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IQuery<WHERE extends object = any> {
    where?: WHERE;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ISortableQuery<WHERE extends object = any, SORTABLE_FIELDS extends string = string> extends IQuery<WHERE> {
    sort?: ISort<SORTABLE_FIELDS>;
}
