import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';


interface ILegalNavItem {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    route: string | any[];
    label: string;
}

@Component({
    selector: 'hop-legal-nav',
    templateUrl: './legal-nav.component.html',
    styleUrls: [ './legal-nav.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-legal-nav'
    }
})
export class HopLegalNavComponent {

    readonly items: ILegalNavItem[] = [
        {
            route: '/legal/contact',
            label: 'LEGAL.NAV.COMPONENT.LEGAL_NAV.ITEM.CONTACT.LABEL'
        },
        {
            route: '/legal/downloads',
            label: 'LEGAL.NAV.COMPONENT.LEGAL_NAV.ITEM.DOWNLOADS.LABEL'
        },
        {
            route: '/legal/imprint',
            label: 'LEGAL.NAV.COMPONENT.LEGAL_NAV.ITEM.IMPRINT.LABEL'
        },
        {
            route: '/legal/privacy',
            label: 'LEGAL.NAV.COMPONENT.LEGAL_NAV.ITEM.PRIVACY.LABEL'
        }
    ];
}
