// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace DeleteUserActions {

    export class SendDeleteRequest {
        static readonly type = '[User] Send User Delete Request';

        constructor(readonly userId: number) {
        }
    }
}
