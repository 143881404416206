export interface IInvoice {
    navisionId: string;
    invoiceDate: Date;
    dueDate: Date;
    orderNumber: string;
    displayAmountIncludingVat: string;
    invoiceId: string;
    invoiceAvailable: boolean;
    contractNumber: string;
}
