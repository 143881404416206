import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseRepository, Endpoint, IFindOptions, IPaginated } from '@hopsteiner/shared/data-access';
import { firstValueFrom } from 'rxjs';

import { IActiveDebitor } from '../models/active-debitor.interface';

@Injectable({
    providedIn: 'root'
})
export class EnvironmentDebitorRepository extends BaseRepository<IActiveDebitor> {

    constructor(httpClient: HttpClient) {
        super(httpClient, {
            endpoint: Endpoint.DEBITORS
        });
    }

    find(options: IFindOptions): Promise<IPaginated<IActiveDebitor>> {
        return this._find(options);
    }

    count(): Promise<number> {
        return firstValueFrom(this._httpClient.get<number>(`${this.url}/count`));
    }
}
