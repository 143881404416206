import { Directive, TemplateRef } from '@angular/core';

@Directive({
    selector: 'ng-template[hopMenuDef]'
})
export class HopMenuDefDirective {

    constructor(readonly templateRef: TemplateRef<unknown>) {

    }
}
