<div *ngIf="appearance === IndicatorAppearance.EDGE" [@slideIn]="loading" class="c-hop-loading-indicator__container">
    <ng-container *ngTemplateOutlet="indicatorTemplate"></ng-container>
</div>

<div *ngIf="appearance !== IndicatorAppearance.EDGE && loading" class="c-hop-loading-indicator__container">
    <ng-container *ngTemplateOutlet="indicatorTemplate"></ng-container>
</div>

<ng-template #indicatorTemplate>
    <div class="spinner-border spinner-border-sm" role="status">
    <span class="visually-hidden">
        {{ message || 'DATA_TABLE.TABLE.LOADING_MESSAGE' | translate }}
    </span>
    </div>
</ng-template>
