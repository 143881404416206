import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'hopMemoize'
})
export class HopMemoizePipe implements PipeTransform {

    transform<T, R>(value: T, handler: (arg: T) => R): R {
        return handler(value);
    }
}

@NgModule({
    imports: [ CommonModule ],
    declarations: [ HopMemoizePipe ],
    exports: [ HopMemoizePipe ]
})
export class HopMemoizePipeModule {
}
