import { Injectable } from '@angular/core';
import { HopToast } from '@hopsteiner/shared/overlays';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';

interface IFeedbackMessages {
    downloading: string,
    error: string,
    success: string
}

@Injectable({
    providedIn: 'root'
})
export class HopFileDownloadService {

    constructor(private readonly _translateService: TranslateService,
                private readonly _toast: HopToast) {
    }

    async downloadFile(request: () => Promise<Blob>, fileName: string, messages: IFeedbackMessages) {
        const toastRef = this._toast.show({
            message: this._translateService.instant(messages.downloading, { fileName }),
            autoHide: false
        });

        try {
            saveAs(await request(), fileName);
            toastRef?.close();
            this._toast.success(this._translateService.instant(messages.success, { fileName }));
        } catch (error) {
            toastRef?.close();
            this._toast.error(this._translateService.instant(messages.error, { fileName }));
        }
    }
}
