import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { without as _without } from 'lodash';
import { BehaviorSubject } from 'rxjs';

import { HopToastContainerComponent } from '../components/toast-container/toast-container.component';
import { ErrorToastConfig, HopToastConfig, SuccessToastConfig, WarningToastConfig } from '../models/toast-config';
import { IToastProvider } from '../models/toast-provider.interface';
import { HopToastRef } from '../models/toast-ref';
import { TOASTS } from '../tokens/toasts.token';

@Injectable({
    providedIn: 'root'
})
export class HopToast implements IToastProvider {

    readonly toasts$ = new BehaviorSubject<HopToastRef[]>([]);

    private _overlayRef?: OverlayRef;

    constructor(private readonly _overlay: Overlay) {

    }

    get toasts(): HopToastRef[] {
        return this.toasts$.getValue();
    }

    show(config: HopToastConfig) {
        if (!config) {
            return;
        }

        const toast = new HopToastRef((ref) => this._close(ref), config);
        this.toasts$.next([ ...this.toasts, toast ]);

        if (!this._overlayRef) {
            this._showOverlay();
        }

        return toast;
    }

    success(message: string) {
        return this.show(new SuccessToastConfig(message));
    }

    warning(message: string) {
        return this.show(new WarningToastConfig(message));
    }

    error(message: string) {
        return this.show(new ErrorToastConfig(message));
    }

    private _close(toast: HopToastRef) {
        const index = this.toasts.indexOf(toast);

        if (index < 0) {
            return;
        }

        this.toasts$.next(_without(this.toasts, toast));

        if (this.toasts.length === 0) {
            this._hideOverlay();
        }
    }

    private _showOverlay() {
        this._overlayRef = this._overlay.create({
            positionStrategy: this._overlay.position().global().bottom().left()
        });

        this._overlayRef.attach(new ComponentPortal(HopToastContainerComponent, undefined, Injector.create({
            providers: [
                { provide: TOASTS, useValue: this }
            ]
        })));
    }

    private _hideOverlay() {
        if (!this._overlayRef) {
            return;
        }

        this._overlayRef.dispose();
        delete this._overlayRef;
    }

}
