import { formatDate } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, LOCALE_ID, SecurityContext, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { HopDialogRef } from '../../../dialog/models/dialog-ref';
import { HopDialog } from '../../../dialog/services/dialog.service';
import { HOP_DIALOG_DATA } from '../../../dialog/tokens/dialog-data.token';
import { IConfirmDialogData } from '../../models/confirm-dialog-data.interface';

@Component({
    selector: 'hop-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: [ './confirm-dialog.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-confirm-dialog'
    }
})
export class HopConfirmDialogComponent {

    readonly currentDateTime: string;

    readonly sanitizedMessage: string | null;

    static show(dialog: HopDialog, data: IConfirmDialogData) {
        return dialog.open<HopConfirmDialogComponent, boolean>(HopConfirmDialogComponent, {
            data,
            ariaLabelledBy: 'confirm-dialog-title'
        });
    }

    constructor(@Inject(HOP_DIALOG_DATA) readonly data: IConfirmDialogData,
                private readonly _dialogRef: HopDialogRef,
                domSanitizer: DomSanitizer,
                @Inject(LOCALE_ID) locale: string) {
        this.currentDateTime = formatDate(new Date(), 'dd.MM.yyyy, hh:mm', locale);
        this.sanitizedMessage = domSanitizer.sanitize(SecurityContext.HTML, data.message);
    }

    cancel() {
        this._dialogRef.close(false);
    }

    confirm() {
        this._dialogRef.close(true);
    }
}
