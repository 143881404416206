<hop-drawer>
    <hop-drawer-body>
        <table hopKeyValueTable class="mb-5">

            <tr>
                <th>{{ 'CONTRACT.SHIPMENT_DRAWER.DRAWERS.SHIPMENT.PROPERTY.NUMBER' | translate }}</th>
                <td>{{ shipment.frontendId }}</td>
            </tr>

            <tr>
                <th>{{ 'CONTRACT.SHIPMENT_DRAWER.DRAWERS.SHIPMENT.PROPERTY.QUANTITY' | translate }}</th>
                <td>{{ shipment.displayQuantity }}</td>
            </tr>

            <tr>
                <th>{{ 'CONTRACT.SHIPMENT_DRAWER.DRAWERS.SHIPMENT.PROPERTY.DATE' | translate }}</th>
                <td>{{ shipment.dateFrom | date }} - {{ shipment.dateTo | date }}</td>
            </tr>

            <tr>
                <th>{{ 'CONTRACT.SHIPMENT_DRAWER.DRAWERS.SHIPMENT.PROPERTY.GOODS_COLLECTED' | translate }}</th>
                <td>{{ shipment.goodsCollected | hopYesNo | translate }}</td>
            </tr>

            <tr>
                <th>{{ 'CONTRACT.SHIPMENT_DRAWER.DRAWERS.SHIPMENT.PROPERTY.GOODS_ISSUED_AT' | translate }}</th>
                <td>
                    {{ shipment.goodsIssuedAt | date }}
                </td>
            </tr>

        </table>

        <table hopKeyValueTable>
            <tr>
                <th>{{ 'CONTRACT.SHIPMENT_DRAWER.DRAWERS.SHIPMENT.PROPERTY.PACKAGING' | translate }}</th>
                <td>{{ shipment.packaging?.label }}</td>
            </tr>

            <tr>
                <th>{{ 'CONTRACT.SHIPMENT_DRAWER.DRAWERS.SHIPMENT.PROPERTY.NOTE' | translate }}</th>
                <td>{{ shipment.note }}</td>
            </tr>

            <tr>
                <th>{{ 'CONTRACT.SHIPMENT_DRAWER.DRAWERS.SHIPMENT.PROPERTY.SHIP_TO' | translate }}</th>
                <td>{{ shipment.packaging?.destination }}</td>
            </tr>

            <tr>
                <th>{{ 'CONTRACT.SHIPMENT_DRAWER.DRAWERS.SHIPMENT.PROPERTY.DIRECT_SHIPMENT_BREWERY' | translate }}</th>
                <td>{{ shipment.packaging?.debitor?.navisionId }}</td>
            </tr>

            <tr>
                <th>{{ 'CONTRACT.SHIPMENT_DRAWER.DRAWERS.SHIPMENT.PROPERTY.DIRECT_SHIPMENT_BREWERY_NAME' | translate }}</th>
                <td>{{ shipment.packaging?.debitor?.fullName }}</td>
            </tr>

            <tr>
                <th>{{ 'CONTRACT.SHIPMENT_DRAWER.DRAWERS.SHIPMENT.PROPERTY.DIRECT_SHIPMENT_BREWERY_VAT_ID' | translate }}</th>
                <td>{{ shipment.packaging?.debitor?.vatNumber }}</td>
            </tr>
        </table>

    </hop-drawer-body>
</hop-drawer>
