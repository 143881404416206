import { CollectionViewer, DataSource as CdkDataSource, ListRange } from '@angular/cdk/collections';
import { Directive, Input } from '@angular/core';
import { BehaviorSubject, NEVER, Observable, skip, Subject, switchAll } from 'rxjs';

import { ICollectionViewer } from '../models/collection-viewer.interface';
import { Connection } from '../models/connection.class';
import { DataSource } from '../models/data-source.class';
import { IDataSource } from '../models/data-source.interface';
import { IMutableCdkCollectionViewer } from '../models/mutable-cdk-collection-viewer.interface';

@Directive()
export abstract class HopCollectionViewerDelegateDirective<T, QUERY> implements ICollectionViewer<T, QUERY> {

    readonly total$: Observable<number>;

    private _dataSource?: DataSource<T, QUERY>;

    private _connection?: Connection<T, QUERY>;

    private readonly _totalSubject = new BehaviorSubject<Observable<number>>(NEVER);

    constructor(readonly collectionViewer: IMutableCdkCollectionViewer<T>, readonly skipViewChanges: number = 0) {
        this.total$ = this._totalSubject.pipe(switchAll());
    }

    @Input('hopDataSource')
    get dataSource(): DataSource<T, QUERY> | undefined {
        return this._dataSource;
    }

    set dataSource(value: DataSource<T, QUERY> | undefined) {
        if (value === this.dataSource) {
            return;
        }

        this._dataSource = value;

        if (value) {
            this.collectionViewer.dataSource = new CdkDataSourceDelegate<T, QUERY>(
                value,
                (connection) => this._switchConnection(connection),
                this.skipViewChanges
            );
        }
    }

    get viewChange(): Subject<ListRange> {
        return this.collectionViewer.viewChange;
    }

    get connection(): Connection<T, QUERY> {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this._connection!;
    }


    private _switchConnection(connection: Connection<T, QUERY> | undefined) {
        this._connection = connection;

        if (!connection) {
            return;
        }

        this._totalSubject.next(connection.total$);
    }
}

export class CdkDataSourceDelegate<T, QUERY = unknown> extends CdkDataSource<T> {

    constructor(readonly dataSource: IDataSource<T, QUERY>,
                readonly setConnection: (connection: Connection<T, QUERY> | undefined) => void,
                readonly skip: number = 0) {
        super();
    }

    connect(collectionViewer: CollectionViewer): Observable<readonly T[]> {
        const connection = this.dataSource?.connect({
            viewChange: this.skip ? collectionViewer.viewChange.pipe(skip(this.skip)) : collectionViewer.viewChange,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            queryChange: collectionViewer.queryChange
        });

        this.setConnection(connection);

        return connection.data$;
    }

    disconnect() {
        this.setConnection(void 0);
    }

}
