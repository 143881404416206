import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Pipe({
    name: 'isInvalid',
    pure: false
})
export class HopIsInvalidPipe implements PipeTransform {

    transform(control: AbstractControl | null, submitted: boolean): boolean {
        if (!control) {
            return false;
        }

        return ((control.touched || submitted) && control.invalid) as boolean;
    }
}
