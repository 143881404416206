import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { IDateFilter, IDateRangeFilter } from '../models/filter';


export abstract class DateFilterUtils {

    static getMinDate<Result>(filter: IDateFilter | IDateRangeFilter, result: Result) {
        if (filter.minDate instanceof Date) {
            return this.convertDateToNgbDateStruct(filter.minDate);
        } else if (filter.minDate) {
            const minDate = filter.minDate(result);
            return minDate ? this.convertDateToNgbDateStruct(minDate) : this.getDefaultMinDate();
        } else {
            return this.getDefaultMinDate();
        }
    }

    static getMaxDate<Result>(filter: IDateFilter | IDateRangeFilter, result: Result) {
        if (filter.maxDate instanceof Date) {
            return this.convertDateToNgbDateStruct(filter.maxDate);
        } else if (filter.maxDate) {
            const minDate = filter.maxDate(result);
            return minDate ? this.convertDateToNgbDateStruct(minDate) : this.getDefaultMaxDate();
        } else {
            return this.getDefaultMaxDate();
        }
    }

    static getDefaultMinDate(): NgbDateStruct {
        return {
            year: new Date().getFullYear() - 20,
            month: 1,
            day: 1
        };
    }

    static getDefaultMaxDate(): NgbDateStruct {
        return {
            year: new Date().getFullYear() + 20,
            month: 12,
            day: 31
        };
    }

    static convertDateToNgbDateStruct(date: Date): NgbDateStruct {
        return {
            year: date.getUTCFullYear(),
            month: date.getUTCMonth() + 1,
            day: date.getUTCDate()
        };
    }

    static convertNgbDateStructToDate(date: NgbDateStruct | null | undefined): Date | null {
        return date ? new Date(Date.UTC(date.year, date.month - 1, date.day)) : null;
    }
}
