import { SortDirection } from '@hopsteiner/shared/collection-models';

/**
 * The event that gets emitted when the sort changes
 */
export interface ISortChangeEvent {
    /**
     * The id of the sortable
     */
    activeSortableId: string;

    /**
     * The direction of the sort
     */
    direction: SortDirection;
}
