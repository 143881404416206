import { SelectableDebitorTableColumn } from '../entities/preferences/selectable-debitor-table-column';

export const ALL_SELECTABLE_DEBITOR_COLUMNS: SelectableDebitorTableColumn[] = [
    'address',
    'zip',
    'city',
    'countryCode',
    'beerOutputInHectoliters',
    'gramAlphaPerHectoLiter',
    'phone',
    'email'
];
