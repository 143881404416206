import { Injectable } from '@angular/core';
import { Action, StateContext } from '@ngxs/store';

import { DebitorService } from '../infrastructure/debitor.service';

import { EditDebitorActions } from './actions/edit-debitor.actions';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IDebitorStateModel {

}

@Injectable({
    providedIn: 'root'
})
export class DebitorState {

    constructor(private readonly _debitorService: DebitorService) {
    }

    @Action(EditDebitorActions.EditDebitor)
    async updateDebitor(ctx: StateContext<IDebitorStateModel>, action: EditDebitorActions.EditDebitor) {
        await this._debitorService.updateDebitor(action.debitorId, action.editDebitorData);
    }
}
