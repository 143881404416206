import { Directive, Inject } from '@angular/core';
import { HOP_DATA_COLLECTION_VIEWER_HOST, IDataCollectionViewer, IDataCollectionViewerHost } from '@hopsteiner/shared/data-table';

@Directive()
export class BaseCollectionViewerHostDirective<T> {

    constructor(@Inject(HOP_DATA_COLLECTION_VIEWER_HOST) readonly collectionViewerHost: IDataCollectionViewerHost<T>) {
    }

    get collectionViewer(): IDataCollectionViewer<T> {
        return this.collectionViewerHost.collectionViewer;
    }

}
