import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IDebitorExportOptions } from '@hopsteiner/debitor/api';
import { BaseRepository, Endpoint, IFindOptions, IPaginated } from '@hopsteiner/shared/data-access';
import { firstValueFrom } from 'rxjs';

import { ICancellation } from '../entities/cancellation.interface';
import { IContract, IDetailContract, IOverviewContract } from '../entities/contract.interface';
import { IShipment } from '../entities/shipment.interface';

@Injectable({
    providedIn: 'root'
})
export class ContractRepository extends BaseRepository<IContract> {

    constructor(httpClient: HttpClient) {
        super(httpClient, {
            endpoint: Endpoint.CONTRACTS
        });
    }

    find(options: IFindOptions): Promise<IPaginated<IOverviewContract>> {
        return this._find(options);
    }

    get(contractId: string, track: boolean = true): Promise<IDetailContract> {
        return this._get<IDetailContract>(contractId, { track });
    }

    findShipments(contractId: string, options: IFindOptions): Promise<IPaginated<IShipment>> {
        const params = this._toParams(options);

        return firstValueFrom(this._httpClient.get<IPaginated<IShipment>>(`${this.url}/${contractId}/shipments`, { params }));
    }

    findCancellations(contractId: string, options: IFindOptions): Promise<IPaginated<ICancellation>> {
        const params = this._toParams(options);

        return firstValueFrom(this._httpClient.get<IPaginated<ICancellation>>(`${this.url}/${contractId}/partial-cancellations`, { params }));
    }

    async getComment(contractId: string): Promise<string[]> {
        return firstValueFrom(this._httpClient.get<string[]>(`${this.url}/${contractId}/comments`));
    }

    async export(options: IDebitorExportOptions) {
        const params = this._toParams(options);

        return firstValueFrom(this._httpClient.get(`${this.url}/export`, {
            params,
            responseType: 'blob'
        }));
    }

    async exportCultivar(contractId: string) {
        return firstValueFrom(this._httpClient.get(`${this.url}/${contractId}/export-cultivar`, {
            responseType: 'blob'
        }));
    }
}
