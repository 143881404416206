import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { IEditDebitorData } from '../application/models/edit-debitor-data.interface';

@Injectable({
    providedIn: 'root'
})
export class DebitorService {

    constructor(private readonly _httpClient: HttpClient) {
    }

    updateDebitor(debitorId: string, editDebitorData: IEditDebitorData) {
        return firstValueFrom(this._httpClient.post(`/api/debitors/${debitorId}/change-requests`, editDebitorData));
    }
}
