import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseRepository, Endpoint, IFindOptions, IPaginated } from '@hopsteiner/shared/data-access';

import { IShipment } from '../entities/shipment.interface';

export interface IShipmentFindOptions extends Omit<IFindOptions, 'where'> {
    contractId: string;
}

@Injectable({
    providedIn: 'root'
})
export class ShipmentRepository extends BaseRepository<IShipment> {

    constructor(httpClient: HttpClient) {
        super(httpClient, {
            endpoint: Endpoint.SHIPMENTS
        });
    }

    findAllForContract(contractId: string, options?: IFindOptions): Promise<IPaginated<IShipment>> {
        return this._find(options ? {
            ...options,
            where: options.where ? { ...options.where, contractId } : { contractId }
        } : {
            where: {
                contractId
            }
        });
    }
}
