import { Directive } from '@angular/core';

@Directive({
    selector: '[hopListItemLabelLeft]',
    host: {
        class: 'c-hop-list-item__label-left'
    }
})
export class HopListItemLabelLeftDirective {

}
