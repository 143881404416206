import { Injectable } from '@angular/core';
import { HopContractBrowseFacade, IShipment } from '@hopsteiner/contract/domain';
import { HopShipmentDrawer } from '@hopsteiner/contract/ui-shipment-drawer';
import { DataSource, ISortableQuery } from '@hopsteiner/shared/collections';
import { HopDrawer } from '@hopsteiner/shared/overlays';

@Injectable({
    providedIn: 'root'
})
export class HopContractApiFacade {

    constructor(private readonly _drawer: HopDrawer,
                private readonly _contractBrowseFacade: HopContractBrowseFacade) {

    }

    createShipmentsDataSource(contractId: string): DataSource<IShipment, ISortableQuery> {
        return this._contractBrowseFacade.createShipmentsDataSource(contractId);
    }

    openShipmentDrawer(shipment: IShipment) {
        HopShipmentDrawer.open(this._drawer, shipment);
    }

    downloadDeliveryNote(deliveryNoteId: string): Promise<Blob> {
        return this._contractBrowseFacade.downloadDeliveryNote(deliveryNoteId);
    }
}
