import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

import { HopDataCollectionContainerDirective } from '../../directives/data-collection-container.directive';
import { HopDataCollectionContainerFacade } from '../../facades/data-collection-container.facade';

@Component({
    selector: 'hop-data-collection-container',
    templateUrl: './data-collection-container.component.html',
    styleUrls: [ './data-collection-container.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-data-collection-container'
    },
    providers: [
        HopDataCollectionContainerFacade
    ]
})
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class HopDataCollectionContainerComponent<T = any> extends HopDataCollectionContainerDirective<T> {

}
