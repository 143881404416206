import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { IActiveDebitor } from '@hopsteiner/environment/domain';
import { DataSource, ISortableQuery } from '@hopsteiner/shared/collections';
import { IDataCollectionViewer, IDataCollectionViewerHost } from '@hopsteiner/shared/data-table';

@Directive()
export abstract class BaseDebitorDrawerCollectionComponent implements IDataCollectionViewerHost<IActiveDebitor, ISortableQuery> {

    @Input()
    dataSource?: DataSource<IActiveDebitor, ISortableQuery>;

    @Output()
    readonly selectDebitor = new EventEmitter<IActiveDebitor>();

    abstract get collectionViewer(): IDataCollectionViewer<IActiveDebitor, ISortableQuery>;

    trackElementById(index: number, element: IActiveDebitor) {
        return element.navisionId;
    }
}
