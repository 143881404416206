import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { HopListItemComponent } from './components/list-item/list-item.component';
import { HopListComponent } from './components/list/list.component';
import { HopListItemDefDirective } from './directives/list-item-def.directive';
import { HopListItemLabelLeftDirective } from './directives/list-item-label-left.directive';
import { HopListItemLabelRightDirective } from './directives/list-item-label-right.directive';


@NgModule({
    imports: [
        CommonModule,
        FontAwesomeModule
    ],
    declarations: [
        HopListItemComponent,
        HopListComponent,

        HopListItemLabelLeftDirective,
        HopListItemLabelRightDirective,
        HopListItemDefDirective
    ],
    exports: [
        HopListItemComponent,
        HopListComponent,

        HopListItemLabelLeftDirective,
        HopListItemLabelRightDirective,
        HopListItemDefDirective
    ]
})
export class HopListModule {
}
