import { ListRange } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { HopCollectionViewerDirective, INITIAL_LIST_RANGE } from '@hopsteiner/shared/collections';

import { FilterType } from '../../models/filter-type.enum';
import { BaseSelectFilterComponent } from '../base-select-filter.component';

@Component({
    selector: 'hop-single-select-filter',
    templateUrl: './single-select-filter.component.html',
    styleUrls: [ './single-select-filter.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: HopSingleSelectFilterComponent,
            multi: true
        },
        {
            provide: INITIAL_LIST_RANGE,
            useValue: { start: 0, end: 200 } as ListRange
        }
    ],
    host: {
        class: 'c-hop-single-select-filter'
    }
})
export class HopSingleSelectFilterComponent<T = unknown, OPTION = T> extends BaseSelectFilterComponent<FilterType.SINGLE_SELECT, T> {

    @ViewChild('viewer')
    viewer?: HopCollectionViewerDirective<OPTION>;

    toggleOption(option: T) {
        this.value = this.getOptionValue(option);
    }

    isSelected(option: T) {
        return this.getOptionValue(option) === this.value;
    }

    getOptionValue(option: OPTION | T): T {
        if (this.filter?.getOptionValue) {
            return this.filter.getOptionValue(option);
        }

        return option as T;
    }
}
