<nav>
    <ul #cv="hopCollectionViewer" hopCollectionViewer [dataSource]="debitorsDataSource">
        <li hopCollectionViewer *ngFor="let debitor of (cv.data$ | async)">
            <button hopEnvDebitorSwitcherTab
                    *ngIf="debitor.navisionId !== activeDebitor?.navisionId; else activeDebitorTabTemplate"
                    [title]="debitor.fullName"
                    [subtitle]="debitor.navisionId"
                    [active]="false"
                    [ngbTooltip]="disabled ? ('ENVIRONMENT.DEBITOR_SWITCHER.FEEDBACK.NOT_ALLOWED' | translate) : ''"
                    [disabled]="disabled"
                    [hopHighlightRecord]="debitor.headquarters"
                    [highlightLabel]="'ENVIRONMENT.DEBITOR_SWITCHER.HIGHLIGHTED.HEADQUARTER' | translate"
                    highlightColor="var(--bs-primary)"
                    (click)="setActiveDebitor(debitor)">
            </button>

            <ng-template #activeDebitorTabTemplate>
                <button hopEnvDebitorSwitcherTab
                        [title]="debitor.fullName"
                        [subtitle]="debitor.navisionId"
                        [active]="true"
                        [routerLink]="[ '/debitors', debitor.navisionId ]"
                        [hopHighlightRecord]="debitor.headquarters"
                        [highlightLabel]="'ENVIRONMENT.DEBITOR_SWITCHER.HIGHLIGHTED.HEADQUARTER' | translate"
                        highlightColor="var(--bs-primary)">
                </button>
            </ng-template>
        </li>
    </ul>
</nav>
