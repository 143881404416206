import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HopButtonModule } from '@hopsteiner/shared/components';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { HopEnvLanguageMenuComponent } from './components/language-menu/language-menu.component';

@NgModule({
    imports: [
        CommonModule,

        FontAwesomeModule,
        NgbDropdownModule,
        NgbTooltipModule,
        TranslateModule,

        HopButtonModule
    ],
    declarations: [ HopEnvLanguageMenuComponent ],
    exports: [ HopEnvLanguageMenuComponent ]
})
export class HopEnvFeatureLanguageMenuModule {
}
