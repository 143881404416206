import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RouterDataResolved } from '@ngxs/router-plugin';
import { Action, Actions, NgxsOnInit, ofActionSuccessful, Selector, State, StateContext, Store } from '@ngxs/store';
import { compact as _compact, isEqual as _isEqual } from 'lodash';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { ITitleStateModel } from '../models/title-state-model.interface';
import { BASE_TITLE } from '../tokens/base-title.token';
import { TitleUtils } from '../utils/title.utils';

import { TitleActions } from './title.actions';

@State<ITitleStateModel>({
    name: 'title',
    defaults: {
        baseTitle: '',
        titleSegments: [],
        translatedTitle: ''
    }
})
@Injectable()
export class TitleState implements NgxsOnInit {

    @Selector()
    static selectTitleSegments(state: ITitleStateModel) {
        return _compact([ ...state.titleSegments, state.baseTitle ]);
    }

    @Selector()
    static selectTranslatedTitle(state: ITitleStateModel) {
        return state.translatedTitle;
    }

    constructor(@Inject(BASE_TITLE) private readonly _baseTitle: string,
                private readonly _translateService: TranslateService,
                private readonly _actions$: Actions,
                private readonly _store: Store,
                private readonly _title: Title) {
    }

    ngxsOnInit(ctx: StateContext<ITitleStateModel>) {
        ctx.patchState({
            baseTitle: this._baseTitle
        });

        this._actions$
            .pipe(
                ofActionSuccessful(RouterDataResolved),
                map((action) => TitleUtils.getTitleSegments((action.routerState as RouterStateSnapshot).root.firstChild))
            )
            .subscribe((titles) => {
                ctx.dispatch(new TitleActions.SetTitleSegments(titles));
            });

        // eslint-disable-next-line @typescript-eslint/unbound-method
        this._store.select(TitleState.selectTitleSegments)
            .pipe(
                distinctUntilChanged((a, b) => _isEqual(a, b)),
                switchMap((segments) => TitleUtils.translateAndMergeAll(segments, this._translateService))
            )
            .subscribe((translatedTitle: string) => {
                ctx.dispatch(new TitleActions.SetTranslatedTitle(translatedTitle));
            });
    }

    @Action(TitleActions.SetTitleSegments)
    setTitleSegments(ctx: StateContext<ITitleStateModel>, action: TitleActions.SetTitleSegments) {
        ctx.patchState({
            titleSegments: action.titleSegments
        });
    }

    @Action(TitleActions.SetTranslatedTitle)
    setTranslatedTitle(ctx: StateContext<ITitleStateModel>, action: TitleActions.SetTranslatedTitle) {
        ctx.patchState({
            translatedTitle: action.translatedTitle
        });

        this._title.setTitle(action.translatedTitle);
    }

}
