import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'hop-detail-layout',
    templateUrl: './detail.layout.html',
    styleUrls: [ './detail.layout.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-detail-layout'
    }
})
export class HopDetailLayout {
    readonly _icon = {
        faAngleLeft
    };

    constructor(private _location: Location) {
    }

    returnToPreviousPage(): void {
        this._location.back();
    }
}
