import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { BreakpointLookupType } from '../models/breakpoint-lookup-type.enum';
import { BreakpointMap } from '../models/breakpoint-map';
import { Breakpoint } from '../models/breakpoint.enum';
import { IBetweenBreakpoint, IBreakpoint, IDownBreakpoint, IOnlyBreakpoint, IUpBreakpoint } from '../models/breakpoint.interface';
import { BREAKPOINT_MAP } from '../tokens/breakpoints.token';

@Injectable()
export class Breakpoints {

    static up(breakpoint: Breakpoint): IUpBreakpoint {
        return { type: BreakpointLookupType.UP, breakpoint };
    }

    static down(breakpoint: Breakpoint): IDownBreakpoint {
        return { type: BreakpointLookupType.DOWN, breakpoint };
    }

    static only(breakpoint: Breakpoint): IOnlyBreakpoint {
        return { type: BreakpointLookupType.ONLY, breakpoint };
    }

    static between(min: Breakpoint, max: Breakpoint): IBetweenBreakpoint {
        return { type: BreakpointLookupType.BETWEEN, min, max };
    }

    static next(breakpoint: Breakpoint): Breakpoint | undefined {
        switch (breakpoint) {
            case Breakpoint.XS:
                return Breakpoint.SM;
            case Breakpoint.SM:
                return Breakpoint.MD;
            case Breakpoint.MD:
                return Breakpoint.LG;
            case Breakpoint.LG  :
                return Breakpoint.XL;
            case Breakpoint.XL:
                return Breakpoint.XXL;
            case Breakpoint.XXL:
                return undefined;
        }
    }

    constructor(@Inject(BREAKPOINT_MAP) private readonly _breakpointMap: BreakpointMap,
                private readonly _breakpointObserver: BreakpointObserver) {

    }

    isMatched(breakpoint: IBreakpoint | IBreakpoint[]): boolean {
        return this._breakpointObserver.isMatched(this._createMediaQueries(breakpoint));
    }

    observe(breakpoint: IBreakpoint | IBreakpoint[]): Observable<BreakpointState> {
        return this._breakpointObserver.observe(this._createMediaQueries(breakpoint));
    }

    observeMatched(breakpoint: IBreakpoint | IBreakpoint[]): Observable<boolean> {
        return this.observe(breakpoint)
            .pipe(
                map((state) => state.matches)
            );
    }

    private _createMediaQueries(breakpoint: IBreakpoint | IBreakpoint[]): string | string[] {
        if (Array.isArray(breakpoint)) {
            return breakpoint.map((v) => this._createMediaQuery(v));
        }

        return this._createMediaQuery(breakpoint);
    }

    private _createMediaQuery(breakpoint: IBreakpoint): string {
        switch (breakpoint.type) {
            case BreakpointLookupType.UP:
                return `(min-width: ${this._getBreakpointValue(breakpoint.breakpoint)}px)`;
            case BreakpointLookupType.DOWN:
                return `(max-width: ${this._getBreakpointValue(breakpoint.breakpoint) - 0.02}px)`;
            case BreakpointLookupType.ONLY: {
                const next = Breakpoints.next(breakpoint.breakpoint);

                if (next) {
                    return this._createMediaQuery(Breakpoints.between(breakpoint.breakpoint, next));
                }

                return this._createMediaQuery(Breakpoints.up(breakpoint.breakpoint));
            }
            case BreakpointLookupType.BETWEEN:
                return `${Breakpoints.up(breakpoint.min)} and ${Breakpoints.down(breakpoint.max)}`;
        }
    }

    private _getBreakpointValue(breakpoint: Breakpoint): number {
        return this._breakpointMap[breakpoint];
    }
}
