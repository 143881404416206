import { TemplateRef } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle, faExclamationCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

export class HopToastConfig {
    /**
     * ID for the toast. If omitted, a unique one will be generated.
     **/
    id?: string;

    /**
     * The message to display inside the toast. Will not be displayed when using a custom template or component.
     *
     * @see HopToastConfig#template
     **/
    message?: string;

    /**
     * An optional icon to display next to the message. Will not be displayed when using a custom template or component.
     *
     * @see HopToastConfig#template
     **/
    icon?: IconDefinition;

    /**
     * A custom template for the toast content.
     */
    template?: TemplateRef<unknown>;

    /**
     * Custom class for the toast.
     * */
    toastClass?: string = '';

    /**
     * Whether the toast should be hidden automatically. Defaults to true
     */
    autoHide?: boolean = true;

    /**
     * The duration in milliseconds after which the toast should be hidden. Defaults to 5000 milliseconds
     */
    duration?: number = 5000;
}

export class SuccessToastConfig extends HopToastConfig {

    constructor(message: string) {
        super();

        this.message = message;
        this.icon = faCheckCircle;
        this.toastClass = 'bg-success text-white';
    }
}

export class WarningToastConfig extends HopToastConfig {

    constructor(message: string) {
        super();

        this.message = message;
        this.icon = faExclamationTriangle;
        this.toastClass = 'bg-warning';
    }
}

export class ErrorToastConfig extends HopToastConfig {

    constructor(message: string) {
        super();

        this.message = message;
        this.icon = faExclamationCircle;
        this.toastClass = 'bg-danger text-white';
    }
}
