<div class="c-hop-button__label" *ngIf="!iconOnly">
    <ng-content></ng-content>
</div>

<div class="c-hop-button__append" *ngIf="icon || append?.length">
    <ng-content select="fa-icon,[hopButtonAppend]"></ng-content>
</div>

<div class="c-hop-button__append" *ngIf="loading">
    <div class="spinner-border spinner-border-sm" role="status">
        <span class="visually-hidden">{{ (loadingMessage || 'COMPONENTS.BUTTON.LOADING') | translate }}</span>
    </div>
</div>

