import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { TitleState } from './state/title.state';
import { BASE_TITLE } from './tokens/base-title.token';


@NgModule({
    imports: [
        CommonModule,
        NgxsModule.forFeature([ TitleState ])
    ]
})
export class HopTitleModule {

    static forRoot(baseTitle: string): ModuleWithProviders<HopTitleModule> {
        return {
            ngModule: HopTitleModule,
            providers: [
                { provide: BASE_TITLE, useValue: baseTitle }
            ]
        };
    }
}
