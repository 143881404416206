import { HopOverlayConfig } from '../../shared/models/overlay-config';

import { DialogPosition } from './dialog-position';
import { DialogRole } from './dialog-role';


export class HopDialogConfig<DATA = unknown> extends HopOverlayConfig<DATA> {

    /** The ARIA role of the dialog element. */
    role?: DialogRole = 'dialog';

    /** Position overrides. */
    position?: DialogPosition;

}
