import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { HopAddressModule } from '../address/address.module';

import { HopContactCompanyComponent } from './components/contact-company/contact-company.component';
import { HopContactMethodComponent } from './components/contact-method/contact-method.component';
import { HopContactPersonComponent } from './components/contact-person/contact-person.component';


@NgModule({
    imports: [
        CommonModule,

        FontAwesomeModule,

        HopAddressModule
    ],
    declarations: [
        HopContactMethodComponent,
        HopContactCompanyComponent,
        HopContactPersonComponent
    ],
    exports: [
        HopContactMethodComponent,
        HopContactCompanyComponent,
        HopContactPersonComponent
    ]
})
export class HopContactModule {
}
