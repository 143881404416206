export enum Endpoint {
    CONTACTS = 'contacts',
    CONTRACTS = 'contracts',
    COUNTRIES = 'countries',
    CULTIVARS = 'cultivars',
    DASHBOARD = 'dashboard',
    DEBITORS = 'debitors',
    GROWING_AREAS = 'growing-areas',
    HARVEST_YEARS = 'harvest-years',
    INVOICES = 'invoices',
    ORDER_REQUEST = 'order-requests',
    ORDER_REQUESTS_DATA = 'order-requests-data',
    PRODUCTS = 'products',
    SHIPMENTS = 'shipments',
    PARTIAL_CANCELLATIONS = 'partial-cancellations',
    USERS = 'users',
    RECENT_ACTIVE_DEBITORS = 'active-debitor/recents',
    ACTIVE_DEBITOR = 'active-debitor',
    DEBITOR_SWITCHER = 'debitor-switcher/list',
    STATIC_CONTENT = 'static-content',
    DELIVERY_NOTES = 'delivery-notes',
    QUANTITY_UNITS = 'quantity-units'
}
