import { Injectable } from '@angular/core';
import { Action, createSelector, State, StateContext, StateOperator } from '@ngxs/store';

import { QueryUtils } from '../../data-collection-container/utils/query.utils';
import { IOverviewLayoutStateModel } from '../models/overview-layout-state.interface';

import { OverviewLayoutActions } from './overview-layout.actions';

function patchFeatureState(featureName: string, featureState: Partial<IOverviewLayoutStateModel[string]>): StateOperator<IOverviewLayoutStateModel> {
    return (state: IOverviewLayoutStateModel) => {
        return {
            ...state,
            [featureName]: {
                ...(state[featureName] ?? {}),
                ...featureState
            }
        };
    };
}

@State<IOverviewLayoutStateModel>({
    name: 'overviewLayout',
    defaults: {}
})
@Injectable()
export class OverviewLayoutState {

    static feature(featureName: string) {
        return createSelector([ OverviewLayoutState ], (state: IOverviewLayoutStateModel) => {
            return state[featureName];
        });
    }

    static searchTerm(featureName: string) {
        return createSelector([ OverviewLayoutState.feature(featureName) ], (state?: IOverviewLayoutStateModel[string]) => {
            return state?.searchTerm;
        });
    }

    static filterResult(featureName: string) {
        return createSelector([ OverviewLayoutState.feature(featureName) ], (state?: IOverviewLayoutStateModel[string]) => {
            return state?.filter;
        });
    }

    static sort(featureName: string) {
        return createSelector([ OverviewLayoutState.feature(featureName) ], (state?: IOverviewLayoutStateModel[string]) => {
            return state?.sort;
        });
    }

    static page(featureName: string) {
        return createSelector([ OverviewLayoutState.feature(featureName) ], (state?: IOverviewLayoutStateModel[string]) => {
            return state?.page ?? 0;
        });
    }

    static query(featureName: string) {
        return createSelector([ OverviewLayoutState.feature(featureName) ], (state?: IOverviewLayoutStateModel[string]) => {
            return QueryUtils.createQuery(state?.filter, state?.searchTerm, state?.sort);
        });
    }

    @Action(OverviewLayoutActions.SetSearchTerm)
    setSearchTerm(ctx: StateContext<IOverviewLayoutStateModel>, action: OverviewLayoutActions.SetSearchTerm) {
        const { featureName, searchTerm } = action;

        ctx.setState(patchFeatureState(featureName, { searchTerm }));
    }

    @Action(OverviewLayoutActions.SetFilterResult)
    setFilterResult(ctx: StateContext<IOverviewLayoutStateModel>, action: OverviewLayoutActions.SetFilterResult) {
        const { featureName, filterResult } = action;

        ctx.setState(patchFeatureState(featureName, { filter: filterResult }));
    }

    @Action(OverviewLayoutActions.SetSort)
    setSort(ctx: StateContext<IOverviewLayoutStateModel>, action: OverviewLayoutActions.SetSort) {
        const { featureName, sort } = action;

        ctx.setState(patchFeatureState(featureName, { sort }));
    }

    @Action(OverviewLayoutActions.SetPage)
    setPage(ctx: StateContext<IOverviewLayoutStateModel>, action: OverviewLayoutActions.SetPage) {
        const { featureName, page } = action;

        ctx.setState(patchFeatureState(featureName, { page }));
    }
}
