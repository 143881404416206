import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { SessionActions } from '../actions/session.actions';
import { EnvironmentState } from '../environment.state';

@Injectable({
    providedIn: 'root'
})
export class SessionFacade {

    constructor(private readonly _store: Store) {

    }

    get sessionId() {
        return this._store.selectSnapshot(EnvironmentState.selectSessionId);
    }

    setSessionId(sessionId: string) {
        return this._store.dispatch(new SessionActions.SetSessionId(sessionId));
    }

    clearSessionId() {
        return this._store.dispatch(new SessionActions.ClearSessionId());
    }
}
