import { ComponentType, Overlay, OverlayConfig, OverlayRef, ScrollStrategy } from '@angular/cdk/overlay';
import { Inject, Injectable, Injector, Optional, TemplateRef } from '@angular/core';

import { OverlayBase } from '../../shared/services/overlay.service';
import { OVERLAY_SCROLL_STRATEGY } from '../../shared/tokens/overlay-scroll-strategy.token';
import { HopDrawerContainerComponent } from '../components/drawer-container/drawer-container.component';
import { HopDrawerConfig } from '../models/drawer-config';
import { DrawerPosition } from '../models/drawer-position.enum';
import { HopDrawerRef } from '../models/drawer-ref';
import { HOP_DRAWER_DATA } from '../tokens/drawer-data.token';

/**
 * Service to open drawers
 */
@Injectable()
export class HopDrawer extends OverlayBase<HopDrawerConfig> {

    constructor(overlay: Overlay,
                injector: Injector,
                @Inject(OVERLAY_SCROLL_STRATEGY) scrollStrategy: ScrollStrategy,
                @Optional() defaultConfig?: HopDrawerConfig) {
        super(overlay, injector, HopDrawerContainerComponent, HopDrawerConfig, HopDrawerRef, HOP_DRAWER_DATA, scrollStrategy, defaultConfig);
    }

    override open<T, R>(content: ComponentType<T> | TemplateRef<T>, config: HopDrawerConfig = {}): HopDrawerRef<T, R> {
        return super.open(content, config) as HopDrawerRef<T, R>;
    }

    protected override _createOverlayConfig(config: HopDrawerConfig): OverlayConfig {
        const basePosition = this._overlay.position().global().top();

        return {
            ...super._createOverlayConfig(config),
            positionStrategy: config.position === DrawerPosition.END ? basePosition.right() : basePosition.left()
        };
    }

    protected createOverlayRef<T, R>(overlayRef: OverlayRef, overlayContainer: HopDrawerContainerComponent): HopDrawerRef<T, R> {
        return new HopDrawerRef<T, R>(overlayRef, overlayContainer);
    }

    protected createDefaultConfig(): HopDrawerConfig {
        return new HopDrawerConfig();
    }

}
