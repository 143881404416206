interface IFixedStartDateRange {
    startDate: Date | null;
    endDate?: Date | null;
}

interface IFixedEndDateRange {
    startDate?: Date | null;
    endDate: Date | null;
}

export type DateRange = IFixedStartDateRange | IFixedEndDateRange;
