import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

import { IChange } from '../../models/change.interface';


@Component({
    selector: 'hop-diff',
    templateUrl: './diff.component.html',
    styleUrls: [ './diff.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-diff'
    }
})
export class HopDiffComponent {

    @Input()
    change?: IChange;

}
