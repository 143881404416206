import { Injectable } from '@angular/core';
import { HopEnvironmentApiFacade } from '@hopsteiner/environment/api';
import { DataSource, ISortableQuery } from '@hopsteiner/shared/collections';
import { RepositoryDataSource, transformOptions } from '@hopsteiner/shared/data-access';
import { CsvExportService } from '@hopsteiner/shared/export';
import { pick } from 'lodash';

import { ICountry } from '../../entities/country.interface';
import { IOverviewDebitor } from '../../entities/debitor.interface';
import { CountryDataSource } from '../../infrastructure/country.data-source';
import { CountryRepository } from '../../infrastructure/country.repository';
import { DebitorRepository } from '../../infrastructure/debitor.repository';
import { IDebitorExportOptions } from '../../models/debitor-export-options.interface';

@Injectable({
    providedIn: 'root'
})
export class HopDebitorBrowseFacade {

    constructor(private readonly _debitorRepository: DebitorRepository,
                private readonly _countryRepository: CountryRepository,
                private readonly _environmentFacade: HopEnvironmentApiFacade,
                private readonly _csvExportService: CsvExportService) {

    }

    createDataSource(): DataSource<IOverviewDebitor, ISortableQuery> {
        return new RepositoryDataSource(this._debitorRepository);
    }

    createCountryDataSource(): DataSource<ICountry> {
        return new CountryDataSource(this._countryRepository, this._environmentFacade);
    }

    getCountry(isoCode: string): Promise<ICountry> {
        return this._countryRepository.get(isoCode);
    }


    async export(options: IDebitorExportOptions) {
        const transformedQuery = transformOptions()(null, options);

        return this._debitorRepository.export(pick(transformedQuery, 'where', 'sort'));
    }

    async exportToFile(options: IDebitorExportOptions) {
        return this._csvExportService.export(await this._debitorRepository.export(options), 'debitors');
    }
}
