export interface IAssignedDebitors {

    /**
     * The full name of the debitor
     */
    fullName: string;

    /**
     * The id of the debitor
     */
    navisionId: string;

    /**
     * Whether the debitor is assigned to customer
     */
    assigned: boolean;
}
