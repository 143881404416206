<div class="c-hop-data-list__empty-container" *ngIf="!isLoading && (connection?.total$ | async) === 0">
    {{ emptyMessage || 'DATA_TABLE.LIST.EMPTY_MESSAGE' | translate }}
</div>

<div class="c-hop-data-list__scroll-container" hopScrollContainer>
    <hop-data-card *ngFor="let element of (connection?.data$ | async); trackBy: trackBy"
                   [hopHighlightRecord]="highlightIf ? highlightIf(element) : false"
                   [highlightLabel]="highlightLabel"
                   [highlightColor]="highlightColor">
        <div hopCardTitle>
            <ng-container *ngTemplateOutlet="title?.templateRef || null; context: { $implicit: element }"></ng-container>
        </div>

        <div *ngIf="menu" ngbDropdown hopDataCardMenu>
            <button hopIconButton ngbDropdownToggle>
                <fa-icon [icon]="_icons.faEllipsisH"></fa-icon>
            </button>
            <div ngbDropdownMenu>
                <ng-container *ngTemplateOutlet="menu?.templateRef || null; context: { $implicit: element }"></ng-container>
            </div>
        </div>

        <div *ngIf="button" hopDataCardButton>
            <ng-container *ngTemplateOutlet="button?.templateRef || null; context: { $implicit: element }"></ng-container>
        </div>

        <table hopKeyValueTable>
            <ng-container *ngFor="let propertyDef of propertyDefs">
                <tr *ngIf="displayProperties == null || displayProperties.includes(propertyDef.property)">
                    <ng-container
                        *ngTemplateOutlet="propertyDef.label?.templateRef || null; context: { $implicit: element }"></ng-container>
                    <ng-container
                        *ngTemplateOutlet="propertyDef.value?.templateRef || null; context: { $implicit: element }"></ng-container>
                </tr>
            </ng-container>
        </table>
    </hop-data-card>
</div>

<hop-loading-indicator [loading]="isLoading"
                       [message]="loadingMessage"
                       [appearance]="isInfiniteScroll ? IndicatorAppearance.EDGE : IndicatorAppearance.DEFAULT">
</hop-loading-indicator>
