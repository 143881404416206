import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { HOP_DRAWER_DATA, HopDrawer, HopDrawerRef } from '@hopsteiner/shared/overlays';
import { isEqual as _isEqual, mapValues as _mapValues, omitBy, without as _without } from 'lodash';
import { Subject, takeUntil } from 'rxjs';

import { IFilterBadgeRemoveEvent } from '../../components/filter-badge/filter-badge.component';
import { DateRange } from '../../models/date-range';
import { Filter } from '../../models/filter';
import { IFilterDrawerData } from '../../models/filter-drawer-data.interface';
import { IFilterDrawerResult } from '../../models/filter-drawer-result.interface';
import { FiltersWithKeys, IFilterGroup } from '../../models/filter-group';
import { FilterResult } from '../../models/filter-result';
import { FilterType } from '../../models/filter-type.enum';
import { FilterUtils } from '../../utils/filter.utils';


@Component({
    selector: 'hop-filter-drawer',
    templateUrl: './filter.drawer.html',
    styleUrls: [ './filter.drawer.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-filter-drawer'
    }
})
export class HopFilterDrawer<RESULT extends FilterResult> implements OnInit, OnDestroy {

    readonly FilterType = FilterType;

    readonly dateFormat = 'dd.MM.yyyy';

    @ViewChild('defaultValueTemplate', { static: true })
    defaultValueTemplate!: TemplateRef<unknown>;

    @ViewChild('defaultDateValueTemplate', { static: true })
    defaultDateValueTemplate!: TemplateRef<Date>;

    @ViewChild('defaultDateRangeValueTemplate', { static: true })
    defaultDateRangeValueTemplate!: TemplateRef<DateRange>;


    readonly filters: FiltersWithKeys<RESULT>;
    readonly form: FormGroup;

    private readonly _previousResult?: RESULT;
    private readonly _initialResult?: RESULT;
    private readonly _onDestroy = new Subject<void>();

    static open<R extends FilterResult>(drawer: HopDrawer, filterGroup: IFilterGroup<R>, result?: R | null, initialResult?: R | null) {
        return drawer.open<HopFilterDrawer<R>, IFilterDrawerResult<R>>(HopFilterDrawer, {
            data: {
                filterGroup,
                result,
                initialResult
            },
            width: '40rem',
            ariaLabelledBy: 'hop-filter-drawer-title',
            autoFocus: 'hop-filter-drawer-panel-0'
        });
    }

    constructor(private readonly _drawerRef: HopDrawerRef<HopFilterDrawer<RESULT>, IFilterDrawerResult<RESULT>>,
                @Inject(HOP_DRAWER_DATA) private readonly _data: IFilterDrawerData<RESULT>) {

        this.filters = FilterUtils.createInstances<RESULT>(_data.filterGroup);
        this._initialResult = _data.initialResult;
        const result = this._previousResult = _data.result;

        this.form = new FormGroup(_mapValues(_data.filterGroup.filters, (filter, name) => {
            return new FormControl(result ? result[name] : null);
        }));
    }

    get hasFilters(): boolean {
        return true;
    }

    get result(): RESULT {
        return this.form.value;
    }

    ngOnInit() {
        this.form.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this._updateDisableClose();
            });
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getDefaultValueTemplate(filter: Filter<any>) {
        switch (filter.type) {
            case FilterType.DATE:
                return this.defaultDateValueTemplate;
            case FilterType.DATE_RANGE:
                return this.defaultDateRangeValueTemplate;
            default:
                return this.defaultValueTemplate;
        }
    }

    resetFilters() {
        const count = FilterUtils.getFilterCount(this.filters, this._initialResult as RESULT);

        this._drawerRef.close({ result: this._initialResult, count: count });
    }

    removeValue($event: IFilterBadgeRemoveEvent) {
        const control = this.form.get($event.name);

        if (!control) {
            return;
        }

        if ($event.value == null) {
            control.reset();
        } else {
            control.setValue(_without(control.value, $event.value));
        }
    }

    onSubmit() {
        const result = omitBy(this.result, (value) => value == null) as RESULT;

        const count = FilterUtils.getFilterCount(this.filters, result);

        this._drawerRef.close({ result, count });
    }

    private _updateDisableClose() {
        const result = this.result;

        if (this._previousResult) {
            this._drawerRef.disableClose = !_isEqual(this._previousResult, result);
        } else {
            this._drawerRef.disableClose = FilterUtils.hasSomeValue(this.filters, result);
        }
    }
}
