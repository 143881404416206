import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

import { IMutableRepositoryOptions } from '../models/mutable-repository-options.interface';

import { BaseRepository } from './base-repository';


export abstract class MutableRepository<T, CREATE_DATA = T, PATCH_DATA = T, OPTIONS extends IMutableRepositoryOptions = IMutableRepositoryOptions> extends BaseRepository<T, OPTIONS> {

    constructor(httpClient: HttpClient, options: OPTIONS) {
        super(httpClient, options);
    }

    create(data: CREATE_DATA): Promise<T> {
        return firstValueFrom(this._httpClient.post<T>(this.url, data));
    }

    patch(id: string | number, data: PATCH_DATA): Promise<T> {
        return firstValueFrom(this._httpClient.patch<T>(`${this.url}/${id}`, data));
    }

    remove(id: string | number): Promise<T> {
        return firstValueFrom(this._httpClient.delete<T>(`${this.url}/${id}`));
    }
}
