import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'sanitizeHtml'
})
export class SanitizeHtmlPipe implements PipeTransform {

    constructor(private readonly _domSanitizer: DomSanitizer) {

    }

    transform(value: string) {
        return this._domSanitizer.sanitize(SecurityContext.HTML, value);
    }

}
