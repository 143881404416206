import { animate, state, style, transition, trigger } from '@angular/animations';

import { OverlayAnimationState } from '../../shared/models/overlay-animation-state.enum';


export const DIALOG_ANIMATIONS = {
    /** Animation that scales and fades the dialog in and out. */
    dialogContainer: trigger('dialogContainer', [
        // Note: The `enter` animation transitions to `transform: none`, because for some reason
        // specifying the transform explicitly, causes IE both to blur the dialog content and
        // decimate the animation performance. Leaving it as `none` solves both issues.
        state(`${OverlayAnimationState.VOID}, ${OverlayAnimationState.EXIT}`, style({ opacity: 0, transform: 'scale(0.7)' })),
        state(OverlayAnimationState.ENTER, style({ transform: 'none' })),
        transition(
            `* => ${OverlayAnimationState.ENTER}`,
            animate('150ms cubic-bezier(0, 0, 0.2, 1)', style({ transform: 'none', opacity: 1 }))
        ),
        transition(
            `* => ${OverlayAnimationState.VOID}, * => ${OverlayAnimationState.EXIT}`,
            animate('75ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ opacity: 0 }))
        )
    ])
};
