import { SelectableContractTableColumn } from '../entities/preferences/selectable-contract-table-column';

export const ALL_SELECTABLE_CONTRACT_COLUMNS: SelectableContractTableColumn[] = [
    'cultivar',
    'growingArea',
    'product',
    'harvestYear',
    'unitPrice',
    'quantity',
    'contractDate',
    'fulfilled',
    'alphaPercentage',
    'baseAlphaPercent',
    'alphaClause',
    'analysisDescription',
    'certificate',
    'type',
    'paymentCondition',
    'fullyShipped',
    'packagingInformation',
    'deliveryCondition',
    'cargoIncluded',
    'salesReference',
    'externalPurchaseNumber',
    'endCustomerName',
    'comments',
    'displayOutstandingQuantity',
    'organicLabels'
];
