import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output, ViewEncapsulation } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export interface IFilterBadgeRemoveEvent<T = unknown> {
    name: string;
    value?: T;
}

@Component({
    selector: 'hop-filter-badge',
    templateUrl: './filter-badge.component.html',
    styleUrls: [ './filter-badge.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-filter-badge badge rounded-pill bg-primary',
        // eslint-disable-next-line @angular-eslint/no-host-metadata-property
        'tabindex': '0'
    }
})
export class HopFilterBadgeComponent<T = unknown> {

    readonly _closeIcon = faTimes;

    private _removable: boolean = true;

    @Input()
    name!: string;

    @Input()
    value?: T;

    @Input()
    get removable(): boolean {
        return this._removable;
    }

    set removable(value: BooleanInput) {
        if (value == null) {
            this._removable = true;
            return;
        }

        this._removable = coerceBooleanProperty(value);
    }

    @Output()
    readonly remove = new EventEmitter<IFilterBadgeRemoveEvent<T>>();

    @HostListener('keydown.enter')
    onEnterPressed() {
        this.dispatchRemove();
    }

    onRemoveClicked($event: MouseEvent) {
        this.dispatchRemove();
        $event.stopPropagation();
    }

    dispatchRemove() {
        this.remove.next({ name: this.name, value: this.value });
    }
}
