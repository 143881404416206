import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoint, IFindOptions, IPaginated, MutableRepository } from '@hopsteiner/shared/data-access';
import { UserRole } from '@hopsteiner/shared/models';
import { firstValueFrom } from 'rxjs';

import { ICreateUserChangeRequestData } from '../application/models/create-user-change-request-data.interface';
import { IEditUserData } from '../application/models/edit-user-data.interface';
import { ICreateUserFromNavisionDto } from '../entities/create-user-from-navision.dto';
import { ICreateUserDto } from '../entities/create-user.dto';
import { INavisionUser } from '../entities/navision-user.interface';
import { IUserChangeRequest } from '../entities/user-change-request.interface';
import { IUserDeleteRequest } from '../entities/user-delete-request.interface';
import { IDetailUser, IOverviewUser, IUser } from '../entities/user.interface';
import { IUserExportOptions } from '../models/user-export-options.interface';


@Injectable({
    providedIn: 'root'
})
export class UserRepository extends MutableRepository<IUser, ICreateUserDto, IEditUserData> {

    constructor(httpClient: HttpClient) {
        super(httpClient, {
            endpoint: Endpoint.USERS
        });
    }

    async find(options: IFindOptions): Promise<IPaginated<IOverviewUser>> {
        return this._find<IPaginated<IOverviewUser>>(options);
    }

    async export(options: IUserExportOptions) {
        const params = this._toParams(options);

        return firstValueFrom(this._httpClient.get(`${this.url}/export`, {
            params,
            responseType: 'blob'
        }));
    }

    async get(userId: string | number): Promise<IDetailUser> {
        return this._get<IDetailUser>(userId);
    }

    validateEmail(email: string | null | undefined, role: UserRole): Promise<INavisionUser[]> {
        return firstValueFrom(this._httpClient.post<INavisionUser[]>(`${this.url}/validate-email`, { email, role }));
    }

    createFromNavision(createUserData: ICreateUserFromNavisionDto): Promise<IUser> {
        return firstValueFrom(this._httpClient.post<IUser>(`${this.url}/from-navision`, createUserData));
    }

    createDeleteRequest(userId: number) {
        return firstValueFrom(this._httpClient.post(`${this.url}/${userId}/delete-requests`, {}));
    }

    getDeleteRequest(userId: number): Promise<IUserDeleteRequest> {
        return firstValueFrom(this._httpClient.get<IUserDeleteRequest>(`${this.url}/${userId}/delete-requests`));
    }

    createChangeRequest(userId: number, changeRequestUserData: ICreateUserChangeRequestData) {
        return firstValueFrom(this._httpClient.post<ICreateUserChangeRequestData>(`${this.url}/${userId}/change-requests`, changeRequestUserData));
    }

    getChangeRequest(userId: number): Promise<IUserChangeRequest> {
        return firstValueFrom(this._httpClient.get<IUserChangeRequest>(`${this.url}/${userId}/change-requests`));
    }

    sendPasswordLink(userId: number) {
        return firstValueFrom(this._httpClient.post(`${this.url}/${userId}/send-password-link`, {}));
    }
}
