/* eslint-disable @angular-eslint/directive-class-suffix */
import { CdkCellDef, CdkFooterCellDef, CdkHeaderCellDef } from '@angular/cdk/table';
import { Directive } from '@angular/core';

/**
 * Cell definition for the hop-table.
 * Captures the template of a column's data row cell as well as cell-specific properties.
 */
@Directive({
    selector: '[hopCellDef]',
    providers: [ { provide: CdkCellDef, useExisting: HopCellDef } ]
})
export class HopCellDef extends CdkCellDef {
}

/**
 * Header cell definition for the hop-table.
 * Captures the template of a column's header cell and as well as cell-specific properties.
 */
@Directive({
    selector: '[hopHeaderCellDef]',
    providers: [ { provide: CdkHeaderCellDef, useExisting: HopHeaderCellDef } ]
})
export class HopHeaderCellDef extends CdkHeaderCellDef {
}

/**
 * Footer cell definition for the hop-table.
 * Captures the template of a column's footer cell and as well as cell-specific properties.
 */
@Directive({
    selector: '[hopFooterCellDef]',
    providers: [ { provide: CdkFooterCellDef, useExisting: HopFooterCellDef } ]
})
export class HopFooterCellDef extends CdkFooterCellDef {
}
