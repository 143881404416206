import { Injectable } from '@angular/core';
import { IDebitorExportOptions } from '@hopsteiner/debitor/api';
import { HopInvoiceApiFacade } from '@hopsteiner/invoice/api';
import { DataSource, ISortableQuery } from '@hopsteiner/shared/collections';
import { RepositoryDataSource, transformOptions, TransformOptionsUtils, UnpaginatedRepositoryDataSource } from '@hopsteiner/shared/data-access';
import { CsvExportService } from '@hopsteiner/shared/export';
import { HopDrawer } from '@hopsteiner/shared/overlays';
import { pick } from 'lodash';

import { IContractBrowseFilter } from '../../entities/contract-browse-filter.interface';
import { IContractFindQueryParams } from '../../entities/contract-find-query-params.interface';
import { IOverviewContract } from '../../entities/contract.interface';
import { ICultivar } from '../../entities/cultivar.interface';
import { IGrowingArea } from '../../entities/growing-area.interface';
import { IProduct } from '../../entities/product.interface';
import { IShipment } from '../../entities/shipment.interface';
import { ContractRepository } from '../../infrastructure/contract.repository';
import { CultivarRepository } from '../../infrastructure/cultivar.repository';
import { DeliveryNoteRepository } from '../../infrastructure/delivery-note.repository';
import { GrowingAreaRepository } from '../../infrastructure/growing-area.repository';
import { HarvestYearRepository } from '../../infrastructure/harvest-year.repository';
import { ProductRepository } from '../../infrastructure/product.repository';
import { ShipmentDataSource } from '../../infrastructure/shipment.data-source';

@Injectable({ providedIn: 'root' })
export class HopContractBrowseFacade {

    readonly transformBrowseOptions = transformOptions((where: IContractBrowseFilter & { query?: string }) => {
        const params: Partial<IContractFindQueryParams> = {};

        if ('query' in where) {
            params.query = where.query;
        }

        if ('cultivar' in where) {
            params.cultivar = where.cultivar.join();
        }

        if ('growingArea' in where) {
            params.growingArea = where.growingArea.join();
        }

        if ('product' in where) {
            params.product = where.product.join();
        }

        if ('harvestYear' in where) {
            params.harvestYear = where.harvestYear.join();
        }

        if ('fulfilled' in where) {
            params.fulfilled = where.fulfilled;
        }

        if ('completedAfter' in where || 'completedBefore' in where) {
            params.completedAt = TransformOptionsUtils.convertDateRangeToISOStrings({ startDate: where.completedAfter, endDate: where.completedBefore });
        }

        if ('contractStatus' in where) {
            params.contractStatus = where.contractStatus;
        }

        if ('organicLabel' in where) {
            params.organicLabel = where.organicLabel.join();
        }


        return params;
    });

    constructor(private readonly _drawer: HopDrawer,
                private readonly _cultivarRepository: CultivarRepository,
                private readonly _growingAreaRepository: GrowingAreaRepository,
                private readonly _contractRepository: ContractRepository,
                private readonly _productRepository: ProductRepository,
                private readonly _harvestYearRepository: HarvestYearRepository,
                private readonly _csvExportService: CsvExportService,
                private readonly _deliveryNoteRepository: DeliveryNoteRepository,
                private readonly _invoiceApiFacade: HopInvoiceApiFacade) {
    }

    createDataSource(): DataSource<IOverviewContract, ISortableQuery> {
        return new RepositoryDataSource(this._contractRepository, this.transformBrowseOptions);
    }

    createShipmentsDataSource(contractId: string): DataSource<IShipment, ISortableQuery> {
        return new ShipmentDataSource(contractId, this._contractRepository);
    }

    createCultivarDataSource(): DataSource<ICultivar> {
        return new UnpaginatedRepositoryDataSource(this._cultivarRepository);
    }

    createGrowingAreaDataSource(): DataSource<IGrowingArea> {
        return new UnpaginatedRepositoryDataSource(this._growingAreaRepository);
    }

    createProductDataSource(): DataSource<IProduct> {
        return new UnpaginatedRepositoryDataSource(this._productRepository);
    }

    createHarvestDataSource(): DataSource<number> {
        return new UnpaginatedRepositoryDataSource(this._harvestYearRepository);
    }

    async getComment(contractId: string) {
        return this._contractRepository.getComment(contractId);
    }

    async export(options: IDebitorExportOptions) {
        const transformedQuery = this.transformBrowseOptions(null, options);

        return this._contractRepository.export(pick(transformedQuery, 'where', 'sort'));
    }

    async exportToFile(options: IDebitorExportOptions) {
        return this._csvExportService.export(await this._contractRepository.export(options), 'contracts');
    }

    async downloadDeliveryNote(deliveryNoteId: string): Promise<Blob> {
        return this._deliveryNoteRepository.export(deliveryNoteId);
    }

    async downloadInvoice(invoiceId: string): Promise<Blob> {
        return this._invoiceApiFacade.downloadInvoice(invoiceId);
    }
}
