import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface IMenuItem {
    label: string;
    icon?: IconProp;
    svgIcon?: {
        src: string;
        alt: string;
    }
    routerLink?: string;
    externalLink?: string;
    click?: ($event: MouseEvent) => void;
    disabled?: boolean;
    tooltip?: string;
}
