import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseRepository, Endpoint, IFindOptions, IPaginated } from '@hopsteiner/shared/data-access';

import { IActiveDebitor } from '../models/active-debitor.interface';

@Injectable({
    providedIn: 'root'
})
export class EnvironmentDebitorSwitcherListRepository extends BaseRepository<IActiveDebitor> {

    constructor(httpClient: HttpClient) {
        super(httpClient, {
            endpoint: Endpoint.DEBITOR_SWITCHER
        });
    }

    find(options: IFindOptions): Promise<IPaginated<IActiveDebitor>> {
        return this._find(options);
    }
}
