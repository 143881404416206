import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HopDiffComponent } from './components/diff/diff.component';
import { HopHasChangePipe } from './pipes/has-input-changed.pipe';


@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        HopDiffComponent,
        HopHasChangePipe
    ],
    exports: [
        HopDiffComponent,
        HopHasChangePipe
    ]
})
export class HopChangesModule {
}
