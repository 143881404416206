import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HopAddressComponent } from './address/address.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        HopAddressComponent
    ],
    exports: [
        HopAddressComponent
    ]
})
export class HopAddressModule {
}
