import { Directive } from '@angular/core';

@Directive({
    selector: '[hopDrawerTitle]',
    host: {
        class: 'c-hop-drawer__title h5'
    }
})
export class HopDrawerTitleDirective {

}
