import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, Renderer2, ViewEncapsulation } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle, faExclamationCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { AlertStatus, AlertStatusType } from '../../models/alert-status.enum';

const STATUS_ICONS: Record<AlertStatus, IconDefinition> = {
    [AlertStatus.SUCCESS]: faCheckCircle,
    [AlertStatus.WARNING]: faExclamationTriangle,
    [AlertStatus.ERROR]: faExclamationCircle
};

@Component({
    selector: 'hop-alert',
    templateUrl: './alert.component.html',
    styleUrls: [ './alert.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-alert alert'
    }
})
export class HopAlertComponent {

    icon!: IconDefinition;

    private _status!: AlertStatusType;

    constructor(private readonly _elementRef: ElementRef,
                private readonly _renderer: Renderer2) {
        this.status = AlertStatus.WARNING;
    }

    @Input()
    get status(): AlertStatusType {
        return this._status;
    }

    set status(value: AlertStatusType) {
        if (value === this.status) {
            return;
        }

        const oldStatus = this.status;
        this._status = value;

        if (oldStatus) {
            this._renderer.removeClass(this._elementRef.nativeElement, `alert-${oldStatus}`);
        }

        if (value) {
            this._renderer.addClass(this._elementRef.nativeElement, `alert-${value}`);
        }

        this.icon = STATUS_ICONS[this._status];
    }

    @HostBinding('class.alert-warning')
    get isWarning(): boolean {
        return this.status == AlertStatus.WARNING;
    }
}
