import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class DatePickerCustomFormatService extends NgbDateParserFormatter {

    constructor() {
        super();
    }

    parse(value: string): NgbDateStruct | null {
        if (value) {
            const date = value.split('.');
            return {
                day: parseInt(date[0], 10),
                month: parseInt(date[1], 10),
                year: parseInt(date[2], 10)
            };
        }
        return null;
    }

    format(date: NgbDateStruct | null): string {
        return date ? this._padNumber(date.day) + '.' + this._padNumber(date.month) + '.' + date.year : '';
    }

    /**
     * Appends a 0 in front of the passed number in case the day or month is a single digit
     * If the number passed already has two digits, the first digit is truncated and the number reduced back to two digits
     *
     * @param value
     * @private
     */
    private _padNumber(value: number) {
        return `0${value}`.slice(-2);
    }
}
