/* eslint-disable @typescript-eslint/no-unused-vars */
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { HopDebitorApiFacade } from '@hopsteiner/debitor/api';
import { DebitorSwitcherFacade, IActiveDebitor } from '@hopsteiner/environment/domain';
import { map, Observable } from 'rxjs';

import { NO_ACTIVE_DEBITOR_REDIRECT } from '../tokens/forbidden-redirect.token';

/**
 * Checks whether a debitor is passed via a query param on the route and sets it as the active debitor.
 */
@Injectable({
    providedIn: 'root'
})
export class HopActivateDebitorGuard {

    static readonly QUERY_PARAM = 'activeDebitor';

    constructor(private readonly _debitorSwitcherFacade: DebitorSwitcherFacade,
                private readonly _debitorFacade: HopDebitorApiFacade) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this._activateDebitor(route.queryParams);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this._activateDebitor(route.queryParams);
    }

    protected async _activateDebitor(queryParams: Params) {
        if (HopActivateDebitorGuard.QUERY_PARAM in queryParams) {
            this._debitorSwitcherFacade.setActiveDebitorById(queryParams[HopActivateDebitorGuard.QUERY_PARAM]);
        }

        return true;
    }
}
