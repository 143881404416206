import { SelectableUserTableColumn } from '../entities/preferences/selectable-user-table-column';

export const ALL_SELECTABLE_USER_COLUMNS: SelectableUserTableColumn[] = [
    'email',
    'phone',
    'role',
    'status',
    'createdAt',
    'userManagement',
    'debitors'
];
