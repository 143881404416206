import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { OVERLAY_SCROLL_STRATEGY, overlayScrollStrategyFactory } from '../shared/tokens/overlay-scroll-strategy.token';

import { HopDialogBodyComponent } from './components/dialog-body/dialog-body.component';
import { HopDialogContainerComponent } from './components/dialog-container/dialog-container.component';
import { HopDialogFooterComponent } from './components/dialog-footer/dialog-footer.component';
import { HopDialogHeaderComponent } from './components/dialog-header/dialog-header.component';
import { HopDialogComponent } from './components/dialog/dialog.component';
import { HopDialogTitleDirective } from './directives/dialog-title.directive';
import { HopDialogConfig } from './models/dialog-config';
import { HopDialog } from './services/dialog.service';


@NgModule({
    imports: [
        CommonModule,

        PortalModule,
        OverlayModule
    ],
    providers: [
        HopDialog,
        {
            provide: HopDialogConfig,
            useValue: {
                maxWidth: '30rem'
            }
        },
        { provide: OVERLAY_SCROLL_STRATEGY, useFactory: overlayScrollStrategyFactory }
    ],
    declarations: [
        HopDialogComponent,
        HopDialogContainerComponent,

        HopDialogTitleDirective,
        HopDialogHeaderComponent,
        HopDialogBodyComponent,
        HopDialogFooterComponent
    ],
    exports: [
        HopDialogComponent,
        HopDialogContainerComponent,

        HopDialogTitleDirective,
        HopDialogHeaderComponent,
        HopDialogBodyComponent,
        HopDialogFooterComponent
    ]
})
export class HopDialogModule {

}
