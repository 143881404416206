import { AnimationEvent } from '@angular/animations';
import { FocusOrigin } from '@angular/cdk/a11y';
import { ComponentType } from '@angular/cdk/overlay';
import { BasePortalOutlet, CdkPortalOutlet, ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { ChangeDetectorRef, ComponentRef, Directive, EmbeddedViewRef, Inject, InjectionToken, Injector, Input, StaticProvider, TemplateRef, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';

import { IOverlayAnimationEvent } from '../models/overlay-animation-event.interface';
import { OverlayAnimationState } from '../models/overlay-animation-state.enum';
import { HopOverlayConfig } from '../models/overlay-config';
import { HopOverlayRef } from '../models/overlay-ref';
import { OverlayState } from '../models/overlay-state.enum';
import { OVERLAY_DATA_TOKEN } from '../tokens/overlay-data-token.token';
import { HOP_OVERLAY_DATA } from '../tokens/overlay-data.token';
import { OVERLAY_REF_TYPE } from '../tokens/overlay-ref-type.token';
import { OverlayFocusHelper } from '../utils/focus-helper';

interface IOverlayTemplateContext {
    $implicit: unknown;

    [key: string]: unknown;
}

@Directive()
export abstract class OverlayContainerComponent<CONFIG extends HopOverlayConfig> extends BasePortalOutlet {

    /** ID for the container DOM element. */
    @Input()
    id?: string;

    @ViewChild(CdkPortalOutlet, { static: true })
    contentOutlet?: CdkPortalOutlet;

    state: OverlayAnimationState = OverlayAnimationState.ENTER;

    /** ID of the element that should be considered as the overlay's label. */
    readonly _ariaLabelledBy: string | null;

    readonly _animationStateChanged = new Subject<IOverlayAnimationEvent>();


    constructor(
        protected readonly _changeDetectorRef: ChangeDetectorRef,
        @Inject(HopOverlayConfig) public readonly config: CONFIG,
        @Inject(OVERLAY_REF_TYPE) private readonly _refType: Type<HopOverlayRef>,
        @Inject(OVERLAY_DATA_TOKEN) private readonly _dataToken: InjectionToken<unknown>,
        protected readonly _focusHelper: OverlayFocusHelper,
        protected readonly _injector: Injector
    ) {
        super();
        this._ariaLabelledBy = config.ariaLabelledBy || null;
    }

    set closeInteractionType(value: FocusOrigin) {
        this._focusHelper._closeInteractionType = value;
    }

    /**
     * Attach a ComponentPortal as content to this overlay container.
     * @param portal Portal to be attached as the overlay content.
     */
    attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
        return this._assertOutletReady().attachComponentPortal(portal);
    }

    /**
     * Attach a TemplatePortal as content to this overlay container.
     * @param portal Portal to be attached as the overlay content.
     */
    attachTemplatePortal<C>(portal: TemplatePortal<C>): EmbeddedViewRef<C> {
        return this._assertOutletReady().attachTemplatePortal(portal);
    }

    attachContent<T, R>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
                        config: HopOverlayConfig,
                        overlayRef: HopOverlayRef<CONFIG, T, R>) {

        if (componentOrTemplateRef instanceof TemplateRef) {
            const context = this._createContentContext(config, overlayRef) as unknown as T;

            this.attachTemplatePortal(new TemplatePortal(componentOrTemplateRef, config.viewContainerRef as ViewContainerRef, context));
        } else {
            const injector = this._createContentInjector(config, overlayRef);

            const contentRef = this.attachComponentPortal<T>(new ComponentPortal(componentOrTemplateRef, config.viewContainerRef, injector));
            overlayRef.componentInstance = contentRef.instance;
        }
    }

    /** Initializes the overlay container with the attached content. */
    initializeWithAttachedContent() {
        this._focusHelper.setupFocusTrap();
    }

    recaptureFocus() {
        this._focusHelper.recaptureFocus();
    }

    /** Starts the overlay exit animation. */
    startExitAnimation(): void {
        this.state = OverlayAnimationState.EXIT;

        // Mark the container for check so it can react if the
        // view container is using OnPush change detection.
        this._changeDetectorRef.markForCheck();
    }

    /** Callback, invoked when an animation on the host starts. */
    _onAnimationStart({ toState, totalTime }: AnimationEvent) {
        switch (toState) {
            case OverlayAnimationState.ENTER:
                this._animationStateChanged.next({ state: OverlayState.OPENING, totalTime });
                break;
            case OverlayAnimationState.EXIT:
            case OverlayAnimationState.VOID:
                this._animationStateChanged.next({ state: OverlayState.CLOSING, totalTime });
                break;
        }
    }

    /** Callback, invoked whenever an animation on the host completes. */
    _onAnimationDone({ toState, totalTime }: AnimationEvent) {
        switch (toState) {
            case OverlayAnimationState.ENTER:
                this._focusHelper.trapFocus();
                this._animationStateChanged.next({ state: OverlayState.OPENED, totalTime });
                break;
            case OverlayAnimationState.EXIT:
                this._focusHelper.restoreFocus();
                this._animationStateChanged.next({ state: OverlayState.CLOSED, totalTime });
                break;
        }
    }


    protected _createContentContext<T, R>(config: HopOverlayConfig, overlayRef: HopOverlayRef<CONFIG, T, R>): IOverlayTemplateContext {
        return {
            $implicit: config.data,
            overlayRef
        };
    }

    /**
     * Creates a custom injector to be used inside the overlay. This allows a component loaded inside
     * of a overlay to close itself and, optionally, to return a value.
     * @param config Config object that is used to construct the overlay.
     * @param overlayRef Reference to the overlay.
     * @returns The custom injector that can be used inside the overlay.
     */
    protected _createContentInjector<T, R>(config: HopOverlayConfig, overlayRef: HopOverlayRef<CONFIG, T, R>): Injector {
        const parent = config?.viewContainerRef?.injector ?? this._injector;

        // The overlay container should be provided as the overlay container and the overlay's
        // content are created out of the same `ViewContainerRef` and as such, are siblings
        // for injector purposes. To allow the hierarchy that is expected, the overlay
        // container is explicitly provided in the injector.
        const providers: StaticProvider[] = [
            { provide: OverlayContainerComponent, useValue: this },
            { provide: this._dataToken, useValue: config.data },
            { provide: HOP_OVERLAY_DATA, useValue: this._dataToken },
            { provide: this._refType, useValue: overlayRef },
            { provide: HopOverlayRef, useExisting: this._refType }
        ];

        return Injector.create({ parent, providers });
    }

    private _assertOutletReady(): CdkPortalOutlet | never {
        if (!this.contentOutlet) {
            throw Error('Attempting to attach overlay content but the outlet is not ready yet');
        } else if (this.contentOutlet.hasAttached()) {
            throw Error('Attempting to attach overlay content after content is already attached');
        }

        return this.contentOutlet;
    }

}
