import { IDisplayEnumEntity, IEnumEntity } from './enum-entity.interface';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IProduct extends IEnumEntity {
    shortName?: string;
    name?: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDisplayProduct extends IDisplayEnumEntity {

}
