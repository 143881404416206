import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseRepository, Endpoint } from '@hopsteiner/shared/data-access';
import { firstValueFrom } from 'rxjs';

import { ICreateOrderRequestOptions } from '../entities/create-order-request-options.interface';

@Injectable({
    providedIn: 'root'
})
export class CreateOrderRequestOptionsRepository extends BaseRepository<ICreateOrderRequestOptions> {

    constructor(httpClient: HttpClient) {
        super(httpClient, {
            endpoint: Endpoint.ORDER_REQUESTS_DATA
        });
    }

    get(): Promise<ICreateOrderRequestOptions> {
        return firstValueFrom(this._httpClient.get<ICreateOrderRequestOptions>(`${this.url}`));
    }
}
