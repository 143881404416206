import { ChangeDetectorRef, Directive, EmbeddedViewRef, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { HopAuthStateFacade } from '@hopsteiner/auth/domain';
import { UserPermission } from '@hopsteiner/shared/models';
import { Subject, takeUntil } from 'rxjs';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'ng-template[hasPermission]'
})
export class HopHasPermissionDirective implements OnInit, OnDestroy {

    private _requiredPermission?: UserPermission;
    private _permissions: UserPermission[] = [];

    private _thenTemplateRef?: TemplateRef<void>;
    private _thenViewRef: EmbeddedViewRef<void> | null = null;

    private _elseTemplateRef?: TemplateRef<void>;
    private _elseViewRef: EmbeddedViewRef<void> | null = null;

    private readonly _onDestroy = new Subject<void>();

    constructor(protected readonly _viewContainer: ViewContainerRef,
                templateRef: TemplateRef<void>,
                private readonly _changeDetectorRef: ChangeDetectorRef,
                private readonly _authStateFacade: HopAuthStateFacade) {
        this._thenTemplateRef = templateRef;
    }

    @Input()
    set hasPermission(value: UserPermission) {
        this._requiredPermission = value;
        this._updateView();
    }

    @Input()
    set hasPermissionThen(value: TemplateRef<void>) {
        this._thenTemplateRef = value;
        this._thenViewRef = null;
        this._updateView();
    }

    @Input()
    set hasPermissionElse(value: TemplateRef<void>) {
        this._elseTemplateRef = value;
        this._elseViewRef = null;
        this._updateView();
    }

    ngOnInit() {
        this._authStateFacade.permissions$
            .pipe(takeUntil(this._onDestroy))
            .subscribe((permissions) => {
                this._permissions = permissions ?? [];
                this._updateView();
            });
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    protected validatePermission(): boolean {
        return this._requiredPermission == null || this._permissions.includes(this._requiredPermission);
    }

    private _updateView() {
        if (this.validatePermission()) {
            if (this._thenViewRef) {
                return;
            }

            this._viewContainer.clear();
            this._elseViewRef = null;

            if (this._thenTemplateRef) {
                this._thenViewRef = this._viewContainer.createEmbeddedView(this._thenTemplateRef);
            }
        } else {
            if (this._elseViewRef) {
                return;
            }

            this._viewContainer.clear();
            this._thenViewRef = null;

            if (this._elseTemplateRef) {
                this._elseViewRef = this._viewContainer.createEmbeddedView(this._elseTemplateRef);
            }
        }
    }
}
