import { Directive } from '@angular/core';

import { BaseCollectionViewerHostDirective } from './base-collection-viewer-host.directive';

@Directive({
    selector: '[hopMobileCollectionViewer]'
})
export class HopMobileCollectionViewerDirective<T> extends BaseCollectionViewerHostDirective<T> {

}
