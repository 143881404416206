export interface IEditDebitorData {
    name: string;
    address: string;
    additionalName: string;
    additionalAddress: string;
    zip: string;
    city: string;
    phone: string;
    email: string;
    homepage: string;
    beerOutputInHectoliters: number;
    gramAlphaPerHectoLiter: number;
}
