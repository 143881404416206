import { coerceNumberProperty } from '@angular/cdk/coercion';
import { _DisposeViewRepeaterStrategy, _VIEW_REPEATER_STRATEGY, ListRange } from '@angular/cdk/collections';
import { _COALESCED_STYLE_SCHEDULER, _CoalescedStyleScheduler, BaseRowDef, CDK_TABLE, CdkCellOutlet, CdkTable, RowContext, STICKY_POSITIONING_LISTENER } from '@angular/cdk/table';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, QueryList, ViewEncapsulation } from '@angular/core';
import { CdkDataSourceDelegate, Connection, DataSource, ICollectionViewer, InfiniteDataSource } from '@hopsteiner/shared/collections';
import { BehaviorSubject, firstValueFrom, NEVER, Observable, Subject, Subscription, switchAll, takeUntil } from 'rxjs';

import { IndicatorAppearance } from '../../../shared/components/loading-indicator/loading-indicator.component';
import { IDataCollectionViewer } from '../../../shared/models/data-collection-viewer.interface';
import { HOP_DATA_COLLECTION_VIEWER } from '../../../shared/tokens/data-collection-viewer.token';
import { HopExpandableCellOutlet } from '../../directives/cell-outlet.directive';
import { HopColumnDef } from '../../directives/column-def.directive';
import { DataTableAppearance, DataTableAppearanceType } from '../../models/data-table-appearance.enum';
import { IExpandableProvider } from '../../models/expandable-provider.interface';
import { EXPANDABLE_PROVIDER } from '../../tokens/expandable-provider.token';


@Component({
    selector: 'hop-data-table, table[hop-data-table]',
    exportAs: 'hopDataTable',
    templateUrl: './data-table.component.html',
    styleUrls: [ './data-table.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    // See note on CdkTable for explanation on why this uses the default change detection strategy.
    // tslint:disable-next-line:validate-decorators
    changeDetection: ChangeDetectionStrategy.Default,
    host: {
        class: 'c-hop-data-table'
    },
    providers: [
        // TODO(michaeljamesparsons) Abstract the view repeater strategy to a directive API so this code
        //  is only included in the build if used.
        { provide: _VIEW_REPEATER_STRATEGY, useClass: _DisposeViewRepeaterStrategy },
        { provide: CdkTable, useExisting: HopDataTableComponent },
        { provide: CDK_TABLE, useExisting: HopDataTableComponent },
        { provide: _COALESCED_STYLE_SCHEDULER, useClass: _CoalescedStyleScheduler },
        // Prevent nested tables from seeing this table's StickyPositioningListener.
        { provide: STICKY_POSITIONING_LISTENER, useValue: null },
        { provide: EXPANDABLE_PROVIDER, useExisting: HopDataTableComponent },
        { provide: HOP_DATA_COLLECTION_VIEWER, useExisting: HopDataTableComponent }
    ]
})
// @ts-ignore: required to override _renderCellTemplateForItem
export class HopDataTableComponent<T, QUERY = unknown> extends CdkTable<T> implements ICollectionViewer<T, QUERY>, IExpandableProvider<T>, IDataCollectionViewer<T, QUERY> {

    static DEFAULT_PAGE_SIZE = 20;

    readonly IndicatorAppearance = IndicatorAppearance;

    @Input()
    appearance: DataTableAppearanceType = DataTableAppearance.DEFAULT;

    @Input()
    loadingMessage?: string;

    @Input()
    emptyMessage?: string;

    @Output()
    readonly loadingChange = new EventEmitter<boolean>();

    private readonly _totalSubject = new BehaviorSubject<Observable<number>>(NEVER);
    readonly total$: Observable<number> = this._totalSubject.pipe(switchAll());

    override viewChange: BehaviorSubject<ListRange> = new BehaviorSubject<ListRange>({ start: 0, end: HopDataTableComponent.DEFAULT_PAGE_SIZE });
    readonly queryChange = new BehaviorSubject<QUERY | null>(null);
    readonly expandableChange = new BehaviorSubject<T | null>(null);

    /** Overrides the sticky CSS class set by the `CdkTable`. */
    protected override stickyCssClass = 'c-hop-data-table__sticky';

    /** Overrides the need to add position: sticky on every sticky cell element in `CdkTable`. */
    protected override needsPositionStickyOnElement = false;

    private _hopDataSource?: DataSource<T, QUERY> | null;
    private _connection?: Connection<T, QUERY>;
    private _pageSize: number = HopDataTableComponent.DEFAULT_PAGE_SIZE;
    private _isLoading: boolean = false;
    private _isLoadingSubscription: Subscription | null = null;
    private override readonly _onDestroy = new Subject<void>();

    @HostBinding('class.c-hop-data-table--is-loading')
    get isLoading() {
        return this._isLoading;
    }

    @HostBinding('class.c-hop-data-table--striped')
    get isStriped() {
        return this.appearance === DataTableAppearance.STRIPED;
    }

    @HostBinding('class.c-hop-data-table--infinite-scroll')
    get isInfiniteScroll() {
        return this._hopDataSource instanceof InfiniteDataSource;
    }

    @Input()
    get query(): QUERY | null {
        return this.queryChange.getValue();
    }

    set query(value: QUERY | null) {
        this.queryChange.next(value);
        this.viewChange.next({ start: 0, end: this._pageSize });
        this._elementRef.nativeElement.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    @Input()
    get hopDataSource(): DataSource<T, QUERY> | null | undefined {
        return this._hopDataSource;
    }

    set hopDataSource(value: DataSource<T, QUERY> | null | undefined) {
        if (value === this.hopDataSource) {
            return;
        }

        this._hopDataSource = value;

        if (!value) {
            this.dataSource = [];
            return;
        }

        this.dataSource = new CdkDataSourceDelegate<T, QUERY>(
            value,
            (connection) => this._switchConnection(connection),
            0
        );
    }

    @Input()
    get pageSize(): number {
        return this._pageSize;
    }

    set pageSize(value: number) {
        this._pageSize = coerceNumberProperty(value);

        const { start } = this.viewChange.getValue();
        this.viewChange.next({ start, end: start + this._pageSize });
    }

    get connection(): Connection<T, QUERY> {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this._connection!;
    }

    get expandedElement(): T | null {
        return this.expandableChange.getValue();
    }

    set expandedElement(value: T | null) {
        this.expandableChange.next(value);
    }

    get data(): ReadonlyArray<T> {
        return this._data;
    }

    get contentColumnDefs(): QueryList<HopColumnDef> {
        return this._contentColumnDefs as QueryList<HopColumnDef>;
    }

    firstPage() {
        this.viewChange.next({ start: 0, end: this._pageSize });
    }

    async nextPage() {
        const total = await firstValueFrom(this.total$);
        const { end } = this.viewChange.getValue();

        if (end > total) {
            return;
        }

        this.viewChange.next({ start: end, end: end + this._pageSize });
    }

    isExpanded(element: T): boolean {
        return this.expandedElement === element;
    }

    toggleExpansion(element: T): void {
        this.expandedElement = this.expandedElement === element ? null : element;
    }

    setDataSource(dataSource: DataSource<T, QUERY>) {
        this.hopDataSource = dataSource;
        this._changeDetectorRef.markForCheck();
    }

    setQuery(query: QUERY) {
        this.query = query;
        this._changeDetectorRef.markForCheck();
    }

    initCollectionViewer(dataSource: DataSource<T, QUERY>, query: QUERY) {
        this.hopDataSource = dataSource;
        this.query = query;
        this._changeDetectorRef.markForCheck();
    }

    // We override this method to prevent errors when data is nil
    override _getAllRenderRows() {
        if (this._data == null) {
            return [];
        }

        // @ts-ignore - this method is not exposed by the type, but exists!
        if (super._getAllRenderRows) {
            // @ts-ignore - this method is not exposed by the type, but exists!
            return super._getAllRenderRows();
        }
    }


    // We override this method to eager creation of an embedded view for our cell template.
    // We want to have full control when to create this view, so we pass the template and context to the outlet and can create the view on demand
    override _renderCellTemplateForItem(rowDef: BaseRowDef, context: RowContext<T>) {
        // @ts-ignore: we need access to the private method _getCellTemplates
        for (const cellTemplate of this._getCellTemplates(rowDef)) {
            const outlet = CdkCellOutlet.mostRecentCellOutlet;

            if (outlet instanceof HopExpandableCellOutlet) {
                outlet.setTemplate(cellTemplate, context);
            } else if (outlet) {
                outlet._viewContainer.createEmbeddedView(cellTemplate, context);
            }
        }

        this._changeDetectorRef.markForCheck();
    }

    private _switchConnection(connection: Connection<T, QUERY> | undefined) {
        if (this._connection != null) {
            this._connection.disconnect();
        }

        this._connection = connection;

        if (this._isLoadingSubscription) {
            this._isLoadingSubscription.unsubscribe();
            this._isLoadingSubscription = null;
        }

        if (!connection) {
            return;
        }

        this._setLoading(true);

        this._totalSubject.next(connection.total$);

        this._isLoadingSubscription = connection.isLoading$
            .pipe(takeUntil(this._onDestroy))
            .subscribe((isLoading) => this._setLoading(isLoading));
    }

    private _setLoading(loading: boolean) {
        this._isLoading = loading;
        this.loadingChange.emit(loading);
        this._changeDetectorRef.markForCheck();
    }
}
