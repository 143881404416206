import { Language } from '@hopsteiner/shared/models';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace LanguageActions {

    export class ChangeLanguage {

        static readonly type = '[Environment] Change Language';

        constructor(readonly language: Language) {
        }
    }

    export class ChangeTranslationLanguage {

        static readonly type = '[Environment] Change Translation Language';

        constructor(readonly language: Language) {
        }
    }
}
