import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { HopAlertComponent } from './components/alert/alert.component';

@NgModule({
    imports: [
        CommonModule,
        FontAwesomeModule
    ],
    declarations: [
        HopAlertComponent
    ],
    exports: [
        HopAlertComponent
    ]
})
export class HopAlertModule {
}
