import { IProduct } from '@hopsteiner/contract/api';
import { IOverviewUser } from '@hopsteiner/user/api';

export interface IOrderRequest {
    uid: number;
    debitor: string;
    product: IProduct;
    cultivar: string;
    quantity: string;
    packaging: string;
    comments: string;
    proposer: IOverviewUser;
    status: number;
    createdAt: Date;
    lastModified: Date;
    harvestYear: number;
    quantityUnit: number;
    deliveryDate: Date;
    completedAt?: Date;
    displayQuantityUnitLess: string;
    displayQuantityUnit: string;
    displayQuantity: string;
}
