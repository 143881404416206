import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

import { OverlayContainerComponent } from '../../../shared/directives/overlay-container.component';
import { HopOverlayConfig } from '../../../shared/models/overlay-config';
import { OverlayFocusHelper } from '../../../shared/utils/focus-helper';
import { DIALOG_ANIMATIONS } from '../../animations/dialog.animations';
import { HopDialogConfig } from '../../models/dialog-config';

@Component({
    selector: 'hop-dialog-container',
    templateUrl: './dialog-container.component.html',
    styleUrls: [ './dialog-container.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [ DIALOG_ANIMATIONS.dialogContainer ],
    providers: [
        { provide: HopOverlayConfig, useExisting: HopDialogConfig },
        OverlayFocusHelper
    ],
    host: {
        class: 'c-hop-dialog-container',
        'aria-modal': 'true',
        'role': 'dialog',
        /* eslint-disable @angular-eslint/no-host-metadata-property */
        '[@dialogContainer]': 'state',
        '(@dialogContainer.start)': '_onAnimationStart($event) //noinspection UnresolvedVariable',
        '(@dialogContainer.done)': '_onAnimationDone($event) //noinspection UnresolvedVariable',
        '[attr.aria-labelledby]': 'config.ariaLabel ? null : _ariaLabelledBy',
        '[attr.aria-label]': 'config.ariaLabel',
        '[attr.aria-describedby]': 'config.ariaDescribedBy || null'
        /* eslint-enable @angular-eslint/no-host-metadata-property */
    }
})
export class HopDialogContainerComponent extends OverlayContainerComponent<HopDialogConfig> {

}
