import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';

import { HopButtonModule } from '../button/button.module';

import { HopSearchBarComponent } from './components/search-bar/search-bar.component';


@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,

        TranslateModule,
        FontAwesomeModule,

        HopButtonModule
    ],
    declarations: [
        HopSearchBarComponent
    ],
    exports: [
        HopSearchBarComponent
    ]
})
export class HopSearchBarModule {

}
