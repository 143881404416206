import { coerceNumberProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { ICollectionViewer } from '@hopsteiner/shared/collections';

import { PaginatorUtils } from '../../utils/paginator.utils';


@Component({
    selector: 'hop-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: [ './paginator.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-paginator'
    }
})
export class HopPaginatorComponent<T = unknown> {

    @Input()
    collectionViewer?: ICollectionViewer<T>;

    @Input()
    maxPages: number = 5;

    @Output()
    readonly pageChange = new EventEmitter<number>();

    readonly _icons = {
        faAngleDoubleLeft,
        faAngleLeft,
        faAngleRight,
        faAngleDoubleRight
    };

    private _page: number = 0;
    private _pageSize: number = 10;

    @Input()
    get page(): number {
        return this._page;
    }

    set page(value: string | number | null | undefined) {
        const coerced = coerceNumberProperty(value, 0);

        if (this._page === coerced) {
            return;
        }

        this._page = coerced;
        this._emitViewChange();
    }

    @Input()
    get pageSize(): number {
        return this._pageSize;
    }

    set pageSize(value: string | number | null | undefined) {
        const coerced = coerceNumberProperty(value, 10);

        if (this._pageSize === coerced) {
            return;
        }

        this._pageSize = coerced;
        this._emitViewChange();
    }

    get paginatorPage(): number {
        return this.page + 1;
    }

    set paginatorPage(value: string | number | null | undefined) {
        this.page = coerceNumberProperty(value, 0) - 1;
    }

    private _emitViewChange() {
        this.pageChange.emit(this._page);
        this.collectionViewer?.viewChange.next(PaginatorUtils.toListRange(this._page, this._pageSize));
    }

}
