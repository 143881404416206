import { Direction } from '@angular/cdk/bidi';
import { ScrollStrategy } from '@angular/cdk/overlay';
import { ComponentFactoryResolver, ViewContainerRef } from '@angular/core';

import { OverlayAutoFocusTarget } from './auto-focus-target';


export class HopOverlayConfig<DATA = unknown> {
    /**
     * Where the attached component should live in Angular's *logical* component tree.
     * This affects what is available for injection and the change detection order for the
     * component instantiated inside of the overlay. This does not affect where the overlay
     * content will be rendered.
     */
    viewContainerRef?: ViewContainerRef;

    /** ID for the overlay. If omitted, a unique one will be generated. */
    id?: string;

    /** Custom class for the overlay pane. */
    panelClass?: string | string[] = '';

    /** Whether the overlay has a backdrop. */
    hasBackdrop?: boolean = true;

    /** Custom class for the backdrop. */
    backdropClass?: string | string[] = 'cdk-overlay-dark-backdrop';

    /** Width of the overlay. */
    width?: string;

    /** Height of the overlay. */
    height?: string = 'fit-content';

    /** Min-width of the overlay. If a number is provided, assumes pixel units. */
    minWidth?: number | string;

    /** Min-height of the overlay. If a number is provided, assumes pixel units. */
    minHeight?: number | string;

    /** Max-width of the overlay. If a number is provided, assumes pixel units. Defaults to 80vw. */
    maxWidth?: number | string = '80vw';

    /** Max-height of the overlay. If a number is provided, assumes pixel units. */
    maxHeight?: number | string;

    /** Whether the user can use escape or clicking on the backdrop to close the modal. */
    disableClose?: boolean = false;

    /** Data being injected into the child component. */
    data?: DATA | null = null;

    /** Layout direction for the overlay's content. */
    direction?: Direction;

    /** ID of the element that describes the overlay. */
    ariaDescribedBy?: string | null = null;

    /** ID of the element that labels the overlay. */
    ariaLabelledBy?: string | null = null;

    /** Aria label to assign to the overlay element. */
    ariaLabel?: string | null = null;

    /**
     * Where the overlay should focus on open.
     * @breaking-change 14.0.0 Remove boolean option from autoFocus. Use string or
     * AutoFocusTarget instead.
     */
    autoFocus?: OverlayAutoFocusTarget | string | boolean = 'first-tabbable';

    /**
     * Whether the overlay should restore focus to the
     * previously-focused element, after it's closed.
     */
    restoreFocus?: boolean = true;

    /** Scroll strategy to be used for the overlay. */
    scrollStrategy?: ScrollStrategy;

    /**
     * Whether the overlay should close when the user goes backwards/forwards in history.
     * Note that this usually doesn't include clicking on links (unless the user is using
     * the `HashLocationStrategy`).
     */
    closeOnNavigation?: boolean = true;

    /** Alternate `ComponentFactoryResolver` to use when resolving the associated component. */
    componentFactoryResolver?: ComponentFactoryResolver;
}
