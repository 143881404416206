import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { HopButtonComponent } from './components/button/button.component';
import { HopButtonAppendDirective } from './directives/button-append.directive';


@NgModule({
    imports: [
        CommonModule,

        TranslateModule
    ],
    declarations: [
        HopButtonComponent,
        HopButtonAppendDirective
    ],
    exports: [
        HopButtonComponent,
        HopButtonAppendDirective
    ]
})
export class HopButtonModule {

}
