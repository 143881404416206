import { IShipmentLocation } from './shipment-location.interface';

/**
 * Data already displayed in the drawer which is used for a new shipment request
 */
export interface IRequestShipmentData {
    availableQuantities: number;
    contractQuantity: number;
    displayQuantity: string;
    displayQuantityUnit: string;
    locations: IShipmentLocation[];
    product: string;
}
