import { Directive, TemplateRef } from '@angular/core';

@Directive({
    selector: '[hopSlide]'
})
export class HopSlideDirective {

    constructor(readonly templateRef: TemplateRef<unknown>) {

    }

}
