import { PreferenceGroupColumn } from '../../entities/preferences/preference-group-column';
import { PreferenceGroup } from '../../entities/preferences/preference-group.enum';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace PreferenceActions {

    export class LoadPreferences {
        static readonly type = '[Preferences] Load Preferences';
    }

    export class SetBrowsePageColumns<GROUP extends PreferenceGroup> {
        static readonly type = '[Preferences] Set displayed columns';

        constructor(readonly group: GROUP, readonly columns: PreferenceGroupColumn<GROUP>[]) {
        }
    }
}
