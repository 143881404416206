import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { HopOrderRequestDomainRootModule } from '@hopsteiner/order-request/domain';

@NgModule({
    imports: [ CommonModule ]
})
export class OrderRequestApiModule {
}

@NgModule({})
export class HopOrderRequestApiModule {

    static forRoot(): ModuleWithProviders<HopOrderRequestApiRootModule> {
        return {
            ngModule: HopOrderRequestApiRootModule,
            providers: []
        };
    }
}

@NgModule({
    imports: [
        HopOrderRequestDomainRootModule,
        HopOrderRequestApiModule
    ],
    exports: [
        HopOrderRequestApiModule
    ]
})
export class HopOrderRequestApiRootModule {

}
