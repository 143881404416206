import { Filter, FilterWithKey, FilterWithValue } from './filter';

/**
 * An object mapping keys to {@linkplain Filter filters} based on the desired result type
 */
export type Filters<RESULT> = {
    [KEY in keyof RESULT]: Filter<RESULT[KEY]>;
};
export type FiltersWithKeys<RESULT> = FilterWithKey<RESULT[keyof RESULT]>[];
export type FiltersWithValues<RESULT> = FilterWithValue<RESULT[keyof RESULT]>[];

export type FiltersOrder<T> = (keyof T)[];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IFilterGroup<RESULT = any> {
    filters: Filters<RESULT>;
    order: FiltersOrder<RESULT>;
}
