export type SelectableContractTableColumn =
    'cultivar'
    | 'growingArea'
    | 'product'
    | 'harvestYear'
    | 'unitPrice'
    | 'quantity'
    | 'contractDate'
    | 'fulfilled'
    | 'alphaPercentage'
    | 'baseAlphaPercent'
    | 'alphaClause'
    | 'analysisDescription'
    | 'certificate'
    | 'type'
    | 'paymentCondition'
    | 'fullyShipped'
    | 'packagingInformation'
    | 'deliveryCondition'
    | 'cargoIncluded'
    | 'salesReference'
    | 'externalPurchaseNumber'
    | 'endCustomerName'
    | 'comments'
    | 'displayOutstandingQuantity'
    | 'organicLabels';
