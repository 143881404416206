import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

@Injectable({
    providedIn: 'root'
})
export class CsvExportService {

    export(data: string | Blob, entity: string): string {
        const date = new Date();
        const dateString = date.toISOString().split('T')[0];
        const fileName = `export-${entity}-${dateString}.csv`;

        saveAs(data, fileName);

        return fileName;
    }
}
