import { Directive, TemplateRef } from '@angular/core';

@Directive({
    selector: 'ng-template[hopSelectOption]'
})
export class HopSelectOptionDirective {

    constructor(readonly templateRef: TemplateRef<unknown>) {
    }

}
