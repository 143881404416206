import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'hopYesNo'
})
export class HopYesNoPipe implements PipeTransform {

    transform(value: boolean): string {
        if (value == null) {
            return '';
        }

        return value ? 'COMPONENTS.PIPES.YES_NO.YES' : 'COMPONENTS.PIPES.YES_NO.NO';
    }
}

@NgModule({
    imports: [ CommonModule ],
    declarations: [ HopYesNoPipe ],
    exports: [ HopYesNoPipe ]
})
export class HopYesNoPipeModule {
}
