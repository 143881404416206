import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import SwiperCore, { A11y, Navigation, Pagination } from 'swiper';
import { SwiperModule } from 'swiper/angular';

import { HopButtonModule } from '../button/button.module';

import { HopCarouselComponent } from './components/carousel/carousel.component';
import { HopSlideDirective } from './directives/slide.directive';

SwiperCore.use([ Navigation, Pagination, A11y ]);

@NgModule({
    imports: [
        CommonModule,

        SwiperModule,
        FontAwesomeModule,

        HopButtonModule
    ],
    declarations: [
        HopCarouselComponent,
        HopSlideDirective
    ],
    exports: [
        HopCarouselComponent,
        HopSlideDirective,

        SwiperModule
    ]
})

export class HopCarouselModule {

}
