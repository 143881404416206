<ngb-pagination [class.d-none]="((collectionViewer?.connection?.total$ | async) || 0) < pageSize"
                [(page)]="paginatorPage"
                [collectionSize]="(collectionViewer?.total$ | async) || paginatorPage * pageSize"
                [maxSize]="maxPages"
                [rotate]="true"
                [boundaryLinks]="true"
                [pageSize]="pageSize">
    <ng-template ngbPaginationFirst>
        <fa-icon [icon]="_icons.faAngleDoubleLeft"></fa-icon>
    </ng-template>

    <ng-template ngbPaginationPrevious>
        <fa-icon [icon]="_icons.faAngleLeft"></fa-icon>
    </ng-template>

    <ng-template ngbPaginationNext>
        <fa-icon [icon]="_icons.faAngleRight"></fa-icon>
    </ng-template>

    <ng-template ngbPaginationLast>
        <fa-icon [icon]="_icons.faAngleDoubleRight"></fa-icon>
    </ng-template>
</ngb-pagination>
