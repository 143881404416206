import { SelectableOrderRequestTableColumn } from '../entities/preferences/selectable-order-request-table-column';

export const ALL_SELECTABLE_ORDER_REQUEST_COLUMNS: SelectableOrderRequestTableColumn[] = [
    'createdAt',
    'product',
    'cultivar',
    'harvestYear',
    'quantity',
    'packaging',
    'deliveryDate',
    'comments',
    'proposer'
];
