import { Directive, HostBinding, Input, NgModule } from '@angular/core';

@Directive({
    selector: '[hopHighlightRecord]'
})
export class HopHighlightRecordDirective {
    @Input('hopHighlightRecord')
    @HostBinding('class.c-hop-highlight-record')
    highlightRecord: boolean = false;

    @Input()
    @HostBinding('attr.highlight-label')
    highlightLabel?: string;

    @Input()
    @HostBinding('style.--highlight-color')
    highlightColor?: string;
}

@NgModule({
    declarations: [ HopHighlightRecordDirective ],
    exports: [ HopHighlightRecordDirective ]
})
export class HopHighlightRecordDirectiveModule {
}
