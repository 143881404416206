import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { API_URL } from '../tokens/api-url.token';

const API_REGEX = /^\/api/g;

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

    constructor(@Inject(API_URL) protected readonly apiUrl: string) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const cloned = request.clone({
            url: request.url.replace(API_REGEX, this.apiUrl),
            setHeaders: {
                Authorization: `Basic ${btoa('bynary:io')}`
            }
        });

        return next.handle(cloned);
    }
}
