import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { HopLoadingIndicatorComponentModule } from '../shared/components/loading-indicator/loading-indicator.component';
import { HopHighlightRecordDirectiveModule } from '../shared/directives/highlight-record.directive';
import { HopScrollContainerDirectiveModule } from '../shared/directives/scroll-container.directive';

import { HopDataTableComponent } from './components/data-table/data-table.component';
import { HopExpandableRow } from './components/expandable-row/expandable-row.component';
import { HopNoDataRowComponent } from './components/no-data-row/no-data-row.component';
import { HopRow } from './components/row/row.component';
import { HopCellDef, HopFooterCellDef, HopHeaderCellDef } from './directives/cell-def.directive';
import { HopExpandableCellOutlet } from './directives/cell-outlet.directive';
import { HopCell, HopFooterCell, HopHeaderCell } from './directives/cell.directive';
import { HopColumnDef } from './directives/column-def.directive';
import { HopExpandableCell } from './directives/expandable-cell.directive';
import { HopExpandableRowContentDirective } from './directives/expandable-row-content.directive';
import { HopExpandableTriggerDirective } from './directives/expandable-trigger.directive';
import { HopFooterRowDef, HopHeaderRowDef, HopRowDef } from './directives/row-def.directive';
import { HopFooterRow, HopHeaderRow, HopNoDataRowDef } from './directives/row.directive';

@NgModule({
    imports: [
        CommonModule,

        CdkTableModule,
        TranslateModule,

        HopHighlightRecordDirectiveModule,
        HopLoadingIndicatorComponentModule,
        HopScrollContainerDirectiveModule
    ],
    declarations: [
        HopDataTableComponent,
        HopCellDef,
        HopHeaderCellDef,
        HopFooterCellDef,
        HopColumnDef,
        HopHeaderCell,
        HopFooterCell,
        HopCell,
        HopExpandableCell,
        HopRowDef,
        HopHeaderRowDef,
        HopFooterRowDef,
        HopNoDataRowDef,
        HopRow,
        HopExpandableRow,
        HopHeaderRow,
        HopFooterRow,
        HopExpandableCellOutlet,
        HopExpandableRowContentDirective,
        HopExpandableTriggerDirective,
        HopNoDataRowComponent
    ],
    exports: [
        HopDataTableComponent,
        HopCellDef,
        HopHeaderCellDef,
        HopFooterCellDef,
        HopColumnDef,
        HopHeaderCell,
        HopFooterCell,
        HopCell,
        HopExpandableCell,
        HopRowDef,
        HopHeaderRowDef,
        HopFooterRowDef,
        HopNoDataRowDef,
        HopRow,
        HopExpandableRow,
        HopHeaderRow,
        HopFooterRow,
        HopExpandableCellOutlet,
        HopExpandableRowContentDirective,
        HopExpandableTriggerDirective,
        HopNoDataRowComponent
    ]
})
export class HopDataTableModule {

}
