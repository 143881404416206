import { Injectable } from '@angular/core';
import { HopInvoiceFacade } from '@hopsteiner/invoice/domain';

@Injectable({
    providedIn: 'root'
})
export class HopInvoiceApiFacade {

    constructor(private readonly _invoiceFacade: HopInvoiceFacade) {
    }

    downloadInvoice(invoiceId: string): Promise<Blob> {
        return this._invoiceFacade.downloadInvoice(invoiceId);
    }
}
