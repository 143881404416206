import { Pipe, PipeTransform } from '@angular/core';

import { PasswordStrength } from '../models/password-strength.enum';
import { PasswordUtils } from '../utils/password.utils';

const PASSWORD_STRENGTH_MESSAGES: Record<PasswordStrength, string> = {
    [PasswordStrength.WEAK]: 'COMPONENTS.FORM.ERROR.PASSWORD_STRENGTH.WEAK',
    [PasswordStrength.STRONG]: 'COMPONENTS.FORM.ERROR.PASSWORD_STRENGTH.STRONG'
};

@Pipe({
    name: 'passwordStrengthLabel'
})
export class HopPasswordStrengthLabelPipe implements PipeTransform {

    transform(password: string | null | undefined): string {
        // return empty string and not null because pipe only accepts string on html
        if (password == null) {
            return '';
        }

        if (PasswordUtils.isPasswordStrong(password)) {
            return PASSWORD_STRENGTH_MESSAGES[PasswordStrength.STRONG];
        }

        return PASSWORD_STRENGTH_MESSAGES[PasswordStrength.WEAK];
    }
}
