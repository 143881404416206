import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, ContentChildren, ElementRef, HostBinding, Input, QueryList, ViewEncapsulation } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { EnumLike } from '@hopsteiner/shared/models';

import { HopButtonAppendDirective } from '../../directives/button-append.directive';
import { ButtonAppearance } from '../../models/button-appearance.enum';
import { ButtonSize, ButtonSizeType } from '../../models/button-size.enum';


@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[hopButton], a[hopButton], button[hopIconButton], a[hopIconButton]',
    exportAs: 'hopButton',
    templateUrl: './button.component.html',
    styleUrls: [ './button.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-button btn'
    }
})
export class HopButtonComponent implements AfterContentInit {

    @Input()
    appearance: EnumLike<ButtonAppearance> = ButtonAppearance.PLAIN;

    @Input()
    size: ButtonSizeType = ButtonSize.REGULAR;

    @Input()
    @HostBinding('class.c-hop-button--icon-only')
    iconOnly: boolean;

    @Input()
    loadingMessage?: string;

    @ContentChild(FaIconComponent)
    icon?: FaIconComponent;

    @ContentChildren(HopButtonAppendDirective)
    append?: QueryList<HopButtonAppendDirective>;

    private _disabled: boolean = false;
    private _loading: boolean = false;

    constructor(private readonly _elementRef: ElementRef,
                private readonly _changeDetectorRef: ChangeDetectorRef) {
        this.iconOnly = (_elementRef.nativeElement as HTMLElement).hasAttribute('hopIconButton');
    }

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: boolean) {
        this._disabled = value;
    }

    @Input()
    @HostBinding('class.c-hop-button--loading')
    get loading(): boolean {
        return this._loading;
    }

    set loading(value: boolean) {
        this._loading = value;
    }

    @HostBinding('disabled')
    @HostBinding('class.disabled')
    get isDisabled() {
        return this._disabled || this._loading;
    }

    get nativeElement(): HTMLButtonElement | HTMLAnchorElement {
        return this._elementRef.nativeElement;
    }

    @Input()
    set hopButton(value: EnumLike<ButtonAppearance> | '') {
        this.appearance = value || ButtonAppearance.PLAIN;
    }

    @Input()
    set hopIconButton(value: EnumLike<ButtonAppearance> | '') {
        this.appearance = value || ButtonAppearance.PLAIN;
    }

    @HostBinding('class.c-hop-button--plain')
    get isPlain(): boolean {
        return this.appearance === ButtonAppearance.PLAIN;
    }

    @HostBinding('class.c-hop-button--solid')
    get isSolid(): boolean {
        return this.appearance === ButtonAppearance.SOLID;
    }

    @HostBinding('class.c-hop-button--outline')
    get isOutline(): boolean {
        return this.appearance === ButtonAppearance.OUTLINE;
    }

    @HostBinding('class.c-hop-button--link')
    get isLink(): boolean {
        return this.appearance === ButtonAppearance.LINK;
    }

    @HostBinding('class.btn-lg')
    get isLarge(): boolean {
        return this.size === ButtonSize.LARGE;
    }

    ngAfterContentInit() {
        this.append?.changes.subscribe(() => this._changeDetectorRef.markForCheck());
    }

}
