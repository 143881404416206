import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { IToastProvider } from '../../models/toast-provider.interface';
import { HopToastRef } from '../../models/toast-ref';
import { TOASTS } from '../../tokens/toasts.token';

@Component({
    selector: 'hop-toast-container',
    templateUrl: './toast-container.component.html',
    styleUrls: [ './toast-container.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-toast-container'
    }
})
export class HopToastContainerComponent {

    readonly icons = { faTimes };

    constructor(@Inject(TOASTS) readonly _toasts: IToastProvider) {

    }

    trackToastById(index: number, item: HopToastRef) {
        return item?.id;
    }
}
