import { ChangeDetectorRef, Directive, HostListener, Input, Optional } from '@angular/core';
import { HopButtonComponent } from '@hopsteiner/shared/components';
import { HopToast } from '@hopsteiner/shared/overlays';
import { TranslateService } from '@ngx-translate/core';

@Directive({
    selector: '[hopButton][hopExport], [hopIconButton][hopExport], [hopExport]'
})
export class HopExportButtonDirective {

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('hopExport')
    export?: () => Promise<string | Blob>;

    constructor(private readonly _changeDetectorRef: ChangeDetectorRef,
                private readonly _toast: HopToast,
                private readonly _translateService: TranslateService,
                @Optional() private readonly _button?: HopButtonComponent) {
    }

    @HostListener('click')
    async onClick() {
        if (this._button?.loading) {
            return;
        }

        if (!this.export) {
            throw new Error('No export function specified');
        }

        if (this._button) {
            this._button.loading = true;
        }

        const toastRef = this._toast.show({
            message: 'EXPORT.COMPONENTS.EXPORT_BUTTON.FEEDBACK.PENDING',
            autoHide: false
        });

        try {
            const fileName = await this.export();
            toastRef?.close();
            this._toast.success(this._translateService.instant('EXPORT.COMPONENTS.EXPORT_BUTTON.FEEDBACK.SUCCESS', { fileName }));
        } catch (e) {
            this._toast.error(this._translateService.instant('EXPORT.COMPONENTS.EXPORT_BUTTON.FEEDBACK.ERROR'));
        }

        if (this._button) {
            this._button.loading = false;
        }

        this._changeDetectorRef.markForCheck();

    }
}
