import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Language } from '@hopsteiner/shared/models';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {

    constructor(private readonly _translateService: TranslateService,
                private readonly _httpClient: HttpClient,
                @Inject(DOCUMENT) private readonly _document: Document) {
    }

    /**
     * Returns the current language from the session
     */
    async getCurrentLanguage(): Promise<Language> {
        const { language } = await firstValueFrom(this._httpClient.get<{ language: Language }>('/api/language'));
        return language;
    }

    /**
     * Switches the language used for translations
     *
     * @param language
     */
    switchTranslationLanguage(language: Language) {
        this._translateService.use(language);

        this._document.documentElement.lang = language;
    }

    /**
     * Switches the language and returns the sessionId
     *
     * @param language the new language to use
     */
    async switchLanguageTemporarily(language: Language): Promise<string> {
        const { sessionId } = await firstValueFrom(this._httpClient.request<{ sessionId: string; }>('POST', '/api/language', {
            body: { language }
        }));

        this.switchTranslationLanguage(language);
        return sessionId;
    }
}
