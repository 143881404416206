import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';

import { UserRepository } from '../../infrastructure/user.repository';
import { DeleteUserActions } from '../actions/delete-user.actions';

@Injectable({
    providedIn: 'root'
})
export class HopUserDeleteFacade {

    constructor(private readonly _store: Store,
                private readonly _userRepository: UserRepository) {
    }

    sendDeleteRequest(userId: number) {
        return firstValueFrom(this._store.dispatch(new DeleteUserActions.SendDeleteRequest(userId)));
    }

    getDeleteUserRequest(id: number) {
        return this._userRepository.getDeleteRequest(id);
    }
}
