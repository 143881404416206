// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace SessionActions {

    export class SetSessionId {

        static readonly type = '[Environment] Set session token';

        constructor(readonly sessionId: string) {
        }
    }

    export class ClearSessionId {

        static readonly type = '[Environment] Clear session token';
    }
}
