import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HopFormErrorPipe } from './pipes/form-error.pipe';
import { HopIsInvalidPipe } from './pipes/is-invalid.pipe';
import { HopPasswordStrengthLabelPipe } from './pipes/password-stength-label.pipe';


@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        HopIsInvalidPipe,
        HopFormErrorPipe,
        HopPasswordStrengthLabelPipe
    ],
    exports: [
        HopIsInvalidPipe,
        HopFormErrorPipe,
        HopPasswordStrengthLabelPipe
    ]
})
export class HopFormsModule {
}
