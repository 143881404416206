import {
    Attribute,
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    ContentChildren,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    QueryList,
    Renderer2,
    ViewEncapsulation
} from '@angular/core';
import { BehaviorSubject, pairwise, startWith, Subject, takeUntil } from 'rxjs';

import { HopLceCustomStateDirective } from '../../directives/lce-custom-state.directive';
import { HopLceErrorMessageDirective } from '../../directives/lce-error-message.directive';
import { HopLceErrorDirective } from '../../directives/lce-error.directive';
import { HopLceLoadingDirective } from '../../directives/lce-loading.directive';
import { LceState, LceStateLike } from '../../models/lce-state.enum';

@Component({
    selector: 'hop-lce-layout',
    templateUrl: './lce.layout.html',
    styleUrls: [ './lce.layout.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-lce-layout'
    }
})
export class HopLceLayout implements OnInit, OnDestroy {

    @ContentChild(HopLceLoadingDirective)
    customLoading?: HopLceLoadingDirective;

    @ContentChild(HopLceErrorDirective)
    customError?: HopLceErrorDirective;

    @ContentChild(HopLceErrorMessageDirective)
    customErrorMessage?: HopLceErrorMessageDirective;

    @ContentChildren(HopLceCustomStateDirective)
    customStateChildren!: QueryList<HopLceCustomStateDirective>;

    @Output()
    readonly stateChange: BehaviorSubject<LceStateLike>;

    private readonly _onDestroy = new Subject<void>();

    constructor(@Optional() @Attribute('initialState') initialState: LceStateLike,
                private readonly _elementRef: ElementRef,
                private readonly _renderer: Renderer2) {
        this.stateChange = new BehaviorSubject<LceStateLike>(initialState || LceState.CONTENT);
    }

    @Input()
    get state(): LceStateLike {
        return this.stateChange.getValue();
    }

    set state(value: LceStateLike) {
        if (this.state == value) {
            return;
        }

        this.stateChange.next(value);
    }

    ngOnInit() {
        this.stateChange
            .pipe(
                takeUntil(this._onDestroy),
                startWith(undefined),
                pairwise()
            )
            .subscribe(([ oldState, newState ]) => {
                if (oldState) {
                    this._renderer.removeClass(this._elementRef.nativeElement, `c-hop-lce-layout--${oldState}`);
                }

                if (newState) {
                    this._renderer.addClass(this._elementRef.nativeElement, `c-hop-lce-layout--${newState}`);
                }
            });
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

}
