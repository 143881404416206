/* eslint-disable @angular-eslint/directive-class-suffix,@angular-eslint/directive-selector */
import { Directive } from '@angular/core';

import { HopCell } from './cell.directive';

/** Cell template container that adds the right classes and role. */
@Directive({
    selector: 'hop-expandable-cell',
    host: {
        class: 'c-hop-cell c-hop-cell--expandable',
        role: 'gridcell'
    }
})
export class HopExpandableCell extends HopCell {

}
