/**
 * Sort options
 */
export interface ISortOptions {

    /**
     * Whether the sort can be cleared.
     * If `true`, the sort direction must explicitly be set (= {@link SortDirection.NONE} is prohibited)
     */
    disableClear: boolean;
}
