import { Language, UserStatus } from '@hopsteiner/shared/models';

export interface IEditUserData {
    firstName?: string;
    lastName?: string;
    phone?: string;
    email?: string;
    status: UserStatus;
    language?: Language;
    userManagement?: boolean;
    debitors?: string[];
}
