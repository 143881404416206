import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, HostBinding, HostListener, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'hop-env-debitor-switcher-tab,[hopEnvDebitorSwitcherTab]',
    templateUrl: './debitor-switcher-tab.component.html',
    styleUrls: [ './debitor-switcher-tab.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-env-debitor-switcher-tab'
    }
})
export class HopDebitorSwitcherTabComponent {

    @Input()
    title?: string;

    @Input()
    subtitle?: string;

    private _disabled: boolean = false;
    private _active: boolean = false;

    @Input()
    @HostBinding('class.c-hop-env-debitor-switcher-tab--is-disabled')
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: BooleanInput) {
        this._disabled = coerceBooleanProperty(value);
    }

    @Input()
    @HostBinding('class.c-hop-env-debitor-switcher-tab--is-active')
    get active(): boolean {
        return this._active;
    }

    set active(value: boolean) {
        this._active = coerceBooleanProperty(value);
    }

    @HostListener('click', [ '$event' ])
    onClick($event: MouseEvent) {
        if (this.disabled) {
            $event.stopImmediatePropagation();
        }
    }

}
