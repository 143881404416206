import { IChange, IContactPerson } from '@hopsteiner/shared/components';
import { UserStatus } from '@hopsteiner/shared/models';

export interface IContactPersonWithId extends IContactPerson {
    uid: number;
}

export interface IUserChangeRequest {
    name: IChange;
    email: IChange;
    phone: IChange;
    status: UserStatus;
    createdAt: Date;
    proposer: IContactPersonWithId
}
