import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, NgModule, ViewEncapsulation } from '@angular/core';
import { EnumLike } from '@hopsteiner/shared/models';
import { TranslateModule } from '@ngx-translate/core';

import { SLIDE_IN_ANIMATION } from './animations/slide-in.animation';

export enum IndicatorAppearance {
    DEFAULT = 'default',
    EDGE = 'edge'
}

@Component({
    selector: 'hop-loading-indicator',
    templateUrl: './loading-indicator.component.html',
    styleUrls: [ './loading-indicator.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-loading-indicator'
    },
    animations: [ SLIDE_IN_ANIMATION ]
})
export class HopLoadingIndicatorComponent {

    readonly IndicatorAppearance = IndicatorAppearance;

    @Input()
    loading: boolean = false;

    @Input()
    message?: string;

    @Input()
    appearance: EnumLike<IndicatorAppearance> = IndicatorAppearance.DEFAULT;

    @HostBinding('class.c-hop-loading-indicator--edge')
    get isEdgeAppearance() {
        return this.appearance === IndicatorAppearance.EDGE;
    }
}

@NgModule({
    imports: [
        CommonModule,

        TranslateModule
    ],
    declarations: [
        HopLoadingIndicatorComponent
    ],
    exports: [
        HopLoadingIndicatorComponent
    ]
})
export class HopLoadingIndicatorComponentModule {

}
