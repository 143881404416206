import { CollectionViewer, DataSource, ListRange } from '@angular/cdk/collections';
import { Subject } from 'rxjs';

/**
 * A CDK CollectionViewer that is also mutable (like most implementations), that is: it allows to emit viewChanges and access to a data source
 */
export interface IMutableCdkCollectionViewer<T = unknown> extends CollectionViewer {
    viewChange: Subject<ListRange>;
    dataSource: DataSource<T> | null;
}
