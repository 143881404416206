import { IDebitorAddress } from '@hopsteiner/debitor/api';
import { IContactPerson } from '@hopsteiner/shared/components';

/**
 * Data displayed in a shipment request card which is shown on dashboard and contract detail page
 */
export interface IShipmentRequest {
    lastModified: Date;
    createdAt: Date;
    contractId: string;
    contract: number;
    product: string;
    displayQuantity: string;
    location: IDebitorAddress;
    deliveryDate: Date;
    proposer: IContactPerson;
}
