import { ComponentType, Overlay, OverlayConfig, OverlayRef, ScrollStrategy } from '@angular/cdk/overlay';
import { Inject, Injectable, Injector, Optional, TemplateRef } from '@angular/core';

import { OverlayBase } from '../../shared/services/overlay.service';
import { OVERLAY_SCROLL_STRATEGY } from '../../shared/tokens/overlay-scroll-strategy.token';
import { HopDialogContainerComponent } from '../components/dialog-container/dialog-container.component';
import { HopDialogConfig } from '../models/dialog-config';
import { HopDialogRef } from '../models/dialog-ref';
import { HOP_DIALOG_DATA } from '../tokens/dialog-data.token';

/**
 * Service to open dialogs
 */
@Injectable()
export class HopDialog extends OverlayBase<HopDialogConfig> {

    constructor(overlay: Overlay,
                injector: Injector,
                @Inject(OVERLAY_SCROLL_STRATEGY) scrollStrategy: ScrollStrategy,
                @Optional() defaultConfig?: HopDialogConfig) {
        super(overlay, injector, HopDialogContainerComponent, HopDialogConfig, HopDialogRef, HOP_DIALOG_DATA, scrollStrategy, defaultConfig);
    }

    override open<T, R>(content: ComponentType<T> | TemplateRef<T>, config: HopDialogConfig): HopDialogRef<T, R> {
        return super.open(content, config) as HopDialogRef<T, R>;
    }

    protected override _createOverlayConfig(config: HopDialogConfig): OverlayConfig {
        return {
            positionStrategy: this._overlay.position().global().centerHorizontally().centerVertically(),
            ...super._createOverlayConfig(config)
        };
    }

    protected createOverlayRef<T, R>(overlayRef: OverlayRef, overlayContainer: HopDialogContainerComponent): HopDialogRef<T, R> {
        return new HopDialogRef<T, R>(overlayRef, overlayContainer);
    }

    protected createDefaultConfig(): HopDialogConfig {
        return new HopDialogConfig();
    }

}
