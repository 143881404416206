import { Injectable } from '@angular/core';
import { HopAuthStateFacade, HopLogoutFacade } from '@hopsteiner/auth/domain';
import { UserPermission } from '@hopsteiner/shared/models';

@Injectable({
    providedIn: 'root'
})
export class HopAuthApiFacade {

    constructor(private readonly _authStateFacade: HopAuthStateFacade,
                private readonly _logoutFacade: HopLogoutFacade) {

    }

    get isAuthenticated() {
        return this._authStateFacade.isAuthenticated;
    }

    get isAuthenticated$() {
        return this._authStateFacade.isAuthenticated$;
    }

    get activeUser$() {
        return this._authStateFacade.activeUser$;
    }

    get permissions$() {
        return this._authStateFacade.permissions$;
    }

    hasPermission(permission: UserPermission) {
        return this._authStateFacade.hasPermission(permission);
    }

    hasPermissions(permissions: UserPermission[]) {
        return this._authStateFacade.hasPermissions(permissions);
    }

    updateActiveUser() {
        return this._authStateFacade.updateActiveUser();
    }

    logOut() {
        return this._logoutFacade.logOut();
    }
}
