import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';


@Component({
    selector: 'hop-status',
    templateUrl: './status.component.html',
    styleUrls: [ './status.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-status'
    }
})
export class HopStatusComponent {

    private _status: boolean = false;
    private _isCompact: boolean = false;

    @HostBinding('class.c-hop-status--true')
    @Input()
    get status(): boolean {
        return this._status;
    }

    set status(value: boolean) {
        this._status = coerceBooleanProperty(value);
    }

    @HostBinding('class.c-hop-status--false')
    get isFalse(): boolean {
        return !this._status;
    }

    @HostBinding('class.c-hop-status--compact')
    @Input()
    get compact(): boolean {
        return this._isCompact;
    }

    set compact(value: BooleanInput) {
        this._isCompact = coerceBooleanProperty(value);
    }

}
