import { distinctUntilChanged, map, Observable, scan, shareReplay, Subscription } from 'rxjs';

import { LoadingEventType } from './loading-event-type.enum';
import { LoadingEvent } from './loading-event.interface';
import { ILoadingObserver } from './loading-observer.interface';
import { IPaginatedData } from './paginated-data.interface';

/**
 * A connection to a {@linkplain DataSource data source}
 */
export class Connection<T, QUERY = unknown> {

    readonly data$: Observable<T[]>;
    readonly total$: Observable<number>;
    readonly isLoading$: Observable<boolean>;

    constructor(readonly paginated$: Observable<IPaginatedData<T>>,
                readonly loadingEvents$: Observable<LoadingEvent<QUERY>>,
                private readonly _onDisconnect: () => void) {
        this.data$ = this.paginated$.pipe(map((paginated) => paginated?.data), shareReplay(1));
        this.total$ = this.paginated$.pipe(map((paginated) => paginated?.total), shareReplay(1));
        this.isLoading$ = this.loadingEvents$.pipe(
            scan((state, event: LoadingEvent<QUERY>) => {
                switch (event.type) {
                    case LoadingEventType.START:
                        state.add(event.index);
                        break;
                    case LoadingEventType.END:
                        state.delete(event.index);
                        break;
                    case LoadingEventType.RESET:
                        state.clear();
                        break;
                }

                return state;
            }, new Set<number>()),
            map((state) => state.size > 0),
            distinctUntilChanged()
        );
    }

    /**
     * Disconnects from the data source
     */
    disconnect() {
        this._onDisconnect();
    }

    /**
     * Subscribes to the provided streams.
     * In addition to a plain RxJS observer, this method also allows subscribing to changes of the `loading` state
     *
     * @param observer the observer used to subscribe
     */
    subscribe(observer: ILoadingObserver<IPaginatedData<T>>): Subscription {
        const subscription = this.paginated$.subscribe(observer);

        if (observer.loading) {
            subscription.add(this.isLoading$.subscribe(observer.loading));
        }

        return subscription;
    }

}
