import { Injectable } from '@angular/core';
import { DebitorRepository, HopDebitorFacade, IOverviewDebitor } from '@hopsteiner/debitor/domain';
import { IFindOptions, RepositoryDataSource, TransformOptionsFunction } from '@hopsteiner/shared/data-access';

@Injectable({
    providedIn: 'root'
})
export class HopDebitorApiFacade {

    constructor(private readonly _debitorFacade: HopDebitorFacade,
                private readonly _debitorRepository: DebitorRepository) {

    }

    get debitorRepository() {
        return this._debitorRepository;
    }

    createDataSource<QUERY = unknown>(transformOptions?: TransformOptionsFunction<QUERY>) {
        return new RepositoryDataSource<IOverviewDebitor, QUERY>(this._debitorRepository, transformOptions);
    }

    getDebitor(id: string) {
        return this._debitorFacade.getDebitor(id);
    }

    findDebitors(findOptions: IFindOptions) {
        return this._debitorFacade.findDebitors(findOptions);
    }

    createInvoicesDataSource(id: string) {
        return this._debitorFacade.createInvoicesDataSource(id);
    }
}
