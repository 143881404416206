import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { OrderRequestRepository } from '../infrastructure/order-request.repository';

import { RequestOrderActions } from './actions/request-order.actions';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IOrderRequestStateModel {

}

@State<IOrderRequestStateModel>({
    name: 'orderRequest',
    defaults: {}
})
@Injectable()
export class OrderRequestState {

    constructor(private readonly _orderRequestRepository: OrderRequestRepository) {
    }

    @Action(RequestOrderActions.RequestOrder)
    async requestOrder(ctx: StateContext<IOrderRequestStateModel>, action: RequestOrderActions.RequestOrder) {
        await this._orderRequestRepository.create(action.orderData);
    }
}
