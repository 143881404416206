import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { BreakpointMap } from './models/breakpoint-map';
import { Breakpoint } from './models/breakpoint.enum';
import { Breakpoints } from './services/breakpoints.service';
import { BREAKPOINT_MAP } from './tokens/breakpoints.token';

const DEFAULT_BREAKPOINT_MAP: BreakpointMap = {
    [Breakpoint.XS]: 0,
    [Breakpoint.SM]: 576,
    [Breakpoint.MD]: 768,
    [Breakpoint.LG]: 992,
    [Breakpoint.XL]: 1200,
    [Breakpoint.XXL]: 1400
};

@NgModule({
    imports: [ CommonModule ]
})
export class HopBreakpointsModule {

    static forRoot(breakpointMap: BreakpointMap = DEFAULT_BREAKPOINT_MAP): ModuleWithProviders<HopBreakpointsModule> {
        return {
            ngModule: HopBreakpointsModule,
            providers: [
                { provide: BREAKPOINT_MAP, useValue: breakpointMap },
                Breakpoints
            ]
        };
    }
}
