import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HopAuthApiFacade } from '@hopsteiner/auth/api';
import { HopEnvironmentApiFacade } from '@hopsteiner/environment/api';
import { Observable, tap } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private readonly _authApiFacade: HopAuthApiFacade,
                private readonly _environmentApiFacade: HopEnvironmentApiFacade) {
    }


    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isApiUrl = req.url.startsWith(environment.apiUrl);

        return next.handle(req)
            .pipe(
                tap({
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    error: (error: HttpResponse<any>) => {
                        if (this._authApiFacade.isAuthenticated && isApiUrl && error.status == 401) {
                            this._authApiFacade.logOut();
                        }
                    }
                })
            );
    }
}
