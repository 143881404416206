import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { OnChangeListener, OnTouchedListener } from '@hopsteiner/shared/models';

@Directive({
    host: {
        // eslint-disable-next-line @angular-eslint/no-host-metadata-property
        '[attr.disabled]': 'disabled || null'
    }
})
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export abstract class BaseControlValueAccessorDirective<T = any> implements ControlValueAccessor {

    private _disabled: boolean = false;
    private _value?: T | null;
    private _onChangeListener?: OnChangeListener<T>;
    private _onTouchedListener?: OnTouchedListener;

    constructor(private readonly _renderer: Renderer2, private readonly _elementRef: ElementRef) {
    }

    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: boolean) {
        this._setDisabled(value);
    }

    get value(): T | undefined | null {
        return this._value;
    }

    set value(value: T | undefined | null) {
        this._value = value;
        this._dispatchValueChange(value);
    }

    registerOnChange(fn: OnChangeListener<T>) {
        this._onChangeListener = fn;
    }

    registerOnTouched(fn: OnTouchedListener) {
        this._onTouchedListener = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this._setDisabled(isDisabled);
    }

    writeValue(value: T | undefined | null) {
        this._value = value;
    }

    protected _setDisabled(value: boolean) {
        this._disabled = value;
        this._setProperty('disabled', value);
    }

    protected _dispatchValueChange(newValue: T | undefined | null) {
        if (!this._onChangeListener) {
            return;
        }

        this._onChangeListener(newValue);
    }

    protected _dispatchTouched() {
        if (!this._onTouchedListener) {
            return;
        }

        this._onTouchedListener();
    }

    /**
     * Helper method that sets a property on a target element using the current Renderer
     * implementation.
     */
    protected _setProperty(key: string, value: unknown): void {
        this._renderer.setProperty(this._elementRef.nativeElement, key, value);
    }

}
