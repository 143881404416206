import { ListRange } from '@angular/cdk/collections';
import { ChangeDetectorRef, Directive, Inject, OnInit, Optional, SimpleChange } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';

import { DataSource } from '../models/data-source.class';
import { IPaginatedData } from '../models/paginated-data.interface';
import { INITIAL_LIST_RANGE } from '../tokens/initial-list-range.token';
import { INITIAL_QUERY } from '../tokens/initial-query.token';

import { HopCollectionViewerDirective } from './collection-viewer.directive';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'ng-select[dataSource]'
})
export class HopSelectCollectionViewerDirective<T> extends HopCollectionViewerDirective<T, string> implements OnInit {

    readonly fetchMoreThreshold = 5;

    private readonly _initialListRange: ListRange;
    private readonly _ngSelectQuerySubject = new Subject<string>();

    constructor(@Optional() @Inject(INITIAL_LIST_RANGE) initialListRange: ListRange | undefined,
                @Optional() @Inject(INITIAL_QUERY) initialQuery: string | undefined,
                changeDetectorRef: ChangeDetectorRef,
                private readonly _ngSelect: NgSelectComponent) {
        super(initialListRange, initialQuery, changeDetectorRef);
        this._initialListRange = initialListRange ?? { start: 0, end: 20 };
    }

    get items(): T[] | null {
        return this._ngSelect.items as T[] | null;
    }

    set items(value: T[] | null) {
        this._ngSelect.items = value;

        this._ngSelect.ngOnChanges({
            items: new SimpleChange(void 0, value, false)
        });
        this._ngSelect.detectChanges();
    }

    get hasNext(): boolean {
        const range = this.viewChange.getValue();
        const total = this.total ?? 0;

        return range == null ? total > 0 : range.end < total;
    }

    ngOnInit() {
        this._ngSelect.virtualScroll = true;
        this._ngSelect.typeahead = this._ngSelectQuerySubject;
        this._ngSelect.scroll.subscribe((range) => this.onScroll(range));
        this._ngSelect.scrollToEnd.subscribe(() => this.loadNext());

        this._ngSelectQuerySubject
            .pipe(
                debounceTime(100),
                distinctUntilChanged(),
                takeUntil(this._onDestroy)
            )
            .subscribe((term) => {
                this.query = term;
                this.loadFirst();
            });

        this._ngSelect.detectChanges();
    }

    onScroll(range: ListRange) {
        const items = this.items || [];

        if (this.isLoading || items.length === this.total) {
            return;
        }

        if (range.end + this.fetchMoreThreshold >= items.length) {
            this.loadNext();
        }
    }

    loadFirst() {
        this.viewChange.next(this._initialListRange);
    }

    loadNext() {
        if (!this.hasNext) {
            return;
        }

        const { start, end } = this.viewChange.getValue();
        this.viewChange.next({
            start: end,
            end: end + (end - start)
        });
    }

    protected override _switchDataSource(dataSource: DataSource<T, string> | undefined) {
        super._switchDataSource(dataSource?.infinite());
    }

    protected override _setLoading(loading: boolean) {
        if (this._ngSelect) {
            this._ngSelect.loading = loading;
        }

        super._setLoading(loading);
    }

    protected override _setNext(data: IPaginatedData<T>) {
        this.items = data?.data;

        super._setNext(data);
    }
}
