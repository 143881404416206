import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation } from '@angular/core';
import { HopEnvFeatureAccountMenuModule } from '@hopsteiner/environment/feature-account-menu';
import { HopEnvDebitorSwitcherModule } from '@hopsteiner/environment/feature-debitor-switcher';
import { HopEnvFeatureLanguageMenuModule } from '@hopsteiner/environment/feature-language-menu';
import { HopLegalNavModule } from '@hopsteiner/legal/ui-nav';
import { HopDrawer, HopDrawerModule, HopDrawerRef } from '@hopsteiner/shared/overlays';

import { AppMainNavComponentModule } from '../../components/main-nav/main-nav.component';

@Component({
    selector: 'hop-navigation-drawer',
    templateUrl: './navigation.drawer.html',
    styleUrls: [ './navigation.drawer.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-navigation-drawer'
    }
})
export class AppNavigationDrawer {

    static open(drawer: HopDrawer) {
        return drawer.open(AppNavigationDrawer, {
            height: '100%',
            maxWidth: '25rem',
            width: 'calc(100% - 4rem)'
        });
    }

    constructor(private readonly _drawerRef: HopDrawerRef) {

    }

    close() {
        this._drawerRef.close();
    }
}


@NgModule({
    imports: [
        CommonModule,

        HopDrawerModule,
        HopEnvDebitorSwitcherModule,
        HopEnvFeatureAccountMenuModule,
        HopEnvFeatureLanguageMenuModule,
        HopLegalNavModule,

        AppMainNavComponentModule
    ],
    declarations: [ AppNavigationDrawer ],
    exports: [ AppNavigationDrawer ]
})
export class AppNavigationDrawerModule {

}
