export interface IContractBrowseFilter {
    cultivar: string[];
    growingArea: string[];
    product: string[];
    harvestYear: number[];
    fulfilled: boolean;
    completedAfter: Date | null;
    completedBefore: Date | null;
    contractStatus: string;
    organicLabel: string[];
}
