import { Pipe, PipeTransform } from '@angular/core';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Pipe({
    name: 'isEdgeOfRange'
})
export class HopIsEdgeOfRangePipe implements PipeTransform {

    transform(date: NgbDate, startDate: NgbDateStruct | null, endDate: NgbDateStruct | null): boolean {
        return (startDate != null && date.equals(startDate)) || (endDate != null && date.equals(endDate));
    }
}
