<hop-data-table [trackBy]="trackElementById" hopSort hopScrollContainer>
    <ng-container hopColumnDef="navisionId" hopColumnWidth="8rem">
        <hop-header-cell *hopHeaderCellDef hopSortHeader>
            <span>{{ 'ENVIRONMENT.DEBITOR_SWITCHER.DRAWER.TABLE.COLUMN.NUMBER' | translate }}</span>
        </hop-header-cell>
        <hop-cell *hopCellDef="let element"><span>{{ element.navisionId }}</span></hop-cell>
    </ng-container>

    <ng-container hopColumnDef="fullName">
        <hop-header-cell *hopHeaderCellDef hopSortHeader>
            <span>{{ 'ENVIRONMENT.DEBITOR_SWITCHER.DRAWER.TABLE.COLUMN.NAME' | translate }}</span>
        </hop-header-cell>
        <hop-cell *hopCellDef="let element">
            <div class="text-truncate"><span>{{ element.fullName }}</span></div>
        </hop-cell>
    </ng-container>

    <ng-container hopColumnDef="zip" hopColumnWidth="8rem">
        <hop-header-cell *hopHeaderCellDef hopSortHeader>
            <span>{{ 'ENVIRONMENT.DEBITOR_SWITCHER.DRAWER.TABLE.COLUMN.ZIP' | translate }}</span>
        </hop-header-cell>
        <hop-cell *hopCellDef="let element"><span>{{ element.zip }}</span></hop-cell>
    </ng-container>

    <ng-container hopColumnDef="city">
        <hop-header-cell *hopHeaderCellDef hopSortHeader>
            <span>{{ 'ENVIRONMENT.DEBITOR_SWITCHER.DRAWER.TABLE.COLUMN.CITY' | translate }}</span>
        </hop-header-cell>
        <hop-cell *hopCellDef="let element"><span>{{ element.city }}</span></hop-cell>
    </ng-container>

    <ng-container hopColumnDef="select">
        <hop-header-cell *hopHeaderCellDef></hop-header-cell>
        <hop-cell *hopCellDef="let element" class="c-hop-env-debitor-drawer__select-cell">
            <button hopButton
                    (click)="selectDebitor.emit(element)">
                {{ 'ENVIRONMENT.DEBITOR_SWITCHER.DRAWER.TABLE.COLUMN.SELECT.ACTION' | translate }}
            </button>
        </hop-cell>
    </ng-container>

    <hop-header-row *hopHeaderRowDef="_tableColumns"></hop-header-row>
    <hop-row *hopRowDef="let row; columns: _tableColumns;"
             [hopHighlightRecord]="row.headquarters"
             [highlightLabel]="'ENVIRONMENT.DEBITOR_SWITCHER.HIGHLIGHTED.HEADQUARTER' | translate"
             highlightColor="var(--bs-primary)">
    </hop-row>
</hop-data-table>
