import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HopCardModule } from '@hopsteiner/shared/components';

import { HopDataCardComponent } from './components/data-card/data-card.component';
import { HopDataCardButtonDirective } from './directives/card-button.directive';
import { HopDataCardMenuDirective } from './directives/card-menu.directive';

@NgModule({
    imports: [
        CommonModule,

        HopCardModule
    ],
    declarations: [
        HopDataCardComponent,
        HopDataCardMenuDirective,
        HopDataCardButtonDirective
    ],
    exports: [
        HopDataCardComponent,
        HopDataCardMenuDirective,
        HopDataCardButtonDirective
    ]
})
export class HopDataCardModule {

}
