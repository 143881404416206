import { Injectable } from '@angular/core';
import { DataSource } from '@hopsteiner/shared/collections';

import { IOrderRequest } from '../../entities/public-api';
import { OrderRequestDataSource, OrderRequestRepository } from '../../infrastructure/public-api';

@Injectable({ providedIn: 'root' })
export class HopOrderRequestBrowseFacade {

    constructor(private readonly _orderRequestRepository: OrderRequestRepository) {
    }

    createDataSource(): DataSource<IOrderRequest> {
        return new OrderRequestDataSource(this._orderRequestRepository);
    }
}
