import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

import { IContactCompany } from '../../models/contact-company.interface';

@Component({
    selector: 'hop-contact-company',
    templateUrl: './contact-company.component.html',
    styleUrls: [ './contact-company.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-contact-company'
    }
})
export class HopContactCompanyComponent {

    @Input()
    data?: IContactCompany;
}
