import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { ApiInterceptor } from './interceptors/api.interceptor';
import { API_URL } from './tokens/api-url.token';

@NgModule({})
export class HopDataAccessModule {

    static forRoot(apiUrl: string): ModuleWithProviders<HopDataAccessModule> {
        return {
            ngModule: HopDataAccessModule,
            providers: [
                { provide: API_URL, useValue: apiUrl },
                { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true }
            ]
        };
    }
}
