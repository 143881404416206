import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'limit'
})
export class HopLimitPipe implements PipeTransform {

    transform<T>(array: T[], limit: number, truncate: number = limit): T[] {
        if (!array) {
            return array;
        }

        return array.length > limit ? array.slice(0, truncate) : array;
    }

}
