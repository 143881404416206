import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import { LOCALE_ID, NgModule } from '@angular/core';
import { Language } from '@hopsteiner/shared/models';
import { IModuleTranslationOptions, ModuleTranslateLoader } from '@larscom/ngx-translate-module-loader';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';

import { environment } from '../../environments/environment';

registerLocaleData(localeDe);
registerLocaleData(localeEn);

function httpLoaderFactory(http: HttpClient) {
    const baseTranslateUrl = './assets/i18n';
    const now = new Date();

    const options: IModuleTranslationOptions = {
        modules: [
            { baseTranslateUrl },
            { baseTranslateUrl, moduleName: 'auth.forgot_password', pathTemplate: '{baseTranslateUrl}/auth/feature-forgot-password/{language}' },
            { baseTranslateUrl, moduleName: 'account.delete_account', pathTemplate: '{baseTranslateUrl}/account/feature-delete-account/{language}' },
            { baseTranslateUrl, moduleName: 'account.edit_account', pathTemplate: '{baseTranslateUrl}/account/feature-edit-account/{language}' },
            { baseTranslateUrl, moduleName: 'account.shell', pathTemplate: '{baseTranslateUrl}/account/shell/{language}' },
            { baseTranslateUrl, moduleName: 'account.view_account', pathTemplate: '{baseTranslateUrl}/account/feature-view-account/{language}' },
            { baseTranslateUrl, moduleName: 'auth.layout', pathTemplate: '{baseTranslateUrl}/auth/feature-layout/{language}' },
            { baseTranslateUrl, moduleName: 'auth.login', pathTemplate: '{baseTranslateUrl}/auth/feature-login/{language}' },
            { baseTranslateUrl, moduleName: 'components.button', pathTemplate: '{baseTranslateUrl}/components/button/{language}' },
            { baseTranslateUrl, moduleName: 'components.country_flag', pathTemplate: '{baseTranslateUrl}/components/country-flag/{language}' },
            { baseTranslateUrl, moduleName: 'components.form', pathTemplate: '{baseTranslateUrl}/components/form/{language}' },
            { baseTranslateUrl, moduleName: 'components.language', pathTemplate: '{baseTranslateUrl}/components/language/{language}' },
            { baseTranslateUrl, moduleName: 'components.pipes', pathTemplate: '{baseTranslateUrl}/components/pipes/{language}' },
            { baseTranslateUrl, moduleName: 'components.search_bar', pathTemplate: '{baseTranslateUrl}/components/search-bar/{language}' },
            { baseTranslateUrl, moduleName: 'column_chooser', pathTemplate: '{baseTranslateUrl}/column-chooser/{language}' },
            { baseTranslateUrl, moduleName: 'data_table', pathTemplate: '{baseTranslateUrl}/data-table/{language}' },
            { baseTranslateUrl, moduleName: 'environment.account_menu', pathTemplate: '{baseTranslateUrl}/environment/feature-account-menu/{language}' },
            { baseTranslateUrl, moduleName: 'environment.language_menu', pathTemplate: '{baseTranslateUrl}/environment/feature-language-menu/{language}' },
            { baseTranslateUrl, moduleName: 'environment.debitor_switcher', pathTemplate: '{baseTranslateUrl}/environment/feature-debitor-switcher/{language}' },
            { baseTranslateUrl, moduleName: 'export', pathTemplate: '{baseTranslateUrl}/export/{language}' },
            { baseTranslateUrl, moduleName: 'dashboard.quick_access_layout', pathTemplate: '{baseTranslateUrl}/dashboard/ui-quick-access-layout/{language}' },
            { baseTranslateUrl, moduleName: 'dashboard.shell', pathTemplate: '{baseTranslateUrl}/dashboard/shell/{language}' },
            { baseTranslateUrl, moduleName: 'debitor.edit', pathTemplate: '{baseTranslateUrl}/debitor/feature-edit/{language}' },
            { baseTranslateUrl, moduleName: 'debitor.shell', pathTemplate: '{baseTranslateUrl}/debitor/shell/{language}' },
            { baseTranslateUrl, moduleName: 'debitor.address_table', pathTemplate: '{baseTranslateUrl}/debitor/ui-address-table/{language}' },
            { baseTranslateUrl, moduleName: 'debitor.table', pathTemplate: '{baseTranslateUrl}/debitor/ui-table/{language}' },
            { baseTranslateUrl, moduleName: 'debitor.view', pathTemplate: '{baseTranslateUrl}/debitor/feature-view/{language}' },
            { baseTranslateUrl, moduleName: 'debitor.view_change_requests', pathTemplate: '{baseTranslateUrl}/debitor/feature-view-change-requests/{language}' },
            { baseTranslateUrl, moduleName: 'filter' },
            { baseTranslateUrl, moduleName: 'layouts' },
            { baseTranslateUrl, moduleName: 'legal.nav', pathTemplate: '{baseTranslateUrl}/legal/ui-nav/{language}' },
            { baseTranslateUrl, moduleName: 'legal.shell', pathTemplate: '{baseTranslateUrl}/legal/shell/{language}' },
            { baseTranslateUrl, moduleName: 'order_request.create', pathTemplate: '{baseTranslateUrl}/order-request/feature-create/{language}' },
            { baseTranslateUrl, moduleName: 'order_request.browse', pathTemplate: '{baseTranslateUrl}/order-request/feature-browse/{language}' },
            { baseTranslateUrl, moduleName: 'order_request.table', pathTemplate: '{baseTranslateUrl}/order-request/ui-table/{language}' },
            { baseTranslateUrl, moduleName: 'overlays' },
            { baseTranslateUrl, moduleName: 'user.domain', pathTemplate: '{baseTranslateUrl}/user/domain/{language}' },
            { baseTranslateUrl, moduleName: 'user.browse', pathTemplate: '{baseTranslateUrl}/user/feature-browse/{language}' },
            { baseTranslateUrl, moduleName: 'user.change_request', pathTemplate: '{baseTranslateUrl}/user/ui-change-request/{language}' },
            { baseTranslateUrl, moduleName: 'user.create', pathTemplate: '{baseTranslateUrl}/user/feature-create/{language}' },
            { baseTranslateUrl, moduleName: 'user.debitor_select', pathTemplate: '{baseTranslateUrl}/user/ui-debitor-select/{language}' },
            { baseTranslateUrl, moduleName: 'user.pipes', pathTemplate: '{baseTranslateUrl}/user/ui-pipes/{language}' },
            { baseTranslateUrl, moduleName: 'user.table', pathTemplate: '{baseTranslateUrl}/user/ui-table/{language}' },
            { baseTranslateUrl, moduleName: 'user.view', pathTemplate: '{baseTranslateUrl}/user/feature-view/{language}' },
            { baseTranslateUrl, moduleName: 'user.delete', pathTemplate: '{baseTranslateUrl}/user/feature-delete/{language}' },
            { baseTranslateUrl, moduleName: 'user.edit', pathTemplate: '{baseTranslateUrl}/user/feature-edit/{language}' },
            { baseTranslateUrl, moduleName: 'user.shell', pathTemplate: '{baseTranslateUrl}/user/shell/{language}' },
            { baseTranslateUrl, moduleName: 'user.delete_request', pathTemplate: '{baseTranslateUrl}/user/ui-delete-request/{language}' },
            { baseTranslateUrl, moduleName: 'user.table', pathTemplate: '{baseTranslateUrl}/user/ui-table/{language}' },
            { baseTranslateUrl, moduleName: 'contract.browse', pathTemplate: '{baseTranslateUrl}/contract/feature-browse/{language}' },
            { baseTranslateUrl, moduleName: 'contract.detail', pathTemplate: '{baseTranslateUrl}/contract/feature-detail/{language}' },
            { baseTranslateUrl, moduleName: 'contract.request_shipment', pathTemplate: '{baseTranslateUrl}/contract/feature-request-shipment/{language}' },
            { baseTranslateUrl, moduleName: 'contract.shipment_drawer', pathTemplate: '{baseTranslateUrl}/contract/ui-shipment-drawer/{language}' },
            { baseTranslateUrl, moduleName: 'contract.shipment_table', pathTemplate: '{baseTranslateUrl}/contract/ui-shipment-table/{language}' },
            { baseTranslateUrl, moduleName: 'contract.cancellation_table', pathTemplate: '{baseTranslateUrl}/contract/ui-cancellation-table/{language}' },
            { baseTranslateUrl, moduleName: 'contract.table', pathTemplate: '{baseTranslateUrl}/contract/ui-table/{language}' },
            { baseTranslateUrl, moduleName: 'contract.organic_label', pathTemplate: '{baseTranslateUrl}/contract/ui-organic-label/{language}' },
            { baseTranslateUrl, moduleName: 'contract.view_shipment_request', pathTemplate: '{baseTranslateUrl}/contract/feature-view-shipment-request/{language}' },
            { baseTranslateUrl, moduleName: 'contact.list_contacts', pathTemplate: '{baseTranslateUrl}/contact/feature-list-contacts/{language}' },
            { baseTranslateUrl, moduleName: 'invoice.browse', pathTemplate: '{baseTranslateUrl}/invoice/feature-browse/{language}' }
        ],
        version: `${now.getFullYear()}-${now.getMonth()}-${now.getDay()}`
    };

    return new ModuleTranslateLoader(http, options);
}

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [ HttpClient ]
            },
            defaultLanguage: environment.defaultLanguage
        })
    ],
    providers: [
        {
            provide: LOCALE_ID,
            useFactory: (translate: TranslateService) => {
                return translate.currentLang ?? Language.DE;
            },
            deps: [ TranslateService ]
        }
    ],
    exports: [
        TranslateModule
    ]
})
export class AppTranslationModule {

}
