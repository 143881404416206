import { ContentChild, Directive, Input } from '@angular/core';

import { HopLabelDefDirective } from './label-def.directive';
import { HopValueDefDirective } from './value-def.directive';

@Directive({
    selector: '[hopPropertyDef]'
})
export class HopPropertyDefDirective {

    /**
     * The key of the property
     */
    @Input('hopPropertyDef')
    property!: string;

    @ContentChild(HopLabelDefDirective)
    label!: HopLabelDefDirective;

    @ContentChild(HopValueDefDirective)
    value!: HopValueDefDirective;

}
