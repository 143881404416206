<div class="c-hop-env-debitor-switcher__container" *ngIf="_isShown$ | async" [ngSwitch]="_mode$ | async">

    <hop-env-debitor-switcher-tab-list *ngSwitchCase="DebitorSwitcherAppearance.TABS"
                                       [disabled]="_isDisabled$ | async"
                                       [activeDebitor]="_activeDebitor$ | async"
                                       (activeDebitorChange)="setActiveDebitor($event)">
    </hop-env-debitor-switcher-tab-list>

    <hop-env-debitor-picker *ngSwitchCase="DebitorSwitcherAppearance.PICKER"
                            [disabled]="_isDisabled$ | async"
                            [activeDebitor]="_activeDebitor$ | async"
                            (activeDebitorChange)="setActiveDebitor($event)">
    </hop-env-debitor-picker>

</div>
