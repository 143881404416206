import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HopCollectionsModule } from '@hopsteiner/shared/collections';
import { HopButtonModule } from '@hopsteiner/shared/components';
import { HopDrawerModule } from '@hopsteiner/shared/overlays';
import { NgbAccordionModule, NgbDatepickerI18n, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { HopDateFilterComponent } from './components/date-filter/date-filter.component';
import { HopDateRangeFilterComponent } from './components/date-range-filter/date-range-filter.component';
import { HopFilterBadgeComponent } from './components/filter-badge/filter-badge.component';
import { HopFilterButtonComponent } from './components/filter-button/filter-button.component';
import { HopMultiSelectFilterComponent } from './components/multi-select-filter/multi-select-filter.component';
import { HopSingleSelectFilterComponent } from './components/single-select-filter/single-select-filter.component';
import { HopSelectOptionDirective } from './directives/select-option/select-option.directive';
import { HopFilterDrawer } from './drawers/filter/filter.drawer';
import { HopHasSomeValuePipe } from './pipes/has-some-value.pipe';
import { HopHasValuePipe } from './pipes/has-value.pipe';
import { HopIsEdgeOfRangePipe } from './pipes/is-edge-of-range.pipe';
import { HopIsWithinRangePipe } from './pipes/is-within-range.pipe';
import { HopLimitPipe } from './pipes/limit.pipe';
import { HopWithValuesPipe } from './pipes/with-value.pipe';
import { DatePickerI18nService } from './services/date-picker-i18n.service';


@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,

        FontAwesomeModule,
        NgbAccordionModule,
        NgbDatepickerModule,
        TranslateModule,

        HopButtonModule,
        HopCollectionsModule,
        HopDrawerModule
    ],
    declarations: [
        HopSingleSelectFilterComponent,
        HopMultiSelectFilterComponent,
        HopDateFilterComponent,
        HopDateRangeFilterComponent,
        HopFilterButtonComponent,
        HopFilterBadgeComponent,

        HopFilterDrawer,

        HopSelectOptionDirective,

        HopIsEdgeOfRangePipe,
        HopIsWithinRangePipe,
        HopWithValuesPipe,
        HopHasSomeValuePipe,
        HopHasValuePipe,
        HopLimitPipe
    ],
    exports: [
        HopFilterButtonComponent,
        HopSingleSelectFilterComponent,
        HopMultiSelectFilterComponent,
        HopDateFilterComponent,
        HopDateRangeFilterComponent,

        HopFilterDrawer,

        HopSelectOptionDirective
    ],
    providers: [
        { provide: NgbDatepickerI18n, useClass: DatePickerI18nService }
    ]
})
export class HopFilterModule {
}
