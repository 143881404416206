import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { AuthState } from './application/auth.state';
import { HopForgotPasswordFacade } from './application/facades/forgot-password.facade';
import { HopLoginFacade } from './application/facades/login.facade';
import { PreferencesState } from './application/preferences.state';
import { AuthService } from './infrastructure/auth.service';
import { PreferencesService } from './infrastructure/preferences.service';

@NgModule({
    imports: [
        CommonModule,

        NgxsModule.forFeature([ AuthState, PreferencesState ])
    ],
    providers: [
        AuthService,
        AuthState,
        HopLoginFacade,
        HopForgotPasswordFacade,
        PreferencesService
    ]
})
export class HopAuthDomainRootModule {

}
