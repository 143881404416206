import { ModuleWithProviders, NgModule } from '@angular/core';
import { HopUserDomainRootModule } from '@hopsteiner/user/domain';

@NgModule({})
export class HopUserApiModule {

    static forRoot(): ModuleWithProviders<HopUserApiRootModule> {
        return {
            ngModule: HopUserApiRootModule,
            providers: []
        };
    }
}

@NgModule({
    imports: [
        HopUserDomainRootModule,
        HopUserApiModule
    ],
    exports: [
        HopUserApiModule
    ]
})
export class HopUserApiRootModule {

}
