/* eslint-disable @typescript-eslint/no-unused-vars */
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { DebitorSwitcherFacade } from '@hopsteiner/environment/domain';
import { map, Observable } from 'rxjs';

import { NO_ACTIVE_DEBITOR_REDIRECT } from '../tokens/forbidden-redirect.token';

/**
 * Checks whether an active debitor is selected. Redirects to a route provided via NO_ACTIVE_DEBITOR_REDIRECT if no active debitor is selected.
 */
@Injectable({
    providedIn: 'root'
})
export class HopHasActiveDebitorGuard {

    constructor(private readonly _debitorSwitcherFacade: DebitorSwitcherFacade,
                private readonly _router: Router,
                @Inject(NO_ACTIVE_DEBITOR_REDIRECT) private readonly _noActiveDebitorRedirect: string) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this._verifyActiveDebitor(state.url);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this._verifyActiveDebitor(state.url);
    }

    protected _verifyActiveDebitor(currentUrl: string) {
        return this._debitorSwitcherFacade.activeDebitor$.pipe(
            map((activeDebitor) => {
                if (activeDebitor) {
                    return true;
                }

                const redirect = currentUrl === '/' ? undefined : currentUrl;

                return this._router.createUrlTree([ this._noActiveDebitorRedirect ], {
                    queryParams: { redirect }
                });
            })
        );
    }
}
