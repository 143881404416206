import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { HopAuthStateFacade, HopLoginFacade } from '@hopsteiner/auth/domain';
import { firstValueFrom } from 'rxjs';

import { UNAUTHORIZED_REDIRECT } from '../tokens/unauthorized-redirect.token';

export interface IAutoLoginGuardData {
    skipRedirect?: boolean;
}

export interface IAutoLoginGuardRoute {
    data?: IAutoLoginGuardData;
}

@Injectable({
    providedIn: 'root'
})
export class HopAutoLoginAuthGuard {

    constructor(private readonly _router: Router,
                private readonly _authStateFacade: HopAuthStateFacade,
                private readonly _loginFacade: HopLoginFacade,
                @Inject(UNAUTHORIZED_REDIRECT) private readonly _unauthorizedRedirect: string) {

    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canActivate(route: ActivatedRouteSnapshot & IAutoLoginGuardRoute, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        return this._autoLogin(state.url, route.data.skipRedirect);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canActivateChild(childRoute: ActivatedRouteSnapshot & IAutoLoginGuardRoute, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        return this._autoLogin(state.url, childRoute.data.skipRedirect);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canLoad(route: Route & IAutoLoginGuardRoute, segments: UrlSegment[]): Promise<boolean | UrlTree> {
        return this._autoLogin(undefined, route.data?.skipRedirect);
    }

    protected async _autoLogin(currentUrl?: string, skipRedirect?: boolean): Promise<boolean | UrlTree> {
        if (await firstValueFrom(this._authStateFacade.isAuthenticated$)) {
            return true;
        }

        await firstValueFrom(this._loginFacade.silentLogIn());

        if (await firstValueFrom(this._authStateFacade.isAuthenticated$)) {
            return true;
        }

        if (skipRedirect) {
            return true;
        }

        const redirect = currentUrl === '/' ? undefined : currentUrl;

        return this._router.createUrlTree([ this._unauthorizedRedirect ], {
            queryParams: { redirect }
        });
    }

}
