import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { IActiveDebitor } from '@hopsteiner/environment/domain';

@Directive()
export abstract class BaseDebitorChooserComponent {

    @Input()
    activeDebitor: IActiveDebitor | null = null;

    @Output()
    readonly activeDebitorChange = new EventEmitter<IActiveDebitor | null>();

    private _disabled: boolean = false;

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: BooleanInput) {
        this._disabled = coerceBooleanProperty(value);
    }

    setActiveDebitor(debitor: IActiveDebitor) {
        if (this.disabled) {
            return;
        }

        this.activeDebitorChange.next(debitor);
    }

    clearActiveDebitor() {
        if (this.disabled) {
            return;
        }

        this.activeDebitorChange.next(null);
    }
}
