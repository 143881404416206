import { Directive, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor } from '@angular/forms';
import { OnChangeListener, OnTouchedListener } from '@hopsteiner/shared/models';

@Directive()
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export abstract class DelegateControlValueAccessorDirective<T = any> implements ControlValueAccessor, OnInit {

    private _disabled: boolean = false;
    private _value?: T | null;
    private _onChangeListener?: OnChangeListener<T>;
    private _onTouchedListener?: OnTouchedListener;

    abstract get delegateControl(): AbstractControl;

    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: boolean) {
        this._setDisabled(value);
    }

    get value(): T | undefined | null {
        return this._value;
    }

    set value(value: T | undefined | null) {
        this._value = value;
        this._dispatchValueChange(value);
    }

    ngOnInit() {
        const delegate = this.delegateControl;

        delegate.valueChanges.subscribe((value) => this._dispatchValueChange(value));
    }

    registerOnChange(fn: OnChangeListener<T>) {
        this._onChangeListener = fn;
    }

    registerOnTouched(fn: OnTouchedListener) {
        this._onTouchedListener = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this._setDisabled(isDisabled);
    }

    writeValue(value: T | undefined | null) {
        this._value = value;
        this.delegateControl.setValue(value);
    }

    protected _setDisabled(value: boolean) {
        this._disabled = value;

        if (value) {
            this.delegateControl.disable();
        } else {
            this.delegateControl.enable();
        }
    }

    protected _dispatchValueChange(newValue: T | undefined | null) {
        if (!this._onChangeListener) {
            return;
        }

        this._onChangeListener(newValue);
    }

    protected _dispatchTouched() {
        if (!this._onTouchedListener) {
            return;
        }

        this._onTouchedListener();
    }

}
