/* eslint-disable @angular-eslint/component-class-suffix */
import { AnimationEvent } from '@angular/animations';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CdkCellOutlet, CdkRow } from '@angular/cdk/table';
import { ChangeDetectionStrategy, Component, ContentChildren, ElementRef, HostBinding, HostListener, Inject, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { DATA_TABLE_ANIMATIONS } from '../../animations/data-table.animations';
import { HopCell } from '../../directives/cell.directive';
import { IExpandableProvider } from '../../models/expandable-provider.interface';
import { ExpansionAnimationState, ExpansionState } from '../../models/expansion-state.enum';
import { EXPANDABLE_PROVIDER } from '../../tokens/expandable-provider.token';
import { HopRow } from '../row/row.component';


/** Data row template container that contains the cell outlet. Adds the right class and role. */
@Component({
    selector: 'hop-expandable-row',
    exportAs: 'hopRow',
    templateUrl: './expandable-row.component.html',
    styleUrls: [ './expandable-row.component.scss' ],
    // See note on CdkTable for explanation on why this uses the default change detection strategy.
    // tslint:disable-next-line:validate-decorators
    changeDetection: ChangeDetectionStrategy.Default,
    encapsulation: ViewEncapsulation.None,
    providers: [
        { provide: CdkRow, useExisting: HopRow },
        { provide: HopRow, useExisting: HopExpandableRow }
    ],
    animations: [
        DATA_TABLE_ANIMATIONS.expandRow
    ],
    host: {
        class: 'c-hop-row c-hop-row--expandable',
        role: 'row'
    }
})
export class HopExpandableRow<T = unknown> extends HopRow implements OnInit, OnDestroy {

    @ViewChild(CdkCellOutlet)
    cellOutlet!: CdkCellOutlet;

    @ContentChildren(HopCell)
    cells!: QueryList<HopCell>;

    @HostBinding('@expandRow')
    _animationState: ExpansionAnimationState = ExpansionState.COLLAPSED;

    @Input()
    element?: T;

    private _state: ExpansionState = ExpansionState.COLLAPSED;

    private _expanded: boolean = false;

    private readonly _onDestroy = new Subject<void>();

    constructor(@Inject(EXPANDABLE_PROVIDER) readonly expandableProvider: IExpandableProvider<T>,
                readonly elementRef: ElementRef) {
        super();
    }

    @HostBinding('attr.aria-disabled')
    get disabled(): boolean {
        return !this.expanded;
    }

    get state(): ExpansionState {
        return this._state;
    }

    @Input()
    @HostBinding('class.c-hop-row--expanded')
    get expanded(): boolean {
        return this._expanded;
    }

    set expanded(value: boolean) {
        value = coerceBooleanProperty(value);

        if (value === this._expanded) {
            return;
        }

        this._expanded = value;
        this._animationState = this._expanded ? ExpansionState.EXPANDED : ExpansionState.COLLAPSED;
    }

    ngOnInit() {
        this.expandableProvider.expandableChange
            .pipe(takeUntil(this._onDestroy))
            .subscribe((element) => {
                this.expanded = this.element === element;
            });
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    @HostListener('@expandRow.start', [ '$event' ])
    _onAnimationStart({ toState }: AnimationEvent) {
        switch (toState) {
            case ExpansionState.COLLAPSED:
                this._setState(ExpansionState.COLLAPSING);
                break;
            case ExpansionState.EXPANDED:
                this._setState(ExpansionState.EXPANDING);
                break;
        }
    }

    @HostListener('@expandRow.done', [ '$event' ])
    _onAnimationDone({ toState }: AnimationEvent) {
        switch (toState) {
            case ExpansionState.COLLAPSED:
                this._setState(ExpansionState.COLLAPSED);
                break;
            case ExpansionState.EXPANDED:
                this._setState(ExpansionState.EXPANDED);
                break;
        }
    }

    private _setState(state: ExpansionState) {
        this._state = state;
    }
}
