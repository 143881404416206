// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace TitleActions {

    export class SetTitleSegments {

        static readonly type = '[@hopsteiner/shared/title] Set titles';

        constructor(readonly titleSegments: string[]) {
        }
    }

    export class SetTranslatedTitle {

        static readonly type = '[@hopsteiner/shared/title] Set translated title';

        constructor(readonly translatedTitle: string) {
        }
    }
}
