import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'hop-drawer-footer',
    templateUrl: './drawer-footer.component.html',
    styleUrls: [ './drawer-footer.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-drawer__footer'
    }
})
export class HopDrawerFooterComponent {

}
