import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseRepository, Endpoint } from '@hopsteiner/shared/data-access';
import { firstValueFrom } from 'rxjs';

import { ICountry } from '../entities/country.interface';

@Injectable({
    providedIn: 'root'
})
export class CountryRepository extends BaseRepository<ICountry> {

    constructor(httpClient: HttpClient) {
        super(httpClient, {
            endpoint: Endpoint.COUNTRIES
        });
    }

    async find(): Promise<ICountry[]> {
        return firstValueFrom(this._httpClient.get<ICountry[]>(this.url));
    }

    async get(isoCode: string): Promise<ICountry> {
        return this._get(isoCode);
    }

}
