import { ISort, ISortableQuery } from '@hopsteiner/shared/collections';
import { FilterResult, IFilterDrawerResult } from '@hopsteiner/shared/filter';

export abstract class QueryUtils {

    static createQuery(filterResult?: IFilterDrawerResult<FilterResult>, searchTerm?: string, sort?: ISort) {
        const query: ISortableQuery = {
            where: {
                ...(filterResult?.result ?? {})
            },
            sort: sort
        };

        if (searchTerm != null) {
            query.where.query = searchTerm;
        }

        return query;
    }

}
