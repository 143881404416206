import { Injectable } from '@angular/core';
import { ISort, ISortableQuery } from '@hopsteiner/shared/collections';
import { FilterResult, IFilterDrawerResult } from '@hopsteiner/shared/filter';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';

import { IDataCollectionContainerFacade } from '../models/data-collection-container-facade.interface';
import { QueryUtils } from '../utils/query.utils';


@Injectable()
export class HopDataCollectionContainerFacade implements IDataCollectionContainerFacade {

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public query$: Observable<ISortableQuery<any, string>>;

    private _searchTerm = new BehaviorSubject<string | undefined>(undefined);
    private _filterResult = new BehaviorSubject<IFilterDrawerResult<FilterResult> | undefined>(undefined);
    private _sort = new BehaviorSubject<ISort | undefined>(undefined);
    private _page = new BehaviorSubject<number>(0);

    constructor() {
        this.query$ = combineLatest([
            this._filterResult,
            this._searchTerm,
            this._sort
        ]).pipe(
            map(([ filterResult, searchTerm, sort ]) => QueryUtils.createQuery(filterResult, searchTerm, sort))
        );
    }

    get searchTerm$(): Observable<string | undefined> {
        return this._searchTerm.asObservable();
    }

    get filterResult$(): Observable<IFilterDrawerResult<FilterResult> | undefined> {
        return this._filterResult.asObservable();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get query(): ISortableQuery<any, string> {
        return QueryUtils.createQuery(this._filterResult.value, this._searchTerm.value, this._sort.value);
    }

    get sort(): ISort<string> | undefined {
        return this._sort.getValue();
    }

    get page$(): Observable<number> {
        return this._page.asObservable();
    }

    async setSearchTerm(searchTerm: string): Promise<void> {
        this._searchTerm.next(searchTerm);
    }

    async setFilterResult(filterResult: IFilterDrawerResult<FilterResult>): Promise<void> {
        this._filterResult.next(filterResult);
    }

    async setSort(sort: ISort): Promise<void> {
        this._sort.next(sort);
    }

    async setPage(page: number): Promise<void> {
        this._page.next(page);
    }
}
