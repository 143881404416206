import { IDetailArticle, IOverviewArticle } from './article.interface';
import { OrganicLabel } from './bio-icon.enum';
import { ContractCargoIncluded } from './contract-cargo-included.enum';
import { ContractCertificatedIncluded } from './contract-certificated-included.enum';
import { QuantityUnit } from './quantity-unit.enum';

interface IBaseContract {
    navisionId: string;
    navisionTimestamp: number;
    completedAt: Date;
    comments: string[];
    cancelledAt: string;
    hasPartialCancellations: boolean;

    /**
     * First year of delivery; also used as harvest year!
     */
    firstYearOfDelivery: number;

    /**
     * Last year of delivery
     */
    lastYearOfDelivery: number;
    runtime: number;
    contractConfirmedAt: Date;
    packagingInformation: string;
    salesReference: string;

    /**
     * ISO 4217 currency code
     */
    currency: string;
    analysisDescription: string;
    certificate: ContractCertificatedIncluded;
    cargoIncluded: ContractCargoIncluded;
    deliveryCondition: string;
    deliveryConditionAddition: string;
    paymentCondition: string;
    paymentConditionCode: string;
    paymentDescription: string;
    targetDays: string;

    /**
     * ISO 3166-1 alpha-2 country code
     */
    country: {
        name: string;
        shortName: string;
    }
    externalPurchaseNumber: string;

    /**
     * Bitmask to show icons: [Bio] | [Naturland] | [Bioland] | [Demeter]
     *
     * 0b0001 = Bio
     * 0b0010 = Naturland
     * 0b0100 = Bioland
     * 0b1000 = Demeter
     */
    showIcons: OrganicLabel | number;

    // Relations:

    /**
     * The ID of the debitor
     */
    debitor: string;

    /**
     * The ID of the purchaser (SalesPersonPurchaser)
     */
    purchaser: number;

    /**
     * The ID of the end customer (Contact) ???
     */
    endCustomer: string;


    // from ContractPosition:
    year: number;
    alphaPercentage: string;
    price: number;
    fulfilled: boolean;
    deliveryDateFrom: Date;
    deliveryDateTo: Date;
    quantity: number;
    quantityUnit: QuantityUnit;
    displayQuantity: string;
    displayQuantityUnit: string;
    fullyShipped: boolean;
    unitPrice: string;


    // new product related
    name: string;
    product: {
        name: string;
        shortName: string;
    };
    growingArea: string;
    cultivar: string;
    cultivarDatasheetAvailable: boolean;
    harvestYear: number;

    baseAlphaPercent: number;
    alphaClause: boolean;
    organicLabels: OrganicLabel[];
    umbel: string | null;

    // new contract related
    type: string;
    endCustomerName: string;
    displayOutstandingQuantity?: string;
}

export interface IContract extends IBaseContract {
    /**
     * The ID of the article
     */
    article: string;
}

export interface IDetailContract extends IBaseContract {
    /**
     * The article
     */
    article: IDetailArticle;

    /**
     * The URL to the cultivar datasheet
     */
    cultivarDatasheetUrl: string;
}

export interface IOverviewContract extends Pick<IBaseContract, 'navisionId' | 'firstYearOfDelivery' | 'price' | 'quantity' | 'quantityUnit' | 'completedAt' | 'fulfilled' | 'cancelledAt' | 'alphaPercentage' | 'baseAlphaPercent' | 'alphaClause' | 'analysisDescription' | 'certificate' | 'type' | 'paymentCondition' | 'fullyShipped' | 'packagingInformation' | 'deliveryCondition' | 'cargoIncluded' | 'salesReference' | 'externalPurchaseNumber' | 'endCustomerName' | 'displayOutstandingQuantity' | 'organicLabels'> {
    // changed properties
    article: IOverviewArticle;
    comments: boolean;
}
