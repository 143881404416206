import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HopNavItemComponent } from './components/nav-item/nav-item.component';
import { HopNavListComponent } from './components/nav-list/nav-list.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        HopNavItemComponent,
        HopNavListComponent
    ],
    exports: [
        HopNavItemComponent,
        HopNavListComponent
    ]
})
export class HopNavModule {

}
