import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { DebitorState } from './application/debitor.state';

@NgModule({
    imports: [
        CommonModule,

        NgxsModule.forFeature([ DebitorState ])
    ]
})
export class HopDebitorDomainRootModule {
}
