import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'hop-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: [ './search-bar.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-search-bar'
    }
})
export class HopSearchBarComponent {

    @Input()
    placeholder: string = 'COMPONENTS.SEARCH_BAR.PLACEHOLDER';

    @Output()
    readonly searchChange = new EventEmitter<string>();

    readonly _icons = { faSearch };

    readonly form = new FormGroup({
        search: new FormControl()
    });

    @Input()
    get search(): string | undefined | null {
        return this.form.controls['search'].value;
    }

    set search(value: string | undefined | null) {
        if (this.search === value) {
            return;
        }

        this.form.setValue({ search: value ?? null });
    }

    onSubmit() {
        this.searchChange.emit(this.form.value.search);
    }
}
