export interface IDebitorAddress {
    name: string;
    address: string;
    zip: string;
    city: string;
    country: {
        name: string;
        shortName: string;
    };
}
