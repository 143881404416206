import { CdkRow } from '@angular/cdk/table';
import { ChangeDetectionStrategy, Component, Optional, ViewEncapsulation } from '@angular/core';

import { HopHighlightRecordDirective } from '../../../shared/directives/highlight-record.directive';

/** Data row template container that contains the cell outlet. Adds the right class and role. */
@Component({
    selector: 'hop-row',
    templateUrl: './row.component.html',
    styleUrls: [ './row.component.scss' ],
    // See note on CdkTable for explanation on why this uses the default change detection strategy.
    // tslint:disable-next-line:validate-decorators
    changeDetection: ChangeDetectionStrategy.Default,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'c-hop-row',
        role: 'row'
    },
    exportAs: 'hopRow',
    providers: [ { provide: CdkRow, useExisting: HopRow } ]
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class HopRow extends CdkRow {
    constructor(@Optional() readonly highlight?: HopHighlightRecordDirective) {
        super();
    }
}
