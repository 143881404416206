import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { UserPermission } from '@hopsteiner/shared/models';
import { HopDrawer } from '@hopsteiner/shared/overlays';
import { firstValueFrom } from 'rxjs';

import { HopDebitorDrawer } from '../../drawers/debitor/debitor.drawer';
import { BaseDebitorChooserComponent } from '../base-debitor-chooser.component';

@Component({
    selector: 'hop-env-debitor-picker',
    templateUrl: './debitor-picker.component.html',
    styleUrls: [ './debitor-picker.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-env-debitor-picker'
    }
})
export class HopDebitorPickerComponent extends BaseDebitorChooserComponent {

    readonly UserPermission = UserPermission;

    readonly _searchIcon = faSearch;
    readonly _closeIcon = faTimes;

    constructor(private readonly _drawer: HopDrawer) {
        super();
    }

    async openDebitorDrawer() {
        if (this.disabled) {
            return;
        }

        const debitor = await firstValueFrom(HopDebitorDrawer.open(this._drawer).afterClosed());

        if (debitor) {
            this.setActiveDebitor(debitor);
        }
    }
}
