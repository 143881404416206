import { Pipe, PipeTransform } from '@angular/core';

import { IChange } from '../models/change.interface';

@Pipe({
    name: 'hopHasChange'
})
export class HopHasChangePipe implements PipeTransform {

    transform(value: IChange): boolean {
        return value != null && value.new !== value.old;
    }

}
