<ngb-toast #ngbToast
           class="c-hop-toast"
           *ngFor="let toast of _toasts.toasts$ | async; trackBy: trackToastById"
           [autohide]="toast.config.autoHide!"
           [class]="toast.config.toastClass"
           [delay]="toast.config.duration!"
           (hidden)="toast.close()">
    <ng-template [ngTemplateOutlet]="toast.config.template ?? defaultContent"></ng-template>

    <ng-template #defaultContent>

        <fa-icon *ngIf="toast.config.icon as icon" [icon]="icon" class="c-hop-toast__icon"></fa-icon>

        <p class="c-hop-toast__message">{{ toast.config.message! | translate }}</p>

        <fa-icon (click)="ngbToast.hide()" [icon]="icons.faTimes" class="c-hop-toast__close"></fa-icon>

    </ng-template>
</ngb-toast>
