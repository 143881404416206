import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'li[hopNavItem]',
    templateUrl: './nav-item.component.html',
    styleUrls: [ './nav-item.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-nav-item nav-item'
    }
})
export class HopNavItemComponent {

}
