import { Language, UserPermission, UserRole, UserStatus } from '@hopsteiner/shared/models';

export interface IUser {
    uid: number;
    name: string;
    username: string;
    email: string;
    phone?: string;
    language: Language;
    role: UserRole;
    permissions: UserPermission[];
    status: UserStatus;
    createdAt: Date;
    userManagement: boolean;
}

export interface IDetailUser extends IUser {
    debitors?: { fullName: string; navisionId: string; assigned?: boolean; }[];
    permissions: UserPermission[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOverviewUser extends Pick<IUser, 'uid' | 'name' | 'email' | 'phone' | 'role' | 'status' | 'createdAt'> {
    debitors?: string;
}

export type User = IDetailUser | IOverviewUser;
