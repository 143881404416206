export enum UserPermission {

    /*************
     * CONTRACTS *
     *************/

    /**
     * View contracts
     * - see contracts overview page
     * - see contract detail page
     * - see contracts table on dashboard
     */
    VIEW_CONTRACTS = 'view-contracts',

    /**
     * Request a shipment
     * - see the "request shipment" button on contract detail page
     * - open the "request shipment" drawer
     */
    REQUEST_SHIPMENT = 'request-shipment',

    /**
     * View shipment requests
     * - see shipment requests on contract detail page
     * - see shipment requests on dashboard
     */
    VIEW_SHIPMENT_REQUESTS = 'view-shipment-requests',


    /*************
     * DEBITORS *
     *************/

    /**
     * View debitors
     * - see debitors overview page
     * - see debitor detail page
     * - see debitors table on dashboard
     */
    VIEW_DEBITORS = 'view-debitors',

    /**
     * Request change of a debitor
     * - button on debitor detail page
     * - open debitor edit page
     */
    REQUEST_DEBITOR_CHANGE = 'request-debitor-change',

    /**
     * View debitor change requests
     * - see debitor change requests on dashboard
     */
    VIEW_DEBITOR_CHANGE_REQUESTS = 'view-debitor-change-requests',


    /*********
     * USERS *
     *********/

    /**
     * View users
     * - see users overview page
     * - see user detail page
     * - see users table on dashboard
     */
    VIEW_USERS = 'view-users',

    /**
     * See a user's role
     * - column "role" on users overview page
     * - property "role" on user detail page
     * - input "role" on user edit page
     */
    SEE_USER_ROLE = 'see-user-role',

    /**
     * Create a user:
     * - see the "create user" button on users overview page
     * - see user create page
     */
    CREATE_USER = 'create-user',

    /**
     * Create a salesperson
     */
    CREATE_SALESPERSON = 'create-salesperson',

    /**
     * Create an admin
     */
    CREATE_ADMIN = 'create-admin',

    /**
     * Edit a user:
     * - see the "edit user" button on user detail page
     * - see the user edit page
     */
    EDIT_USER = 'edit-user',

    /**
     * View user change requests
     * - on the user detail page
     */
    VIEW_USER_CHANGE_REQUESTS = 'view-user-change-requests',

    /**
     * Delete a user:
     * - see the "delete user" button on user detail page
     * - see the "delete user" confirmation dialog
     */
    DELETE_USER = 'delete-user',

    /**
     * View user delete requests
     * - on the user detail page
     */
    VIEW_USER_DELETE_REQUESTS = 'view-user-delete-requests',


    /************
     * INVOICES *
     ************/

    /**
     * OPTIONAL
     *
     * View invoices
     * - see the invoices button on contract detail page
     * - see the invoices button on debitor detail page
     * - open the invoice drawer
     */
    VIEW_INVOICES = 'view-invoices',


    /**
     * OPTIONAL
     *
     * Download an invoice
     * - see the "download" menu option for invoices
     * - download the invoice PDF
     */
    DOWNLOAD_INVOICE = 'download-invoice',


    /*********
     * OTHER *
     *********/

    /**
     * See the contacts ("Ansprechpartner")
     * - see contacts on dashboard
     * - see contacts on contract detail page
     * - see contacts on debitor detail page
     */
    VIEW_CONTACTS = 'view-contacts',

    /**
     * Ability to deselect the active debitor (debitor switch)
     */
    DESELECT_ACTIVE_DEBITOR = 'deselect-active-debitor',

    /**
     * See the order request page
     * - request orders
     */
    VIEW_ORDER_REQUESTS = 'view-order-requests',

    /**
     * Create an order request
     */
    CREATE_ORDER_REQUESTS = 'create-order-requests',

    /*********
     * DEMO *
     *********/

    /**
     * See the example pages
     */
    VIEW_EXAMPLES = 'view-examples'
}
