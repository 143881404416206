import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { HopOptionComponent } from './components/option/option.component';
import { HopRadioGroupComponent } from './components/radio-group/radio-group.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    declarations: [
        HopOptionComponent,
        HopRadioGroupComponent
    ],
    exports: [
        HopOptionComponent,
        HopRadioGroupComponent
    ]
})
export class HopSelectionModule {
}
