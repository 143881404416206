import { ChangeDetectionStrategy, Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { BaseControlValueAccessorDirective } from '../../../form/directives/base-control-value-accessor.directive';

@Component({
    selector: 'hop-radio-group',
    templateUrl: './radio-group.component.html',
    styleUrls: [ './radio-group.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-radio-group',
        // eslint-disable-next-line @angular-eslint/no-host-metadata-property
        '[class.c-hop-radio-group--disabled]': 'disabled'
    },
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: HopRadioGroupComponent,
            multi: true
        }
    ]
})
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class HopRadioGroupComponent<T = any> extends BaseControlValueAccessorDirective<T> {

    @Output()
    readonly selection = new EventEmitter<T | undefined | null>();

    protected override _setDisabled(value: boolean) {
        super._setDisabled(value);
    }

    protected override _dispatchValueChange(newValue: T | undefined | null) {
        super._dispatchValueChange(newValue);

        this.selection.emit(newValue);
    }
}
