<hop-drawer>
    <hop-drawer-header>
        <h2 hopDrawerTitle id="hop-debitor-drawer-title">{{ 'ENVIRONMENT.DEBITOR_SWITCHER.DRAWER.TITLE' | translate }}</h2>

        <hop-search-bar id="hop-debitor-drawer-search-bar"
                        [placeholder]="'ENVIRONMENT.DEBITOR_SWITCHER.DRAWER.SEARCH_BAR.PLACEHOLDER' | translate"
                        (searchChange)="container.onSearchTermChanged($event)">
        </hop-search-bar>
    </hop-drawer-header>

    <hop-drawer-body>
        <ng-container *ngTemplateOutlet="container.isMobile ? null : recentDebitors"></ng-container>

        <hop-data-collection-container #container
                                       hopDataCollectionContainer
                                       hopInfiniteScroll
                                       [isMobile]="_isMobile$ | async"
                                       [dataSource]="_dataSource">
            <hop-env-debitor-table hopDesktopCollectionViewer
                                   (selectDebitor)="selectDebitor($event)"></hop-env-debitor-table>

            <hop-env-debitor-list hopMobileCollectionViewer (selectDebitor)="selectDebitor($event)">
                <ng-container *ngTemplateOutlet="recentDebitors"></ng-container>
                <p *ngIf="(_recentDebitors$ | async)?.length" class="c-hop-env-debitor-drawer__recent-debitors-notice">
                    {{ 'ENVIRONMENT.DEBITOR_SWITCHER.DRAWER.HINT.ALL_DEBITORS' | translate }}
                </p>
            </hop-env-debitor-list>
        </hop-data-collection-container>
    </hop-drawer-body>

</hop-drawer>


<ng-template #recentDebitors>
    <ng-container *ngIf="(_recentDebitors$ | async) as recentDebitors">
        <ng-container *ngIf="recentDebitors?.length">
            <p class="c-hop-env-debitor-drawer__recent-debitors-notice">
                {{ 'ENVIRONMENT.DEBITOR_SWITCHER.DRAWER.HINT.LAST_SELECTED_DEBITORS' | translate }}
            </p>
            <div class="c-hop-env-debitor-drawer__recent-debitors">
                <ng-container *ngFor="let debitor of recentDebitors; trackBy: _trackDebitorById">
                    <hop-env-debitor-card [debitor]="debitor"
                                          [hopHighlightRecord]="debitor.headquarters"
                                          [highlightLabel]="'DEBITOR.TABLE.COMPONENTS.HIGHLIGHTED.BADGE.HEADQUARTER' | translate"
                                          highlightColor="var(--bs-primary)"
                                          (click)="selectDebitor(debitor)">

                    </hop-env-debitor-card>
                </ng-container>
            </div>
        </ng-container>
    </ng-container>
</ng-template>
