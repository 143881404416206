/* eslint-disable @angular-eslint/directive-class-suffix,@angular-eslint/no-inputs-metadata-property */
import { CdkFooterRowDef, CdkHeaderRowDef, CdkRowDef } from '@angular/cdk/table';
import { Directive } from '@angular/core';

/**
 * Header row definition for the hop-table.
 * Captures the header row's template and other header properties such as the columns to display.
 */
@Directive({
    selector: '[hopHeaderRowDef]',
    providers: [ { provide: CdkHeaderRowDef, useExisting: HopHeaderRowDef } ],
    inputs: [ 'columns: hopHeaderRowDef', 'sticky: hopHeaderRowDefSticky' ]
})
export class HopHeaderRowDef extends CdkHeaderRowDef {
}

/**
 * Footer row definition for the hop-table.
 * Captures the footer row's template and other footer properties such as the columns to display.
 */
@Directive({
    selector: '[hopFooterRowDef]',
    providers: [ { provide: CdkFooterRowDef, useExisting: HopFooterRowDef } ],
    inputs: [ 'columns: hopFooterRowDef', 'sticky: hopFooterRowDefSticky' ]
})
export class HopFooterRowDef extends CdkFooterRowDef {
}

/**
 * Data row definition for the hop-table.
 * Captures the data row's template and other properties such as the columns to display and
 * a when predicate that describes when this row should be used.
 */
@Directive({
    selector: '[hopRowDef]',
    providers: [ { provide: CdkRowDef, useExisting: HopRowDef } ],
    inputs: [ 'columns: hopRowDefColumns', 'when: hopRowDefWhen' ]
})
export class HopRowDef<T> extends CdkRowDef<T> {
}

/*

/!**
 * A row to show when the data is loading
 *!/
@Directive({
    selector: '[hopLoadingRowDef]',
    providers: [ { provide: CdkRowDef, useExisting: HopRowDef } ]
})
export class HopLoadingRowDef<T> extends CdkRowDef<T> {
}

/!**
 A row to show when there is no data and no data is loading
 *!/
@Directive({
    selector: '[hopEmptyRowDef]',
    providers: [ { provide: CdkRowDef, useExisting: HopRowDef } ]
})
export class HopEmptyRowDef<T> extends CdkRowDef<T> {
}
*/
