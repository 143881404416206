import { ListRange } from '@angular/cdk/collections';
import { DataSource, IPaginatedData, ISortableQuery } from '@hopsteiner/shared/collections';
import { transformOptions, TransformOptionsFunction } from '@hopsteiner/shared/data-access';
import { from, Observable } from 'rxjs';

import { IDebitorAddress } from '../entities/address.interface';

import { DebitorRepository } from './debitor.repository';

export class DebitorAddressDataSource<QUERY = ISortableQuery> extends DataSource<IDebitorAddress, QUERY> {

    constructor(private readonly _debitorId: string,
                private readonly _repository: DebitorRepository,
                private readonly _createFindOptions: TransformOptionsFunction<QUERY> = transformOptions() as TransformOptionsFunction<QUERY>) {
        super();
    }

    fetch(listRange: ListRange | null, query: QUERY | null): Observable<IPaginatedData<IDebitorAddress>> {
        return from(this._repository.findAddresses(this._debitorId, this._createFindOptions(listRange, query)));
    }

}
