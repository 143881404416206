import { IDateRangeQuery } from '@hopsteiner/shared/filter';

export interface IContractFindQueryParams {
    query: string;
    cultivar: string;
    growingArea: string;
    product: string;
    harvestYear: string;
    fulfilled: boolean;
    completedAt: IDateRangeQuery;
    contractStatus: string;
    organicLabel: string;
}
