/* eslint-disable @angular-eslint/directive-class-suffix,@angular-eslint/no-inputs-metadata-property */
import { CdkColumnDef } from '@angular/cdk/table';
import { Directive, Input } from '@angular/core';

/**
 * Column definition for the hop-table.
 * Defines a set of cells available for a table column.
 */
@Directive({
    selector: '[hopColumnDef]',
    inputs: [ 'sticky', 'name: hopColumnDef' ],
    providers: [
        { provide: CdkColumnDef, useExisting: HopColumnDef }
    ]
})
export class HopColumnDef extends CdkColumnDef {

    /** Explicit width for this column. */
        // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('hopColumnWidth')
    width?: string | null;

    /** Unique name for this column. */
    @Input('hopColumnDef')
    override get name(): string {
        return this._name;
    }

    override set name(name: string) {
        this._setNameInput(name);
    }

    /**
     * Add "c-hop-column-" prefix in addition to "cdk-column-" prefix.
     * In the future, this will only add "c-hop-column-" and columnCssClassName
     * will change from type string[] to string.
     * @docs-private
     */
    protected override _updateColumnCssClassName() {
        super._updateColumnCssClassName();
        this._columnCssClassName?.push(`c-hop-column-${this.cssClassFriendlyName}`);
    }
}
