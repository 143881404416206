import { coerceNumberProperty } from '@angular/cdk/coercion';
import { Directive, ElementRef, Input, NgModule, NgZone, OnInit } from '@angular/core';
import { InfiniteScrollDirective, InfiniteScrollModule } from 'ngx-infinite-scroll';

import { HopDataCollectionContainerDirective } from './data-collection-container.directive';

@Directive({
    selector: '[hopInfiniteScroll]'
})
export class HopInfiniteScrollDirective extends InfiniteScrollDirective implements OnInit {

    private _start: number = 0;
    private _pageSize: number = 10;

    constructor(
        elementRef: ElementRef,
        zone: NgZone,
        private readonly _host: HopDataCollectionContainerDirective
    ) {
        super(elementRef, zone);
    }

    @Input()
    get pageSize(): number {
        return this._pageSize;
    }

    set pageSize(value: string | number | null | undefined) {
        const coerced = coerceNumberProperty(value, 10);

        if (this._pageSize === coerced) {
            return;
        }

        this._pageSize = coerced;
    }

    ngOnInit() {
        this.scrollWindow = false;
        this.infiniteScrollContainer = '[hopScrollContainer]';
        this.scrolled.subscribe(() => this.onScroll());
    }

    onScroll() {
        this._host._collectionViewer?.nextPage();
    }
}

@NgModule({
    imports: [ InfiniteScrollModule ],
    declarations: [ HopInfiniteScrollDirective ],
    exports: [ HopInfiniteScrollDirective ]
})
export class HopInfiniteScrollDirectiveModule {

}
