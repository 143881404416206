import { ChangeDetectionStrategy, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { DataSource } from '@hopsteiner/shared/collections';
import { SwiperComponent } from 'swiper/angular';

import { IDataCollectionViewer } from '../../../shared/models/data-collection-viewer.interface';
import { BaseDataListComponent } from '../base-data-list.component';

@Component({
    selector: 'hop-data-carousel',
    templateUrl: './data-carousel.component.html',
    styleUrls: [ './data-carousel.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-data-carousel'
    }
})
export class HopDataCarouselComponent<T, QUERY = unknown> extends BaseDataListComponent<T, QUERY> implements IDataCollectionViewer<T, QUERY> {

    @ViewChild('swiper', { static: true })
    swiper!: SwiperComponent;

    setDataSource(dataSource: DataSource<T, QUERY>) {
        this.dataSource = dataSource;
    }

    setQuery(query: QUERY) {
        this.query = query;
    }

    initCollectionViewer(dataSource: DataSource<T, QUERY>, query: QUERY) {
        this.dataSource = dataSource;
        this.query = query;
    }

    protected override _onLoadingChanged(loading: boolean) {
        if (loading) {
            return;
        }

        if (this.swiper?.swiperRef && this.swiper.swiperRef.activeIndex !== 0) {
            this.swiper.swiperRef.slideTo(0);
        }
    }

}
