import { HttpClient } from '@angular/common/http';

import { IFindOptions } from '../models/find-options.interface';
import { IPaginated } from '../models/paginated.interface';
import { IRepositoryOptions } from '../models/repository-options.interface';

import { BaseRepository } from './base-repository';

export abstract class Repository<T, OPTIONS extends IRepositoryOptions = IRepositoryOptions, FIND_OPTIONS extends object = IFindOptions, GET_OPTIONS extends object = object>
    extends BaseRepository<T, OPTIONS, FIND_OPTIONS, GET_OPTIONS> {

    constructor(httpClient: HttpClient, options: OPTIONS) {
        super(httpClient, options);
    }

    find(options?: FIND_OPTIONS): Promise<IPaginated<T>> {
        return this._find(options);
    }

    get(id: string, options?: GET_OPTIONS): Promise<T> {
        return this._get(id, options);
    }
}

export abstract class UnpaginatedRepository<T, OPTIONS extends IRepositoryOptions = IRepositoryOptions, FIND_OPTIONS extends object = IFindOptions, GET_OPTIONS extends object = object>
    extends BaseRepository<T, OPTIONS, FIND_OPTIONS, GET_OPTIONS> {

    constructor(httpClient: HttpClient, options: OPTIONS) {
        super(httpClient, options);
    }

    find(options?: FIND_OPTIONS): Promise<T[]> {
        return this._find(options);
    }

    get(id: string, options?: GET_OPTIONS): Promise<T> {
        return this._get(id, options);
    }
}
