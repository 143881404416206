import { ListRange } from '@angular/cdk/collections';

import { LoadingEventType } from './loading-event-type.enum';

/**
 * Represents the start or end of a loading phase
 */
export interface ILoadingEvent<QUERY = unknown> {

    type: LoadingEventType.START | LoadingEventType.END;

    /**
     * The range of items affected by this event
     */
    listRange: ListRange | null;

    /**
     * The query used for loading the items
     */
    query: QUERY | null;

    /**
     * The auto-incremented index / identifier of the event. Used to match start and end of a loading phase
     */
    index: number;
}

/**
 * Resets loading completely (e.g. for when filters change)
 */
export interface IResetLoadingEvent {

    type: LoadingEventType.RESET;
}

export type LoadingEvent<QUERY> = ILoadingEvent<QUERY> | IResetLoadingEvent;
