import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { HopDebitorDomainRootModule } from '@hopsteiner/debitor/domain';

@NgModule({
    imports: [ CommonModule ]
})
export class DebitorApiModule {
}

@NgModule({})
export class HopDebitorApiModule {

    static forRoot(): ModuleWithProviders<HopDebitorApiRootModule> {
        return {
            ngModule: HopDebitorApiRootModule,
            providers: []
        };
    }
}

@NgModule({
    imports: [
        HopDebitorDomainRootModule,
        HopDebitorApiModule
    ],
    exports: [
        HopDebitorApiModule
    ]
})
export class HopDebitorApiRootModule {

}
