import { ListRange } from '@angular/cdk/collections';
import { Observable, Subject } from 'rxjs';

import { Connection } from './connection.class';


export interface IReadonlyCollectionViewer<T, QUERY = unknown> {
    viewChange: Observable<ListRange>;
    queryChange?: Observable<QUERY | null>;
    connection?: Connection<T, QUERY>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ICollectionViewer<T, QUERY = any> extends IReadonlyCollectionViewer<T, QUERY> {
    viewChange: Subject<ListRange>;
    queryChange?: Subject<QUERY | null>;
    total$: Observable<number>;
}
