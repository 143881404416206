import { IEditDebitorData } from '../models/edit-debitor-data.interface';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace EditDebitorActions {

    export class EditDebitor {
        static readonly type = '[Debitor] Edit Debitor';

        constructor(readonly debitorId: string, readonly editDebitorData: IEditDebitorData) {
        }
    }
}
