export interface IPaginated<T> {
    /**
     * The number of elements skipped. Aka the start index
     */
    skip: number;

    /**
     * The maximum number of elements in this response. Aka the page size
     */
    limit: number;

    /**
     * The total number of elements matching the query
     */
    total: number;

    /**
     * The actual elements
     */
    data: T[];
}
