import { HopOverlayConfig } from '../../shared/models/overlay-config';

import { DrawerPosition } from './drawer-position.enum';


export class HopDrawerConfig<DATA = unknown> extends HopOverlayConfig<DATA> {

    /** Position overrides. */
    position?: DrawerPosition = DrawerPosition.END;

    showCloseButton?: boolean = false;

    constructor() {
        super();

        this.autoFocus = 'overlay';
        this.height = '100%';
    }
}
