import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { DebitorSwitcherState } from './application/debitor-switcher.state';
import { EnvironmentState } from './application/environment.state';
import { SessionInterceptor } from './infrastructure/session.interceptor';
import { DEBITOR_SWITCHER_TAB_LIST_MAX_COUNT } from './tokens/debitor-switcher-tab-list-max-count.token';

@NgModule({
    imports: [
        CommonModule,

        NgxsModule.forFeature([ EnvironmentState, DebitorSwitcherState ])
    ],
    providers: [
        {
            provide: DEBITOR_SWITCHER_TAB_LIST_MAX_COUNT,
            useValue: 3
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SessionInterceptor,
            multi: true
        }
    ]
})
export class HopEnvironmentDomainRootModule {

}
