import { ElementRef, Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ISort } from '@hopsteiner/shared/collections';
import { FilterResult, IFilterDrawerResult } from '@hopsteiner/shared/filter';
import { Store } from '@ngxs/store';
import { size, uniqueId } from 'lodash';
import { firstValueFrom } from 'rxjs';

import { IDataCollectionContainerFacade } from '../../data-collection-container/models/data-collection-container-facade.interface';
import { IOverviewLayoutParams } from '../models/overview-layout-params.interface';
import { IQueryParamsTransformer } from '../models/query-params-transformer.interface';
import { OverviewLayoutActions } from '../state/overview-layout.actions';
import { OverviewLayoutState } from '../state/overview-layout.state';
import { QUERY_PARAMS_TRANSFORMER } from '../tokens/query-params-transformer.token';


@Injectable()
export class OverviewLayoutFacade implements IDataCollectionContainerFacade {

    private readonly _name: string;

    constructor(elementRef: ElementRef,
                private readonly _store: Store,
                private readonly _activatedRoute: ActivatedRoute,
                private readonly _router: Router,
                @Inject(QUERY_PARAMS_TRANSFORMER) private readonly _queryParamsTransformer: IQueryParamsTransformer<IOverviewLayoutParams>) {
        this._name = (elementRef.nativeElement as HTMLElement).getAttribute('name') ?? uniqueId('overviewLayout');

        const queryParams: IOverviewLayoutParams = this._queryParamsTransformer.fromQueryParams(_activatedRoute.snapshot.queryParams);

        if (size(queryParams) > 0) {
            this._store.dispatch(new OverviewLayoutActions.SetSearchTerm(this._name, queryParams.search));
            this._store.dispatch(new OverviewLayoutActions.SetFilterResult(this._name, queryParams.filter));
            this._store.dispatch(new OverviewLayoutActions.SetSort(this._name, queryParams.sort));
            this._store.dispatch(new OverviewLayoutActions.SetPage(this._name, queryParams.page ?? 0));
        } else {
            void this._updateQueryParams();
        }
    }

    get searchTerm$() {
        return this._store.select(OverviewLayoutState.searchTerm(this._name));
    }

    get filterResult$() {
        return this._store.select(OverviewLayoutState.filterResult(this._name));
    }

    get query() {
        return this._store.selectSnapshot(OverviewLayoutState.query(this._name));
    }

    get query$() {
        return this._store.select(OverviewLayoutState.query(this._name));
    }

    get sort() {
        return this._store.selectSnapshot(OverviewLayoutState.sort(this._name));
    }

    get page$() {
        return this._store.select(OverviewLayoutState.page(this._name));
    }

    async setSearchTerm(searchTerm: string) {
        await firstValueFrom(this._store.dispatch(new OverviewLayoutActions.SetSearchTerm(this._name, searchTerm)));

        await this._updateQueryParams();
    }

    async setFilterResult(filterResult: IFilterDrawerResult<FilterResult>) {
        await firstValueFrom(this._store.dispatch([
            new OverviewLayoutActions.SetFilterResult(this._name, filterResult),
            new OverviewLayoutActions.SetPage(this._name, 0)
        ]));

        await this._updateQueryParams();
    }

    async setSort(sort: ISort) {
        await firstValueFrom(this._store.dispatch(new OverviewLayoutActions.SetSort(this._name, sort)));

        await this._updateQueryParams();
    }

    async setPage(page: number) {
        await firstValueFrom(this._store.dispatch(new OverviewLayoutActions.SetPage(this._name, page)));

        await this._updateQueryParams();
    }

    private async _updateQueryParams() {
        const snapshot = this._store.selectSnapshot(OverviewLayoutState.feature(this._name));

        if (!snapshot) {
            return;
        }

        const { searchTerm, filter, sort, page } = snapshot;

        await this._router.navigate([], {
            queryParams: this._queryParamsTransformer.toQueryParams({
                search: searchTerm,
                filter,
                sort,
                page
            })
        });
    }
}
