import { ChangeDetectionStrategy, Component, ContentChildren, QueryList, ViewEncapsulation } from '@angular/core';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { HopSlideDirective } from '../../directives/slide.directive';


@Component({
    selector: 'hop-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: [ './carousel.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-carousel'
    }
})
export class HopCarouselComponent {

    @ContentChildren(HopSlideDirective)
    slides!: QueryList<HopSlideDirective>;

    readonly _icons = {
        faChevronLeft,
        faChevronRight
    };
}
