import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ENTER, SPACE } from '@angular/cdk/keycodes';
import { CdkColumnDef } from '@angular/cdk/table';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, HostListener, Input, OnDestroy, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { ExplicitSortDirection, SortDirection } from '@hopsteiner/shared/collection-models';
import { Subject, takeUntil } from 'rxjs';

import { HopSortDirective } from '../../directives/sort.directive';
import { ISortable } from '../../models/sortable.interface';


@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[hopSortHeader]',
    templateUrl: './sort-header.component.html',
    styleUrls: [ './sort-header.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-sort-header'
    }
})
export class HopSortHeaderComponent implements ISortable, OnInit, OnDestroy {

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('hopSortHeader')
    id!: string;

    @Input()
    initialSortDirection: ExplicitSortDirection = SortDirection.ASC;

    readonly _icons = {
        faSort,
        faSortUp,
        faSortDown
    };

    private _disableClear: boolean = false;
    private _disabled: boolean = false;

    private readonly _onDestroy = new Subject<void>();

    constructor(@Optional() readonly _columnDef: CdkColumnDef,
                private readonly _sort: HopSortDirective,
                private readonly _changeDetectorRef: ChangeDetectorRef) {
    }

    @Input()
    get disableClear(): boolean {
        return this._disableClear;
    }

    set disableClear(value: boolean) {
        this._disableClear = coerceBooleanProperty(value);
    }

    @Input()
    @HostBinding('class.c-hop-sort-header--disabled')
    get disabled(): boolean {
        return this._sort.disabled || this._disabled;
    }

    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
    }

    get direction(): SortDirection | null | undefined {
        return this._sort.activeSortableId == this.id ? this._sort.direction : null;
    }

    get isSorted() {
        return this.direction != null;
    }

    @HostBinding('attr.aria-sort')
    get ariaSortAttribute() {
        if (!this.isSorted) {
            return 'none';
        }

        return this.direction == SortDirection.ASC ? 'ascending' : 'descending';
    }


    ngOnInit() {
        if (!this.id && this._columnDef) {
            this.id = this._columnDef.name;
        }

        this._sort.sortChange
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => this._changeDetectorRef.markForCheck());
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    onClick() {
        if (this.disabled) {
            return;
        }

        this._toggleSortDirection();
    }

    @HostListener('keydown', [ '$event' ])
    onKeydown($event: KeyboardEvent) {
        if (this.disabled || ($event.keyCode !== SPACE && $event.keyCode !== ENTER)) {
            return;
        }

        $event.preventDefault();
        this._toggleSortDirection();
    }

    private _toggleSortDirection() {
        this._sort.sort(this);
    }

}
