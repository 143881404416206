import { Injectable } from '@angular/core';
import { UserPermission } from '@hopsteiner/shared/models';
import { Store } from '@ngxs/store';

import { AuthActions } from '../actions/auth.actions';
import { AuthState } from '../auth.state';

@Injectable({ providedIn: 'root' })
export class HopAuthStateFacade {

    constructor(private readonly _store: Store) {

    }

    get isAuthenticated() {
        return this._store.selectSnapshot(AuthState.selectIsAuthenticated);
    }

    get isAuthenticated$() {
        return this._store.select(AuthState.selectIsAuthenticated);
    }

    get activeUser$() {
        return this._store.select(AuthState.selectActiveUser);
    }

    get permissions$() {
        return this._store.select(AuthState.selectPermissions);
    }

    updateActiveUser() {
        return this._store.dispatch(new AuthActions.UpdateActiveUser());
    }

    hasPermission(permission: UserPermission) {
        return this._store.select(AuthState.hasPermission(permission));
    }

    hasPermissions(permissions: UserPermission[]) {
        return this._store.select(AuthState.hasPermissions(permissions));
    }
}
