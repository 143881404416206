import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

import { KeyValueTableAppearance, KeyValueTableAppearanceType } from '../../models/key-value-table-appearance.enum';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'table[hopKeyValueTable]',
    templateUrl: './key-value-table.component.html',
    styleUrls: [ './key-value-table.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-key-value-table table table-striped table-borderless'
    }
})
export class HopKeyValueTableComponent {

    @Input()
    appearance: KeyValueTableAppearanceType = KeyValueTableAppearance.DEFAULT;

    @Input()
    set hopKeyValueTable(value: KeyValueTableAppearanceType | '') {
        this.appearance = value || KeyValueTableAppearance.DEFAULT;
    }

    get isDefault(): boolean {
        return this.appearance == KeyValueTableAppearance.DEFAULT;
    }

    @HostBinding('class.c-hop-key-value-table--transparent')
    get isTransparent(): boolean {
        return this.appearance == KeyValueTableAppearance.TRANSPARENT;
    }
}
