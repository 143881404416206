import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, Input, OnInit, QueryList, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH';
import { Breakpoint, Breakpoints } from '@hopsteiner/shared/breakpoints';
import { DataSource, ISortableQuery } from '@hopsteiner/shared/collections';
import { NgbDropdownItem } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, map, Observable, of, startWith, switchAll, switchMap, takeUntil } from 'rxjs';

import { HopDataCollectionContainerDirective } from '../../../data-collection-container/directives/data-collection-container.directive';
import { HopDataCollectionContainerFacade } from '../../../data-collection-container/facades/data-collection-container.facade';
import { OverviewLayoutFacade } from '../../facades/overview-layout.facade';


@Component({
    selector: 'hop-overview-layout',
    templateUrl: './overview.layout.html',
    styleUrls: [ './overview.layout.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: [ 'name' ],
    host: {
        class: 'c-hop-overview-layout'
    },
    providers: [
        OverviewLayoutFacade,
        { provide: HopDataCollectionContainerFacade, useExisting: OverviewLayoutFacade },
        { provide: HopDataCollectionContainerDirective, useExisting: HopOverviewLayout }
    ]
})
export class HopOverviewLayout<T = unknown> extends HopDataCollectionContainerDirective<T> implements OnInit, AfterContentInit {

    private _showSearchBar: boolean = true;

    @Input()
    get showSearchBar(): boolean {
        return this._showSearchBar;
    }

    set showSearchBar(value: BooleanInput) {
        this._showSearchBar = coerceBooleanProperty(value);
    }

    @ContentChildren(NgbDropdownItem)
    compactMenuItems!: QueryList<NgbDropdownItem>;

    readonly _overflowIcon = faEllipsisH;
    readonly _isMobile$: Observable<boolean>;
    readonly _showCompactMenu$: Observable<boolean>;
    readonly _showFullMenu$: Observable<boolean>;

    private readonly _compactMenuItemCounts = new BehaviorSubject<Observable<number>>(of(0));

    constructor(router: Router,
                activatedRoute: ActivatedRoute,
                overviewLayoutFacade: OverviewLayoutFacade,
                changeDetectorRef: ChangeDetectorRef,
                private readonly _breakpoints: Breakpoints) {
        super(router, activatedRoute, changeDetectorRef, overviewLayoutFacade);
        this._isMobile$ = _breakpoints.observe(Breakpoints.down(Breakpoint.SM)).pipe(
            map((breakpoint) => breakpoint.matches)
        );

        this._showFullMenu$ = this._breakpoints.observeMatched(Breakpoints.up(Breakpoint.LG));
        this._showCompactMenu$ = this._breakpoints.observeMatched(Breakpoints.down(Breakpoint.LG))
            .pipe(
                switchMap((breakpointMatched) => breakpointMatched
                    ? this._compactMenuItemCounts.pipe(switchAll(), map((itemCount) => itemCount > 0))
                    : of(false))
            );
    }

    override ngOnInit() {
        super.ngOnInit();

        this._isMobile$
            .pipe(takeUntil(this._onDestroy))
            .subscribe((isMobile) => this.isMobile = isMobile);
    }

    protected override _switchDataSource(dataSource: DataSource<T, ISortableQuery> | null | undefined) {
        super._switchDataSource(dataSource?.infinite());
    }

    showPage(page: number) {
        this._facade.setPage(page);
    }

    override ngAfterContentInit() {
        super.ngAfterContentInit();

        this._compactMenuItemCounts.next(
            this.compactMenuItems.changes.pipe(
                map(() => this.compactMenuItems.length),
                startWith(this.compactMenuItems.length)
            )
        );
    }
}
