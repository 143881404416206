import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';

import { IActiveUser } from '../../entities/active-user.interface';
import { LoginActions } from '../actions/login.actions';
import { ILogInCredentials } from '../models/log-in-credentials.interface';

@Injectable({
    providedIn: 'root'
})
export class HopLoginFacade {

    constructor(private readonly _store: Store) {

    }

    logIn(credentials: ILogInCredentials): Promise<IActiveUser> {
        return firstValueFrom(this._store.dispatch(new LoginActions.LogIn(credentials)));
    }

    silentLogIn() {
        return this._store.dispatch(new LoginActions.SilentLogIn());
    }
}
