import { ISort, ISortableQuery } from '@hopsteiner/shared/collections';
import { FilterResult, IFilterDrawerResult } from '@hopsteiner/shared/filter';
import { Observable } from 'rxjs';

export interface IDataCollectionContainerFacade {
    readonly searchTerm$: Observable<string | undefined>;
    readonly filterResult$: Observable<IFilterDrawerResult<FilterResult> | undefined>;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    readonly query: ISortableQuery<any, string>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    readonly query$: Observable<ISortableQuery<any, string>>;
    readonly sort: ISort<string> | undefined;
    readonly page$: Observable<number>;

    setSearchTerm(searchTerm: string): Promise<void>;

    setFilterResult(filterResult: IFilterDrawerResult<FilterResult>): Promise<void>;

    setSort(sort: ISort): Promise<void>;

    setPage(page: number): Promise<void>;
}
