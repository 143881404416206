<ngb-datepicker [formControl]="formControl"
                [dayTemplate]="dateTemplate"
                [displayMonths]="2"
                [maxDate]="maxDate"
                [minDate]="minDate"
                outsideDays="collapsed"
                (dateSelect)="onDateSelection($event)"
                (mouseleave)="setHoveredDate(null)">
</ngb-datepicker>

<ng-template #dateTemplate let-date>
  <span class="c-hop-date-range-filter__day"
        [class.c-hop-date-range-filter__day--highlighted]="date | isEdgeOfRange:startDate:endDate"
        [class.c-hop-date-range-filter__day--muted]="date | isWithinRange:startDate:endDate || hoveredDate"
        (mouseenter)="setHoveredDate(date)">
    {{ date.day }}
  </span>
</ng-template>
