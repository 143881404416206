import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import { IShipment } from '@hopsteiner/contract/domain';
import { HOP_DRAWER_DATA, HopDrawer } from '@hopsteiner/shared/overlays';

import { IShipmentDrawerData } from '../../models/shipment-drawer-data.interface';

@Component({
    selector: 'hop-shipment-drawer',
    templateUrl: './shipment.drawer.html',
    styleUrls: [ './shipment.drawer.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-shipment-drawer'
    }
})
export class HopShipmentDrawer {

    readonly shipment: IShipment;

    static open(drawer: HopDrawer, shipment: IShipment) {
        return drawer.open<HopShipmentDrawer, void>(HopShipmentDrawer, {
            data: {
                shipment
            } as IShipmentDrawerData,
            width: '40rem'
        });
    }

    constructor(@Inject(HOP_DRAWER_DATA) data: IShipmentDrawerData) {
        this.shipment = data.shipment;
    }

}
