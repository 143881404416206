import { ListRange } from '@angular/cdk/collections';
import { isEqual as _isEqual } from 'lodash';
import { combineLatest, concatAll, distinctUntilChanged, map, Observable, of, scan, share, shareReplay, Subject, switchAll, switchMap, takeUntil, tap } from 'rxjs';

import { CdkDataSourceShim } from '../utils/cdk-data-source-shim';

import { IReadonlyCollectionViewer } from './collection-viewer.interface';
import { Connection } from './connection.class';
import { IDataSource } from './data-source.interface';
import { LoadingEventType } from './loading-event-type.enum';
import { LoadingEvent } from './loading-event.interface';
import { IPaginatedData } from './paginated-data.interface';

/**
 * Provides data for use in the ui, e.g. inside a table or list
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export abstract class DataSource<T, QUERY = any> implements IDataSource<T, QUERY> {

    private _cdkDataSource?: CdkDataSourceShim<T, QUERY>;

    /**
     * Connects a {@linkplain IReadonlyCollectionViewer collection viewer}, e.g. a table or list to this data source
     *
     * @param collectionViewer the component that will display the data
     */
    connect(collectionViewer: IReadonlyCollectionViewer<T, QUERY>): Connection<T, QUERY> {

        const loadingSubject = new Subject<LoadingEvent<QUERY>>();
        const onDisconnectSubject = new Subject<void>();
        let index = 0;

        const viewChange$: Observable<ListRange> = collectionViewer.viewChange.pipe(distinctUntilChanged(_isEqual));
        const queryChange$: Observable<QUERY | null> = collectionViewer.queryChange ?? of(null);
        const paginated$: Observable<IPaginatedData<T>> = combineLatest([ viewChange$, queryChange$ ]).pipe(
            map(([ listRange, query ]) => {
                const currentIndex = index++;

                loadingSubject.next({
                    type: LoadingEventType.START,
                    listRange,
                    query,
                    index: currentIndex
                });

                return this.fetch(listRange, query)
                    .pipe(
                        tap(() => {
                            loadingSubject.next({
                                type: LoadingEventType.END,
                                listRange,
                                query,
                                index: currentIndex
                            });
                        }),
                        share()
                    );
            }),
            switchAll(),
            takeUntil(onDisconnectSubject),
            shareReplay(1)
        );

        const loading$ = loadingSubject.pipe(takeUntil(onDisconnectSubject));

        return new Connection<T, QUERY>(
            paginated$,
            loading$,
            () => {
                onDisconnectSubject.next();
                onDisconnectSubject.complete();
                loadingSubject.unsubscribe();
            }
        );
    }

    /**
     * Fetches a new segment of data
     *
     * @param listRange the optional range to fetch
     * @param query an optional query to apply
     */
    abstract fetch(listRange: ListRange | null, query: QUERY | null): Observable<IPaginatedData<T>>;

    toCdkDataSource() {
        if (!this._cdkDataSource) {
            this._cdkDataSource = new CdkDataSourceShim(this);
        }

        return this._cdkDataSource;
    }

    /**
     * Wraps this DataSource in an {@link InfiniteDataSource} for infinite scrolling
     */
    infinite(): InfiniteDataSource<T, QUERY> {
        return InfiniteDataSource.create(this);
    }
}

export class InfiniteDataSource<T, QUERY = unknown> extends DataSource<T, QUERY> {

    static create<S, Q>(dataSource: DataSource<S, Q>): InfiniteDataSource<S, Q> {
        return dataSource instanceof InfiniteDataSource ? dataSource as InfiniteDataSource<S, Q> : new InfiniteDataSource(dataSource);
    }

    private constructor(private readonly _dataSource: DataSource<T>) {
        super();
    }

    override connect(collectionViewer: IReadonlyCollectionViewer<T, QUERY>): Connection<T, QUERY> {
        const loadingSubject = new Subject<LoadingEvent<QUERY>>();
        const onDisconnectSubject = new Subject<void>();
        let index = 0;

        const queryChange$: Observable<QUERY | null> = collectionViewer.queryChange ?? of(null);
        const paginated$: Observable<IPaginatedData<T>> = queryChange$.pipe(
            distinctUntilChanged(_isEqual),
            switchMap((query: QUERY | null) => {
                loadingSubject.next({ type: LoadingEventType.RESET });
                index = 0;

                return collectionViewer.viewChange.pipe(
                    distinctUntilChanged((a, b) => a.start === b.start && a.end === b.end),
                    map((listRange) => {
                        const currentIndex = index++;

                        loadingSubject.next({ type: LoadingEventType.START, listRange, query, index: currentIndex });

                        return this.fetch(listRange, query)
                            .pipe(
                                share(),
                                tap(() => {
                                    loadingSubject.next({ type: LoadingEventType.END, listRange, query, index: currentIndex });
                                })
                            );
                    }),
                    concatAll(),
                    scan((result: IPaginatedData<T>, paginated: IPaginatedData<T>) => {
                        const data = result.data;

                        data.splice(paginated.skip, paginated.skip + paginated.limit, ...paginated.data);

                        return {
                            skip: 0,
                            limit: data.length,
                            total: paginated.total,
                            data
                        };
                    }, { skip: 0, limit: 0, total: 0, data: [] })
                );
            }),
            takeUntil(onDisconnectSubject),
            shareReplay(1)
        );

        const loading$: Observable<LoadingEvent<QUERY>> = loadingSubject.pipe(
            takeUntil(onDisconnectSubject)
        );

        return new Connection<T, QUERY>(
            paginated$,
            loading$,
            () => {
                onDisconnectSubject.next();
                onDisconnectSubject.complete();
                loadingSubject.unsubscribe();
            }
        );
    }

    override fetch(listRange: ListRange | null, query: QUERY | null): Observable<IPaginatedData<T>> {
        return this._dataSource.fetch(listRange, query);
    }

    override infinite(): InfiniteDataSource<T, QUERY> {
        return this;
    }
}
