export enum SortDirection {
    /**
     * Ascending
     */
    ASC = 'asc',

    /**
     * Descending
     */
    DESC = 'desc',

    /**
     * No sort / default behaviour
     */
    NONE = ''
}

export type ExplicitSortDirection = SortDirection.ASC | SortDirection.DESC;
