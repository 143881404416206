import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';

import { HopDetailLayoutToolbarItemDirective } from './directives/detail-layout-toolbar-item.directive';
import { HopDetailLayout } from './layouts/detail/detail.layout';


@NgModule({
    imports: [
        CommonModule,

        FontAwesomeModule,
        TranslateModule
    ],
    declarations: [
        HopDetailLayout,

        HopDetailLayoutToolbarItemDirective
    ],
    exports: [
        HopDetailLayout,

        HopDetailLayoutToolbarItemDirective
    ]
})
export class HopDetailLayoutModule {

}
