interface IBaseDebitor {
    navisionId: string;
    navisionTimestamp: number;
    name: string;
    fullName: string;
    additionalName?: string;
    address?: string;
    additionalAddress?: string;
    zip?: string;
    city?: string;

    /**
     * ISO 3166-1 alpha-2 country code
     */
    country: {
        name: string;
        shortName: string;
    };
    vatNumber?: string;
    phone?: string;
    email?: string;
    homepage?: string;
    beerOutputInHectoliters: number;
    gramAlphaPerHectoLiter: number;
    displayBeerOutputInHectoliters: number;
    displayGramAlphaPerHectoLiter: number;
    headquarters: boolean;
}

export interface IDebitor extends IBaseDebitor {
    /**
     * The ID of the article
     */
    parent?: string;
    salesPerson?: string;
}

export interface IDetailDebitor extends IBaseDebitor {
    /**
     * The article
     */
    parent?: IBaseDebitor;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOverviewDebitor extends Pick<IBaseDebitor, 'navisionId' | 'name' | 'fullName' | 'zip' | 'city' | 'country' | 'displayBeerOutputInHectoliters' | 'displayGramAlphaPerHectoLiter' | 'beerOutputInHectoliters' | 'gramAlphaPerHectoLiter' | 'headquarters'> {

}

export type Debitor = IDebitor | IDetailDebitor | IOverviewDebitor;

export type ApiDebitor<T extends Debitor = Debitor> = T & {
    beerOutputInHectoliters: string;
    gramAlphaPerHectoLiter: string;
}
