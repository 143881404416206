import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { INITIAL_LIST_RANGE } from '@hopsteiner/shared/collections';
import { includes as _includes, without as _without } from 'lodash';

import { FilterType } from '../../models/filter-type.enum';
import { BaseSelectFilterComponent } from '../base-select-filter.component';

@Component({
    selector: 'hop-multi-select-filter',
    templateUrl: './multi-select-filter.component.html',
    styleUrls: [ './multi-select-filter.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: HopMultiSelectFilterComponent,
            multi: true
        },
        {
            provide: INITIAL_LIST_RANGE,
            useValue: { start: 0, end: 50 }
        }
    ],
    host: {
        class: 'c-hop-multi-select-filter'
    }
})
export class HopMultiSelectFilterComponent<T = unknown, OPTION = T> extends BaseSelectFilterComponent<FilterType.MULTI_SELECT, T[], T> {

    toggleOption(option: T) {
        const value = this.getOptionValue(option);

        if (this.isValueSelected(value)) {
            this.value = _without(this.value, value);
        } else {
            this.value = this.value ? [ ...this.value, value ] : [ value ];
        }
    }

    isSelected(option: T) {
        return this.isValueSelected(this.getOptionValue(option));
    }

    isValueSelected(value: T) {
        return _includes(this.value, value);
    }

    getOptionValue(option: OPTION | T): T {
        if (this.filter?.getOptionValue) {
            return this.filter.getOptionValue(option);
        }

        return option as unknown as T;
    }
}
