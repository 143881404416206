import { ICultivar, IProduct } from '@hopsteiner/contract/api';

import { IQuantityUnit } from './quantity-unit.interface';

export interface ICreateOrderRequestOptions {
    products: IProduct[];
    cultivars: ICultivar[];
    harvestYears: number[];
    quantityUnits: IQuantityUnit[];
}
