<input id="option-{{name}}"
       class="btn-check"
       type="radio"
       autocomplete="off"
       [(ngModel)]="checked"
       [value]="value"
       [disabled]="disabled" />
<label class="c-hop-option__content" for="option-{{name}}" role="button">
    <ng-content></ng-content>
</label>
