import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[hopListItem]',
    templateUrl: './list-item.component.html',
    styleUrls: [ './list-item.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-list-item list-group-item list-group-item-action'
    }
})
export class HopListItemComponent {

    readonly _icon = faChevronRight;

}
