import { Overlay } from '@angular/cdk/overlay';
import { inject, InjectionToken } from '@angular/core';

export const OVERLAY_SCROLL_STRATEGY = new InjectionToken('@hopsteiner/shared/overlays.overlay-scroll-strategy');

export function overlayScrollStrategyFactory() {
    const overlay = inject(Overlay);

    return overlay.scrollStrategies.block();
}
