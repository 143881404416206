<ng-container [ngSwitch]="stateChange | async">

    <ng-template ngSwitchCase="loading">
        <ng-content select="[hopLceLoading]"></ng-content>

        <div class="c-hop-lce-layout__default-wrapper" *ngIf="!customLoading">
            <div *ngIf="!customLoading" class="spinner-border spinner-border-sm u-center" role="status">
                <span class="visually-hidden">{{ 'LAYOUTS.LCE.STATE.LOADING.MESSAGE' | translate }}</span>
            </div>
        </div>
    </ng-template>

    <ng-template ngSwitchCase="content">
        <ng-content select="[hopLceContent]"></ng-content>
    </ng-template>

    <ng-template ngSwitchCase="error">
        <ng-content select="[hopLceError]"></ng-content>

        <div class="c-hop-lce-layout__default-wrapper" *ngIf="!customError">
            <hop-alert status="error">
                <ng-content select="[hopLceErrorMessage]"></ng-content>
                <ng-container *ngIf="!customErrorMessage">{{ 'LAYOUTS.LCE.STATE.ERROR.MESSAGE' | translate }}</ng-container>
            </hop-alert>
        </div>
    </ng-template>

    <ng-template ngSwitchDefault>
        <ng-container *ngFor="let child of (customStateChildren | filterState:state)">
            <ng-template [ngTemplateOutlet]="child.templateRef"></ng-template>
        </ng-container>
    </ng-template>

</ng-container>
