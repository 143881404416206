import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';

import { LogoutActions } from '../actions/logout.actions';

@Injectable({ providedIn: 'root' })
export class HopLogoutFacade {


    constructor(private readonly _router: Router, private readonly _store: Store) {

    }

    async logOut() {
        await firstValueFrom(this._store.dispatch(new LogoutActions.LogOut()));
        await this._router.navigateByUrl('/auth/login');
    }
}
