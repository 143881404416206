import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';

import { ICreateShipmentRequestDto } from '../../entities/create-shipment-request.dto';
import { RequestShipmentActions } from '../actions/request-shipment.actions';

@Injectable({ providedIn: 'root' })
export class HopContractRequestShipmentFacade {

    constructor(private readonly _store: Store) {
    }

    requestShipment(navisionId: string, shipmentData: ICreateShipmentRequestDto) {
        return lastValueFrom(this._store.dispatch(new RequestShipmentActions.RequestShipment(navisionId, shipmentData)));
    }
}
