import { coerceNumberProperty } from '@angular/cdk/coercion';
import { Directive, Input, NgModule } from '@angular/core';
import { DataSource, HopCollectionViewerDirective } from '@hopsteiner/shared/collections';

import { IDataCollectionViewer } from '../models/data-collection-viewer.interface';

@Directive({
    selector: '[hopDataCollectionViewer]',
    exportAs: 'hopDataCollectionViewer'
})
export class HopDataCollectionViewerDirective<T, QUERY = unknown> extends HopCollectionViewerDirective<T, QUERY> implements IDataCollectionViewer<T, QUERY> {

    private _pageSize: number = 20;

    @Input()
    get pageSize(): number {
        return this._pageSize;
    }

    set pageSize(value: number) {
        this._pageSize = coerceNumberProperty(value);

        const { start } = this.viewChange.getValue();
        this.viewChange.next({ start, end: start + this._pageSize });
    }

    firstPage(): void {
        this.viewChange.next({ start: 0, end: this._pageSize });
    }

    initCollectionViewer(dataSource?: DataSource<T, QUERY>, query?: QUERY): void {
        // set query first to prevent double request being sent on query change
        this.setQuery(query);
        this.setDataSource(dataSource);
    }

    nextPage(): void {
        const { end } = this.viewChange.getValue();
        this.viewChange.next({ start: end, end: end + this._pageSize });
    }

    setDataSource(dataSource?: DataSource<T, QUERY>): void {
        this._switchDataSource(dataSource);
    }

    setQuery(query?: QUERY): void {
        this.queryChange.next(query ?? null);
    }

}

@NgModule({
    declarations: [ HopDataCollectionViewerDirective ],
    exports: [ HopDataCollectionViewerDirective ]
})
export class HopDataCollectionViewerDirectiveModule {

}

