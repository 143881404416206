import { HttpClient, HttpParams } from '@angular/common/http';
import { flatten } from 'flat';
import { omitBy, size } from 'lodash';
import { firstValueFrom } from 'rxjs';

import { IFindOptions } from '../models/find-options.interface';
import { IPaginated } from '../models/paginated.interface';
import { IRepositoryOptions } from '../models/repository-options.interface';


export abstract class BaseRepository<T, OPTIONS extends IRepositoryOptions = IRepositoryOptions, FIND_OPTIONS extends object = IFindOptions, GET_OPTIONS extends object = object> {

    readonly url: string;

    constructor(protected readonly _httpClient: HttpClient,
                protected readonly _options: OPTIONS) {
        this.url = _options.url ?? `/api/${_options.endpoint}`;
    }

    /**
     * Finds a paginated list of entities
     *
     * @param options a set of options that will be added to the request as http params
     * @protected
     */
    protected _find<S = IPaginated<T>>(options?: FIND_OPTIONS): Promise<S> {
        const params = this._toParams(options);

        return firstValueFrom(this._httpClient.get<S>(this.url, { params }));
    }

    /**
     * Finds a paginated list of entities
     *
     * @param path an optional subpath of the endpoint
     * @param options a set of options that will be added to the request as http params
     * @protected
     */
    protected _getPaginatedData<S = T>(path: string, options?: FIND_OPTIONS): Promise<IPaginated<S>>;
    protected _getPaginatedData<S = T>(options?: FIND_OPTIONS): Promise<IPaginated<S>>;
    protected _getPaginatedData<S = T>(pathOrOptions?: string | FIND_OPTIONS, options?: FIND_OPTIONS): Promise<IPaginated<S>> {
        const path = typeof pathOrOptions === 'string' ? pathOrOptions : undefined;
        options = options ?? pathOrOptions as FIND_OPTIONS;

        const params = this._toParams(options);

        return firstValueFrom(this._httpClient.get<IPaginated<S>>(path ? this.url + path : this.url, { params }));
    }

    /**
     * Gets a single entity
     *
     * @param id the id of the entity
     * @param options a set of options that will be added to the request as http params
     * @protected
     */
    protected _get<S = T>(id: string | number, options?: GET_OPTIONS): Promise<S> {
        const params = this._toParams(options);

        return firstValueFrom(this._httpClient.get<S>(`${this.url}/${id}`, { params }));
    }

    protected _toParams(options?: object): HttpParams | undefined {
        if (!options || size(options) === 0) {
            return void 0;
        }

        return new HttpParams({
            fromObject: flatten(omitBy(options, (value) => value == null), { safe: true })
        });
    }
}

