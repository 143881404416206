/**
 * 0 ist leer (ist einfach ein Wert, den Kunde nimmt, um zu prüfen ob Feld von Vertrieb gefüllt → heißt 0 ist eigentlich ein “Fehler” in der Pflege)
 * 1 inkl
 * 2 zuzüglich
 * 3 ohne
 */
export enum ContractCertificatedIncluded {
    EMPTY = 0,
    INCLUDED = 1,
    ADDITIONAL = 2,
    NOT_INCLUDED = 3
}
