import { IActiveUser } from '../../entities/active-user.interface';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AuthActions {

    export class UpdateActiveUser {
        static readonly type = '[Auth] Update active user';
    }

    export class SetActiveUser {
        static readonly type = '[Auth] Set active user';

        constructor(readonly activeUser: IActiveUser) {
        }
    }
}
