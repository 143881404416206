<swiper #swiper
        [centeredSlides]="true"
        [grabCursor]="true"
        slidesPerView="auto"
        [spaceBetween]="10">
    <ng-template *ngFor="let element of (connection?.data$ | async); trackBy: trackBy" swiperSlide>
        <hop-data-card [hopHighlightRecord]="highlightIf ? highlightIf(element) : false"
                       [highlightLabel]="highlightLabel"
                       [highlightColor]="highlightColor">
            <div hopCardTitle>
                <ng-container *ngTemplateOutlet="title?.templateRef || null; context: { $implicit: element }"></ng-container>
            </div>

            <div *ngIf="menu" ngbDropdown hopDataCardMenu>
                <button hopIconButton ngbDropdownToggle>
                    <fa-icon [icon]="_icons.faEllipsisH"></fa-icon>
                </button>
                <div ngbDropdownMenu>
                    <ng-container *ngTemplateOutlet="menu?.templateRef || null; context: { $implicit: element }"></ng-container>
                </div>
            </div>

            <div *ngIf="button" hopDataCardButton>
                <ng-container *ngTemplateOutlet="button?.templateRef || null; context: { $implicit: element }"></ng-container>
            </div>

            <table hopKeyValueTable>
                <ng-container *ngFor="let propertyDef of propertyDefs">
                    <tr *ngIf="displayProperties == null || displayProperties.includes(propertyDef.property)">
                        <ng-container
                            *ngTemplateOutlet="propertyDef.label?.templateRef || null; context: { $implicit: element }"></ng-container>
                        <ng-container
                            *ngTemplateOutlet="propertyDef.value?.templateRef || null; context: { $implicit: element }"></ng-container>
                    </tr>
                </ng-container>
            </table>
        </hop-data-card>
    </ng-template>
</swiper>

<hop-loading-indicator [loading]="isLoading" [message]="loadingMessage"></hop-loading-indicator>

<div class="c-hop-data-carousel__empty-container" *ngIf="!isLoading && (connection?.total$ | async) === 0">
    {{ emptyMessage || 'DATA_TABLE.CAROUSEL.EMPTY_MESSAGE' | translate }}
</div>
