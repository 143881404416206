import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, EventEmitter, Inject, Input, Optional, Output } from '@angular/core';
import { ExplicitSortDirection, SortDirection } from '@hopsteiner/shared/collection-models';

import { ISortChangeEvent } from '../models/sort-change-event.interface';
import { ISortOptions } from '../models/sort-options.interface';
import { ISortable } from '../models/sortable.interface';
import { SORT_OPTIONS } from '../tokens/sort-options.token';

@Directive({
    selector: '[hopSort]'
})
export class HopSortDirective {

    activeSortableId?: string;

    initialSortDirection: ExplicitSortDirection = SortDirection.ASC;

    direction: SortDirection = this.initialSortDirection;

    disableClear: boolean = false;

    @Output()
    readonly sortChange = new EventEmitter<ISortChangeEvent>();

    private _disabled: boolean = false;

    constructor(@Inject(SORT_OPTIONS) @Optional() private readonly _defaultOptions?: ISortOptions) {

    }

    @Input()
    set hopSort(value: BooleanInput) {
        this.disabled = !coerceBooleanProperty(value);
    }

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: BooleanInput) {
        this._disabled = coerceBooleanProperty(value);
    }

    sort(sortable: ISortable) {
        if (this.activeSortableId != sortable.id) {
            this.activeSortableId = sortable.id;
            this.direction = sortable.initialSortDirection ? sortable.initialSortDirection : this.initialSortDirection;
        } else {
            this.direction = this.getNextSortDirection(sortable);
        }

        this.sortChange.emit({ activeSortableId: this.activeSortableId, direction: this.direction });
    }

    /**
     *  Returns the next sort direction of the active sortable, checking for potential overrides.
     */
    getNextSortDirection(sortable: ISortable): SortDirection {
        if (!sortable) {
            return SortDirection.NONE;
        }

        // Get the sort direction cycle with the potential sortable overrides.
        const disableClear = sortable?.disableClear ?? this.disableClear ?? !!this._defaultOptions?.disableClear;
        const sortDirectionCycle = this.getSortDirectionCycle(sortable.initialSortDirection || this.initialSortDirection, disableClear);

        // Get and return the next direction in the cycle
        let nextDirectionIndex = sortDirectionCycle.indexOf(this.direction) + 1;
        if (nextDirectionIndex >= sortDirectionCycle.length) {
            nextDirectionIndex = 0;
        }
        return sortDirectionCycle[nextDirectionIndex];
    }

    /**
     * Returns the sort direction cycle to use given the provided parameters of order and clear.
     */
    getSortDirectionCycle(start: ExplicitSortDirection, disableClear: boolean): SortDirection[] {
        const sortOrder: SortDirection[] = [ SortDirection.ASC, SortDirection.DESC ];

        if (start == SortDirection.DESC) {
            sortOrder.reverse();
        }
        if (!disableClear) {
            sortOrder.push(SortDirection.NONE);
        }

        return sortOrder;
    }

}
