import { Routes } from '@angular/router';
import { HopAutoLoginAuthGuard, HopHasPermissionGuard } from '@hopsteiner/auth/api';
import { HopDisableDebitorSwitcherGuard, HopShowDebitorSwitcherGuard } from '@hopsteiner/environment/api';
import { UserPermission } from '@hopsteiner/shared/models';

import { AppRootLayout } from '../layouts/root/root.layout';

export const APP_ROUTES: Routes = [
    {
        path: '',
        component: AppRootLayout,
        canActivate: [ HopAutoLoginAuthGuard ],
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'dashboard'
            },
            {
                path: 'dashboard',
                loadChildren: () => import('@hopsteiner/dashboard/shell').then((m) => m.HopDashboardShellModule)
            },
            {
                path: 'contracts',
                data: {
                    [HopHasPermissionGuard.PERMISSIONS_PROPERTY]: [ UserPermission.VIEW_CONTRACTS ]
                },
                canActivate: [ HopHasPermissionGuard, HopShowDebitorSwitcherGuard ],
                canDeactivate: [ HopShowDebitorSwitcherGuard ],
                loadChildren: () => import('@hopsteiner/contract/shell').then((m) => m.HopContractShellModule)
            },
            {
                path: 'debitors',
                data: {
                    [HopHasPermissionGuard.PERMISSIONS_PROPERTY]: [ UserPermission.VIEW_DEBITORS ]
                },
                canActivate: [ HopHasPermissionGuard ],
                loadChildren: () => import('@hopsteiner/debitor/shell').then((m) => m.HopDebitorShellModule)
            },
            {
                path: 'order-requests',
                data: {
                    [HopHasPermissionGuard.PERMISSIONS_PROPERTY]: [ UserPermission.VIEW_ORDER_REQUESTS ]
                },
                canActivate: [ HopHasPermissionGuard, HopShowDebitorSwitcherGuard ],
                canDeactivate: [ HopShowDebitorSwitcherGuard ],
                loadChildren: () => import('@hopsteiner/order-request/shell').then((m) => m.HopOrderRequestShellModule)
            },
            {
                path: 'users',
                data: {
                    [HopHasPermissionGuard.PERMISSIONS_PROPERTY]: [ UserPermission.VIEW_USERS ]
                },
                canActivate: [ HopHasPermissionGuard, HopShowDebitorSwitcherGuard ],
                canDeactivate: [ HopShowDebitorSwitcherGuard ],
                loadChildren: () => import('@hopsteiner/user/shell').then((m) => m.HopUserShellModule)
            },
            {
                path: 'example',
                data: {
                    [HopHasPermissionGuard.PERMISSIONS_PROPERTY]: [ UserPermission.VIEW_EXAMPLES ]
                },
                canActivate: [ HopHasPermissionGuard, HopShowDebitorSwitcherGuard, HopDisableDebitorSwitcherGuard ],
                canDeactivate: [ HopShowDebitorSwitcherGuard, HopDisableDebitorSwitcherGuard ],
                loadChildren: () => import('../pages/example/example.page').then((m) => m.ExamplePageModule)
            },
            {
                path: 'account',
                loadChildren: () => import('@hopsteiner/account/shell').then((m) => m.HopAccountShellModule)
            }
        ]
    },
    {
        path: 'auth',
        loadChildren: () => import('@hopsteiner/auth/shell').then((m) => m.HopAuthShellModule)
    },
    {
        path: 'legal',
        component: AppRootLayout,
        data: {
            skipRedirect: true
        },
        canActivate: [ HopAutoLoginAuthGuard ],
        loadChildren: () => import('@hopsteiner/legal/shell').then((m) => m.HopLegalShellModule)
    },
    {
        path: '**',
        loadChildren: () => import('../pages/not-found/not-found.page').then((m) => m.NotFoundPageModule)
    }
];
