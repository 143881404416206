import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { IAccountPreferences } from '../entities/preferences/account-preferences.interface';
import { PreferenceGroup } from '../entities/preferences/preference-group.enum';
import { SanitizedPreferences } from '../utils/preference.utils';

@Injectable()
export class PreferencesService {

    constructor(private readonly _httpClient: HttpClient) {
    }

    getAllPreferences() {
        return firstValueFrom(this._httpClient.get<IAccountPreferences>(`/api/account/preferences`));
    }

    getGroupPreferences<GROUP extends PreferenceGroup>(group: GROUP) {
        return firstValueFrom(this._httpClient.get<IAccountPreferences[GROUP]>(`/api/account/preferences/${group}`));
    }

    patchGroupPreferences<GROUP extends PreferenceGroup>(group: GROUP, preferences: SanitizedPreferences<GROUP>) {
        return firstValueFrom(this._httpClient.patch(`/api/account/preferences/${group}`, preferences));
    }
}
