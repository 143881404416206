import { IDisplayEnumEntity, IEnumEntity } from './enum-entity.interface';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICultivar extends IEnumEntity {

}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IDisplayCultivar extends IDisplayEnumEntity {

}
