import { Directive, TemplateRef } from '@angular/core';

@Directive({
    selector: 'ng-template[hopButtonDef]'
})
export class HopButtonDefDirective {

    constructor(readonly templateRef: TemplateRef<unknown>) {
    }

}
