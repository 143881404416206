import { ListRange } from '@angular/cdk/collections';
import { Observable, of } from 'rxjs';

import { DataSource } from '../models/data-source.class';
import { IPaginatedData } from '../models/paginated-data.interface';

import { DataSourceUtils } from './data-source.utils';


export type ApplyQueryFunction<T, QUERY> = (data: T[], query: QUERY | null) => T[];

export class ArrayDataSource<T, QUERY = unknown> extends DataSource<T, QUERY> {

    constructor(readonly array: T[], private readonly _applyQuery?: ApplyQueryFunction<T, QUERY>) {
        super();
    }

    fetch(listRange: ListRange | null, query: QUERY | null): Observable<IPaginatedData<T>> {
        const data = this.applyQuery(this.array, query);

        return of(DataSourceUtils.paginate(data, listRange));
    }

    protected applyQuery(data: T[], query: QUERY | null): T[] {
        return this._applyQuery ? this._applyQuery(data, query) : data;
    }

}
