import { HopOverlayRef } from '../../shared/models/overlay-ref';

import { HopDrawerConfig } from './drawer-config';

export class HopDrawerRef<T = unknown, R = unknown> extends HopOverlayRef<HopDrawerConfig, T, R> {

    /**
     * Updates the drawer's width.
     * @param width New width of the drawer.
     */
    updateSize(width: string = ''): this {
        this._overlayRef.updateSize({ width });
        this._overlayRef.updatePosition();
        return this;
    }
}
