<form [formGroup]="form" (submit)="onSubmit()">
    <hop-drawer>

        <hop-drawer-header>
            <h2 hopDrawerTitle id="hop-filter-drawer-title">{{ 'FILTER.DRAWER.TITLE' | translate }}</h2>
        </hop-drawer-header>

        <hop-drawer-body>
            <ngb-accordion [closeOthers]="true">
                <ngb-panel id="hop-filter-drawer-panel-{{index}}" *ngFor="let filter of filters; let index = index">

                    <ng-template ngbPanelTitle>
                        <span class="c-hop-filter-drawer__filter-title">{{ filter.name | translate }}</span>

                        <div class="c-hop-filter-drawer__filter-badges" *ngIf="filter | hasValue:result">
                            <hop-filter-badge *ngIf="filter.type !== FilterType.MULTI_SELECT; else multiSelectTemplate"
                                              [name]="filter.key"
                                              [removable]="filter.removable"
                                              (remove)="removeValue($event)">
                                <ng-container
                                    *ngTemplateOutlet="filter.valueTemplate || getDefaultValueTemplate(filter); context: { $implicit: result[filter.key] }"></ng-container>
                            </hop-filter-badge>

                            <ng-template #multiSelectTemplate>
                                <hop-filter-badge *ngFor="let item of result[filter.key] | limit:3:2"
                                                  [name]="filter.key"
                                                  [value]="item"
                                                  [removable]="filter.removable"
                                                  (remove)="removeValue($event)">
                                    <ng-container
                                        *ngTemplateOutlet="filter.valueTemplate || defaultValueTemplate; context: { $implicit: item }"></ng-container>
                                </hop-filter-badge>

                                <div class="c-hop-filter-drawer__filter-badge-overflow-count" *ngIf="result[filter.key].length > 3">
                                    +{{ result[filter.key].length - 2 }}
                                </div>
                            </ng-template>
                        </div>

                    </ng-template>

                    <ng-template ngbPanelContent [ngSwitch]="filter.type">
                        <hop-multi-select-filter *ngSwitchCase="FilterType.MULTI_SELECT"
                                                 [filter]="$any(filter)"
                                                 [key]="filter.key"
                                                 [optionTemplate]="$any(filter).optionTemplate"
                                                 [formControlName]="filter.key">
                        </hop-multi-select-filter>

                        <hop-single-select-filter *ngSwitchCase="FilterType.SINGLE_SELECT"
                                                  [filter]="$any(filter)"
                                                  [key]="filter.key"
                                                  [optionTemplate]="$any(filter).optionTemplate"
                                                  [formControlName]="filter.key">
                        </hop-single-select-filter>

                        <hop-date-filter *ngSwitchCase="FilterType.DATE"
                                         [filter]="$any(filter)"
                                         [result]="result"
                                         [formControlName]="filter.key">
                        </hop-date-filter>

                        <hop-date-range-filter *ngSwitchCase="FilterType.DATE_RANGE"
                                               [filter]="$any(filter)"
                                               [result]="result"
                                               [formControlName]="filter.key">
                        </hop-date-range-filter>
                    </ng-template>

                </ngb-panel>
            </ngb-accordion>
        </hop-drawer-body>

        <hop-drawer-footer>
            <button hopButton="plain" *ngIf="filters | hasSomeValue:result" type="button" (click)="resetFilters()">
                {{ 'FILTER.DRAWER.ACTION.RESET' | translate }}
            </button>
            <button hopButton="solid" type="submit">{{ 'FILTER.DRAWER.ACTION.APPLY' | translate }}</button>
        </hop-drawer-footer>

    </hop-drawer>
</form>

<ng-template #defaultValueTemplate let-value>
    {{ value }}
</ng-template>

<ng-template #defaultDateValueTemplate let-date>
    {{ date | date:dateFormat }}
</ng-template>

<ng-template #defaultDateRangeValueTemplate let-dateRange>
    {{ dateRange.startDate | date:dateFormat }} - {{ dateRange.endDate | date:dateFormat }}
</ng-template>
