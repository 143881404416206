import { ChangeDetectionStrategy, Component, ContentChild, ViewEncapsulation } from '@angular/core';
import { HopCollectionViewerDirective } from '@hopsteiner/shared/collections';

import { HopListItemDefDirective } from '../../directives/list-item-def.directive';

@Component({
    selector: 'hop-list',
    templateUrl: './list.component.html',
    styleUrls: [ './list.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-list list-group'
    }
})
export class HopListComponent<T, QUERY = unknown> extends HopCollectionViewerDirective<T, QUERY> {

    @ContentChild(HopListItemDefDirective)
    listItemDef!: HopListItemDefDirective;
}
