/* eslint-disable @angular-eslint/directive-class-suffix */
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CdkCellOutlet, CdkRow } from '@angular/cdk/table';
import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({ selector: '[hopExpandableCellOutlet]' })
export class HopExpandableCellOutlet<CONTEXT = unknown> extends CdkCellOutlet {

    private _templateRef?: TemplateRef<CONTEXT>;
    private _embeddedViewRef?: EmbeddedViewRef<CONTEXT>;
    private _show: boolean = false;

    constructor(viewContainer: ViewContainerRef, readonly row: CdkRow) {
        super(viewContainer);
    }

    @Input()
    get show(): boolean {
        return this._show;
    }

    set show(value: boolean) {
        this._show = coerceBooleanProperty(value);

        if (this._show) {
            if (this._embeddedViewRef) {
                this._embeddedViewRef.reattach();
            } else {
                if (this._templateRef) {
                    this._embeddedViewRef = this._viewContainer.createEmbeddedView(this._templateRef, this.context);
                }
            }
        } else {
            if (this._embeddedViewRef) {
                this._embeddedViewRef.detach();
            }
        }
    }

    setTemplate(templateRef: TemplateRef<CONTEXT>, context: CONTEXT) {
        this._templateRef = templateRef;
        this.context = context;
    }

}
