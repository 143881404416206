import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HopCollectionViewerTableDirective } from './directives/collection-viewer-table.directive';
import { HopCollectionViewerDirective } from './directives/collection-viewer.directive';
import { HopSelectCollectionViewerDirective } from './directives/select-collection-viewer.directive';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        HopCollectionViewerDirective,
        HopCollectionViewerTableDirective,
        HopSelectCollectionViewerDirective
    ],
    exports: [
        HopCollectionViewerDirective,
        HopCollectionViewerTableDirective,
        HopSelectCollectionViewerDirective
    ]
})
export class HopCollectionsModule {
}
