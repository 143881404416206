import { animate, state, style, transition, trigger } from '@angular/animations';

import { OverlayAnimationState } from '../../shared/models/overlay-animation-state.enum';


export const DRAWER_ANIMATIONS = {
    /** Animation that slides a drawer in and out. */
    drawerContainer: trigger('drawerContainer', [
        // Note: The `enter` animation transitions to `transform: none`, because for some reason
        // specifying the transform explicitly, causes IE both to blur the dialog content and
        // decimate the animation performance. Leaving it as `none` solves both issues.
        state(`${OverlayAnimationState.VOID}, ${OverlayAnimationState.EXIT}`, style({
            'box-shadow': 'none',
            'visibility': 'hidden'
        })),
        state(OverlayAnimationState.ENTER, style({
            'transform': 'none',
            'visibility': 'visible'
        })),
        transition(
            `* => ${OverlayAnimationState.ENTER}, * => ${OverlayAnimationState.VOID}, * => ${OverlayAnimationState.EXIT}`,
            animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)')
        )
    ])
};
