import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, NgModule, ViewEncapsulation } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { faDatabase } from '@fortawesome/free-solid-svg-icons/faDatabase';
import { faFileContract } from '@fortawesome/free-solid-svg-icons/faFileContract';
import { faGamepad } from '@fortawesome/free-solid-svg-icons/faGamepad';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { faUserCog } from '@fortawesome/free-solid-svg-icons/faUserCog';
import { HopAuthApiModule } from '@hopsteiner/auth/api';
import { HopNavModule, IndicatorPosition } from '@hopsteiner/shared/components';
import { EnumLike, UserPermission } from '@hopsteiner/shared/models';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'hop-main-nav',
    templateUrl: './main-nav.component.html',
    styleUrls: [ './main-nav.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-main-nav'
    }
})
export class AppMainNavComponent {
    readonly UserPermission = UserPermission;

    readonly _icons = {
        faDatabase,
        faFileContract,
        faGamepad,
        faHome,
        faUserCog,
        faShoppingCart
    };

    @Input()
    indicatorPosition: EnumLike<IndicatorPosition> = IndicatorPosition.RIGHT;
}

@NgModule({
    imports: [
        CommonModule,
        RouterModule,

        FontAwesomeModule,
        TranslateModule,

        HopAuthApiModule,
        HopNavModule
    ],
    declarations: [ AppMainNavComponent ],
    exports: [ AppMainNavComponent ]
})
export class AppMainNavComponentModule {
}
