import { Language, UserPermission, UserRole } from '@hopsteiner/shared/models';

export interface IActiveUser {
    name: string;
    username: string;
    email: string;
    phone?: string;
    language: Language;
    role: UserRole;
    permissions: UserPermission[];
}
