import { ChangeDetectionStrategy, Component, HostBinding, ViewEncapsulation } from '@angular/core';

import { OverlayContainerComponent } from '../../../shared/directives/overlay-container.component';
import { HopOverlayConfig } from '../../../shared/models/overlay-config';
import { OverlayFocusHelper } from '../../../shared/utils/focus-helper';
import { DRAWER_ANIMATIONS } from '../../animations/drawer.animations';
import { HopDrawerConfig } from '../../models/drawer-config';
import { DrawerPosition } from '../../models/drawer-position.enum';


@Component({
    selector: 'hop-drawer-container',
    templateUrl: './drawer-container.component.html',
    styleUrls: [ './drawer-container.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [ DRAWER_ANIMATIONS.drawerContainer ],
    providers: [
        { provide: HopOverlayConfig, useExisting: HopDrawerConfig },
        OverlayFocusHelper
    ],
    host: {
        class: 'c-hop-drawer-container',
        'aria-modal': 'true',
        'role': 'dialog',
        /* eslint-disable @angular-eslint/no-host-metadata-property */
        '[@drawerContainer]': 'state',
        '(@drawerContainer.start)': '_onAnimationStart($event) //noinspection UnresolvedVariable',
        '(@drawerContainer.done)': '_onAnimationDone($event) //noinspection UnresolvedVariable',
        '[attr.aria-labelledby]': 'config.ariaLabel ? null : _ariaLabelledBy',
        '[attr.aria-label]': 'config.ariaLabel',
        '[attr.aria-describedby]': 'config.ariaDescribedBy || null'
        /* eslint-enable @angular-eslint/no-host-metadata-property */
    }
})
export class HopDrawerContainerComponent extends OverlayContainerComponent<HopDrawerConfig> {

    @HostBinding('class.c-hop-drawer-container--start')
    get isPositionStart() {
        return this.config.position === DrawerPosition.START;
    }

    @HostBinding('class.c-hop-drawer-container--end')
    get isPositionEnd() {
        return this.config.position === DrawerPosition.END;
    }
}
