import { CDK_TABLE } from '@angular/cdk/table';
import { Directive, Inject } from '@angular/core';

import { IMutableCdkCollectionViewer } from '../models/mutable-cdk-collection-viewer.interface';
import { CDK_COLLECTION_VIEWER } from '../tokens/cdk-collection-viewer.token';

import { HopCollectionViewerDelegateDirective } from './collection-viewer-delegate.directive';


@Directive({
    selector: '[cdk-table][hopCollectionViewerDelegate]',
    exportAs: 'hopCollectionViewerDelegate',
    providers: [
        { provide: CDK_COLLECTION_VIEWER, useExisting: CDK_TABLE }
    ]
})
export class HopCollectionViewerTableDirective<T, QUERY = unknown> extends HopCollectionViewerDelegateDirective<T, QUERY> {

    constructor(@Inject(CDK_COLLECTION_VIEWER) collectionViewer: IMutableCdkCollectionViewer<T>) {
        super(collectionViewer, 1);
    }
}
