import { IDebitor } from '@hopsteiner/debitor/api';

export interface IShipment {
    uid: number;
    frontendId: string;
    contractId: string;
    deliveryNoteId: string;
    deliveryNoteAvailable: boolean;
    analysisCertificateUrl: string;
    analysisCertificateAvailable: boolean;
    invoiceId: string;
    year: number;
    quantity: string;
    quantityUnit: number;
    dateFrom: Date;
    dateTo: Date;
    note: string;
    goodsCollected: boolean;
    goodsIssuedAt: Date;
    packaging: {
        label: string;
        debitor?: IDebitor;
        destination?: string;
    };
    displayQuantity: string;
    displayQuantityUnit: string;
}
