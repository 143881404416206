<div *ngIf="currentLanguageData$ | async as currentLanguageData"
     ngbDropdown
     [placement]="['bottom', 'top']">
    <button ngbDropdownToggle hopButton>
        <img class="c-hop-env-language-menu__country-flag"
             [src]="currentLanguageData.flagUrl"
             [alt]="currentLanguageData.name | translate" />
        <fa-icon [icon]="_icon.faChevronDown"></fa-icon>
    </button>
    <div ngbDropdownMenu class="c-hop-env-language-menu__dropdown">
        <button ngbDropdownItem
                *ngFor="let language of availableLanguages"
                [ngbTooltip]="languages[language].name | translate"
                (click)="onItemClick($event, language)">
            <img class="c-hop-env-language-menu__country-flag"
                 [src]="languages[language].flagUrl"
                 [alt]="languages[language].name | translate" />
        </button>
    </div>
</div>
