import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import { DebitorSwitcherFacade, IActiveDebitor } from '@hopsteiner/environment/domain';
import { Breakpoint, Breakpoints } from '@hopsteiner/shared/breakpoints';
import { DataSource } from '@hopsteiner/shared/collections';
import { HopDrawer, HopDrawerRef, HopOverlayRef } from '@hopsteiner/shared/overlays';
import { Observable } from 'rxjs';


@Component({
    selector: 'hop-env-debitor-drawer',
    templateUrl: './debitor.drawer.html',
    styleUrls: [ './debitor.drawer.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-env-debitor-drawer'
    }
})
export class HopDebitorDrawer {

    readonly _dataSource: DataSource<IActiveDebitor>;

    readonly _isMobile$: Observable<boolean>;
    readonly _recentDebitors$: Observable<IActiveDebitor[]>;

    static open(drawer: HopDrawer) {
        return drawer.open<HopDebitorDrawer, IActiveDebitor | null>(HopDebitorDrawer, {
            width: '60rem',
            ariaLabelledBy: 'hop-debitor-drawer-title',
            autoFocus: 'hop-debitor-drawer-search-bar'
        });
    }

    constructor(debitorSwitcherFacade: DebitorSwitcherFacade,
                @Inject(HopOverlayRef) readonly drawerRef: HopDrawerRef<HopDebitorDrawer, IActiveDebitor | null>,
                breakpointObserver: Breakpoints) {

        this._dataSource = debitorSwitcherFacade.createAllDebitorsDataSource().infinite();
        this._recentDebitors$ = debitorSwitcherFacade.recentActiveDebitors$;
        this._isMobile$ = breakpointObserver.observeMatched(Breakpoints.down(Breakpoint.SM));
    }

    selectDebitor(debitor: IActiveDebitor) {
        this.drawerRef.close(debitor);
    }

    _trackDebitorById(index: number, element: IActiveDebitor) {
        return element.navisionId;
    }

}
