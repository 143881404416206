import { ListRange } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { IActiveDebitor } from '@hopsteiner/environment/domain';
import { ISortableQuery } from '@hopsteiner/shared/collections';
import { HOP_DATA_COLLECTION_VIEWER_HOST, HopDataCollectionViewerDirective, IDataCollectionViewer } from '@hopsteiner/shared/data-table';
import { BehaviorSubject } from 'rxjs';

import { BaseDebitorDrawerCollectionComponent } from '../base-debitor-drawer-collection.component';

@Component({
    selector: 'hop-env-debitor-list',
    templateUrl: './list.component.html',
    styleUrls: [ './list.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-env-debitor-list'
    },
    providers: [
        {
            provide: HOP_DATA_COLLECTION_VIEWER_HOST,
            useExisting: HopEnvDebitorListComponent
        }
    ]
})
export class HopEnvDebitorListComponent extends BaseDebitorDrawerCollectionComponent {

    static DEFAULT_PAGE_SIZE = 20;

    readonly viewChange: BehaviorSubject<ListRange> = new BehaviorSubject<ListRange>({ start: 0, end: HopEnvDebitorListComponent.DEFAULT_PAGE_SIZE });

    @ViewChild('debitorList', { static: true })
    list!: HopDataCollectionViewerDirective<IActiveDebitor, ISortableQuery>;

    get collectionViewer(): IDataCollectionViewer<IActiveDebitor, ISortableQuery> {
        return this.list;
    }

}
