import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { OrderRequestState } from './application/order-request.state';

@NgModule({
    imports: [
        CommonModule,

        NgxsModule.forFeature([ OrderRequestState ])
    ]
})
export class HopOrderRequestDomainRootModule {
}
