import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HopAlertModule, HopButtonModule } from '@hopsteiner/shared/components';
import { TranslateModule } from '@ngx-translate/core';

import { HopDialogModule } from '../dialog/dialog.module';

import { HopConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';


@NgModule({
    imports: [
        CommonModule,

        TranslateModule,

        HopAlertModule,
        HopButtonModule,
        HopDialogModule
    ],
    declarations: [
        HopConfirmDialogComponent,
        SanitizeHtmlPipe
    ],
    exports: [
        HopConfirmDialogComponent
    ]
})
export class HopConfirmDialogModule {
}
