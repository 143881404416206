import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Endpoint, UnpaginatedRepository } from '@hopsteiner/shared/data-access';

import { IProduct } from '../entities/product.interface';

@Injectable({
    providedIn: 'root'
})
export class ProductRepository extends UnpaginatedRepository<IProduct> {

    constructor(httpClient: HttpClient) {
        super(httpClient, {
            endpoint: Endpoint.PRODUCTS
        });
    }
}
