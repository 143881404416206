import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HopButtonModule, HopKeyValueTableModule } from '@hopsteiner/shared/components';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from 'swiper/angular';

import { HopDataCardModule } from '../card/data-card.module';
import { HopLoadingIndicatorComponentModule } from '../shared/components/loading-indicator/loading-indicator.component';
import { HopScrollContainerDirectiveModule } from '../shared/directives/scroll-container.directive';
import { HopHighlightRecordDirectiveModule } from '../shared/directives/highlight-record.directive';
import { HopDataTableModule } from '../table/data-table.module';

import { HopDataCarouselComponent } from './components/data-carousel/data-carousel.component';
import { HopDataListComponent } from './components/data-list/data-list.component';
import { HopButtonDefDirective } from './directives/button-def.directive';
import { HopLabelDefDirective } from './directives/label-def.directive';
import { HopMenuDefDirective } from './directives/menu-def.directive';
import { HopPropertyDefDirective } from './directives/property-def.directive';
import { HopTitleDefDirective } from './directives/title-def.directive';
import { HopValueDefDirective } from './directives/value-def.directive';

@NgModule({
    imports: [
        CommonModule,

        FontAwesomeModule,
        NgbDropdownModule,
        SwiperModule,
        TranslateModule,

        HopButtonModule,
        HopDataCardModule,
        HopKeyValueTableModule,
        HopLoadingIndicatorComponentModule,
        HopScrollContainerDirectiveModule,
        HopDataTableModule,
        HopHighlightRecordDirectiveModule
    ],
    declarations: [
        HopDataCarouselComponent,
        HopDataListComponent,

        HopLabelDefDirective,
        HopMenuDefDirective,
        HopPropertyDefDirective,
        HopTitleDefDirective,
        HopValueDefDirective,
        HopButtonDefDirective
    ],
    exports: [
        NgbDropdownModule,

        HopDataCarouselComponent,
        HopDataListComponent,

        HopLabelDefDirective,
        HopMenuDefDirective,
        HopPropertyDefDirective,
        HopTitleDefDirective,
        HopValueDefDirective,
        HopButtonDefDirective
    ]
})
export class HopDataListModule {

}
