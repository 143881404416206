/**
 * 0 = kg
 *
 * 1 = kg Alpha
 *
 * 2 = ztr.
 *
 * 3 = lbs
 *
 * 4 = ltr
 *
 * 5 = Stück
 */
export enum QuantityUnit {
    /**
     * Kilogramm: kg
     */
    KG = 0,

    /**
     * Kilogramm Alpha: kg Alpha
     */
    KG_ALPHA = 1,

    /**
     * Zentner: ztr.
     */
    CENTUM_WEIGHT = 2,

    /**
     * Pfund: lbs
     */
    POUND = 3,

    /**
     * Liter: ltr
     */
    LITRE = 4,

    /**
     * Stück: Stück
     */
    PIECES = 5
}
