import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';

import { EditDebitorActions } from '../actions/edit-debitor.actions';
import { IEditDebitorData } from '../models/edit-debitor-data.interface';

@Injectable({
    providedIn: 'root'
})
export class HopDebitorEditFacade {

    constructor(private readonly _store: Store) {
    }

    updateDebitor(debitorId: string, editDebitorData: IEditDebitorData) {
        return lastValueFrom(this._store.dispatch(new EditDebitorActions.EditDebitor(debitorId, editDebitorData)));
    }
}
