import { Pipe, PipeTransform } from '@angular/core';
import { distinctUntilChanged, isObservable, materialize, Observable, scan, startWith } from 'rxjs';

import { LceState, LceStateLike } from '../models/lce-state.enum';

@Pipe({
    name: 'lceState'
})
export class HopLceStatePipe implements PipeTransform {

    transform<T>(value: Promise<T>, initialState?: LceStateLike, defaultState?: LceStateLike, resolvedState?: LceStateLike): Promise<LceStateLike>;
    transform<T>(value: Observable<T>, initialState?: LceStateLike, defaultState?: LceStateLike, resolvedState?: LceStateLike): Observable<LceStateLike>;
    transform<T>(value: Promise<T> | Observable<T> | null | undefined, initialState?: LceStateLike, defaultState?: LceStateLike, resolvedState?: LceStateLike): Promise<LceStateLike> | Observable<LceStateLike>;
    transform<T>(value: Promise<T> | Observable<T> | null | undefined,
                 initialState: LceStateLike = LceState.LOADING,
                 defaultState: LceStateLike = initialState,
                 resolvedState: LceStateLike = LceState.CONTENT): Promise<LceStateLike> | Observable<LceStateLike> {
        if (!value) {
            return Promise.resolve(defaultState);
        }

        if (isObservable(value)) {
            return this.transformObservable(value, initialState, resolvedState);
        }

        return this.transformPromise(value, resolvedState);
    }

    transformObservable<T>(value: Observable<T>, initialState: LceStateLike, resolvedState: LceStateLike): Observable<LceStateLike> {
        return value.pipe(
            materialize(),
            scan((current, next) => {
                switch (next.kind) {
                    case 'N':
                        return resolvedState;
                    case 'E':
                        return LceState.ERROR;
                    default:
                        return current;
                }
            }, initialState),
            startWith(initialState),
            distinctUntilChanged()
        );
    }

    transformPromise<T>(value: Promise<T>, resolvedState: LceStateLike): Promise<LceStateLike> {
        return value
            .then(() => resolvedState)
            .catch(() => LceState.ERROR);
    }

}
