import { CollectionViewer, DataSource as CdkDataSource } from '@angular/cdk/collections';
import { Observable } from 'rxjs';

import { ICollectionViewer } from '../models/collection-viewer.interface';
import { Connection } from '../models/connection.class';
import { IDataSource } from '../models/data-source.interface';

export class CdkDataSourceShim<T, QUERY = unknown> extends CdkDataSource<T> {

    private _connections = new Map<CollectionViewer, Connection<T, QUERY>>();

    constructor(readonly dataSource: IDataSource<T, QUERY>) {
        super();
    }

    connect(collectionViewer: CollectionViewer): Observable<readonly T[]> {
        const connection = this.dataSource.connect(collectionViewer as ICollectionViewer<T, QUERY>);

        this._connections.set(collectionViewer, connection);

        return connection.data$;
    }

    disconnect(collectionViewer: CollectionViewer) {
        const connection = this._connections.get(collectionViewer);

        if (connection) {
            connection.disconnect();
        }

        this._connections.delete(collectionViewer);
    }

    getConnection(collectionViewer: CollectionViewer): Connection<T, QUERY> | undefined {
        return this._connections.get(collectionViewer);
    }

}
