import { coerceNumberProperty } from '@angular/cdk/coercion';
import { ListRange } from '@angular/cdk/collections';
import { ChangeDetectorRef, ContentChild, ContentChildren, Directive, HostBinding, Input, OnDestroy, QueryList, TrackByFunction } from '@angular/core';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { Connection, DataSource, ICollectionViewer, InfiniteDataSource } from '@hopsteiner/shared/collections';
import { BehaviorSubject, debounceTime, NEVER, Observable, Subscription, switchAll } from 'rxjs';

import { HopButtonDefDirective } from '../directives/button-def.directive';
import { HopMenuDefDirective } from '../directives/menu-def.directive';
import { HopPropertyDefDirective } from '../directives/property-def.directive';
import { HopTitleDefDirective } from '../directives/title-def.directive';

@Directive()
export abstract class BaseDataListComponent<T, QUERY = unknown> implements ICollectionViewer<T, QUERY>, OnDestroy {

    static DEFAULT_PAGE_SIZE = 20;

    readonly _icons = {
        faEllipsisH
    };

    @Input()
    highlightColor?: string;

    @Input()
    highlightLabel?: string;

    @Input()
    highlightIf?: (item: T) => boolean;

    @Input()
    loadingMessage?: string;

    @Input()
    emptyMessage?: string;

    /**
     * A list of property names that should be displayed. If omitted, all defined properties will be displayed
     */
    @Input()
    displayProperties?: string[];

    @ContentChild(HopTitleDefDirective)
    title?: HopTitleDefDirective;

    @ContentChild(HopButtonDefDirective)
    button?: HopButtonDefDirective;

    @ContentChild(HopMenuDefDirective)
    menu?: HopMenuDefDirective;

    @ContentChildren(HopPropertyDefDirective)
    propertyDefs!: QueryList<HopPropertyDefDirective>;

    readonly viewChange: BehaviorSubject<ListRange> = new BehaviorSubject<ListRange>({ start: 0, end: BaseDataListComponent.DEFAULT_PAGE_SIZE });
    readonly queryChange = new BehaviorSubject<QUERY | null>(null);
    readonly total$: Observable<number>;

    private _dataSource?: DataSource<T, QUERY>;
    private _connection?: Connection<T, QUERY>;
    private _pageSize: number = BaseDataListComponent.DEFAULT_PAGE_SIZE;
    private _isLoading: boolean = false;
    private _isLoadingSubscription: Subscription | null = null;
    private readonly _totalSubject = new BehaviorSubject<Observable<number>>(NEVER);

    constructor(private readonly _changeDetectorRef: ChangeDetectorRef) {
        this.total$ = this._totalSubject.pipe(switchAll());
    }

    @Input()
    get query(): QUERY | null {
        return this.queryChange.getValue();
    }

    set query(value: QUERY | null) {
        this.queryChange.next(value);
    }

    @Input()
    get pageSize(): number {
        return this._pageSize;
    }

    set pageSize(value: number) {
        this._pageSize = coerceNumberProperty(value);

        const { start } = this.viewChange.getValue();
        this.viewChange.next({ start, end: start + this._pageSize });
    }

    @Input()
    get dataSource(): DataSource<T, QUERY> | undefined {
        return this._dataSource;
    }

    set dataSource(value: DataSource<T, QUERY> | undefined) {
        if (value === this.dataSource) {
            return;
        }

        if (this._connection) {
            this._connection.disconnect();
        }

        if (this._isLoadingSubscription) {
            this._isLoadingSubscription.unsubscribe();
            this._isLoadingSubscription = null;
        }

        this._dataSource = value;

        if (!value) {
            return;
        }

        this._connection = value.connect(this);
        this._onConnectionChanged(this._connection);

        this._totalSubject.next(this._connection.total$);
        this._isLoadingSubscription = this._connection.isLoading$
            .pipe(
                debounceTime(500)
            )
            .subscribe((isLoading) => this._setLoading(isLoading));

        this._changeDetectorRef.markForCheck();
    }

    get connection(): Connection<T, QUERY> | undefined {
        return this._connection;
    }

    @HostBinding('class.c-hop-data-list--is-loading')
    get isLoading() {
        return this._isLoading;
    }

    @HostBinding('class.c-hop-data-list--infinite-scroll')
    get isInfiniteScroll() {
        return this.dataSource instanceof InfiniteDataSource;
    }

    ngOnDestroy() {
        this._isLoadingSubscription?.unsubscribe();
    }

    @Input()
    trackBy: TrackByFunction<T> = (index, element) => element;

    firstPage() {
        this.viewChange.next({ start: 0, end: this._pageSize });
    }

    nextPage() {
        const { end } = this.viewChange.getValue();
        this.viewChange.next({ start: end, end: end + this._pageSize });
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
    protected _onConnectionChanged(connection: Connection<T, QUERY>) {

    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
    protected _onLoadingChanged(loading: boolean) {

    }

    private _setLoading(loading: boolean) {
        this._isLoading = loading;
        this._onLoadingChanged(this._isLoading);
        this._changeDetectorRef.markForCheck();
    }
}
