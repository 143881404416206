import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ShipmentRequestRepository } from '../infrastructure/shipment-request.repository';

import { RequestShipmentActions } from './actions/request-shipment.actions';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IContractStateModel {

}

@State<IContractStateModel>({
    name: 'contract',
    defaults: {}
})
@Injectable()
export class ContractUiState {

    constructor(private readonly _shipmentRequestRepository: ShipmentRequestRepository) {
    }

    @Action(RequestShipmentActions.RequestShipment)
    async requestShipment(ctx: StateContext<IContractStateModel>, action: RequestShipmentActions.RequestShipment) {
        await this._shipmentRequestRepository.create(action.navisionId, action.shipmentData);
    }
}
