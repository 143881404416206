/* eslint-disable @angular-eslint/component-class-suffix,@angular-eslint/directive-class-suffix */
import { CDK_ROW_TEMPLATE, CdkFooterRow, CdkHeaderRow, CdkNoDataRow } from '@angular/cdk/table';
import { ChangeDetectionStrategy, Component, Directive, ViewEncapsulation } from '@angular/core';

/** Header template container that contains the cell outlet. Adds the right class and role. */
@Component({
    selector: 'hop-header-row',
    template: CDK_ROW_TEMPLATE,
    host: {
        class: 'c-hop-header-row',
        role: 'row'
    },
    // See note on CdkTable for explanation on why this uses the default change detection strategy.
    // tslint:disable-next-line:validate-decorators
    changeDetection: ChangeDetectionStrategy.Default,
    encapsulation: ViewEncapsulation.None,
    exportAs: 'hopHeaderRow',
    providers: [ { provide: CdkHeaderRow, useExisting: HopHeaderRow } ]
})
export class HopHeaderRow extends CdkHeaderRow {
}

/** Footer template container that contains the cell outlet. Adds the right class and role. */
@Component({
    selector: 'hop-footer-row',
    template: CDK_ROW_TEMPLATE,
    host: {
        class: 'c-hop-footer-row',
        role: 'row'
    },
    // See note on CdkTable for explanation on why this uses the default change detection strategy.
    // tslint:disable-next-line:validate-decorators
    changeDetection: ChangeDetectionStrategy.Default,
    encapsulation: ViewEncapsulation.None,
    exportAs: 'hopFooterRow',
    providers: [ { provide: CdkFooterRow, useExisting: HopFooterRow } ]
})
export class HopFooterRow extends CdkFooterRow {
}

/** Row that can be used to display a message when no data is shown in the table. */
@Directive({
    selector: 'ng-template[hopNoDataRow]',
    providers: [ { provide: CdkNoDataRow, useExisting: HopNoDataRowDef } ]
})
export class HopNoDataRowDef extends CdkNoDataRow {

}
