import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'hop-dialog-footer',
    templateUrl: './dialog-footer.component.html',
    styleUrls: [ './dialog-footer.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-dialog__footer'
    }
})
export class HopDialogFooterComponent {

}
