import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';

import { HopAuthApiFacade } from '../facades/auth-api.facade';
import { UNAUTHORIZED_REDIRECT } from '../tokens/unauthorized-redirect.token';

@Injectable({
    providedIn: 'root'
})
export class HopAuthGuard {

    constructor(private readonly _router: Router,
                private readonly _authFacade: HopAuthApiFacade,
                @Inject(UNAUTHORIZED_REDIRECT) private readonly _unauthorizedRedirect: string) {

    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this._verifyAuthenticated(state.url);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this._verifyAuthenticated(state.url);
    }

    protected _verifyAuthenticated(currentUrl: string) {
        return this._authFacade.isAuthenticated$.pipe(
            map((isAuthenticated) => {
                if (isAuthenticated) {
                    return true;
                }

                const redirect = currentUrl === '/' ? undefined : currentUrl;

                return this._router.createUrlTree([ this._unauthorizedRedirect ], {
                    queryParams: { redirect }
                });
            })
        );
    }

}
