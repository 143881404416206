import { ChangeDetectionStrategy, Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { HopAuthApiFacade, IActiveUser } from '@hopsteiner/auth/api';
import { Observable } from 'rxjs';

@Component({
    selector: 'hop-env-account-menu',
    templateUrl: './account-menu.component.html',
    styleUrls: [ './account-menu.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'c-hop-env-account-menu'
    }
})
export class HopAccountMenuComponent {

    readonly _icon = {
        faChevronDown
    };

    readonly user$: Observable<IActiveUser | null>;

    @Output()
    readonly itemClick = new EventEmitter<MouseEvent>();

    constructor(private readonly _authFacade: HopAuthApiFacade) {
        this.user$ = this._authFacade.activeUser$;
    }

    onItemClick($event: MouseEvent) {
        this.itemClick.emit($event);
    }

    logOut($event: MouseEvent) {
        this.onItemClick($event);
        void this._authFacade.logOut();
    }

}
