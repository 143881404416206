import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HopButtonModule } from '@hopsteiner/shared/components';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { HopAccountMenuComponent } from './components/account-menu/account-menu.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,

        FontAwesomeModule,
        TranslateModule,
        NgbModule,

        HopButtonModule
    ],
    declarations: [
        HopAccountMenuComponent
    ],
    exports: [
        HopAccountMenuComponent
    ]
})
export class HopEnvFeatureAccountMenuModule {
}
