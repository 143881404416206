export interface ICreateOrderRequestDto {
    product: string;
    cultivar: string;
    harvestYear: number;
    quantity: number;
    quantityUnit: number;
    packaging: string;
    deliveryDate: Date;
    comments: string;
}
