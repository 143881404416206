import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HopAuthApiModule } from '@hopsteiner/auth/api';
import { HopButtonModule, HopCardModule, HopPaginatorModule, HopSearchBarModule, HopSortModule } from '@hopsteiner/shared/components';
import {
    HopDataCollectionViewerDirectiveModule,
    HopDataListModule,
    HopDataTableModule,
    HopHighlightRecordDirectiveModule,
    HopLoadingIndicatorComponentModule,
    HopScrollContainerDirectiveModule
} from '@hopsteiner/shared/data-table';
import { HopDataCollectionContainerModule, HopInfiniteScrollDirectiveModule } from '@hopsteiner/shared/layouts';
import { HopDrawerModule } from '@hopsteiner/shared/overlays';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { HopEnvDebitorCardComponent } from './components/card/card.component';
import { HopDebitorPickerComponent } from './components/debitor-picker/debitor-picker.component';
import { HopDebitorSwitcherTabListComponent } from './components/debitor-switcher-tab-list/debitor-switcher-tab-list.component';
import { HopDebitorSwitcherTabComponent } from './components/debitor-switcher-tab/debitor-switcher-tab.component';
import { HopDebitorSwitcherComponent } from './components/debitor-switcher/debitor-switcher.component';
import { HopEnvDebitorListComponent } from './components/list/list.component';
import { HopEnvDebitorTableComponent } from './components/table/table.component';
import { HopDebitorDrawer } from './drawers/debitor/debitor.drawer';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,

        FontAwesomeModule,
        NgbTooltipModule,
        TranslateModule,

        HopButtonModule,
        HopCardModule,
        HopDataListModule,
        HopDataTableModule,
        HopDrawerModule,
        HopPaginatorModule,
        HopSearchBarModule,
        HopSortModule,
        HopAuthApiModule,
        HopInfiniteScrollDirectiveModule,
        HopDataCollectionContainerModule,
        HopScrollContainerDirectiveModule,
        HopDataCollectionViewerDirectiveModule,
        HopLoadingIndicatorComponentModule,
        HopHighlightRecordDirectiveModule
    ],
    declarations: [
        HopDebitorSwitcherTabListComponent,
        HopDebitorSwitcherTabComponent,
        HopDebitorSwitcherComponent,
        HopDebitorPickerComponent,
        HopDebitorDrawer,
        HopEnvDebitorTableComponent,
        HopEnvDebitorListComponent,
        HopEnvDebitorCardComponent
    ],
    exports: [
        HopDebitorSwitcherComponent
    ]
})
export class HopEnvDebitorSwitcherModule {
}
