import { Injectable } from '@angular/core';
import { DataSource } from '@hopsteiner/shared/collections';
import { RepositoryDataSource } from '@hopsteiner/shared/data-access';
import { Store } from '@ngxs/store';

import { EnvironmentDebitorSwitcherListRepository } from '../../infrastructure/debitor-switcher-list.repository';
import { IActiveDebitor } from '../../models/active-debitor.interface';
import { DebitorSwitcherMode } from '../../models/debitor-switcher-mode.enum';
import { DebitorSwitcherActions } from '../actions/debitor-switcher.actions';
import { DebitorSwitcherState } from '../debitor-switcher.state';

@Injectable({ providedIn: 'root' })
export class DebitorSwitcherFacade {

    constructor(private readonly _store: Store,
                private readonly _debitorRepository: EnvironmentDebitorSwitcherListRepository) {

    }

    get isShown$() {
        return this._store.select(DebitorSwitcherState.selectIsShown);
    }

    get isDisabled$() {
        return this._store.select(DebitorSwitcherState.selectIsDisabled);
    }

    get mode$() {
        return this._store.select(DebitorSwitcherState.selectMode);
    }

    get activeDebitor$() {
        return this._store.select(DebitorSwitcherState.selectActiveDebitor);
    }

    get recentActiveDebitors$() {
        return this._store.select(DebitorSwitcherState.selectRecentActiveDebitors);
    }

    show(show: boolean) {
        this._store.dispatch(new DebitorSwitcherActions.Show(show));
    }

    disable() {
        this._store.dispatch(new DebitorSwitcherActions.Disable());
    }

    enable() {
        this._store.dispatch(new DebitorSwitcherActions.Enable());
    }

    initDebitorSwitcher() {
        this._store.dispatch(new DebitorSwitcherActions.InitDebitorSwitcher());
    }

    setMode(mode: DebitorSwitcherMode) {
        this._store.dispatch(new DebitorSwitcherActions.SetMode(mode));
    }

    setActiveDebitor(debitor: IActiveDebitor) {
        this._store.dispatch(new DebitorSwitcherActions.SetActiveDebitor(debitor));
    }

    setActiveDebitorById(debitorId: string) {
        this._store.dispatch(new DebitorSwitcherActions.SetActiveDebitorById(debitorId));
    }

    clearActiveDebitor() {
        this._store.dispatch(new DebitorSwitcherActions.ClearActiveDebitor());
    }

    createAllDebitorsDataSource(): DataSource<IActiveDebitor> {
        return new RepositoryDataSource(this._debitorRepository);
    }
}
