import { AlertStatusType } from '@hopsteiner/shared/components';

export interface IConfirmDialogData {
    title: string;
    message: string;
    messageArgs?: unknown;
    status?: AlertStatusType;
    cancelButtonLabel?: string;
    confirmButtonLabel?: string;
}
