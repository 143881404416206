import { ModuleWithProviders, NgModule } from '@angular/core';
import { HopAuthDomainRootModule } from '@hopsteiner/auth/domain';

import { HopHasPermissionDirective } from './directives/has-permission.directive';
import { IAuthApiModuleOptions } from './models/auth-api-module-options.interface';
import { FORBIDDEN_REDIRECT } from './tokens/forbidden-redirect.token';
import { UNAUTHORIZED_REDIRECT } from './tokens/unauthorized-redirect.token';


@NgModule({
    declarations: [
        HopHasPermissionDirective
    ],
    exports: [
        HopHasPermissionDirective
    ]
})
export class HopAuthApiModule {

    static forRoot(options: IAuthApiModuleOptions) {
        return HopAuthApiRootModule.forRoot(options);
    }
}

@NgModule({
    imports: [
        HopAuthDomainRootModule,
        HopAuthApiModule
    ],
    exports: [
        HopAuthApiModule
    ]
})
export class HopAuthApiRootModule {

    static forRoot(options: IAuthApiModuleOptions): ModuleWithProviders<HopAuthApiRootModule> {
        return {
            ngModule: HopAuthApiRootModule,
            providers: [
                { provide: UNAUTHORIZED_REDIRECT, useValue: options.redirects.unauthorized },
                { provide: FORBIDDEN_REDIRECT, useValue: options.redirects.forbidden }
            ]
        };
    }
}
