<ng-content select="caption"></ng-content>
<ng-content select="colgroup, col"></ng-content>
<ng-container headerRowOutlet></ng-container>
<div class="c-hop-data-table__body">
    <ng-container rowOutlet></ng-container>
</div>
<ng-container noDataRowOutlet></ng-container>
<ng-container footerRowOutlet></ng-container>

<div class="c-hop-data-table__empty-container" *ngIf="!isLoading && !data?.length">
    {{ loadingMessage || 'DATA_TABLE.TABLE.NO_DATA_ROW.MESSAGE' | translate }}
</div>

<hop-loading-indicator [loading]="isLoading"
                       [message]="loadingMessage"
                       [appearance]="isInfiniteScroll ? IndicatorAppearance.EDGE : IndicatorAppearance.DEFAULT">
</hop-loading-indicator>
