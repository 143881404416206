// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace UserActions {

    export class ActivateUser {
        static readonly type = '[User] Activate User';

        constructor(readonly userId: number) {
        }
    }

    export class DeactivateUser {
        static readonly type = '[User] Deactivate User';

        constructor(readonly userId: number) {
        }
    }
}
