import { animate, state, style, transition, trigger } from '@angular/animations';

const ANIMATION_TIMING = '200ms ease-in-out';

export const SLIDE_IN_ANIMATION = trigger('slideIn', [
    state('true', style({ transform: 'translate3d(0, 0, 0)' })),
    state('false', style({ transform: 'translate3d(0, 4rem, 0)', display: 'none' })),
    transition('* => true', [
        animate(ANIMATION_TIMING)
    ]),
    transition('* => false', [
        animate(ANIMATION_TIMING)
    ])
]);
