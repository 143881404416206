import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IAddress } from '../models/address.interface';

@Component({
    selector: 'hop-address',
    templateUrl: './address.component.html',
    styleUrls: [ './address.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HopAddressComponent {

    @Input()
    address?: IAddress;
}
