import { DataSource, ICollectionViewer } from '@hopsteiner/shared/collections';

export interface IDataCollectionViewer<T = unknown, QUERY = unknown> extends ICollectionViewer<T, QUERY> {
    setDataSource(dataSource?: DataSource<T, QUERY>): void;

    setQuery(query?: QUERY): void;

    initCollectionViewer(dataSource?: DataSource<T, QUERY>, query?: QUERY): void;

    firstPage(): void;

    nextPage(): void;
}
