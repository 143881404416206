import { ListRange } from '@angular/cdk/collections';

export abstract class PaginatorUtils {

    /**
     * Converts a page & page size notation to a list range
     *
     * @param page the index of the page
     * @param pageSize the number of items per page
     */
    static toListRange(page: number, pageSize: number): ListRange {
        const start = page * pageSize;

        return {
            start,
            end: start + pageSize
        };
    }
}
