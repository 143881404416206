import { Injectable } from '@angular/core';
import { DebitorSwitcherFacade, DebitorSwitcherMode, IActiveDebitor, LanguageFacade, SessionFacade } from '@hopsteiner/environment/domain';
import { Language } from '@hopsteiner/shared/models';

@Injectable({
    providedIn: 'root'
})
export class HopEnvironmentApiFacade {

    constructor(private readonly _debitorSwitcherFacade: DebitorSwitcherFacade,
                private readonly _languageFacade: LanguageFacade,
                private readonly _sessionIdFacade: SessionFacade) {

    }

    get currentLanguage() {
        return this._languageFacade.currentLanguage;
    }

    get currentLanguage$() {
        return this._languageFacade.currentLanguage$;
    }

    get isDebitorSwitcherShown$() {
        return this._debitorSwitcherFacade.isShown$;
    }

    get debitorSwitcherMode$() {
        return this._debitorSwitcherFacade.mode$;
    }

    get activeDebitor$() {
        return this._debitorSwitcherFacade.activeDebitor$;
    }

    get sessionId() {
        return this._sessionIdFacade.sessionId;
    }

    changeLanguage(language: Language) {
        return this._languageFacade.changeLanguage(language);
    }

    changeTranslationLanguage(language: Language) {
        return this._languageFacade.changeTranslationLanguage(language);
    }

    showDebitorSwitcher(show: boolean) {
        this._debitorSwitcherFacade.show(show);
    }

    setDebitorSwitcherMode(mode: DebitorSwitcherMode) {
        this._debitorSwitcherFacade.setMode(mode);
    }

    setActiveDebitor(debitor: IActiveDebitor) {
        this._debitorSwitcherFacade.setActiveDebitor(debitor);
    }

    clearActiveDebitor() {
        this._debitorSwitcherFacade.clearActiveDebitor();
    }

    setSessionId(sessionId: string) {
        return this._sessionIdFacade.setSessionId(sessionId);
    }

    initDebitorSwitcher() {
        return this._debitorSwitcherFacade.initDebitorSwitcher();
    }

    clearSessionId() {
        return this._sessionIdFacade.clearSessionId();
    }

}
