import { Injectable } from '@angular/core';
import { HopPreferencesFacade, PreferenceGroup, PreferenceGroupColumn } from '@hopsteiner/auth/domain';

@Injectable({ providedIn: 'root' })
export class HopPreferencesApiFacade {

    constructor(private readonly _preferencesFacade: HopPreferencesFacade) {

    }

    get preferences$() {
        return this._preferencesFacade.preferences$;
    }

    getPreferenceGroup(group: PreferenceGroup) {
        return this._preferencesFacade.getPreferenceGroup(group);
    }

    getBrowsePageColumns<GROUP extends PreferenceGroup>(group: GROUP) {
        return this._preferencesFacade.getBrowsePageColumns<GROUP>(group);
    }

    watchBrowsePageColumns<GROUP extends PreferenceGroup>(group: GROUP) {
        return this._preferencesFacade.watchBrowsePageColumns<GROUP>(group);
    }

    setBrowsePageColumns<GROUP extends PreferenceGroup>(group: GROUP, columns: PreferenceGroupColumn<GROUP>[]) {
        return this._preferencesFacade.setBrowsePageColumns(group, columns);
    }

}
