export type SelectableOrderRequestTableColumn =
    'createdAt'
    | 'product'
    | 'cultivar'
    | 'harvestYear'
    | 'quantity'
    | 'packaging'
    | 'deliveryDate'
    | 'comments'
    | 'proposer';
