import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseRepository, Endpoint, IPaginated } from '@hopsteiner/shared/data-access';
import { firstValueFrom } from 'rxjs';

import { ICreateOrderRequestDto } from '../entities/create-order-request.dto';
import { IOrderRequest } from '../entities/order-request.interface';

@Injectable({
    providedIn: 'root'
})
export class OrderRequestRepository extends BaseRepository<IOrderRequest> {

    constructor(httpClient: HttpClient) {
        super(httpClient, {
            endpoint: Endpoint.ORDER_REQUEST
        });
    }

    find(): Promise<IPaginated<IOrderRequest>> {
        return this._getPaginatedData();
    }

    create(createOrderRequestData: ICreateOrderRequestDto): Promise<IOrderRequest> {
        return firstValueFrom(this._httpClient.post<IOrderRequest>(`${this.url}`, createOrderRequestData));
    }
}
