import { Injectable } from '@angular/core';
import { HopUserFacade } from '@hopsteiner/user/domain';

@Injectable({
    providedIn: 'root'
})
export class HopUserApiFacade {

    constructor(private readonly _userFacade: HopUserFacade) {
    }

    sendPasswordLink(userId: number) {
        return this._userFacade.sendPasswordLink(userId);
    }

    activateUser(userId: number) {
        return this._userFacade.activateUser(userId);
    }

    deactivateUser(userId: number) {
        return this._userFacade.deactivateUser(userId);
    }

}
