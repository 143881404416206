import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseRepository, Endpoint } from '@hopsteiner/shared/data-access';
import { firstValueFrom } from 'rxjs';

import { IActiveDebitor } from '../models/active-debitor.interface';

@Injectable({
    providedIn: 'root'
})
export class ActiveDebitorRepository extends BaseRepository<IActiveDebitor> {

    constructor(httpClient: HttpClient) {
        super(httpClient, {
            endpoint: Endpoint.ACTIVE_DEBITOR
        });
    }

    setActiveDebitor(navisionId: string): Promise<IActiveDebitor[]> {
        return firstValueFrom(this._httpClient.post<IActiveDebitor[]>(this.url, { navisionId }));
    }

    async removeActiveDebitor(): Promise<void> {
        await firstValueFrom(this._httpClient.delete(this.url));
    }

    getActiveDebitor(): Promise<IActiveDebitor> {
        return firstValueFrom(this._httpClient.get<IActiveDebitor>(this.url));
    }

    getMostRecent(): Promise<IActiveDebitor[]> {
        return firstValueFrom(this._httpClient.get<IActiveDebitor[]>(`${this.url}/recents`));
    }
}
