import { Directive, Input } from '@angular/core';
import { BaseControlValueAccessorDirective } from '@hopsteiner/shared/components';
import { uniqueId as _uniqueId } from 'lodash';

import { Filter } from '../models/filter';
import { FilterType } from '../models/filter-type.enum';

@Directive()
export abstract class BaseFilterComponent<TYPE extends FilterType, VALUE> extends BaseControlValueAccessorDirective<VALUE> {

    @Input()
    filter?: Filter<VALUE> & { type: TYPE };

    @Input()
    key: string = _uniqueId('filter');

}
